import Axios from "axios";

import { assoc, compose, map, trim, split, isEmpty } from "ramda";
import { actions as AC, mutations as MU, DEAL_TYPE_AGENT } from "./constants";
import { actions as ACBorrower } from "@/store/borrower-loan/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetDealStructure = async ({ commit, rootState, dispatch }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_DEAL_STRUCTURE, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/summary/dealstructure`);

    const setDefaults = compose(
      // Per Alkesh, hard code this field to MO for monthly
      assoc("minimumInterestFrequency", "MO"),
      assoc("currency", data.currency === null ? "CAD" : data.currency)
    );
    const values = setDefaults(data);

    commit(MU.SET_INFORMATION_DEAL_STRUCTURE, values);
  } catch (err) {
    commit(MU.SET_ERROR_DEAL_STRUCTURE, logAndGetErrorMessage(AC.CALL_GET_DEAL_STRUCTURE, err));
  } finally {
    commit(MU.SET_LOADING_DEAL_STRUCTURE, false);
  }
};

const saveDealStructure = async ({ commit, rootState, dispatch }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;

  try {
    if (formData.dealType === DEAL_TYPE_AGENT) {
      formData.agentName = null;
    }

    commit(MU.SET_LOADING_DEAL_STRUCTURE, true);
    await Axios.patch(`/api/borrower/client/${clientid}/summary/dealstructure`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_DEAL_STRUCTURE);
    dispatch(ACBorrower.CALL_BORROWER);
  } catch (err) {
    commit(MU.SET_ERROR_DEAL_STRUCTURE, logAndGetErrorMessage(AC.SAVE_DEAL_STRUCTURE, err));
  } finally {
    commit(MU.SET_LOADING_DEAL_STRUCTURE, false);
  }
};

const callGetLinkBorrowers = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_LINK_BORROWERS, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/children`);

    if (!isEmpty(data)) {
      const trimLinks = compose(map(trim), split(","));
      commit(MU.SET_LIST_LINK_BORROWERS, trimLinks(data));
    } else {
      commit(MU.SET_LIST_LINK_BORROWERS, []);
    }
  } catch (err) {
    commit(MU.SET_ERROR_LINK_BORROWERS, logAndGetErrorMessage(AC.CALL_GET_LINK_BORROWERS, err));
  } finally {
    commit(MU.SET_LOADING_LINK_BORROWERS, false);
  }
};

const saveLinkBorrowers = async ({ commit, rootState }, childClientids) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_LINK_BORROWERS, true);

    await Axios.patch(`/api/borrower/client/${clientid}/children`, JSON.stringify(childClientids));
  } catch (err) {
    commit(MU.SET_ERROR_LINK_BORROWERS, logAndGetErrorMessage(AC.SAVE_LINK_BORROWER, err));
  } finally {
    commit(MU.SET_LOADING_LINK_BORROWERS, false);
  }
};

export const actions = {
  [AC.CALL_GET_DEAL_STRUCTURE]: callGetDealStructure,
  [AC.SAVE_DEAL_STRUCTURE]: saveDealStructure,
  [AC.CALL_GET_LINK_BORROWERS]: callGetLinkBorrowers,
  [AC.SAVE_LINK_BORROWER]: saveLinkBorrowers
};
