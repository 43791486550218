import Axios from "axios";
import { find, propEq, isNil } from "ramda";
import { formatDate, formatToShortDateISO } from "@/utils/index";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const DAYS_BACK = 30;
const BASE_CASH_ITEM = {
  action: "create",
  asofdate: null,
  deposit: null,
  endingbalance: null
};

const callGetOperatingCash = ({ rootState, commit }, accountNumber) =>
  new Promise(async resolve => {
    const clientid = rootState.borrower_loan.borrower.current;
    try {
      commit(MU.SET_LOADING, true);
      commit(MU.SET_ERROR_BANK_ACCOUNT, null);
      commit(MU.SET_SHOW_TOTALS, false);

      // Get data over past 30 days
      const { data } = await Axios.get(`/api/cash/client/${clientid}/${accountNumber}`, {
        params: {
          days: DAYS_BACK
        }
      });

      // Fill in line items with initial or existing data using "asofdate"
      const rows = Array.from({ length: DAYS_BACK }, (_, idx) => {
        let d = new Date();
        const asOfDate = formatToShortDateISO(d.setDate(d.getDate() - idx));

        const oc = find(propEq("asofdate", asOfDate))(data);
        if (!isNil(oc)) {
          oc.action = "update";
          oc.asofdateFormatted = formatDate(oc.asofdate);
          return oc;
        } else {
          return {
            ...BASE_CASH_ITEM,
            accountnumber: accountNumber,
            clientid: clientid,
            asofdate: asOfDate,
            asofdateFormatted: formatDate(asOfDate),
            creationdate: asOfDate
          };
        }
      });

      resolve(rows);
    } catch (err) {
      commit(MU.SET_ERROR_BANK_ACCOUNT, logAndGetErrorMessage(AC.CALL_GET_OPERATING_CASH, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const callSaveOperatingCash = async ({ commit, dispatch, rootState }, { changes }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_ERROR_BANK_ACCOUNT, null);
    await Axios.put(`/api/cash/client/${clientid}`, JSON.stringify(changes));
    dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION);
  } catch (err) {
    commit(MU.SET_ERROR_BANK_ACCOUNT, logAndGetErrorMessage(AC.CALL_SAVE_OPERATING_CASH, err));
  }
};

const callUploadFile = async ({ rootState, commit }, values) => {
  try {
    commit(MU.SET_ERROR_UPLOAD, null);
    const clientid = rootState.borrower_loan.borrower.current;

    const formData = new FormData();
    formData.append("clientid", clientid);
    formData.append("periodEnding", values.periodEnding);
    formData.append("comment", values.comment);
    formData.append("file", values.file);
    formData.append("type", "OPERATING_ACCT_CASH");
    formData.append("entityId", values.id);
    formData.append("entityName", "operating_acct_cash");
    formData.append("frequency", "Daily");
    formData.append("typeFrequency", "D");

    await Axios.post(`/api/documents/client/${clientid}/type/${type}`, formData);
  } catch (err) {
    commit(MU.SET_ERROR_UPLOAD, logAndGetErrorMessage(AC.CALL_UPLOAD_FILE, err));
  }
};

const callGetOperatingCashTotals = ({ rootState, commit }) =>
  new Promise(async resolve => {
    try {
      const clientid = rootState.borrower_loan.borrower.current;

      commit(MU.SET_SHOW_TOTALS, true);
      commit(MU.SET_INDEX, -1);
      commit(MU.SET_LOADING, true);
      commit(MU.SET_ERROR_BANK_ACCOUNT, null);

      // Get data over past 30 days
      const { data } = await Axios.get(`/api/cash/client/${clientid}`, {
        params: {
          aggregated: true,
          days: DAYS_BACK
        }
      });

      // Fill in line items with initial or existing data using "asofdate"
      const rows = Array.from({ length: DAYS_BACK }, (_, idx) => {
        let d = new Date();
        const asOfDate = formatToShortDateISO(d.setDate(d.getDate() - idx));

        const oc = find(propEq("asofdate", asOfDate))(data);
        if (!isNil(oc)) {
          // oc.action = "update";
          oc.asofdateFormatted = formatDate(oc.asofdate);
          return oc;
        } else {
          return {
            ...BASE_CASH_ITEM,
            asofdate: asOfDate,
            asofdateFormatted: formatDate(asOfDate),
            creationdate: asOfDate
          };
        }
      });

      resolve(rows);
    } catch (err) {
      commit(MU.SET_ERROR_BANK_ACCOUNT, logAndGetErrorMessage(AC.CALL_GET_OPERATING_CASH, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }

    resolve([]);
  });

export const actions = {
  [AC.CALL_GET_OPERATING_CASH]: callGetOperatingCash,
  [AC.CALL_SAVE_OPERATING_CASH]: callSaveOperatingCash,
  [AC.CALL_UPLOAD_FILE]: callUploadFile,
  [AC.CALL_GET_OPERATING_CASH_TOTALS]: callGetOperatingCashTotals
};
