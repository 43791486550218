import Axios from "axios";
import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetCategories = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/api/admin/naics/categories");
    commit(MU.SET_CATEGORIES, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_CATEGORIES, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_CATEGORIES]: callGetCategories
};
