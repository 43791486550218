import { appendName } from "../utils";

const append = appendName("OPERATING_CASH");

export const actions = {
  CALL_GET_OPERATING_CASH: append("CALL_GET_OPERATING_CASH"),
  CALL_GET_OPERATING_CASH_TOTALS: append("CALL_GET_OPERATING_CASH_TOTALS"),
  CALL_SAVE_OPERATING_CASH: append("CALL_SAVE_OPERATING_CASH"),
  CALL_UPLOAD_FILE: append("CALL_UPLOAD_FILE")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR_BANK_ACCOUNT: append("SET_ERROR_BANK_ACCOUNT"),
  SET_ERROR_UPLOAD: append("SET_ERROR_UPLOAD"),
  SET_INDEX: append("SET_INDEX"),
  SET_SHOW_TOTALS: append("SET_SHOW_TOTALS")
};
