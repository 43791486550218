import { actions } from "./actions";
import { mutations } from "./mutations";

export const GeneralLedgerEntryModule = {
  state: {
    loading: { ledgerEntry: false, loans: false },
    error: { ledgerEntry: null, loans: null },
    list: []
  },
  actions,
  mutations,
  getters: {}
};
