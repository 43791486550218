import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_BANK_ACCOUNT]: function(state, loading) {
    Vue.set(state.bankAccount.loading, "bank", loading);
  },
  [MU.SET_ERROR_BANK_ACCOUNT]: function(state, error) {
    Vue.set(state.bankAccount.error, "bank", error);
  },
  [MU.SET_LIST_BANK_ACCOUNT]: function(state, list) {
    Vue.set(state.bankAccount.list, "bank", list);
  },
  [MU.SET_LOADING_DISBURSEMENT_ACCOUNT]: function(state, loading) {
    Vue.set(state.bankAccount.loading, "disbursement", loading);
  },
  [MU.SET_ERROR_DISBURSEMENT_ACCOUNT]: function(state, error) {
    Vue.set(state.bankAccount.error, "disbursement", error);
  },
  [MU.SET_LIST_DISBURSEMENT_ACCOUNT]: function(state, list) {
    Vue.set(state.bankAccount.list, "disbursement", list);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "bankAccount", clone(DEFAULT_STATE));
  }
};
