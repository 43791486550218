import { appendName } from "../utils";

const append = appendName("CODES");

export const actions = {
  CALL_GET_CODES: append("CALL_GET_CODES"),
  CALL_CREATE_NEW_HOLD_CODE: append("CALL_CREATE_NEW_HOLD_CODE"),
  CALL_EDIT_HOLD_CODE: append("CALL_EDIT_HOLD_CODE"),
  CALL_SAVE_DAYS_OVER_COLUMNS: append("CALL_SAVE_DAYS_OVER_COLUMNS"),
  CALL_SAVE_INELIGIBLE_DEFAULTS: append("CALL_SAVE_INELIGIBLE_DEFAULTS"),
  CALL_SAVE_CATEGORY: append("CALL_SAVE_CATEGORY"),
  CALL_SAVE_CODE: append("CALL_SAVE_CODE")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_CATEGORY_CODES: append("SET_CATEGORY_CODES"),
  SET_CATEGORY: append("SET_CATEGORY"),
  UPDATED_CATEGORY: append("UPDATED_CATEGORY")
};

export const getters = {
  GET_AGING_SPREADS_BY_DAY: append("GET_AGING_SPREADS_BY_DAY"),
  GET_INELIGIBLE_DEFAULTS_BY_NAME: append("GET_INELIGIBLE_DEFAULTS_BY_NAME"),
  GET_INELIGIBLE_CODES_WITHOUT_OV_XA: append("GET_INELIGIBLE_CODES_WITHOUT_OV_XA")
};

export const REQUIRED_CODES = [
  "INELIGIBLE_DEFAULTS",
  "INELIGIBLE_CODES",
  "INVOICE_INELIGIBLE_CODES",
  "INVOICE_VERIFICATION_FREQUENCY",
  "AGING_SPREADS",
  "PARTICIPATION_CODE",
  "INVENTORY_APPRAISAL_VALUE_TYPES",
  "M_AND_E_APPRAISAL_VALUE_TYPES",
  "REAL_ESTATE_APPRAISAL_VALUE_TYPES",
  "BANK_ACCOUNT_FUNCTION",
  "DOMINION",
  "COVENANT_TYPES",
  "ANNUAL_STATEMENT_TYPES",
  "RISC_CODES",
  "WORKLOAD",
  "DEAL_TYPES",
  "BUSINESS_ENTITY",
  "REGION_CODES",
  "INTELLECTUAL_PROPERTY_APPRAISAL_VALUE_TYPES",
  "INSURANCE",
  "TRANSACTION_CODES",
  "INVENTORY_CODES",
  "COVENANT_TEST_FREQUENCY",
  "COVENANT_TEST_PERIOD",
  "SECURITY_TYPES",
  "LOAN_TYPE",
  "GL_TYPE",
  "LOAN_DOCUMENT_TYPE",
  "INELIGIBLE_DATE_CRITERIA",
  "NAICS",
  "WF_WATERFALL"
];

export const MODAL_ACTION = {
  NEW: "new",
  EDIT: "edit"
};
