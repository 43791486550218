import { actions } from "./actions";
import { mutations } from "./mutations";

export const RollforwardModule = {
  state: {
    loading: false,
    error: null,
    list: [],
    loanid: null,
    hasChanges: false,
    changes: {
      news: [], // store id from form group
      edits: [], // store id from form group
      deletes: [] // store id from form group
    }
  },
  actions,
  mutations,
  getters: {}
};
