import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetConfigProperties = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_GRID, true);
    const { data } = await Axios.get("/api/configuration/property");
    commit(MU.SET_GRID_DATA, data);
  } catch (err) {
    commit(MU.SET_ERROR_GRID, logAndGetErrorMessage(AC.CALL_GET_CONFIG_PROPERTIES, err));
  } finally {
    commit(MU.SET_LOADING_GRID, false);
  }
};

const callGetConfigProperty = ({}, configProperty) =>
  new Promise(async resolve => {
    try {
      const { data } = await Axios.get(`/api/configuration/property/${configProperty}`);
      resolve(data);
    } catch (err) {
      resolve([]);
    }
  });

const createConfigProperty = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.post("/api/configuration/property", formData);
    dispatch(AC.CALL_GET_CONFIG_PROPERTIES);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.CREATE_CONFIG_PROPERTY, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const updateConfigProperty = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.put(`/api/configuration/property/${formData.id}`, formData);
    dispatch(AC.CALL_GET_CONFIG_PROPERTIES);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.UPDATE_CONFIG_PROPERTY, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

export const actions = {
  [AC.CALL_GET_CONFIG_PROPERTIES]: callGetConfigProperties,
  [AC.CALL_GET_CONFIG_PROPERTY]: callGetConfigProperty,
  [AC.CREATE_CONFIG_PROPERTY]: createConfigProperty,
  [AC.UPDATE_CONFIG_PROPERTY]: updateConfigProperty
};
