import Axios from "axios";
import { compose, omit, equals, prop, isEmpty } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { concatArrays } from "@/utils/index";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetNavigationInformation = async ({ commit, dispatch, rootState }) => {
  try {
    commit(MU.SET_LOADING_NAVIGATION, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    const responses = await Promise.allSettled([
      Axios.get(`/api/ineligibles/buckets/codes/loan/${loan.id}`, {
        params: {
          includeAll: true
        }
      }),
      dispatch(AC.CALL_GET_ADJUSTMENT_INFORMATION)
    ]);

    const hasError = responses.find(r => r.status === "rejected");
    if (hasError || isEmpty(responses) || responses.length < 2) {
      commit(
        MU.SET_ERROR_NAVIGATION,
        logAndGetErrorMessage(
          AC.CALL_GET_NAVIGATION_INFORMATION,
          "There was an error retrieving Ineligible information."
        )
      );
    } else {
      commit(MU.SET_NAVIGATION_LIST, responses[0].value.data);
      const manualAdjustmentsTotal = !isEmpty(responses[1].value)
        ? responses[1].value.reduce((acc, i) => acc + i.amount, 0)
        : 0;
      commit(MU.SET_MANUAL_ADJUSTMENTS_TOTAL, manualAdjustmentsTotal);
    }
  } catch (err) {
    commit(MU.SET_ERROR_NAVIGATION, logAndGetErrorMessage(AC.CALL_GET_NAVIGATION_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_NAVIGATION, false);
  }
};

const callGetDebtorInformation = async ({ commit, rootState }, code) => {
  try {
    commit(MU.SET_LOADING_DEBTOR, true);
    commit(MU.SET_CURRENT_CODE, code);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const { data } = await Axios.get(`api/ineligibles/buckets/codes/loan/${loan.id}/${code}`);

    commit(MU.SET_DEBTOR_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR_DEBTOR, logAndGetErrorMessage(AC.CALL_GET_DEBTOR_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_DEBTOR, false);
  }
};

const callGetAdjustmentInformation = ({ commit, rootState }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_ADJUSTMENT, true);

      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];
      const { data } = await Axios.get(`/api/ineligibles/adjustments/manual/loan/${loan.id}`);

      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR_ADJUSTMENT, logAndGetErrorMessage(AC.CALL_GET_ADJUSTMENT_INFORMATION, err));
    } finally {
      commit(MU.SET_LOADING_ADJUSTMENT, false);
    }
    resolve([]);
  });

const callSaveAllChanges = async ({ commit, dispatch, rootState, state }, formData) => {
  try {
    // normalize the object before sending it to the API
    const normalizeItem = (action, omitProps, loanid) => item => ({
      ...omit(omitProps, item),
      action,
      loanid
    });

    // find values by Ids in the formData
    const findInFormValues = id => formData.find(compose(equals(id), prop("id")));

    // get the item for the specific action, remove/ommit some props
    const getItem = (action, omitProps, loanid) => compose(normalizeItem(action, omitProps, loanid), findInFormValues);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    // get the items for POST
    const getPostItems = getItem("create", ["id"], loan.id);
    // get the items for PUT
    const getPutItems = getItem("update", [""], loan.id);

    const { news, edits, deletes } = state.changes;
    const changes = concatArrays([
      news.map(getPostItems),
      edits.map(getPutItems),
      // map items for DELETE
      deletes.map(id => ({ id, loanid: loan.id, action: "delete" }))
    ]);
    //@krojas until here

    await Axios.put(`/api/ineligibles/adjustments/manual/loan/${loan.id}`, JSON.stringify(changes));
    commit(MU.CLEAN_ALL_CHANGES);

    // call back navigation info, to get the updated totals
    dispatch(AC.CALL_GET_NAVIGATION_INFORMATION);
  } catch (err) {
    commit(MU.SET_ERROR_ADJUSTMENT, logAndGetErrorMessage(AC.CALL_SAVE_ALL_CHANGES, err));
  } finally {
    commit(MU.SET_LOADING_ADJUSTMENT, false);
  }
};

const callGetMostRecentPeriod = ({ commit, rootState }) =>
  new Promise(async resolve => {
    try {
      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];
      const { data } = await Axios.get(`/api/loan/${loan.id}/ar/dates/max`);

      resolve(data);
    } catch (err) {
      logAndGetErrorMessage(AC.CALL_GET_ADJUSTMENT_INFORMATION, err);
    }
    resolve([]);
  });

export const actions = {
  [AC.CALL_GET_NAVIGATION_INFORMATION]: callGetNavigationInformation,
  [AC.CALL_GET_DEBTOR_INFORMATION]: callGetDebtorInformation,
  [AC.CALL_SAVE_ALL_CHANGES]: callSaveAllChanges,
  [AC.CALL_GET_ADJUSTMENT_INFORMATION]: callGetAdjustmentInformation,
  [AC.CALL_GET_MOST_RECENT_PERIOD]: callGetMostRecentPeriod
};
