import Axios from "axios";
import {
  clone,
  isEmpty,
  assoc,
  propEq,
  find,
  map,
  omit,
  prop,
  slice,
  sortWith,
  ascend,
  startsWith,
  replace
} from "ramda";
import {
  actions as AC,
  mutations as MU,
  REPORTS,
  BORROWER_SEGMENTS,
  FORMAT_TYPES,
  USER_PREFIX,
  REPORT_ACTIONS
} from "./constants";
import { actions as ACComment } from "@/store/comment/constants";
import { getters as GEBorrowerLoan } from "@/store/borrower-loan/constants";

import { logAndGetErrorMessage } from "@/store/utils";
import { formatDateParams, isNilOrEmpty } from "@/utils/index";

const getReport = async ({ state, rootState, commit }) => {
  // Don't mutate report criteria
  let values = clone(state.reportCriteria);

  try {
    commit(MU.SET_LOADING, true);
    commit(MU.SET_REPORT, {});
    commit(MU.SET_ERROR, null);

    let clientid = rootState.borrower_loan.borrower.current;
    const report = values.name;
    const action = values.action;
    const type = values.type;

    // Normalize data for back-end
    switch (report) {
      case REPORTS.LOAN_LEDGER:
        if (values.clientid === BORROWER_SEGMENTS.ALL) clientid = BORROWER_SEGMENTS.ALL;

        // API needs date/time string
        if (!/:/.test(values.fromdate)) values.fromdate += " 00:00:00";
        if (!/:/.test(values.todate)) values.todate += " 23:59:59";
        break;
      case REPORTS.MONTHLY_INTEREST_STATEMENT:
        values.clientids = values.clientids === BORROWER_SEGMENTS.ALL ? [] : [clientid];
        values.viewParticipationLoans = "Y";

        // Set todate to be the last day of the month (local time)
        const fromDate = new Date(values.fromdate + "T00:00:00");
        const lastDayOfMonth = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
        values.todate = formatDateParams(lastDayOfMonth);

        // Set the period to be todate so can show in report
        values.period = values.todate;
        break;
      case REPORTS.CLIENT_LOAN_STATUS:
        values = assoc("businessStatus", values, {});
        break;
      case REPORTS.FTA:
        // Make sure dates are in ISO format for submission
        const formatDate = ds => (typeof ds === "string" && /\d{2}\/\d{2}\/\d{4}/.test(ds) ? formatDateParams(ds) : ds);
        values = map(formatDate, state.reportCriteria);
        break;
      case REPORTS.DAILY_LOAN:
        // needed to save note with clientid user is in when running report
        values.sourceClientId = clientid;

        if (values.reportBy === BORROWER_SEGMENTS.ALL) clientid = BORROWER_SEGMENTS.ALL;

        // Need to always pass in a value for the fields for BE to use reflection to call proper reporting method
        values.userid = startsWith(USER_PREFIX, values.reportBy) ? replace(USER_PREFIX, "", values.reportBy) : "";
        break;
      case REPORTS.HEALTHCARE_DAILY_LOAN:
        // needed to save note with clientid user is in when running report
        values.sourceClientId = clientid;
        if (values.clientid === BORROWER_SEGMENTS.ALL) clientid = BORROWER_SEGMENTS.ALL;
        break;
      case REPORTS.HCTICKLER:
        clientid = values.clientid === BORROWER_SEGMENTS.ALL ? BORROWER_SEGMENTS.ALL : values.clientid;

        // Convert from string to number as BE expects Integer
        values.dueDays = isNilOrEmpty(values.dueDays) ? 0 : Number(values.dueDays);
        break;
      case REPORTS.CSR3:
        values = assoc("trendMap", omit(["lastupdated"], values), {});
        break;
      case REPORTS.SHIFT_ANALYSIS:
        values = assoc("params", values, {});
        break;
      case REPORTS.GENERAL_LEDGER:
        values.isSage = action === REPORT_ACTIONS.DOWNLOAD_SAGE;
        break;
      case REPORTS.INTEREST_AND_PROOF:
        if (values.clientid === BORROWER_SEGMENTS.ALL) clientid = BORROWER_SEGMENTS.ALL;
        break;
      case REPORTS.MODELTREND:
        // BE needs a value to do reflection properly as passing in null doesn't provide the data type to BE
        if (!values.commentary) values.commentary = "";
        break;
    }

    const formData = assoc("clientid", clientid, values);
    if (type === FORMAT_TYPES.HTML) {
      const { data } = await Axios.post(`/api/report/${report}/${type}`, formData);
      commit(MU.SET_REPORT, { action, type, data });
    } else {
      const response = await Axios.post(`/api/report/${report}/${type}`, formData, { responseType: "blob" });
      const blob = response.data;
      if (blob.size > 0) {
        // Get filename from content-disposition header if set by server
        let filename = `report.${type}`;
        const header = response.headers["content-disposition"];
        const regexp = /^.*?filename="([^\"]+)"$/;
        if (header && regexp.test(header)) {
          filename = header.replace(regexp, "$1");
        }

        // Clear previous report and release resources (blob)
        if (!isEmpty(state.report)) {
          clearReport({ state, commit });
        }

        const href = window.URL.createObjectURL(blob);
        commit(MU.SET_REPORT, { action, type, filename, href });
      } else {
        commit(MU.SET_ERROR, "There is no data to report.  Please try different criteria.");
        commit(MU.SET_REPORT, {});
      }
    }
    commit(MU.SET_REPORT_CRITERIA_CHANGED, false);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_REPORT, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const getAPAgingDetailDateOptions = ({ commit }, loanId) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get(`/api/loan/${loanId}/ap/dates`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_AP_AGING_DETAIL_DATE_OPTIONS, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const getClientLoanStatusBusinessStatus = ({ commit, rootState }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const clientid = rootState.borrower_loan.borrower.current;
      const { data } = await Axios.get(`/api/reports/name/status/client/${clientid}`);

      // Limit action plans to most recent 7 entries
      // and create 7 slots for entries as necessary
      if (data.actionPlans) {
        const sortAsc = ascend(prop("statusDate"));
        const sort = sortWith([sortAsc]);
        const ap = slice(0, 7, sort(data.actionPlans));

        for (let i = ap.length; i < 7; i++) {
          ap.push({ clientid: clientid, id: null, item: "", status: "", statusDate: "" });
        }
        data.actionPlans = ap;
      }

      commit(MU.SET_REPORT_CRITERIA, data);

      resolve(omit(["actionPlans"], data));
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_CLIENT_LOAN_STATUS_BUSINESS_STATUS, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const getARAgingDetailDateOptions = ({ commit }, loanId) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get(`/api/loan/${loanId}/ar/dates`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_AR_AGING_DETAIL_DATE_OPTIONS, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const getFtaInfo = ({ commit, rootState }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const clientid = rootState.borrower_loan.borrower.current;
      const { data } = await Axios.get(`/api/client/${clientid}/fta/info`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_FTA_INFO, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const getHCTickerFields = ({ commit }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get(`/api/healthcare/tickler/fields`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_HC_TICKLER_FIELDS, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const getCSR3Information = ({ commit, rootState }) =>
  new Promise(async resolve => {
    try {
      const clientid = rootState.borrower_loan.borrower.current;
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get(`/api/reports/name/CSR3/client/${clientid}`);
      commit(MU.SET_REPORT_CRITERIA, data);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_CSR3_INFORMATION, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const getDailyLoanInformation = async ({ commit, dispatch, getters }) => {
  try {
    commit(MU.SET_LOADING, true);
    const defaultLoanId = getters[GEBorrowerLoan.GET_CURRENT_BORROWER_VALUE]("defaultLoanToCharge");
    const data = await dispatch(ACComment.CALL_GET_LATEST_NOTE, defaultLoanId);
    if (data) {
      commit(MU.UPDATE_REPORT_CRITERIA, {
        comment: data.comment ?? "",
        commentDate: data.lastModifiedDate
      });
    }
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_DAILY_LOAN_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const getHealthcareDailyLoanInformation = async ({ commit, dispatch }) => {
  try {
    commit(MU.SET_LOADING, true);
    const data = await dispatch(ACComment.CALL_GET_HEALTHCARE_COMMENTS);
    if (data) {
      commit(MU.UPDATE_REPORT_CRITERIA, {
        comment: data.comments ?? "",
        commentDate: data.lastupdated
      });
    }
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_HEALTHCARE_DAILY_LOAN_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const getIneligibleslDateOptions = ({ commit }, loanId) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get(`/api/ineligibles/loan/${loanId}/dates`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_AP_AGING_DETAIL_DATE_OPTIONS, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const clearReport = async ({ state, commit }) => {
  if (!isEmpty(state.report)) {
    // Release existing object URL if previously created
    window.URL.revokeObjectURL(state.report.href);
    commit(MU.SET_REPORT, {});
  }
  commit(MU.SET_ERROR, null);
  commit(MU.SET_REPORT_DISABLED, false);
};

const getTrendCardCommentary = ({ commit, rootState }) =>
  new Promise(async resolve => {
    try {
      const clientid = rootState.borrower_loan.borrower.current;
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get(`/api/reports/trend-card-commentary/client/${clientid}`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.GET_TREND_CARD_COMMENTARY, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

// GAK: not currently used but might use it in the future
// Submit form using browser so PDFs can be viewed inline
const submitForm = (method, target, action, headers, params) => {
  let form = document.createElement("form");
  form.setAttribute("method", method);
  form.setAttribute("target", target);
  form.setAttribute("action", action);

  // Add "_csrf" token if present
  const _csrf = find(propEq("header", "X-CSRF-TOKEN"), headers);
  if (_csrf) {
    addHiddenField(form, "_csrf", _csrf.value);
  }

  for (const key in params) {
    addHiddenField(form, key, params[key]);
  }

  document.body.appendChild(form);
  form.submit();
};

// GAK: not currently used but might use it in the future
// Submit form using browser so PDFs can be viewed inline
const addHiddenField = (form, name, value) => {
  let hf = document.createElement("input");
  hf.setAttribute("type", "hidden");
  hf.setAttribute("name", name);
  hf.setAttribute("value", value);
  form.appendChild(hf);
};

export const actions = {
  [AC.GET_REPORT]: getReport,
  [AC.GET_AP_AGING_DETAIL_DATE_OPTIONS]: getAPAgingDetailDateOptions,
  [AC.GET_AR_AGING_DETAIL_DATE_OPTIONS]: getARAgingDetailDateOptions,
  [AC.GET_CLIENT_LOAN_STATUS_BUSINESS_STATUS]: getClientLoanStatusBusinessStatus,
  [AC.GET_FTA_INFO]: getFtaInfo,
  [AC.GET_HC_TICKLER_FIELDS]: getHCTickerFields,
  [AC.GET_CSR3_INFORMATION]: getCSR3Information,
  [AC.GET_DAILY_LOAN_INFORMATION]: getDailyLoanInformation,
  [AC.GET_HEALTHCARE_DAILY_LOAN_INFORMATION]: getHealthcareDailyLoanInformation,
  [AC.GET_INELIGIBLES_DATE_OPTIONS]: getIneligibleslDateOptions,
  [AC.GET_TREND_CARD_COMMENTARY]: getTrendCardCommentary,
  [AC.CLEAR_REPORT]: clearReport
};
