import { appendName } from "../utils";

const append = appendName("NAICS");

export const actions = {
  CALL_GET_CATEGORIES: append("CALL_GET_CATEGORIES")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_CATEGORIES: append("SET_CATEGORIES")
};
