import { appendName } from "@/store/utils";

export const mutations = {
  SET_IS_ACTIVE_BORROWER: appendName("BORROWER_DETAIL")("SET_IS_ACTIVE_BORROWER"),
  SET_CREATE_MODE: appendName("BORROWER_DETAIL")("SET_CREATE_MODE")
};

export const BORROWER_TYPES = {
  SIENA: "siena",
  HEALTHCARE: "healthcare",
  CANADA: "canada"
};

export const BORROWER_TYPE_OPTIONS = {
  [BORROWER_TYPES.SIENA]: "Siena",
  [BORROWER_TYPES.HEALTHCARE]: "Healthcare",
  [BORROWER_TYPES.CANADA]: "Canada"
};

export const BORROWER_MODE = {
  CREATE: "create",
  EDIT: "edit"
};

export const MODAL_HELPER_ACTION = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  VIEW: "view"
};

export const APPEND_BORROWER_DETAIL_MODULE_NAME = appendName("BORROWER_DETAIL");
