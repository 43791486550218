import { actions } from "./actions";
import { mutations } from "./mutations";

export const CollateralReserveModule = {
  state: {
    loading: false,
    error: null,
    list: [],
    loanid: null,
    categoryCodes: [],
    changes: {
      news: [], // store id from form group
      edits: [], // store id from form group
      deletes: [] // store id from form group
    }
  },
  actions,
  mutations,
  getters: {}
};
