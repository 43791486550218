import Vue from "vue";
import { mergeAll } from "ramda";

import { mutations as MU } from "./constants";

//> Inner Modules
import { GeneralInfoModule } from "./general-info";
import { BankAccountModule } from "./bank-account";
import { AppraisalModule } from "./appraisal";
import { FieldExamModule } from "./field-exam";
import { CovenantModule } from "./covenant";
import { MonitoringModule } from "./monitoring";
import { OtherModule } from "./other";
import { SubordinatorGuarantorModule } from "./subordinator-guarantor";
import { DealStructureModule } from "./deal-structure";
import { YieldFeeModule } from "./yield-fee";
import { ContactInformationModule } from "./contact-information";
import { HealthcareModule } from "./healthcare";
import { StatusModule } from "./status";

export const BorrowerModule = {
  state: {
    isActive: true,
    createMode: false,
    generalInfo: GeneralInfoModule.state,
    bankAccount: BankAccountModule.state,
    appraisal: AppraisalModule.state,
    fieldExam: FieldExamModule.state,
    covenant: CovenantModule.state,
    monitoring: MonitoringModule.state,
    other: OtherModule.state,
    subordinatorGuarantor: SubordinatorGuarantorModule.state,
    dealStructure: DealStructureModule.state,
    yieldFee: YieldFeeModule.state,
    contact: ContactInformationModule.state,
    healthcare: HealthcareModule.state,
    status: StatusModule.state
  },
  actions: mergeAll([
    GeneralInfoModule.actions,
    BankAccountModule.actions,
    AppraisalModule.actions,
    FieldExamModule.actions,
    CovenantModule.actions,
    MonitoringModule.actions,
    OtherModule.actions,
    SubordinatorGuarantorModule.actions,
    DealStructureModule.actions,
    YieldFeeModule.actions,
    ContactInformationModule.actions,
    HealthcareModule.actions,
    StatusModule.actions
  ]),
  mutations: mergeAll([
    {
      [MU.SET_IS_ACTIVE_BORROWER]: function(state, value) {
        Vue.set(state, "isActive", value);
      },
      [MU.SET_CREATE_MODE]: function(state, value) {
        Vue.set(state, "createMode", value);
      }
    },
    GeneralInfoModule.mutations,
    BankAccountModule.mutations,
    AppraisalModule.mutations,
    FieldExamModule.mutations,
    CovenantModule.mutations,
    MonitoringModule.mutations,
    OtherModule.mutations,
    SubordinatorGuarantorModule.mutations,
    DealStructureModule.mutations,
    YieldFeeModule.mutations,
    ContactInformationModule.mutations,
    HealthcareModule.mutations,
    StatusModule.mutations
  ]),
  getters: mergeAll([
    GeneralInfoModule.getters,
    BankAccountModule.getters,
    AppraisalModule.getters,
    FieldExamModule.getters,
    CovenantModule.getters,
    MonitoringModule.getters,
    OtherModule.getters,
    SubordinatorGuarantorModule.getters,
    DealStructureModule.getters,
    YieldFeeModule.getters,
    ContactInformationModule.getters,
    HealthcareModule.getters,
    StatusModule.getters
  ])
};
