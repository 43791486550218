import {
  sortBy,
  prop,
  toLower,
  compose,
  omit,
  replace,
  toUpper,
  keys,
  reduce,
  concat,
  join,
  props,
  map,
  zipObj,
  isNil,
  isEmpty,
  or
} from "ramda";
import formatDateFns from "date-fns/format";
import parseISODate from "date-fns/parseISO";

// Default Number format
const numberFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencySign: "accounting",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

// Number format - 2 decimal places
const numberFormatWithDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  currencySign: "accounting",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

// Default currency format - 2 decimal places
const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

// Currency with no decimals
const currencyFormatNoDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

// Percentage with 2 decimals
const percentageFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 5
});

const percentageFiveDecimalsFormat = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 5
});

const percentageFormatNoDecimals = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export const number = (value, useDecimals = false) =>
  useDecimals
    ? numberFormatWithDecimals.format(value || 0).replace(/\$/, "")
    : numberFormat.format(value || 0).replace(/\$/, "");

export const currency = (value, useDecimals = true) =>
  useDecimals ? currencyFormat.format(value || 0) : currencyFormatNoDecimals.format(value || 0);

export const percentage = (value, useDecimals = true, fiveDecimals = false) => {
  if (!useDecimals) return percentageFormatNoDecimals.format(value / 100);

  if (!fiveDecimals) percentageFormat.format(value / 100);

  return percentageFiveDecimalsFormat.format(value / 100);
};

/**
 * @param value Number
 * @param isPercentage Boolean, default value is false
 * @returns Could be currency if `isPercentage` is set to `false` otherwise will be a percentage format
 */
export const formatNumber = (value, isPercentage = false) => {
  if (isPercentage) return `${value || 0}%`;
  return currencyFormat.format(value || 0);
};

/**
 * @param data Array
 * @param property String
 * @returns Sorted array according to the property
 */
export const sortArray = (data, property) => {
  if ((data || []).length === 0) return [];
  const f = data[0][property];
  if (typeof f === "string") {
    return sortBy(compose(toLower, prop(property)))(data);
  }
  return sortBy(prop(property))(data);
};

// (String|[String] -> {}) -> {}
export const removeKeys = (k, o) => omit(typeof k === "string" ? [k] : k, o);

export const capitalize = compose(replace(/^./, toUpper), toLower);

export const sleep = ms => new Promise(r => setTimeout(r, ms));

// N -> N
export const concatValues = (v, d = "") => reduce(concat, d)(v);

// [Array] -> Array
export const concatArrays = a => reduce(concat, [])(a);

export const hasProps = o => keys(o).length > 0;

// (String -> [{}]) -> {}
export const arrayToObjectByKey = (key, arr) => arr.reduce((acc, current) => ({ ...acc, [current[key]]: current }), {});

export const formatAgGridDate = ({ value }) => (value ? formatDateFns(new Date(value), "MM/dd/yyyy hh:mm a") : null);

export const formatDate = (d, format = "MM/dd/yyyy") => {
  if (isNil(d)) return null;
  if (typeof d === "string") return formatDateFns(parseISODate(d), format);
  return formatDateFns(d, format);
};

export const formatDateParams = d => {
  if (!d) return null;
  const date = new Date(d);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
};

export const timeFromDate = d => {
  const date = new Date(d);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + " " + ampm;
};

export const formatPlainDate = d => (d ? new Date(`${d} 00:00:00`).toLocaleDateString() : null);

export const formatToShortDateISO = (d = new Date()) => (d ? new Date(d).toISOString().split("T")[0] : null);

export const joinProps = (ps, j = " ") => compose(join(j), props(ps));

// create UniqId
export const uniqueId = () => crypto.randomUUID();

/**
 * Map the specific values to create the options for a drop down, `ps` have to follow the first label then value order
 * @param {Array} ps  array that contains the properties to map from the array
 * @param {data} d array with the data
 * @returns Array of options for a drop down
 */
export const mapValuesForOptions = (ps, d, extraProps = []) =>
  d ? map(compose(zipObj(concat(["label", "value"], extraProps)), props(concat(ps, extraProps))), d) : [];

export const isNilOrEmpty = x => or(isNil(x), isEmpty(x));

/**
 * Create ellipsis in the middle of the string if its length is more than max
 * @param {String} s string to check if should transform with ellipsis
 * @param {Integer} max check if string is greater than this value to transform string
 */
export const ellipsis = (s, max) =>
  !isNilOrEmpty(s) && s.length > max ? s.slice(0, max / 2 - 2) + "..." + s.slice((-1 * max) / 2 + 2) : s;
