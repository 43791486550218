import Axios from "axios";
import { forEach, prop, compose, assoc, head, equals, isNil } from "ramda";

import { actions as AC, mutations as MU, USER_ACTION, LOGIN_ERROR_CODES } from "./constants";
import { actions as ACRoot, NOTIFICATION_TYPE } from "@/store/root/constants";

import { actions as ACG } from "@/store/borrower/general-info/constants";
import { actions as ACCI } from "@/store/borrower/contact-information/constants";

import { clearCustomHeader } from "@/config/axios";
import { SessionStorage } from "@/config/sessionStorage";
import { removeKeys } from "@/utils/index";
import { logAndGetErrorMessage } from "@/store/utils";

//NOTE: this does not handle loading/error state because if the request fails
// the API will return an 302 status to redirect to a page
// because user information is need it to navigate through the app
const callGetUserInformation = async ({ commit, rootState }) => {
  try {
    const { data } = await Axios.get("/api/user");
    commit(MU.SET_PROFILE, data);
    commit(MU.SET_LOGGED_USER_IS_SIENA, data.clientid === rootState.root.clientIdSiena);
  } catch (err) {
    logAndGetErrorMessage(AC.CALL_GET_USER_INFORMATION, err);
  }
};

// NOTE: this does not handle loading state because the user
// may get redirected to another page based on the response
const callLogin = async ({ commit }, values) => {
  try {
    commit(MU.SET_LOGIN_ERROR_CODE, null);
    commit(MU.SET_ERROR_PASSWORD, null);

    let formData = new FormData();
    formData.append("username", values.username);
    formData.append("password", values.password);

    const { data } = await Axios.post(`/login`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  } catch (err) {
    logAndGetErrorMessage(AC.CALL_LOGIN, err);

    // Set error code for login flow and simpler error message
    commit(MU.SET_LOGIN_ERROR_CODE, err?.response?.data?.code || LOGIN_ERROR_CODES.invalid);
    commit(MU.SET_ERROR_PASSWORD, err?.response?.data?.message || "Inavlid username or password.");
  }
};

const callLogout = async (_, { headers, doLogoutRequest = true }) => {
  if (doLogoutRequest) await Axios.post("/logout", {});
  forEach(compose(clearCustomHeader, prop("header")), headers);
  SessionStorage.clearAll();
};

const createUser = async (user, clientid) => {
  const userToCreate = removeKeys(["group", "password_confirm", "password_confirm"], user);
  const data = {
    user: {
      password: user.password
    },
    profile: assoc("status", "active", userToCreate)
  };
  await Axios.post(`/api/auth/${clientid}/user/${user.username}/group/${user.group}`, data);
};

const updateUser = async ({ state }, user) => {
  await Axios.put(`/api/auth/user/${user.username}`, removeKeys("group", user));
  if (!isNil(user.password)) {
    const body = {
      newPassword: user.password,
      newPasswordConfirmation: user.password_confirm
    };
    await Axios.put(`/api/auth/user/${user.username}/password`, body);
  }

  const previousUserData = state.list.user.find(u => u.profile.username === user.username);
  if (previousUserData) {
    const previousGroup = head(previousUserData.groups);
    if (!equals(user.group, previousGroup)) {
      await Axios.put(`/api/auth/group/switch/user/${user.username}/old/${previousGroup}/new/${user.group}`);
    }
  }
};

const callGetUsers = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING_LIST, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/authorities/users/client/${clientid}`);
    commit(MU.SET_LIST_USER, data);
  } catch (err) {
    commit(MU.SET_ERROR_LIST, logAndGetErrorMessage(AC.CALL_GET_USERS, err));
  } finally {
    commit(MU.SET_LOADING_LIST, false);
  }
};

const callGetSienaUsers = async ({ commit, state, rootState }) => {
  try {
    commit(MU.SET_LOADING_SIENA_LIST, true);
    const { data } = await Axios.get(`/api/authorities/users/client/${rootState.root.clientIdSiena}`);
    commit(
      MU.SET_LIST_USER_SIENA,
      data.filter(d => d.profile.status === "active")
    );
  } catch (err) {
    logAndGetErrorMessage(AC.CALL_GET_SIENA_USERS, err);
  } finally {
    commit(MU.SET_LOADING_SIENA_LIST, false);
  }
};

const savePrimayContact = async ({ commit, dispatch }, data) => {
  try {
    commit(MU.SET_LOADING_LIST, true);
    await dispatch(ACG.SAVE_BORROWER_DETAIL, data);
    await dispatch(ACG.CALL_GET_BORROWER_DETAIL);
    await dispatch(AC.CALL_GET_USERS);
    dispatch(ACCI.CALL_GET_PRIMARY_CONTACT);
  } catch (err) {
    commit(MU.SET_ERROR_LIST, logAndGetErrorMessage(AC.SAVE_PRIMARY_CONTACT, err));
  } finally {
    commit(MU.SET_LOADING_LIST, false);
  }
};

const saveUserInformation = async ({ commit, dispatch, state, rootState }, user) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    commit(MU.SET_ERROR_MODAL, null);

    if (state.action === USER_ACTION.edit) {
      await updateUser({ state }, user);
    } else {
      await createUser(user, rootState.borrower_loan.borrower.current);
    }

    // Refresh data from server
    dispatch(AC.CALL_GET_USERS);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.SAVE_USER_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const deactivateUser = async ({ commit, dispatch }, user) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.put(`/api/auth/user/${user.username}`, assoc("status", "inactive", user));

    await dispatch(AC.CALL_GET_USERS);
    await dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.success,
      body: "User deactivated!"
    });
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.DEACTIVATE_USER, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const callGetGroupNames = ({ commit }) =>
  new Promise(async resolve => {
    let valueToResolve = [];
    try {
      const { data } = await Axios.get("/api/auth/groups/names");
      valueToResolve = data;
    } catch (err) {
      commit(MU.SET_ERROR_LIST, logAndGetErrorMessage(AC.CALL_GET_GROUP_NAMES, err));
    }
    resolve(valueToResolve);
  });

const callAuthorize = ({ commit }, token) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_AUTHORIZE, true);
      commit(MU.SET_ERROR_AUTHORIZE, null);
      const { data } = await Axios.get(`/authorization`, { params: { token } });
      resolve(data.username);
    } catch (err) {
      // Use simpler error message
      logAndGetErrorMessage(AC.CALL_AUTHORIZE, err);
      commit(MU.SET_ERROR_AUTHORIZE, err?.response?.data?.message || "Error changing your password.");
    } finally {
      commit(MU.SET_LOADING_AUTHORIZE, false);
    }

    resolve(null);
  });

const callResetPassword = async ({ commit }, username) => {
  try {
    commit(MU.SET_LOADING_PASSWORD, true);
    commit(MU.SET_ERROR_PASSWORD, null);
    await Axios.get(`/authorization/password/recover/${username}`);
  } catch (err) {
    commit(MU.SET_ERROR_PASSWORD, logAndGetErrorMessage(AC.CALL_RESET_PASSWORD, err));
  } finally {
    commit(MU.SET_LOADING_PASSWORD, false);
  }
};

const changePassword = async ({ rootState, commit }, values) => {
  try {
    commit(MU.SET_LOADING_PASSWORD, true);
    commit(MU.SET_ERROR_PASSWORD, null);

    const username = values.username ?? rootState.user?.profile?.username;
    await Axios.put(`/api/auth/user/${username}/password`, assoc("username", username, values));
  } catch (err) {
    // Use simpler error message
    logAndGetErrorMessage(AC.CHANGE_PASSWORD, err);
    commit(MU.SET_ERROR_PASSWORD, err?.response?.data?.message || "Error changing your password.");
  } finally {
    commit(MU.SET_LOADING_PASSWORD, false);
  }
};

const changeExpiredPassword = async ({ commit }, values) => {
  try {
    commit(MU.SET_LOADING_PASSWORD, true);
    commit(MU.SET_ERROR_PASSWORD, null);

    await Axios.put("/api/auth/password/expired", values);
  } catch (err) {
    // Use simpler error message
    logAndGetErrorMessage(AC.CHANGE_EXPIRED_PASSWORD, err);
    commit(MU.SET_ERROR_PASSWORD, err?.response?.data?.message || "Error changing your password.");
  } finally {
    commit(MU.SET_LOADING_PASSWORD, false);
  }
};

export const actions = {
  [AC.CALL_GET_USER_INFORMATION]: callGetUserInformation,
  [AC.CALL_LOGIN]: callLogin,
  [AC.CALL_LOGOUT]: callLogout,
  [AC.CALL_GET_USERS]: callGetUsers,
  [AC.SAVE_USER_INFORMATION]: saveUserInformation,
  [AC.DEACTIVATE_USER]: deactivateUser,
  [AC.CALL_GET_GROUP_NAMES]: callGetGroupNames,
  [AC.CALL_GET_SIENA_USERS]: callGetSienaUsers,
  [AC.SAVE_PRIMARY_CONTACT]: savePrimayContact,
  [AC.CALL_AUTHORIZE]: callAuthorize,
  [AC.CALL_RESET_PASSWORD]: callResetPassword,
  [AC.CALL_CHANGE_PASSWORD]: changePassword,
  [AC.CALL_CHANGE_EXPIRED_PASSWORD]: changeExpiredPassword
};
