import Axios from "axios";
import qs from "qs";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetTableNames = ({ commit }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get("/api/admin/audit/trans/type/transactions");
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_TABLE_NAMES, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const callGetLogEntries = ({ commit }, searchData) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);
      const { data } = await Axios.get("/api/admin/audit/trans", {
        params: searchData,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        }
      });
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_LOG_ENTRIES, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

export const actions = {
  [AC.CALL_GET_TABLE_NAMES]: callGetTableNames,
  [AC.CALL_GET_LOG_ENTRIES]: callGetLogEntries
};
