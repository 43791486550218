import { actions } from "./actions";
import { mutations } from "./mutations";
//import { getters } from "./getters";

export const VendorModule = {
  state: {
    loading: {list: false, details: false, invoices: false},
    hasChanges: {details: false, linked : false},
    error: {list: null, details: null, invoices: null},
    id: null,
    list: { vendors: [], linked: [] },
    name: null,
    current: {},
    loanid: null,
    countryCodes: []
  },
  actions,
  mutations,
  getters: {}
};
