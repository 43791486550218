//@krojs, take a look to this, need to extrac common functions and debug a bit more the logic for the table
import Axios from "axios";
import { equals, sum, omit, toLower, includes } from "ramda";

import { actions as AC, mutations } from "./constants";
import { actions as ACRoot, NOTIFICATION_TYPE } from "../root/constants";
import { getters as BGetters } from "../borrower-loan/constants";

import { sortArray, arrayToObjectByKey } from "@/utils/index";
import { periodHeaders, getPeriodCharAt, removeLetterFromString, equalsDefinition } from "./utils";
import { logAndGetErrorMessage } from "@/store/utils";

const callDocumentsByClientId = async ({ commit, getters }, { frequency, to, from, type }) => {
  const clientid = getters[BGetters.GET_CURRENT_BORROWER];
  try {
    if (clientid) {
      commit(mutations.SET_LOADING, true);
      const filters = {
        typeFrequency: frequency,
        documentType: type,
        searchDateStart: from,
        searchDateEnd: to,
        pageSize: 1000
      };
      const params = { params: filters };
      const { data } = await Axios.get(`/api/documents/client/${clientid}`, params);
      commit(mutations.SET_DOCUMENTS, sortArray(data.contents, "filename"));
    }
  } catch (err) {
    commit(mutations.SET_ERROR, logAndGetErrorMessage(AC.CALL_DOCUMENTS_BY_CLIENTID, err));
  } finally {
    commit(mutations.SET_LOADING, false);
  }
};

const validateUploadStatus = ({ progress, error, files }) => {
  const totalProgress = files * 100;
  const hasError = Object.keys(error || {});
  const currentProgress = sum(Object.values(progress || {}));

  return {
    hasError: !equals(hasError.length, 0),
    isCompleted: equals(totalProgress, currentProgress)
  };
};

// @krojas, create object by Frequenc and save all the types that belongs to it, it's better for search later in the
// change for the upload table
const callDocumentDefinitions = async ({ commit }) => {
  try {
    commit(mutations.SET_DOC_DEFINITION_INFO, { loading: true });
    const { data } = await Axios.get("/api/documents/definitions");
    commit(mutations.SET_DOC_DEFINITION_INFO, {
      loading: false,
      list: arrayToObjectByKey(
        "objid",
        data.map(d => ({ ...d, frequency: d.frequency || "" }))
      )
    });
  } catch (err) {
    commit(mutations.SET_DOC_DEFINITION_INFO, {
      loading: false,
      error: logAndGetErrorMessage(AC.CALL_DOCUMENTS_DEFINITIONS, err)
    });
  }
};

const callDocumentDefinitionsByPermissions = async ({ commit, rootState }, permissions) => {
  try {
    commit(mutations.SET_DOC_DEFINITION_INFO, { loading: true });
    const params = { params: permissions };
    const { data } = await Axios.get("/api/documents/definitions", params);

    const documentsWithUserPermission = [];
    const authorities = rootState.user.authorities.map(toLower);
    const documentWithFrequencyAndUploadPermission = data.filter(d => !!d.frequency && equals(toLower(d.upload), "y"));

    documentWithFrequencyAndUploadPermission.forEach(item => {
      const permission = `${toLower(item.document)}_upload`;
      if (includes(permission, authorities)) documentsWithUserPermission.push(item);
    });

    commit(mutations.SET_DOC_DEFINITION_INFO, {
      loading: false,
      byPermission: documentsWithUserPermission
    });
  } catch (err) {
    commit(mutations.SET_DOC_DEFINITION_INFO, {
      loading: false,
      error: logAndGetErrorMessage(AC.CALL_DOCUMENTS_DEFINITIONS_BY_PERMISSIONS, err)
    });
  }
};

const callCreateDocumentType = async ({ commit, state }, values) =>
  new Promise(async resolve => {
    try {
      commit(mutations.SET_DOC_DEFINITION_INFO, { loading: true });
      const name = values.name.trim();
      const body = {
        document: name.replace(/\W/g, "_"),
        shortDescription: name
      };

      const { data } = await Axios.post("/api/documents/definition", body);

      commit(mutations.SET_DOC_DEFINITION_INFO, {
        loading: false,
        list: { ...state.definition.list, [data.objid]: data }
      });
      resolve({ hasError: false, data });
    } catch (err) {
      commit(mutations.SET_DOC_DEFINITION_INFO, {
        loading: false,
        error: logAndGetErrorMessage(AC.CALL_CREATE_DOCUMENT_TYPE, err)
      });
      resolve({ hasError: true, data: null });
    }
  });

// handle text change
const saveTextChange = ({ commit, state }, values) => {
  const { list, changes } = state.definition;
  const { objid } = values;
  // get the one in the changes, if doesn't exists get the one from the original data source
  const current = changes[objid] || list[objid];
  const hasTextChanges = !equals(current.shortDescription, values.input);
  const { hasChkChanges } = current;

  if (hasTextChanges || hasChkChanges) {
    commit(mutations.SET_DOC_DEFINITION_INFO, {
      changes: { ...changes, [objid]: { ...current, shortDescription: values.input, hasTextChanges } }
    });
  } else {
    const { [objid]: removed, ...newChanges } = changes;
    commit(mutations.SET_DOC_DEFINITION_INFO, { changes: newChanges });
  }
};

const addInfoToChanges = ({ commit, state }, values) => {
  const { list, changes } = state.definition;
  const { objid, field } = values;
  const current = changes[objid] || list[objid];
  const isPeriodField = periodHeaders.includes(field);
  let changedField = {};

  if (isPeriodField) {
    const previousFreq = current.frequency || "";
    const frequency = getPeriodCharAt(field);
    changedField = { frequency: `${previousFreq}${frequency}` };
  } else {
    changedField = { [field]: "Y" };
  }
  const newCurrent = { ...current, ...changedField };
  const hasChkChanges = !equalsDefinition(list[objid], newCurrent);
  commit(mutations.SET_DOC_DEFINITION_INFO, {
    changes: { ...changes, [objid]: { ...newCurrent, hasChkChanges } }
  });
};

const removeInfoFromChanges = ({ commit, state }, values) => {
  const { list, changes } = state.definition;
  const { objid, field } = values;
  const current = changes[objid] || list[objid];
  const { hasTextChanges } = current;
  const isPeriodField = periodHeaders.includes(field);
  const newCurrent = {
    ...current,
    ...(isPeriodField ? { frequency: removeLetterFromString(field.charAt(0), current.frequency) } : { [field]: "N" })
  };
  const hasChkChanges = !equalsDefinition(list[objid], newCurrent);

  if (hasTextChanges || hasChkChanges) {
    commit(mutations.SET_DOC_DEFINITION_INFO, {
      changes: {
        ...changes,
        [objid]: {
          ...current,
          ...newCurrent,
          hasChkChanges
        }
      }
    });
  } else {
    const { [objid]: removed, ...newChanges } = changes;
    commit(mutations.SET_DOC_DEFINITION_INFO, { changes: newChanges });
  }
};

const callSaveChanges = async ({ commit, dispatch, state }) => {
  try {
    commit(mutations.SET_DOC_DEFINITION_INFO, {
      loading: true
    });

    const changedIds = Object.values(state.definition.changes);

    const changes = changedIds
      .filter(c => c.hasChkChanges || c.hasTextChanges)
      .map(a => Axios.put(`/api/documents/definition/${a.objid}`, omit(["hasChkChanges"], a)));

    // @krojas, use Promise.allSetled and check for erors
    await Axios.all(changes);

    commit(mutations.SET_DOC_DEFINITION_INFO, {
      loading: false,
      changes: {}
    });
    await dispatch(AC.CALL_DOCUMENTS_DEFINITIONS);

    dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.success,
      body: "Changes were successfully saved!",
      onSuccess: () => {
        changedIds.forEach(({ objid }) => {
          const elem = document.getElementById(objid);
          elem.classList.add("row-highlight");
        });
      }
    });
  } catch (error) {
    dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: logAndGetErrorMessage(AC.CALL_SAVE_CHANGES, err)
    });
  }
};

const uploadDocumentFiles = async (
  { commit, dispatch, getters, state },
  { name, loan, type, file, period, comment, frequency }
) => {
  if (!state.upload.uploading) commit(mutations.SET_DOC_UPLOAD_INFO, { uploading: true });

  const clientid = getters[BGetters.GET_CURRENT_BORROWER];
  try {
    const formData = new FormData();
    formData.append("clientid", clientid);
    formData.append("loanid", loan);
    formData.append("type", type);
    formData.append("file", file);
    formData.append("periodEnding", period);
    formData.append("comment", comment);
    formData.append("typeFrequency", frequency);

    await Axios.post(`/api/documents/client/${clientid}/type/${type}`, formData, {
      onUploadProgress: ({ loaded, total }) =>
        commit(mutations.SET_DOC_UPLOAD_INFO, {
          progress: { ...state.upload.progress, [name]: Math.round((loaded * 100) / total) }
        })
    });

    const status = validateUploadStatus(state.upload);
    if (!status.hasError && status.isCompleted) {
      commit(mutations.SET_DOC_UPLOAD_INFO, { uploading: false });
      dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
        type: NOTIFICATION_TYPE.success,
        body: "All files were successfully uploaded!",
        redirectTo: "documents"
      });
    }
  } catch (err) {
    logAndGetErrorMessage(AC.UPLOAD_DOCUMENT_FILES, err);
    const error = {
      ...state.upload.error,
      [name]: { file: name, msg: err.response.data.message }
    };
    const status = validateUploadStatus({
      ...state.upload,
      error
    });
    commit(mutations.SET_DOC_UPLOAD_INFO, {
      error,
      uploading: !status.isCompleted
    });
  }
};

const downloadSpecificFile = async (_, id) => {
  try {
    const {
      data: { file, filename }
    } = await Axios.get(`/api/documents/id/${id}`);

    const atob = window.atob(file);
    const uint = new Uint8Array(atob.length);
    const arrBytes = uint.map((_, i) => atob.charCodeAt(i));
    const blob = new Blob([arrBytes]);
    const href = window.URL.createObjectURL(blob);

    const $el = document.createElement("a");
    $el.setAttribute("href", href);
    $el.setAttribute("id", `download-file-${id}`);
    $el.setAttribute("download", filename);
    $el.style.display = "none";
    document.body.appendChild($el);
    $el.click();
    document.body.removeChild($el);
  } catch (err) {
    dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: logAndGetErrorMessage(AC.DOWNLOAD_SPECIFIC_FILE, err)
    });
  }
};

const callDeleteDocument = async ({ commit, state }, id) => {
  try {
    commit(mutations.SET_LOADING, true);
    await Axios.delete(`/api/document/id/${id}`);
    commit(
      mutations.SET_DOCUMENTS,
      sortArray(
        state.list.filter(l => l.id !== id),
        "filename"
      )
    );
  } catch (err) {
    commit(mutations.SET_ERROR, logAndGetErrorMessage(AC.CALL_DELETE_DOCUMENT, err));
  } finally {
    commit(mutations.SET_LOADING, false);
  }
};

const callUpdateDocumentInformation = ({ commit, state }, values) =>
  new Promise(async resolve => {
    try {
      commit(mutations.SET_LOADING, true);
      const omited = omit(["id"], values);
      await Axios.put(`/api/document/id/${values.id}`, omited);

      const doc = state.list.find(l => l.id === values.id);
      if (doc) {
        const updated = { ...doc, ...omited };
        const newList = state.list.map(l => (l.id === values.id ? updated : l));
        commit(mutations.SET_DOCUMENTS, sortArray(newList, "filename"));
      }

      commit(mutations.SET_LOADING, false);
      resolve({ hasError: false, error: null });
    } catch (err) {
      commit(mutations.SET_LOADING, false);
      resolve({ hasError: true, error: logAndGetErrorMessage(AC.CALL_UPDATE_DOCUMENT_INFORMATION, err) });
    }
  });

export const actions = {
  [AC.CALL_DOCUMENTS_BY_CLIENTID]: callDocumentsByClientId,
  [AC.CALL_DOCUMENTS_DEFINITIONS]: callDocumentDefinitions,
  [AC.CALL_CREATE_DOCUMENT_TYPE]: callCreateDocumentType,
  [AC.SAVE_INPUT_TEXT_CHANGES]: saveTextChange,
  [AC.ADD_INFO_TO_CHANGES]: addInfoToChanges,
  [AC.REMOVE_INFO_FROM_CHANGES]: removeInfoFromChanges,
  [AC.CALL_SAVE_CHANGES]: callSaveChanges,
  [AC.UPLOAD_DOCUMENT_FILES]: uploadDocumentFiles,
  [AC.DOWNLOAD_SPECIFIC_FILE]: downloadSpecificFile,
  [AC.CALL_DOCUMENTS_DEFINITIONS_BY_PERMISSIONS]: callDocumentDefinitionsByPermissions,
  [AC.CALL_DELETE_DOCUMENT]: callDeleteDocument,
  [AC.CALL_UPDATE_DOCUMENT_INFORMATION]: callUpdateDocumentInformation
};
