import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { mutations as MUBorrower } from "../constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetPrimaryContact = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_PRIMARY_CONTACT, true);
    const { data } = await Axios.get(` /api/borrower/client/${clientid}/contact`);
    commit(MU.SET_INFORMATION_PRIMARY_CONTACT, data);
  } catch (err) {
    commit(MU.SET_ERROR_PRIMARY_CONTACT, logAndGetErrorMessage(AC.CALL_GET_PRIMARY_CONTACT, err));
  } finally {
    commit(MU.SET_LOADING_PRIMARY_CONTACT, false);
  }
};

const callGetSienaContact = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_SIENA_CONTACT, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/sienacontact`);
    commit(MU.SET_INFORMATION_SIENA_CONTACT, data);
  } catch (err) {
    commit(MU.SET_ERROR_SIENA_CONTACT, logAndGetErrorMessage(AC.CALL_GET_SIENA_CONTACT, err));
  } finally {
    commit(MU.SET_LOADING_SIENA_CONTACT, false);
  }
};

export const actions = {
  [AC.CALL_GET_PRIMARY_CONTACT]: callGetPrimaryContact,
  [AC.CALL_GET_SIENA_CONTACT]: callGetSienaContact
};
