import { appendName } from "../utils";

const append = appendName("ACTIVE_SESSION");

export const actions = {
  CALL_GET_ACTIVE_SESSIONS: append("CALL_GET_ACTIVE_SESSIONS"),
  EXPIRE_ACTIVE_SESSIONS: append("EXPIRE_ACTIVE_SESSIONS")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_LIST: append("SET_LIST")
};
