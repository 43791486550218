import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", error);
  },
  [MU.SET_ADVANCE_WORKLIST]: function(state, list) {
    Vue.set(state, "list", list);
  },
  [MU.SET_FEES]: function(state, fees) {
    Vue.set(state, "fees", fees);
  },
  [MU.SET_SEARCH]: function(state, search) {
    Vue.set(state, "search", search);
  }
};
