import Vue from "vue";
import store from "@/store/index";
import { createNotificationItem } from "@/store/notification/helper";
import { actions as ACNotification } from "@/store/notification/constants";
import { actions as ACRoot } from "@/store/root/constants";
import { uniqueId } from "@/utils/index";

export const ENTIRE_PORTFOLIO_KEY_NAME = "ENTIRE_PORTFOLIO_BORROWER";

//> Success and Error should be handled here
const getSuccessMessage = subject => `The process for ${subject} was executed successfully!`;
const getErrorMessage = subject => `The process for ${subject} failed!`;

const getMessage = (subject, success) => (success ? getSuccessMessage(subject) : getErrorMessage(subject));

export const balancesListener = event => {
  if (event) {
    const data = JSON.parse(event.data).data || null;
    if (data) {
      const success = data.clientFailures === 0;
      let subject = "Borrower";

      if (!data.clientid) {
        subject = "Portfolio";
      } else {
        const borrower = store.state.borrower_loan.borrower.list.find(b => b.clientid === data.clientid);
        if (borrower) subject = borrower.customerDescription;
      }

      const message = getMessage(subject, success);

      if (success) Vue.toasted.show(message);
      else store.dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, message);

      const id = !data.clientid ? ENTIRE_PORTFOLIO_KEY_NAME : uniqueId();

      store.dispatch(
        ACNotification.ADD_NOTIFICATION_ITEM,
        createNotificationItem(id, "Process", message, success, true)
      );
    }
  }
};
