import { actions } from "./actions";
import { mutations } from "./mutations";

export const LoanModule = {
  state: {
    loading: false,
    error: null,
    currentLoanId: null,
    participationCode: { error: null, loading: false, list: [] },
    interestRate: { error: null, loading: false, list: [] },
    borrowingBaseTotals: { error: null, loading: false, list: [] }
  },
  actions,
  mutations,
  getters: {}
};
