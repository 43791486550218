import Vue from "vue";
import { concat, equals, toLower } from "ramda";

import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_USERNAME]: function(state, username) {
    Vue.set(state, "username", username);
  },
  [MU.SET_REQUEST_HEADERS]: function(state, headers) {
    Vue.set(state, "requestHeaders", concat(state.requestHeaders, headers));
  },
  [MU.SET_NOTIFICATION_INFO]: function(state, notification) {
    Vue.set(state, "notification", { ...state.notification, ...notification });
  },
  [MU.SET_HAS_TO_VALIDATE_REDIRECT_AFTER_LOGGING]: function(state, value) {
    state.hasToValidateRedirectAfterLogging = value;
  },
  [MU.SET_NOTE_SECTION]: function(state, section) {
    Vue.set(state.note, "section", section);
  },
  [MU.SET_NOTE_SHOW_MODAL]: function(state, show) {
    Vue.set(state.note, "showModal", show);
  },
  [MU.SET_SHOW_TEST_SYSTEM_BANNER]: function(state, show) {
    Vue.set(state, "showTestSystemBanner", equals(toLower(show), "true"));
  }
};
