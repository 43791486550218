import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ACTION]: function(state, action) {
    Vue.set(state, "action", action);
  },
  [MU.SET_LOANID]: function(state, loanid) {
    Vue.set(state, "loanid", loanid);
  },
  [MU.SET_SEARCHING]: function(state, searching) {
    Vue.set(state.search, "searching", searching);
  },
  [MU.SET_STATUS_COUNT]: function(state, { status, count }) {
    Vue.set(state.counts, status, count);
  },

  [MU.SET_REQUESTED_AMOUNT]: function(state, amount) {
    Vue.set(state.search, "requestedAmount", amount);
  },
  [MU.SET_SEARCH]: function(state, { list, amount }) {
    Vue.set(state.search, "hasSearched", true);
    Vue.set(state.search, "list", list);
    Vue.set(state.search, "requestedAmount", 0);
    Vue.set(state.search, "amount", amount);
  },
  [MU.RESET_SEARCH]: function(state) {
    Vue.set(state.search, "hasSearched", false);
    Vue.set(state.search, "list", []);
    Vue.set(state.search, "requestedAmount", 0);
    Vue.set(state.search, "amount", 0);
  }
};
