import { actions } from "./actions";
import { mutations } from "./mutations";

export const OperatingCash = {
  state: {
    loading: false,
    error: { bankAccount: null, upload: null },
    index: 0,
    showTotals: false
  },
  actions,
  mutations,
  getters: {}
};
