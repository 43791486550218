import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_FIELD_EXAM]: function(state, loading) {
    Vue.set(state.fieldExam.loading, "fieldExam", loading);
  },
  [MU.SET_LOADING_FIELD_EXAM_CRITERIA]: function(state, loading) {
    Vue.set(state.fieldExam.loading, "criteria", loading);
  },
  [MU.SET_ERROR_FIELD_EXAM]: function(state, error) {
    Vue.set(state.fieldExam.error, "fieldExam", error);
  },
  [MU.SET_ERROR_FIELD_EXAM_CRITERIA]: function(state, error) {
    Vue.set(state.fieldExam.error, "criteria", error);
  },
  [MU.SET_LIST_FIELD_EXAM]: function(state, list) {
    Vue.set(state.fieldExam.list, "fieldExam", list);
  },
  [MU.SET_LIST_FIELD_EXAM_CRITERIA]: function(state, list) {
    Vue.set(state.fieldExam.list, "criteria", list);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "fieldExam", clone(DEFAULT_STATE));
  }
};
