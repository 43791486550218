import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_LEDGER_ENTRY]: function(state, loading) {
    Vue.set(state.loading, "entryLedger", loading);
  },
  [MU.SET_LOADING_LOANS]: function(state, loading) {
    Vue.set(state.loading, "loans", loading);
  },
  [MU.SET_ERROR_LEDGER_ENTRY]: function(state, error) {
    Vue.set(state.error, "entryLedger", error);
  },
  [MU.SET_ERROR_LOANS]: function(state, error) {
    Vue.set(state.error, "loans", error);
  },
  [MU.SET_LIST_LEDGER_ENTRY]: function(state, list) {
    Vue.set(state, "list", list);
  }
};
