import Vue from "vue";
import Axios from "axios";
import { isNil, mergeRight } from "ramda";
import formatDate from "date-fns/format";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot } from "@/store/root/constants";
import { actions as ACNotification } from "@/store/notification/constants";
import { actions as ACComment } from "@/store/comment/constants";
import { REPORTS, FORMAT_TYPES } from "@/store/reports/constants";
import { logAndGetErrorMessage } from "@/store/utils";
import { formatDateParams } from "@/utils/index";
import { createNotificationItem } from "../notification/helper";
import { ENTIRE_PORTFOLIO_KEY_NAME } from "@/sse/balances";

const getCallBorrowerPrimaryLoan = async ({ commit, dispatch, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/summary/defaultloan`);
    commit(MU.SET_CURRENT_LOAN, data.defaultLoanToCharge);
  } catch (err) {
    //> set the default as the one in the header's dropdown
    commit(MU.SET_CURRENT_LOAN, rootState.borrower_loan.loan.current);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.GET_CALL_BORROWER_PRIMARY_LOAN, err));
  }
};

const getCallLoanStatsList = async ({ commit, dispatch, rootState }) => {
  try {
    commit(MU.SET_LOADING_LIST, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/loan/summary/${clientid}`);
    commit(MU.SET_LIST, data);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.GET_CALL_LOAN_STATS_LIST, err);
    commit(MU.SET_ERROR_LIST, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_LIST, false);
  }
};

const getCallGetLoanCurrentActivitySummaryTabInformation = async ({ commit, dispatch, rootState, state }) => {
  try {
    commit(MU.SET_LOADING_CURRENT_ACTIVITY, true);
    if (!isNil(state.current)) {
      const clientid = rootState.borrower_loan.borrower.current;
      const { data } = await Axios.get(`/api/worksheets/summary/client/${clientid}/loan/${state.current}`);
      commit(MU.SET_CURRENT_ACTIVITY, data);
    }
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.GET_CALL_LOAN_CURRENT_ACTIVITY_SUMMARY_TAB_INFORMATION, err);
    commit(MU.SET_ERROR_CURRENT_ACTIVITY, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_CURRENT_ACTIVITY, false);
  }
};

const getCallGetLoanMonthDateSummaryTabInformation = async ({ commit, dispatch, rootState, state }) => {
  try {
    commit(MU.SET_LOADING_MONTH_DATE, true);
    if (!isNil(state.current)) {
      const clientid = rootState.borrower_loan.borrower.current;
      const { data } = await Axios.get(`/api/loans/summary/client/${clientid}/loan/${state.current}`);
      commit(MU.SET_MONTH_DATE, data);
    }
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.GET_CALL_LOAN_MONTH_DATE_SUMMARY_TAB_INFORMATION, err);
    commit(MU.SET_ERROR_MONTH_DATE, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_MONTH_DATE, false);
  }
};

const callProcessBorrower = ({ dispatch, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    const date = formatDateParams(new Date());
    Axios.post(`/api/processing/balances/client/${clientid}/date/${date}`, {
      allowPreviousMonth: false,
      runMonthly: false,
      dontAddInterestToLoanBalance: false
    });
    Vue.toasted.show("Process started successfully!");
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.CALL_PROCESS_BORROWER, err);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  }
};

const callProcessPortfolio = async ({ dispatch }) => {
  try {
    dispatch(
      ACNotification.ADD_NOTIFICATION_ITEM,
      createNotificationItem(
        ENTIRE_PORTFOLIO_KEY_NAME,
        "Portfolio Process",
        "Connecting to the server to start the process",
        true,
        false
      )
    );
    Vue.toasted.show("Portfolio process is connecting to the server to start the process!");

    await Axios.post("/api/processing/balances", {
      allowPreviousMonth: false,
      runMonthly: false,
      dontAddInterestToLoanBalance: false
    });

    Vue.toasted.show("Portfolio process started successfully!");
  } catch (err) {
    Vue.toasted.show("Portfolio process failed to start!");
    dispatch(ACNotification.REMOVE_NOTIFICATION_ITEM, ENTIRE_PORTFOLIO_KEY_NAME);
    const msg = logAndGetErrorMessage(AC.CALL_PROCESS_BORROWER, err);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  }
};

const getCallSpreadsAmount = async ({ commit, dispatch, state, rootState }) => {
  try {
    commit(MU.SET_LOADING_SPREAD, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const today = formatDate(new Date(), "yyyy-MM-dd");
    const { data } = await Axios.get(`/api/loans/client/${clientid}/loan/${state.current}/spreads/amount`, {
      params: { agingDate: today }
    });
    commit(MU.SET_SPREAD, data);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.GET_CALL_SPREADS_AMOUNT, err);
    commit(MU.SET_ERROR_SPREAD, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_SPREAD, false);
  }
};

const getCallDebtorsConcentration = async ({ commit, dispatch, state, rootState }, days = 10) => {
  try {
    commit(MU.SET_LOADING_DEBTOR, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const today = formatDate(new Date(), "yyyy-MM-dd");
    const { data } = await Axios.get(`/api/loans/client/${clientid}/loan/${state.current}/debtors/concentration`, {
      params: { agingDate: today, numTopDebtors: days }
    });
    commit(MU.SET_DEBTOR, data);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.GET_CALL_DEBTORS_CONCENTRATION, err);
    commit(MU.SET_ERROR_DEBTOR, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_DEBTOR, false);
  }
};

const callSendEmailNotification = async ({ commit, rootState }, values) => {
  try {
    commit(values.isNotifyLO ? MU.SET_NOTIFYING_LO : MU.SET_NOTIFYING_CLIENT, true);

    const clientid = rootState.borrower_loan.borrower.current;
    values = mergeRight(
      {
        clientid: clientid,
        fileType: FORMAT_TYPES.PDF,
        asOfDate: formatDate(new Date(), "yyyy-MM-dd"),
        action: "VIEW",
        report: REPORTS.AVAILABILITY
      },
      values
    );

    await Axios.post("/api/emailnotification/BORROWING_BASE_UPDATE", values);
    Vue.toasted.show("Successfully sent email notification!");
  } catch (err) {
    Vue.toasted.show("Failed to send email notification!");
  } finally {
    commit(values.isNotifyLO ? MU.SET_NOTIFYING_LO : MU.SET_NOTIFYING_CLIENT, false);
  }
};

const getCallLatestNote = async ({ commit, dispatch, state }) => {
  try {
    commit(MU.SET_LOADING_NOTE, true);
    const data = await dispatch(ACComment.CALL_GET_LATEST_NOTE, state.current);
    if (data) commit(MU.SET_NOTE, data);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.GET_CALL_LATEST_NOTE, err);
    commit(MU.SET_ERROR_NOTE, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_NOTE, false);
  }
};

export const actions = {
  [AC.GET_CALL_BORROWER_PRIMARY_LOAN]: getCallBorrowerPrimaryLoan,
  [AC.GET_CALL_LOAN_STATS_LIST]: getCallLoanStatsList,
  [AC.GET_CALL_LOAN_CURRENT_ACTIVITY_SUMMARY_TAB_INFORMATION]: getCallGetLoanCurrentActivitySummaryTabInformation,
  [AC.GET_CALL_LOAN_MONTH_DATE_SUMMARY_TAB_INFORMATION]: getCallGetLoanMonthDateSummaryTabInformation,
  [AC.CALL_PROCESS_BORROWER]: callProcessBorrower,
  [AC.CALL_PROCESS_PORTFOLIO]: callProcessPortfolio,
  [AC.GET_CALL_SPREADS_AMOUNT]: getCallSpreadsAmount,
  [AC.GET_CALL_DEBTORS_CONCENTRATION]: getCallDebtorsConcentration,
  [AC.CALL_SEND_EMAIL_NOTIFICATION]: callSendEmailNotification,
  [AC.GET_CALL_LATEST_NOTE]: getCallLatestNote
};
