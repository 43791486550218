import { appendName } from "../utils";

const append = appendName("ROOT");

export const actions = {
  SHOW_SUCCESS_NOTIFICATION: append("SHOW_SUCCESS_NOTIFICATION"),
  SHOW_ERROR_NOTIFICATION: append("SHOW_ERROR_NOTIFICATION"),
  SHOW_ALERT_NOTIFICATION: append("SHOW_ALERT_NOTIFICATION"),
  SHOW_CONFIRM_LEAVE_ROUTE: append("SHOW_CONFIRM_LEAVE_ROUTE"),
  SHOW_CONFIRM_ACTION_NOTIFICATION: append("SHOW_CONFIRM_ACTION_NOTIFICATION"),
  CLOSE_NOTIFICATION: append("CLOSE_NOTIFICATION")
};

export const mutations = {
  SET_USERNAME: append("SET_USERNAME"),
  SET_REQUEST_HEADERS: append("SET_REQUEST_HEADERS"),
  SET_NOTIFICATION_INFO: append("SET_NOTIFICATION_INFO"),
  SET_HAS_TO_VALIDATE_REDIRECT_AFTER_LOGGING: append("SET_HAS_TO_VALIDATE_REDIRECT_AFTER_LOGGING"),
  SET_SHOW_TEST_SYSTEM_BANNER: append("SET_SHOW_TEST_SYSTEM_BANNER")
};

export const NOTIFICATION_TYPE = {
  success: "success",
  error: "danger",
  message: "message"
};

export const DEFAULT_NOTIFICATION_VALUES = {
  type: null,
  show: false,
  title: null,
  body: null,
  html: false,
  redirectTo: null,
  alert: null,
  okButtonClass: null,
  okButtonText: null,
  cancelButtonText: null,
  showOkButton: null,
  showCancelButton: null,
  onSuccess: null,
  modalSize: null
};
