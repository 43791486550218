import { appendName } from "../utils";

const append = appendName("LOAN_DOCUMENT");

export const actions = {
  CALL_GET_LOAN_DOCUMENTS: append("CALL_GET_LOAN_DOCUMENTS"),
  UPLOAD_DOCUMENT_FILE: append("UPLOAD_DOCUMENT_FILE"),
  UPDATE_LOAN_DOCUMENT: append("UPDATE_LOAN_DOCUMENT"),
  DELETE_LOAN_DOCUMENT: append("DELETE_LOAN_DOCUMENT")
};

export const getters = {};

export const mutations = {
  //> documents
  SET_LOADING_DOCUMENTS: append("SET_LOADING_DOCUMENTS"),
  SET_ERROR_DOCUMENTS: append("SET_ERROR_DOCUMENTS"),
  SET_LOAN_DOCUMENTS: append("SET_LOAN_DOCUMENTS"),
  //> clean
  FULL_CLEAR_STATE: append("FULL_CLEAR_STATE")
};

export const DEFAULT_STATE = {
  list: { documents: [] },
  loading: { documents: false },
  error: { documents: null }
};
