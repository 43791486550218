import { assoc, compose, equals, head, zipObj } from "ramda";
import { formatDate } from "@/utils/index";

export const mapTicklerFieldsWithValues = (fields, values) => {
  const commonSuffixes = ["Frequency", "LastReceived"];
  const getPropertiesToMapFromItem = name => commonSuffixes.map(cs => `${name}${cs}`);
  const getDueDateValue = name => values[`${name}DueDate`];
  const getFrequencyValue = name => values[`${name}${head(commonSuffixes)}`];

  const defaulMappedTicklers = {
    cm: {
      label: "Contract Maturity",
      dueDate: values.cmDueDate ? formatDate(values.cmDueDate, "MMMM dd, yyyy") : null,
      frequency: values.cmFrequency,
      lastReceived: values.cmLastReceived ? formatDate(values.cmLastReceived, "MMMM dd, yyyy") : null,
      readOnly: true
    }
  };

  const mappedTicklers = fields.reduce((previous, current) => {
    const itemProps = getPropertiesToMapFromItem(current.item);
    const obj = zipObj(["frequency", "lastReceived"], itemProps);
    const dueDate = getDueDateValue(current.item);
    const dto = compose(
      assoc("label", current.label),
      assoc("dueDate", dueDate ? formatDate(dueDate, "MMMM dd, yyyy") : null),
      assoc("dueDateValue", dueDate ? formatDate(dueDate) : null),
      assoc("unused", equals(getFrequencyValue(current.item), "NA"))
    );

    return assoc(current.item, dto(obj), previous);
  }, defaulMappedTicklers);

  return mappedTicklers;
};
