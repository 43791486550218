import { prop, equals, compose, toLower, includes } from "ramda";
import { sortArray, formatDate } from "../../utils";

import { getters as GE, DOCUMENT_FREQUENCY_OPTIONS} from "./constants";
import { getters as GELoan } from "../borrower-loan/constants";

const checkStrNull = v => v || "";
const isYes = equals("y");
const getProp = p => compose(isYes, toLower, checkStrNull, prop(p));

const getDocumentDefinitionForTable = ({ definition }) => {
  const { list, changes } = definition;
  const definitions = Object.values({ ...list, ...changes }).map(l => {
    const hasDownload = getProp("download");
    const hasProcess = getProp("process");
    const hasDelete = getProp("delete");
    const hasUpload = getProp("upload");
    const frequency = prop("frequency")(l) || "";

    return {
      objid: prop("objid", l),
      document: prop("document", l),
      shortDescription: prop("shortDescription", l),
      upload: hasUpload(l),
      download: hasDownload(l),
      process: hasProcess(l),
      delete: hasDelete(l),
      daily: includes("D", frequency),
      weekly: includes("W", frequency),
      monthly: includes("M", frequency),
      quarterly: includes("Q", frequency),
      biannually: includes("B", frequency),
      annually: includes("A", frequency)
    };
  });

  return sortArray(definitions, "shortDescription");
};

const getDocTypeByFrequency = ({ definition }) => f => {
  const list = Object.values(definition.list);
  if (!f) return [];
  return list
    .filter(({ frequency }) => includes(f, frequency))
    .map(({ document, shortDescription }) => ({ value: document, label: shortDescription }));
};

const getRowsForDocumentGrid = ({ definition, list }, getters) => {
  const loans = getters[GELoan.GET_CURRENT_LOANS];
  const isSameType = t => compose(equals, toLower)(t);
  const isSameDocType = t => d => compose(isSameType(t), toLower, prop("document"))(d);
  const isSameDocLoanId = l => d => compose(equals(l), prop("loanId"))(d);
  return list.map(l => {
    const type = definition.byPermission.find(isSameDocType(l.type));
    const currentLoan = loans.find(isSameDocLoanId(l.loanId));
    const loanId = currentLoan ? ` - ${currentLoan.loanId.substring(currentLoan.loanId.length - 3)}` : "";

    // Set up formatted fields for quick display while keeping original data intact
    return {
      ...l,
      description: type ? type.shortDescription : null,
      permission: l.type,
      loan: currentLoan ? `${currentLoan.loanName}${loanId}` : null,
      loanName: currentLoan ? `${currentLoan.loanName} - ${currentLoan.loanId}` : null,
      frequencyDescription: DOCUMENT_FREQUENCY_OPTIONS[l.typeFrequency],
    };
  });
};

const getDocTypePermissionByFrequency = ({ definition }) => f => {
  if (!f) return [];
  return definition.byPermission
    .filter(({ frequency }) => includes(f, frequency))
    .map(({ document, shortDescription }) => ({ value: document, label: shortDescription }));
};

export const getters = {
  [GE.GET_DOCUMENT_DEFINITION_FOR_TABLE]: getDocumentDefinitionForTable,
  [GE.GET_DOC_TYPE_BY_FREQUENCY]: getDocTypeByFrequency,
  [GE.GET_DOC_TYPE_PERMISSION_BY_FREQUENCY]: getDocTypePermissionByFrequency,
  [GE.GET_ROWS_FOR_DOCUMENT_GRID]: getRowsForDocumentGrid
};
