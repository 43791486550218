import Axios from "axios";
import { assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetBankAccounts = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_BANK_ACCOUNT, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/BankAccounts`);
    commit(MU.SET_LIST_BANK_ACCOUNT, data);
  } catch (err) {
    commit(MU.SET_ERROR_BANK_ACCOUNT, logAndGetErrorMessage(AC.CALL_GET_BANK_ACCOUNTS, err));
  } finally {
    commit(MU.SET_LOADING_BANK_ACCOUNT, false);
  }
};

const callGetDisbursementAccounts = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_DISBURSEMENT_ACCOUNT, true);
    const { data } = await Axios.get(`/api/borrower/disbursement-account/client/${clientid}`);
    commit(MU.SET_LIST_DISBURSEMENT_ACCOUNT, data);
  } catch (err) {
    commit(MU.SET_ERROR_DISBURSEMENT_ACCOUNT, logAndGetErrorMessage(AC.CALL_GET_DISBURSEMENT_ACCOUNTS, err));
  } finally {
    commit(MU.SET_LOADING_DISBURSEMENT_ACCOUNT, false);
  }
};

const createBankAccount = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_BANK_ACCOUNT, true);
    await Axios.post(`/api/borrower/helper/${clientid}/BankAccounts`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_BANK_ACCOUNTS);
  } catch (err) {
    commit(MU.SET_ERROR_BANK_ACCOUNT, logAndGetErrorMessage(AC.CREATE_BANK_ACCOUNT, err));
  } finally {
     commit(MU.SET_LOADING_BANK_ACCOUNT, false);
   }
};

const createDisbursementAccount = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_DISBURSEMENT_ACCOUNT, true);
    await Axios.post(`/api/borrower/disbursement-account/${clientid}`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_DISBURSEMENT_ACCOUNTS);
  } catch (err) {
    commit(MU.SET_ERROR_DISBURSEMENT_ACCOUNT, logAndGetErrorMessage(AC.CREATE_DISBURSEMENT_ACCOUNT, err));
  } finally {
     commit(MU.SET_LOADING_DISBURSEMENT_ACCOUNT, false);
   }
};

const updateBankAccount = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_BANK_ACCOUNT, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/BankAccounts`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_BANK_ACCOUNTS);
  } catch (err) {
    commit(MU.SET_ERROR_BANK_ACCOUNT, logAndGetErrorMessage(AC.UPDATE_BANK_ACCOUNT, err));
  } finally {
     commit(MU.SET_LOADING_BANK_ACCOUNT, false);
   }
};

const updateDisbursementAccount = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_DISBURSEMENT_ACCOUNT, true);
    await Axios.put(` /api/borrower/disbursement-account/${formData.objid}`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_DISBURSEMENT_ACCOUNTS);
  } catch (err) {
    commit(MU.SET_ERROR_DISBURSEMENT_ACCOUNT, logAndGetErrorMessage(AC.UPDATE_DISBURSEMENT_ACCOUNT, err));
  } finally {
    commit(MU.SET_LOADING_DISBURSEMENT_ACCOUNT, false);
  }
};

const deleteBankAccount = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_BANK_ACCOUNT, true);
    await Axios.delete(`/api/borrower/helper/${formData.id}/BankAccounts`);
    dispatch(AC.CALL_GET_BANK_ACCOUNTS);
  } catch (err) {
    commit(MU.SET_ERROR_BANK_ACCOUNT, err.response.data.message);
  } finally {
    commit(MU.SET_LOADING_BANK_ACCOUNT, false);
  }
};

export const actions = {
  [AC.CALL_GET_BANK_ACCOUNTS]: callGetBankAccounts,
  [AC.CALL_GET_DISBURSEMENT_ACCOUNTS]: callGetDisbursementAccounts,
  [AC.CREATE_BANK_ACCOUNT]: createBankAccount,
  [AC.CREATE_DISBURSEMENT_ACCOUNT]: createDisbursementAccount,
  [AC.UPDATE_BANK_ACCOUNT]: updateBankAccount,
  [AC.UPDATE_DISBURSEMENT_ACCOUNT]: updateDisbursementAccount,
  [AC.DELETE_BANK_ACCOUNT]: deleteBankAccount
};
