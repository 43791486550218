import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, value) {
    Vue.set(state, "loading", value);
  },
  [MU.SET_ERROR]: function(state, value) {
    Vue.set(state, "error", value);
  },
  [MU.SET_REPORT]: function(state, value) {
    Vue.set(state, "report", value);
  },
  [MU.SET_REPORT_DISABLED]: function(state, values) {
    Vue.set(state, "reportDisabled", values);
  },
  [MU.SET_REPORT_CRITERIA]: function(state, values) {
    Vue.set(state, "reportCriteria", values);
  },
  [MU.UPDATE_REPORT_CRITERIA]: function(state, values) {
    Vue.set(state, "reportCriteria", { ...state.reportCriteria, ...values });
  },
  [MU.SET_REPORT_CRITERIA_CHANGED]: function(state, value) {
    Vue.set(state, "reportCriteriaChanged", value);
  },
  [MU.SET_SELECTED_REPORT_NAME]: function(state, value) {
    Vue.set(state, "selectedReportName", value);
  }
};
