import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetActiveSessions = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/admin/sessions");
    commit(MU.SET_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_ACTIVE_SESSIONS, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const expireActiveSessions = async ({ commit, dispatch }, sessions) => {
  try {
    commit(MU.SET_LOADING, true);
    const requests = Object.keys(sessions).map(s => Axios.put(`/admin/session/expire/${s}`, {}));
    const responses = await Promise.allSettled(requests);
    const hasError = responses.find(r => r.status === "rejected");
    if (hasError) {
      await dispatch(
        ACRoot.SHOW_ERROR_NOTIFICATION,
        "There was an error expiring all the active session for the user."
      );
    } else {
      await dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION);
    }
    dispatch(AC.CALL_GET_ACTIVE_SESSIONS);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.EXPIRE_ACTIVE_SESSIONS, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_ACTIVE_SESSIONS]: callGetActiveSessions,
  [AC.EXPIRE_ACTIVE_SESSIONS]: expireActiveSessions
};
