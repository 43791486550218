import Axios from "axios";
import { compose, assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot } from "@/store/root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetFieldExam = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_FIELD_EXAM, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/MonitoringCollateral`);
    commit(
      MU.SET_LIST_FIELD_EXAM,
      data.filter(d => d.type === "FIELD_EXAM")
    );
  } catch (err) {
    commit(MU.SET_ERROR_FIELD_EXAM, logAndGetErrorMessage(AC.CALL_GET_FIELD_EXAM, err));
    reject(err);
  } finally {
    commit(MU.SET_LOADING_FIELD_EXAM, false);
  }
};

const createFieldExam = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    const data = compose(assoc("clientid", clientid), assoc("type", "FIELD_EXAM"))(formData);
    await Axios.post(`/api/borrower/helper/${clientid}/MonitoringCollateral`, data);
    dispatch(AC.CALL_GET_FIELD_EXAM);
  } catch (err) {
    commit(MU.SET_ERROR_FIELD_EXAM, logAndGetErrorMessage(AC.CREATE_FIELD_EXAM, err));
  } finally {
    commit(MU.SET_LOADING_FIELD_EXAM, false);
  }
};

const updateFieldExam = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_FIELD_EXAM, true);
    const data = compose(assoc("clientid", clientid), assoc("type", "FIELD_EXAM"))(formData);
    await Axios.put(`/api/borrower/helper/${formData.id}/MonitoringCollateral`, data);
    dispatch(AC.CALL_GET_FIELD_EXAM);
  } catch (err) {
    commit(MU.SET_ERROR_FIELD_EXAM, logAndGetErrorMessage(AC.UPDATE_FIELD_EXAM, err));
  } finally {
    commit(MU.SET_LOADING_FIELD_EXAM, false);
  }
};

const callGetFieldExamCriteria = async ({ rootState, commit }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_FIELD_EXAM_CRITERIA, true);
    const { data } = await Axios.get(`/api/borrower/${clientid}/BorrowersMonitoringCollateral`);
    commit(MU.SET_LIST_FIELD_EXAM_CRITERIA, data);
  } catch (err) {
    commit(MU.SET_ERROR_FIELD_EXAM_CRITERIA, logAndGetErrorMessage(AC.CALL_GET_FIELD_EXAM_CRITERIA, err));
  } finally {
    commit(MU.SET_LOADING_FIELD_EXAM_CRITERIA, false);
  }
};

const saveFieldExamCriteria = async ({ rootState, commit, dispatch }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    const data = assoc("clientid", clientid, formData);
    if (data.id) await Axios.put(`/api/borrower/helper/${data.id}/BorrowersMonitoringCollateral`, data);
    else await Axios.post(`/api/borrower/helper/${data.clientid}/BorrowersMonitoringCollateral`, data);

    await dispatch(AC.CALL_GET_FIELD_EXAM_CRITERIA);
  } catch (err) {
    commit(MU.SET_ERROR_FIELD_EXAM_CRITERIA, logAndGetErrorMessage(AC.SAVE_FIELD_EXAM_CRITERIA, err));
  } finally {
    commit(MU.SET_LOADING_FIELD_EXAM_CRITERIA, false);
  }
};

export const actions = {
  [AC.CALL_GET_FIELD_EXAM]: callGetFieldExam,
  [AC.CREATE_FIELD_EXAM]: createFieldExam,
  [AC.UPDATE_FIELD_EXAM]: updateFieldExam,
  [AC.CALL_GET_FIELD_EXAM_CRITERIA]: callGetFieldExamCriteria,
  [AC.SAVE_FIELD_EXAM_CRITERIA]: saveFieldExamCriteria
};
