import { appendName } from "@/store/utils";

import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToSetDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_YIELD_FEE: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_YIELD_FEE"),
  SAVE_YIELD_FEE: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_YIELD_FEE")
};

export const mutations = {
  SET_LOADING_YIELD_FEE: appendToLoading("YIELD_FEE"),
  SET_ERROR_YIELD_FEE: appendToError("YIELD_FEE"),
  SET_INFORMATION_YIELD_FEE: appendToSetDetail("YIELD_FEE"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("YIELD_FEE")
};

export const DEFAULT_STATE = {
  loading: false,
  error: null,
  data: null
};
