import Vue from "vue";

import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_LIST]: function(state, loading) {
    Vue.set(state.loading, "list", loading);
  },
  [MU.SET_LOADING_MODAL]: function(state, loading) {
    Vue.set(state.loading, "modal", loading);
  },
  [MU.SET_LOADING_SIENA_LIST]: function(state, value) {
    Vue.set(state.loading, "siena", value);
  },
  [MU.SET_LOADING_PASSWORD]: function(state, value) {
    Vue.set(state.loading, "password", value);
  },
  [MU.SET_LOADING_AUTHORIZE]: function(state, value) {
    Vue.set(state.loading, "password", value);
  },
  [MU.SET_ERROR_LIST]: function(state, error) {
    Vue.set(state.error, "list", error);
  },
  [MU.SET_ERROR_MODAL]: function(state, error) {
    Vue.set(state.error, "modal", error);
  },
  [MU.SET_ERROR_AUTHORIZE]: function(state, error) {
    Vue.set(state.error, "authorize", error);
  },
  [MU.SET_ERROR_PASSWORD]: function(state, error) {
    Vue.set(state.error, "password", error);
  },
  [MU.SET_LIST_USER]: function(state, list) {
    Vue.set(state.list, "user", list);
  },
  [MU.SET_LIST_USER_SIENA]: function(state, list) {
    Vue.set(state.list, "userSiena", list);
  },
  [MU.SET_PROFILE]: function(state, data) {
    Vue.set(state, "profile", data);
  },
  [MU.SET_GROUPS]: function(state, groups) {
    Vue.set(state, "groups", groups);
  },
  [MU.SET_AUTHORITIES]: function(state, authorities) {
    Vue.set(state, "authorities", authorities);
  },
  [MU.SET_USER_ACTION]: function(state, action) {
    Vue.set(state, "action", action);
  },
  [MU.SET_LOGGED_USER_IS_SIENA]: function(state, value) {
    Vue.set(state, "isSienaUser", value);
  },
  [MU.SET_LOGIN_ERROR_CODE]: function(state, value) {
    Vue.set(state, "loginErrorCode", value);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state.list, "user", DEFAULT_STATE.list.user);
    Vue.set(state, "error", DEFAULT_STATE.error);
    Vue.set(state, "loading", DEFAULT_STATE.loading);
    Vue.set(state, "action", DEFAULT_STATE.action);
    Vue.set(state, "loginErrorCode", DEFAULT_STATE.loginErrorCode);
  }
};
