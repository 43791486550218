import Vue from "vue";

import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", error);
  },
  [MU.SET_CURENT_LOANID]: function(state, loanId) {
    Vue.set(state, "currentLoanId", loanId);
  },
  [MU.SET_PARTICIPATION_CODE]: function(state, codes) {
    Vue.set(state, "participationCode", { ...state.participationCode, ...codes });
  },
  [MU.SET_INTEREST_RATE]: function(state, rates) {
    Vue.set(state, "interestRate", { ...state.interestRate, ...rates });
  },
  [MU.SET_LOADING_LOAN_SUMMARY_BORROWING_BASE_TOTAL]: function(state, loading) {
    Vue.set(state.borrowingBaseTotals, "loading", loading);
  },
  [MU.SET_ERROR_LOAN_SUMMARY_BORROWING_BASE_TOTAL]: function(state, error) {
    Vue.set(state.borrowingBaseTotals, "error", error);
  },
  [MU.SET_LIST_LOAN_SUMMARY_BORROWING_BASE_TOTAL]: function(state, list) {
    Vue.set(state.borrowingBaseTotals, "list", list);
  }
};
