import { appendName } from "../utils";

const append = appendName("VENDOR");

export const actions = {
  EDIT_VENDOR: append("EDIT_VENDOR"),
  CALL_GET_LIST: append("CALL_GET_LIST"),
  CALL_GET_DETAILS: append("CALL_GET_DETAILS"),
  CALL_GET_INVOICES: append("CALL_GET_INVOICES"),
  CALL_SAVE_INVOICES: append("CALL_SAVE_INVOICES"),
  CALL_LINKED_VENDORS: append("CALL_LINKED_VENDORS"),
  SAVE_AS_PARENT_VENDOR: append("SAVE_AS_PARENT_VENDOR")
};

export const mutations = {
  SET_LOADING_LIST: append("SET_LOADING_LIST"),
  SET_ERROR_LIST: append("SET_ERROR_LIST"),
  SET_LOADING_DETAILS: append("SET_LOADING_DETAILS"),
  SET_ERROR_DETAILS: append("SET_ERROR_DETAILS"),
  SET_HAS_CHANGES_DETAILS: append("SET_HAS_CHANGES_DETAILS"),
  SET_LOADING_INVOICES: append("SET_LOADING_INVOICES"),
  SET_ERROR_INVOICES: append("SET_ERROR_INVOICES"),
  SET_LOANID: append("SET_LOANID"),
  SET_VENDOR: append("SET_VENDOR"),
  SET_COUNTRY_CODES: append("SET_COUNTRY_CODES"),
  SET_VENDORS_LIST: append("SET_VENDORS_LIST"),
  SET_LINKED_VENDORS: append("SET_LINKED_VENDORS"),
  SET_HAS_CHANGES_LINKED_VENDORS: append("SET_HAS_CHANGES_LINKED_VENDORS")
};
