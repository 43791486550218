import { clone } from "ramda";
import Vue from "vue";
import { DEFAULT_STATE, mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_COLLATERAL_COMPARISON]: function(state, loading) {
    Vue.set(state.loading, "collateralComparison", loading);
  },
  [MU.SET_ERROR_COLLATERAL_COMPARISON]: function(state, error) {
    Vue.set(state.error, "collateralComparison", error);
  },
  [MU.SET_LIST_COLLATERAL_COMPARISON]: function(state, list) {
    Vue.set(state.list, "collateralComparison", list);
  },
  [MU.SET_LOADING_AGING_COMPARISON]: function(state, loading) {
    Vue.set(state.loading, "agingComparison", loading);
  },
  [MU.SET_ERROR_AGING_COMPARISON]: function(state, error) {
    Vue.set(state.error, "agingComparison", error);
  },
  [MU.SET_LIST_AGING_COMPARISON]: function(state, list) {
    Vue.set(state.list, "agingComparison", list);
  },
  [MU.SET_LOADING_AR_INELIGIBLE]: function(state, loading) {
    Vue.set(state.loading, "arIneligible", loading);
  },
  [MU.SET_ERROR_AR_INELIGIBLE]: function(state, error) {
    Vue.set(state.error, "arIneligible", error);
  },
  [MU.SET_LIST_AR_INELIGIBLE]: function(state, list) {
    Vue.set(state.list, "arIneligible", list);
  },
  [MU.SET_LOADING_COLLATERAL_RESERVE]: function(state, loading) {
    Vue.set(state.loading, "collateralReserve", loading);
  },
  [MU.SET_ERROR_COLLATERAL_RESERVE]: function(state, error) {
    Vue.set(state.error, "collateralReserve", error);
  },
  [MU.SET_LIST_COLLATERAL_RESERVE]: function(state, list) {
    Vue.set(state.list, "collateralReserve", list);
  },
  [MU.SET_LOADING_SHIFT]: function(state, loading) {
    Vue.set(state.loading, "shift", loading);
  },
  [MU.SET_ERROR_SHIFT]: function(state, error) {
    Vue.set(state.error, "shift", error);
  },
  [MU.SET_LIST_SHIFT]: function(state, list) {
    Vue.set(state.list, "shift", list);
  },
  [MU.SET_LOADING_SPREAD]: function(state, loading) {
    Vue.set(state.loading, "spread", loading);
  },
  [MU.SET_ERROR_SPREAD]: function(state, error) {
    Vue.set(state.error, "spread", error);
  },
  [MU.SET_LIST_SPREAD]: function(state, list) {
    Vue.set(state.list, "spread", list);
  },
  [MU.SET_LOADING_AR_ACTIVITY]: function(state, loading) {
    Vue.set(state.loading, "arActivity", loading);
  },
  [MU.SET_ERROR_AR_ACTIVITY]: function(state, error) {
    Vue.set(state.error, "arActivity", error);
  },
  [MU.SET_LIST_AR_ACTIVITY]: function(state, list) {
    Vue.set(state.list, "arActivity", list);
  },
  [MU.SET_LOADING_AR_TREND]: function(state, loading) {
    Vue.set(state.loading, "arTrend", loading);
  },
  [MU.SET_ERROR_AR_TREND]: function(state, error) {
    Vue.set(state.error, "arTrend", error);
  },
  [MU.SET_LIST_AR_TREND]: function(state, list) {
    Vue.set(state.list, "arTrend", list);
  },
  [MU.SET_LOADING_INV_AGING_COMPARISON]: function(state, loading) {
    Vue.set(state.loading, "invAgingComparison", loading);
  },
  [MU.SET_ERROR_INV_AGING_COMPARISON]: function(state, error) {
    Vue.set(state.error, "invAgingComparison", error);
  },
  [MU.SET_LIST_INV_AGING_COMPARISON]: function(state, list) {
    Vue.set(state.list, "invAgingComparison", list);
  },
  [MU.SET_LOADING_INV_INELIGIBLE]: function(state, loading) {
    Vue.set(state.loading, "invIneligible", loading);
  },
  [MU.SET_ERROR_INV_INELIGIBLE]: function(state, error) {
    Vue.set(state.error, "invIneligible", error);
  },
  [MU.SET_LIST_INV_INELIGIBLE]: function(state, list) {
    Vue.set(state.list, "invIneligible", list);
  },
  [MU.SET_LOADING_TOP_VENDOR]: function(state, loading) {
    Vue.set(state.loading, "topVendor", loading);
  },
  [MU.SET_ERROR_TOP_VENDOR]: function(state, error) {
    Vue.set(state.error, "topVendor", error);
  },
  [MU.SET_LIST_TOP_VENDOR]: function(state, list) {
    Vue.set(state.list, "topVendor", list);
  },
  [MU.SET_LIST_TOP_DEBTOR]: function(state, list) {
    Vue.set(state.list, "topDebtor", list);
  },
  [MU.CLEAN_STATE]: function(state) {
    Vue.set(state, "list", clone(DEFAULT_STATE.list));
    Vue.set(state, "loading", clone(DEFAULT_STATE.loading));
    Vue.set(state, "error", clone(DEFAULT_STATE.error));
  }
};
