import { appendName } from "../utils";

const append = appendName("PROCESS_DOCUMENT");

export const actions = {
  CALL_PROCESS_DOCUMENT_PREVIEW: append("CALL_PROCESS_DOCUMENT_PREVIEW"),
  CALL_META_FIELD_TYPES: append("CALL_META_FIELD_TYPES"),
  COMPUTE_AVAILABLE_EXCEL_COLUMNS: append("COMPUTE_AVAILABLE_EXCEL_COLUMNS"),
  START_PROCESS_DOCUMENT: append("START_PROCESS_DOCUMENT"),
  CALL_GET_SHEET_NAMES: append("CALL_GET_SHEET_NAMES")
};

export const getters = {
  GET_SPREADSHEET_DATA: append("GET_SPREADSHEET_DATA")
};

export const mutations = {
  SET_PROCESS_DOCUMENT_PREVIEW: append("SET_PROCESS_DOCUMENT_PREVIEW"),
  SET_EXCEL_SHEET_NAMES: append("SET_EXCEL_SHEET_NAMES"),
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_PROCESS_DOCUMENT: append("SET_PROCESS_DOCUMENT"),
  SET_COLUMN_MAP: append("SET_COLUMN_MAP"),
  SET_PROCESSING: append("SET_PROCESSING")
};
