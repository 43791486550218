import { appendName } from "@/store/utils";
import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToSetDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_BORROWER_DETAIL: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_BORROWER_DETAIL"),
  SAVE_BORROWER_DETAIL: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_BORROWER_DETAIL"),
  CREATE_BORROWER_DETAIL: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_BORROWER_DETAIL"),
  CALL_GET_SPONSORS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_SPONSORS"),
  CALL_GET_OWNERSHIP: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_OWNERSHIP"),
  CREATE_SPONSORS: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_SPONSORS"),
  CREATE_OWNERSHIP: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_OWNERSHIP"),
  UPDATE_SPONSORS: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_SPONSORS"),
  UPDATE_OWNERSHIP: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_OWNERSHIP"),
  DELETE_SPONSORS: APPEND_BORROWER_DETAIL_MODULE_NAME("DELETE_SPONSORS"),
  DELETE_OWNERSHIP: APPEND_BORROWER_DETAIL_MODULE_NAME("DELETE_OWNERSHIP")
};

export const mutations = {
  //> borrower
  SET_LOADING_GENERAL_INFO_BORROWER: appendToLoading("GENERAL_INFO_BORROWER"),
  SET_ERROR_GENERAL_INFO_BORROWER: appendToError("GENERAL_INFO_BORROWER"),
  SET_INFORMATION_GENERAL_INFO_BORROWER: appendToSetDetail("GENERAL_INFO_BORROWER"),
  //> ownership
  SET_LOADING_GENERAL_INFO_OWNERSHIP: appendToLoading("GENERAL_INFO_OWNERSHIP"),
  SET_ERROR_GENERAL_INFO_OWNERSHIP: appendToError("GENERAL_INFO_OWNERSHIP"),
  SET_LIST_GENERAL_INFO_OWNERSHIP: appendToList("GENERAL_INFO_OWNERSHIP"),
  //> sponsors
  SET_LOADING_GENERAL_INFO_SPONSORS: appendToLoading("GENERAL_INFO_SPONSORS"),
  SET_ERROR_GENERAL_INFO_SPONSORS: appendToError("GENERAL_INFO_SPONSORS"),
  SET_LIST_GENERAL_INFO_SPONSORS: appendToList("GENERAL_INFO_SPONSORS"),
  SET_GENERAL_INFO_CHANGES: appendToList("SET_GENERAL_INFO_CHANGES"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("GENERAL_INFO_BORROWER")
};

export const DEFAULT_STATE = {
  loading: { borrower: false, ownership: false, sponsors: false },
  error: { borrower: null, ownership: null, sponsors: null },
  list: { ownership: [], sponsors: [] },
  borrower: null,
  mode: "CREATE",
  changes: false
};
