import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state.list, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state.list, "error", error);
  },
  [MU.SET_LIST]: function(state, data) {
    Vue.set(state.list, "data", data);
  },
  [MU.SET_LOADING_MODAL]: function(state, loading) {
    Vue.set(state.modal, "loading", loading);
  },
  [MU.SET_ERROR_MODAL]: function(state, error) {
    Vue.set(state.modal, "error", error);
  }
};
