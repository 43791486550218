import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot, NOTIFICATION_TYPE } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const dates = ["establishedDate"];

const callGetList = ({ commit, rootState }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_LIST, true);

      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];

      const { data } = await Axios.get(`/api/loan/${loan.id}/vendors`);

      commit(MU.SET_VENDORS_LIST, data);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR_LIST, logAndGetErrorMessage(AC.CALL_GET_LIST, err));
    } finally {
      commit(MU.SET_LOADING_LIST, false);
    }
    resolve([]);
  });

const callGetDetails = ({ commit, state }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_DETAILS, true);
      const { data } = await Axios.get(`/api/vendors/${state.id}`);
      await getCountryCodes({ commit, state });
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR_DETAILS, logAndGetErrorMessage(AC.CALL_GET_DETAILS, err));
    } finally {
      commit(MU.SET_LOADING_DETAILS, false);
    }
    resolve([]);
  });

const getCountryCodes = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_DETAILS, true);
    const { data } = await Axios.get(`/api/countrycodes`);
    commit(MU.SET_COUNTRY_CODES, data);
  } catch (err) {
    commit(MU.SET_ERROR_DETAILS, logAndGetErrorMessage("getCountryCodes", err));
  } finally {
    commit(MU.SET_LOADING_DETAILS, false);
  }
};

const callGetInvoices = ({ commit, rootState, state }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_INVOICES, true);

      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];

      const { data } = await Axios.get(`/api/loan/${loan.id}/ap/vendor/${state.id}/invoices`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR_INVOICES, logAndGetErrorMessage(AC.CALL_GET_INVOICES, err));
    } finally {
      commit(MU.SET_LOADING_INVOICES, false);
    }
    resolve([]);
  });

const callSaveInvoices = async ({ commit, rootState, dispatch }, formValues) => {
  try {
    commit(MU.SET_LOADING_INVOICES, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    await Axios.put(`/api/loan/${loan.id}/invoices`, formValues);

    dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.CALL_SAVE_INVOICES, err);
    commit(MU.SET_ERROR_INVOICES, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_INVOICES, false);
  }
};

const editVendor = async ({ commit, state, dispatch }, formValues) => {
  try {
    commit(MU.SET_LOADING_DETAILS, true);

    // Strip out the time stamp so api works for now
    dates.forEach(field => {
      if (formValues[field]) formValues[field] = formValues[field].replace(/^(.*?)\s[0-9\:]+/, "$1");
    });

    await Axios.put(`/api/vendors/${state.id}`, formValues);
    dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.EDIT_VENDOR, err);
    commit(MU.SET_ERROR_DETAILS, msg);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_DETAILS, false);
  }
};

const saveAsParentDebtor = async ({ commit, dispatch, rootState }, data) => {
  try {
    commit(MU.SET_LOADING_DETAILS, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    await Axios.patch(`/api/loan/${loan.id}/address/vendors`, data.child);
    if (data.notify) {
      dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
        type: NOTIFICATION_TYPE.success,
        body: "Changes were successfully saved!",
        redirectTo: "vendor-detail"
      });
    }
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.SAVE_AS_PARENT_VENDOR, err);
    commit(MU.SET_ERROR_DETAILS, msg);
    await dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: msg
    });
  } finally {
    if (!data.continue) {
      commit(MU.SET_LOADING_DETAILS, false);
    }
  }
};

const callLinkedVendors = async ({ commit, rootState }, id) => {
  try {
    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    const { data } = await Axios.get(`/api/loan/${loan.id}/address/${id}/vendors`);
    commit(MU.SET_LINKED_VENDORS, data);
  } catch (err) {
    commit(MU.SET_ERROR_DETAILS, logAndGetErrorMessage(AC.CALL_LINKED_VENDORS, err));
  }
};

export const actions = {
  [AC.EDIT_VENDOR]: editVendor,
  [AC.CALL_GET_LIST]: callGetList,
  [AC.CALL_GET_DETAILS]: callGetDetails,
  [AC.CALL_GET_INVOICES]: callGetInvoices,
  [AC.CALL_SAVE_INVOICES]: callSaveInvoices,
  [AC.CALL_LINKED_VENDORS]: callLinkedVendors,
  [AC.SAVE_AS_PARENT_VENDOR]: saveAsParentDebtor
};
