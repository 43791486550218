import { compose, equals, prop } from "ramda";
import { getters as GE } from "./constants";

const defaultStyles = { hIdx: -1, hStyle: 0, rIdx: -1, rStyle: 1 };

export const getters = {
  [GE.GET_SPREADSHEET_DATA]: ({ preview }) => (sheet, config) => {
    if (!sheet && !preview) return [];

    const { hIdx, hStyle, rIdx, rStyle } = { ...defaultStyles, ...config };
    const rows = [];
    if (preview) {
      preview.forEach((item, rowIdx) => {
        const cells = {};
        for (let idx = 0; idx < item.length; idx++) {
          cells[idx] = {
            text: item[idx],
            ...(equals(hIdx - 1, rowIdx) ? { style: hStyle } : null),
            ...(equals(rIdx - 1, rowIdx) ? { style: rStyle } : null)
          };
        }
        rows[rowIdx] = { cells };
      });
    }
    return [
      {
        rows,
        name: sheet,
        styles: [
          {
            bgcolor: "#2ecc71",
            color: "#000000"
          },
          {
            bgcolor: "#f1c40f",
            color: "#000000"
          }
        ]
      }
    ];
  }
};
