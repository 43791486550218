import Axios from "axios";
import { omit, head, assoc, filter, compose, not, equals, prop, toLower, trim, toUpper } from "ramda";

import { actions as ACRoot, NOTIFICATION_TYPE } from "../root/constants";
import { actions as AC, mutations as MU, AUTHORITY_PERMISSION_ACTION } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

//remove permission from object according to the auth value
const removePermission = (auth, permissions) => {
  const isAuthority = compose(equals, toLower);
  return filter(compose(not, isAuthority(auth), toLower, prop("authority")), permissions);
};

const callGetGroups = async ({ commit, dispatch }) => {
  try {
    commit(MU.SET_LOADING_GROUP, true);
    const { data } = await Axios.get("/api/auth/groups");
    commit(MU.SET_LIST_GROUP, data);
    if (data.length > 0) {
      await dispatch(AC.CALL_GET_GROUP_DETAIL, head(data).groupName);
    }
  } catch (err) {
    commit(MU.SET_ERROR_GROUP, logAndGetErrorMessage(AC.CALL_GET_GROUPS, err));
  } finally {
    commit(MU.SET_LOADING_GROUP, false);
  }
};

const callGetGroupDetail = async ({ commit }, groupName) => {
  try {
    commit(MU.SET_LOADING_GROUP, true);
    const { data } = await Axios.get(`/api/authorities/group/${groupName}`);
    commit(MU.SET_CURRENT_GROUP, data);
  } catch (err) {
    commit(MU.SET_ERROR_GROUP, logAndGetErrorMessage(AC.CALL_GET_GROUP_DETAIL, err));
  } finally {
    commit(MU.SET_LOADING_GROUP, false);
  }
};

const callGetAuthorities = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_AUTHORITY, true);
    const { data } = await Axios.get("/api/authorities");
    commit(MU.SET_LIST_AUTHORITY, data);
  } catch (err) {
    commit(MU.SET_ERROR_AUTHORITY, logAndGetErrorMessage(AC.CALL_GET_AUTHORITIES, err));
  } finally {
    commit(MU.SET_LOADING_AUTHORITY, false);
  }
};

const callCreateAuthority = async ({ commit, dispatch, state }, formData) =>
  new Promise(async resolve => {
    let resultToResolve = false;
    try {
      commit(MU.SET_LOADING_AUTHORITY, true);
      const { authority } = state.current;
      const name = trim(formData.name);
      const body = {
        id: {
          section: authority.id,
          shortDescription: toUpper(name.replace(/\W/g, "_"))
        },
        longDescription: name
      };

      await Axios.post("/api/authorities", body);

      resultToResolve = true;

      // show modal to notify
      await dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
        type: NOTIFICATION_TYPE.success,
        body: `${authority.title} created!`,
        onSuccess: async () => await dispatch(AC.CALL_GET_AUTHORITIES)
      });
    } catch (err) {
      commit(MU.SET_ERROR_AUTHORITY, logAndGetErrorMessage(AC.CALL_CREATE_AUTHORITY, err));
    } finally {
      commit(MU.SET_LOADING_AUTHORITY, false);
    }
    resolve(resultToResolve);
  });

const addAuthToGroup = async ({ commit, state }, { formData: { shortDescription, section, name }, permission }) => {
  const authority = permission ? `${shortDescription}_${permission.toUpperCase()}` : shortDescription;
  const { authorityChanges } = state;
  const item = authorityChanges[authority];
  const auth = {
    authority,
    shortDescription: `${section}_${shortDescription}`,
    longDescription: name,
    action: AUTHORITY_PERMISSION_ACTION.add
  };

  const key = item ? item.authority : authority;
  const newAuthorityChanges = authorityChanges[key]
    ? removePermission(key, authorityChanges)
    : assoc(key, auth, authorityChanges);

  commit(MU.SET_AUTHORITY_CHANGES, newAuthorityChanges);
};

const removeAuthFromGroup = async ({ commit, state }, { authority }) => {
  const { authorityChanges } = state;
  const auth = {
    authority,
    action: AUTHORITY_PERMISSION_ACTION.remove
  };
  const item = authorityChanges[authority];
  const newAuthorityChanges = item
    ? removePermission(authority, authorityChanges)
    : assoc(authority, auth, authorityChanges);
  commit(MU.SET_AUTHORITY_CHANGES, newAuthorityChanges);
};

const callCreateGroup = ({ commit, dispatch }, data) =>
  new Promise(async resolve => {
    let resultToResolve = false;
    try {
      commit(MU.SET_LOADING_GROUP, true);

      await Axios.post(`/api/auth/group/${trim(data.name)}`, JSON.stringify([]));

      dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
        type: NOTIFICATION_TYPE.success,
        body: "Group created!",
        onSuccess: () => dispatch(AC.CALL_GET_GROUPS)
      });

      resultToResolve = true;
    } catch (err) {
      commit(MU.SET_ERROR_GROUP, logAndGetErrorMessage(AC.CALL_CREATE_GROUP, err));
    } finally {
      commit(MU.SET_LOADING_GROUP, false);
    }
    resolve(resultToResolve);
  });

const callSaveAuthChanges = async ({ commit, dispatch, state }) => {
  try {
    commit(MU.SET_LOADING_AUTHORITY, true);
    const { groupName } = state.current.group;
    const changes = Object.values(state.authorityChanges);
    const isRemove = compose(equals(AUTHORITY_PERMISSION_ACTION.remove), prop("action"));
    const isAdd = compose(equals(AUTHORITY_PERMISSION_ACTION.add), prop("action"));

    // remove
    const authsToRemove = changes
      .filter(isRemove)
      .map(({ authority }) => Axios.delete(`/api/auth/group/${groupName}/authority/${authority}`));

    //@krojas, Promise.allSetled
    await Promise.all(authsToRemove);

    // add
    const authsToAdd = changes
      .filter(isAdd)
      .map(a => Axios.put(`/api/auth/group/${groupName}/authority`, omit(["action"], a)));

    //@krojas, Promise.allSetled
    await Promise.all(authsToAdd);

    await dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.success,
      body: "Changes were successfully saved!",
      onSuccess: async () => commit(MU.SET_AUTHORITY_CHANGES, {})
    });

    await dispatch(AC.CALL_GET_AUTHORITIES);
    await dispatch(AC.CALL_GET_GROUP_DETAIL, groupName);
  } catch (err) {
    dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: logAndGetErrorMessage(AC.CALL_SAVE_AUTH_CHANGES, err)
    });
  } finally {
    commit(MU.SET_LOADING_AUTHORITY, false);
  }
};

export const actions = {
  [AC.CALL_GET_GROUPS]: callGetGroups,
  [AC.CALL_GET_GROUP_DETAIL]: callGetGroupDetail,
  [AC.CALL_GET_AUTHORITIES]: callGetAuthorities,
  [AC.CALL_CREATE_AUTHORITY]: callCreateAuthority,
  [AC.CALL_CREATE_GROUP]: callCreateGroup,
  [AC.CALL_SAVE_AUTH_CHANGES]: callSaveAuthChanges,
  [AC.ADD_AUTH_TO_GROUP]: addAuthToGroup,
  [AC.REMOVE_AUTH_FROM_GROUP]: removeAuthFromGroup
};
