import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", { ...state.loading, ...loading });
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", { ...state.error, ...error });
  },
  [MU.SET_EXCEL_SHEET_NAMES]: function(state, sheets) {
    Vue.set(state, "sheets", sheets);
  },
  [MU.SET_PROCESS_DOCUMENT_PREVIEW]: function(state, preview) {
    Vue.set(state, "preview", preview);
  },
  [MU.SET_PROCESS_DOCUMENT]: function(state, doc) {
    Vue.set(state, "doc", doc);
  },
  [MU.SET_COLUMN_MAP]: function(state, columnMap) {
    Vue.set(state, "columnMap", columnMap);
  },
  [MU.SET_PROCESSING]: function(state, processing) {
    Vue.set(state, "processing", processing);
  }
};
