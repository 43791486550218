import { appendName } from "../utils";

const append = appendName("USER");

export const actions = {
  CALL_GET_USER_INFORMATION: append("CALL_GET_USER_INFORMATION"),
  CALL_GET_SIENA_USERS: append("CALL_GET_SIENA_USERS"),
  CALL_LOGIN: append("CALL_LOGIN"),
  CALL_LOGOUT: append("CALL_LOGOUT"),
  CALL_GET_USERS: append("CALL_USERS"),
  SAVE_USER_INFORMATION: append("SAVE_USER_INFORMATION"),
  DEACTIVATE_USER: append("DEACTIVATE_USER"),
  CALL_GET_GROUP_NAMES: append("CALL_GET_GROUP_NAMES"),
  SAVE_PRIMARY_CONTACT: append("SAVE_PRIMARY_CONTACT"),
  CALL_AUTHORIZE: append("CALL_AUTHORIZE"),
  CALL_RESET_PASSWORD: append("CALL_RESET_PASSWORD"),
  CALL_CHANGE_PASSWORD: append("CALL_CHANGE_PASSWORD"),
  CALL_CHANGE_EXPIRED_PASSWORD: append("CALL_CHANGE_EXPIRED_PASSWORD")
};

export const getters = {
  LOGGED_USER_HAS_PERMISSION: append("LOGGED_USER_HAS_PERMISSION"),
  GET_USERS_BY_CLIENT_ID: append("GET_USERS_BY_CLIENT_ID"),
  GET_BORROWER_TYPE_OPTIONS: append("GET_BORROWER_TYPE_OPTIONS")
};

export const mutations = {
  //> common
  SET_PROFILE: append("SET_PROFILE"),
  SET_GROUPS: append("SET_GROUPS"),
  SET_AUTHORITIES: append("SET_AUTHORITIES"),
  //> list
  SET_LOADING_LIST: append("SET_LOADING_LIST"),
  SET_ERROR_LIST: append("SET_ERROR_LIST"),
  SET_LIST_USER: append("SET_LIST_USER"),
  //> modal
  SET_LOADING_MODAL: append("SET_LOADING_MODAL"),
  SET_ERROR_MODAL: append("SET_ERROR_MODAL"),
  SET_USER_ACTION: append("SET_USER_ACTION"),
  //> login/password
  SET_LOGIN_ERROR_CODE: append("SET_LOGIN_ERROR_CODE"),
  SET_LOADING_AUTHORIZE: append("SET_LOADING_AUTHORIZE"),
  SET_LOADING_PASSWORD: append("SET_LOADING_PASSWORD"),
  SET_ERROR_AUTHORIZE: append("SET_ERROR_AUTHORIZE"),
  SET_ERROR_PASSWORD: append("SET_ERROR_PASSWORD"),
  //> siena
  SET_LIST_USER_SIENA: append("SET_LIST_USER_SIENA"),
  SET_LOGGED_USER_IS_SIENA: append("SET_LOGGED_USER_IS_SIENA"),
  SET_LOADING_SIENA_LIST: append("SET_LOADING_SIENA_LIST"),
  //> clean
  FULL_CLEAR_STATE: append("FULL_CLEAR_STATE")
};

export const DIVISIONS = {
  SIENA: "siena",
  HEALTHCARE: "healthcare",
  CANADA: "canada",
  GLOBAL: "global"
};

export const DIVISION_OPTIONS = {
  [DIVISIONS.SIENA]: "Siena",
  [DIVISIONS.HEALTHCARE]: "Healthcare",
  [DIVISIONS.CANADA]: "Canada",
  [DIVISIONS.GLOBAL]: "Global"
};

export const USER_ACTION = {
  new: "NEW",
  edit: "EDIT"
};

export const LOGIN_ERROR_CODES = {
  invalid: "invalid",
  expired: "expired",
  locked: "locked"
};

export const DEFAULT_STATE = {
  profile: {},
  groups: [],
  loading: { list: false, modal: false, siena: false, password: false },
  error: { list: null, modal: null, authorize: null, password: null },
  list: { user: [], userSiena: [] },
  loginErrorCode: null,
  isSienaUser: false,
  action: USER_ACTION.new
};
