import Axios from "axios";
import { assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACBorrower } from "@/store/borrower-loan/constants";
import { mutations as MUBorrower } from "@/store/borrower/constants";

import { mutations as MUAppraisal } from "@/store/borrower/appraisal/constants";
import { mutations as MUBankAccount } from "@/store/borrower/bank-account/constants";
import { mutations as MUContactInformation } from "@/store/borrower/contact-information/constants";
import { mutations as MUCovenant } from "@/store/borrower/covenant/constants";
import { mutations as MUDealStructure } from "@/store/borrower/deal-structure/constants";
import { mutations as MUFieldExam } from "@/store/borrower/field-exam/constants";
import { mutations as MUOther } from "@/store/borrower/other/constants";
import { mutations as MUSubordinatorGuarantor } from "@/store/borrower/subordinator-guarantor/constants";
import { mutations as MUYieldFee } from "@/store/borrower/yield-fee/constants";
import { mutations as MULoanDocument } from "@/store/loan-document/constants";
import { mutations as MUUSer } from "@/store/user/constants";
import { mutations as MUScheduleD } from "@/store/schedule-d/constants";

import { logAndGetErrorMessage } from "@/store/utils";

const callGetBorrowerDetail = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_BORROWER, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}`);
    commit(MU.SET_INFORMATION_GENERAL_INFO_BORROWER, data);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_BORROWER, logAndGetErrorMessage(AC.CALL_GET_BORROWER_DETAIL, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_BORROWER, false);
  }
};

const saveBorrowerDetail = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_BORROWER, true);
    await Axios.put(`/api/borrower/${formData.objid}`, assoc("clientid", clientid, formData));
    await dispatch(AC.CALL_GET_BORROWER_DETAIL);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_BORROWER, logAndGetErrorMessage(AC.SAVE_BORROWER_DETAIL, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_BORROWER, false);
  }
};

const createBorrowerDetail = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_BORROWER, true);
    const { data } = await Axios.post("/api/borrower", { borrower: assoc("status", "active", formData) });

    await dispatch(ACBorrower.CALL_BORROWERS);
    await dispatch(ACBorrower.SAVE_CURRENT_BORROWER_ID, data.clientid);

    const responses = await Promise.allSettled([
      dispatch(AC.CALL_GET_BORROWER_DETAIL),
      dispatch(AC.CALL_GET_OWNERSHIP),
      dispatch(AC.CALL_GET_SPONSORS)
    ]);

    const hasError = responses.find(r => r.status === "rejected");
    if (hasError) {
      commit(
        MU.SET_ERROR_GENERAL_INFO_BORROWER,
        logAndGetErrorMessage(
          AC.CREATE_BORROWER_DETAIL,
          "There was an error refreshing the data after creating the borrower."
        )
      );
    } else {
      // Clear out possible other borrower information as new borrower created
      commit(MUAppraisal.FULL_CLEAR_STATE);
      commit(MUBankAccount.FULL_CLEAR_STATE);
      commit(MUContactInformation.FULL_CLEAR_STATE);
      commit(MUCovenant.FULL_CLEAR_STATE);
      commit(MUDealStructure.FULL_CLEAR_STATE);
      commit(MUFieldExam.FULL_CLEAR_STATE);
      commit(MUOther.FULL_CLEAR_STATE);
      commit(MUSubordinatorGuarantor.FULL_CLEAR_STATE);
      commit(MUYieldFee.FULL_CLEAR_STATE);
      commit(MULoanDocument.FULL_CLEAR_STATE);
      commit(MUUSer.FULL_CLEAR_STATE);
      commit(MUScheduleD.FULL_CLEAR_STATE);
    }

    commit(MUBorrower.SET_CREATE_MODE, false);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_BORROWER, logAndGetErrorMessage(AC.CREATE_BORROWER_DETAIL, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_BORROWER, false);
  }
};

const callGetSponsors = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/Sponsor`);
    commit(MU.SET_LIST_GENERAL_INFO_SPONSORS, data);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_SPONSORS, logAndGetErrorMessage(AC.CALL_GET_SPONSORS, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, false);
  }
};

const callGetOwnership = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/Ownership`);
    commit(MU.SET_LIST_GENERAL_INFO_OWNERSHIP, data);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_OWNERSHIP, logAndGetErrorMessage(AC.CALL_GET_OWNERSHIP, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, false);
  }
};

const createSponsor = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, true);
    await Axios.post(`/api/borrower/helper/${clientid}/Sponsor`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_SPONSORS);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_SPONSORS, logAndGetErrorMessage(AC.CREATE_SPONSORS, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, false);
  }
};

const createOwnership = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, true);
    await Axios.post(`/api/borrower/helper/${clientid}/Ownership`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_OWNERSHIP);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_OWNERSHIP, logAndGetErrorMessage(AC.CREATE_OWNERSHIP, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, false);
  }
};

const updateSponsor = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/Sponsor`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_SPONSORS);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_SPONSORS, logAndGetErrorMessage(AC.UPDATE_SPONSORS, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, false);
  }
};

const updateOwnership = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/Ownership`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_OWNERSHIP);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_OWNERSHIP, logAndGetErrorMessage(AC.UPDATE_OWNERSHIP, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, false);
  }
};

const deleteSponsor = async ({ commit, dispatch }, id) => {
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, true);
    await Axios.delete(`/api/borrower/helper/${id}/Sponsor`);
    dispatch(AC.CALL_GET_SPONSORS);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_SPONSORS, logAndGetErrorMessage(AC.DELETE_SPONSORS, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_SPONSORS, false);
  }
};

const deleteOwnership = async ({ commit, dispatch }, id) => {
  try {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, true);
    await Axios.delete(`/api/borrower/helper/${id}/Ownership`);
    dispatch(AC.CALL_GET_OWNERSHIP);
  } catch (err) {
    commit(MU.SET_ERROR_GENERAL_INFO_OWNERSHIP, logAndGetErrorMessage(AC.DELETE_OWNERSHIP, err));
  } finally {
    commit(MU.SET_LOADING_GENERAL_INFO_OWNERSHIP, false);
  }
};

export const actions = {
  [AC.CALL_GET_BORROWER_DETAIL]: callGetBorrowerDetail,
  [AC.SAVE_BORROWER_DETAIL]: saveBorrowerDetail,
  [AC.CREATE_BORROWER_DETAIL]: createBorrowerDetail,
  [AC.CALL_GET_SPONSORS]: callGetSponsors,
  [AC.CALL_GET_OWNERSHIP]: callGetOwnership,
  [AC.CREATE_SPONSORS]: createSponsor,
  [AC.CREATE_OWNERSHIP]: createOwnership,
  [AC.UPDATE_SPONSORS]: updateSponsor,
  [AC.UPDATE_OWNERSHIP]: updateOwnership,
  [AC.DELETE_SPONSORS]: deleteSponsor,
  [AC.DELETE_OWNERSHIP]: deleteOwnership
};
