import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const DocumentModule = {
  state: {
    list: [],
    loading: false,
    error: null,
    definition: {
      list: {},
      byPermission: [],
      changes: {}, //{documentid: {values}}
      loading: false,
      error: null
    },
    upload: {
      files: 0,
      uploading: false,
      error: null, //{fileName: {docName: String, error: String}}
      progress: null //{documentid: value}
    }
  },
  actions,
  getters,
  mutations
};
