import { actions } from "./actions";
import { mutations } from "./mutations";

export const AuditLogModule = {
  state: {
    loading: false,
    error: null
  },
  actions,
  mutations,
  getters: {}
};
