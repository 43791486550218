import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_COVENANT]: function(state, loading) {
    Vue.set(state.covenant.loading, "covenant", loading);
  },
  [MU.SET_LOADING_COVENANT_TEST]: function(state, loading) {
    Vue.set(state.covenant.loading, "covenantTest", loading);
  },
  [MU.SET_LOADING_DEFAULT]: function(state, loading) {
    Vue.set(state.covenant.loading, "default", loading);
  },
  [MU.SET_LOADING_COVENANT_FORM]: function(state, loading) {
    Vue.set(state.covenant.form, "loading", loading);
  },
  [MU.SET_ERROR_COVENANT]: function(state, error) {
    Vue.set(state.covenant.error, "covenant", error);
  },
  [MU.SET_ERROR_COVENANT_TEST]: function(state, error) {
    Vue.set(state.covenant.error, "covenantTest", error);
  },
  [MU.SET_ERROR_DEFAULT]: function(state, error) {
    Vue.set(state.covenant.error, "default", error);
  },
  [MU.SET_ERROR_COVENANT_FORM]: function(state, error) {
    Vue.set(state.covenant.form, "error", error);
  },
  [MU.SET_LIST_COVENANT]: function(state, data) {
    Vue.set(state.covenant.list, "covenant", data);
  },
  [MU.SET_LIST_DEFAULT]: function(state, data) {
    Vue.set(state.covenant.list, "default", data);
  },
  [MU.SET_COVENANT_FORM]: function(state, data) {
    Vue.set(state.covenant.form, "data", data);
  },
  [MU.SET_COVENANT_TEST_MODAL]: function(state, data) {
    Vue.set(state.covenant, "covenantTestModal", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "covenant", clone(DEFAULT_STATE));
  }
};
