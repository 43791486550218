import { actions as AC, mutations as MU, NOTIFICATION_TYPE, DEFAULT_NOTIFICATION_VALUES } from "./constants";

const showAlertNotification = ({ commit }, values) =>
  commit(MU.SET_NOTIFICATION_INFO, {
    ...DEFAULT_NOTIFICATION_VALUES,
    ...values,
    show: true,
    alert: true,
    showOkButton: true,
    showCancelButton: false
  });

const showConfirmLeaveRoute = ({ commit }, values) =>
  commit(MU.SET_NOTIFICATION_INFO, {
    ...DEFAULT_NOTIFICATION_VALUES,
    type: null,
    title: "Confirmation",
    body: "Do you really want to leave? You have unsaved changes!",
    ...values,
    show: true,
    alert: false,
    showOkButton: true,
    showCancelButton: true
  });

const showConfirmAction = ({ commit }, values) =>
  commit(MU.SET_NOTIFICATION_INFO, {
    ...DEFAULT_NOTIFICATION_VALUES,
    type: null,
    title: "Confirmation",
    ...values,
    show: true,
    alert: false,
    showOkButton: true,
    showCancelButton: true
  });

const closeNotification = ({ commit }) => commit(MU.SET_NOTIFICATION_INFO, DEFAULT_NOTIFICATION_VALUES);

const showSuccessNotification = (context, msg = "Changes were successfully saved!") =>
  showAlertNotification(context, {
    type: NOTIFICATION_TYPE.success,
    body: msg
  });

const showErrorNotification = (context, error) =>
  showAlertNotification(context, {
    type: NOTIFICATION_TYPE.error,
    body: error
  });

export const actions = {
  [AC.SHOW_SUCCESS_NOTIFICATION]: showSuccessNotification,
  [AC.SHOW_ERROR_NOTIFICATION]: showErrorNotification,
  [AC.SHOW_ALERT_NOTIFICATION]: showAlertNotification,
  [AC.SHOW_CONFIRM_LEAVE_ROUTE]: showConfirmLeaveRoute,
  [AC.SHOW_CONFIRM_ACTION_NOTIFICATION]: showConfirmAction,
  [AC.CLOSE_NOTIFICATION]: closeNotification
};
