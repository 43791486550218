import { assoc, reduce } from "ramda";
import { getters as GE } from "./constants";

const reduceByName = reduce((p, c) => assoc(c.code, c, p), {});

export const getters = {
  [GE.GET_AGING_SPREADS_BY_DAY]: ({ categoryCodes }) =>
    categoryCodes ? reduceByName(categoryCodes.aging_spreads.list) : {},

  [GE.GET_INELIGIBLE_DEFAULTS_BY_NAME]: ({ categoryCodes }) =>
    categoryCodes ? reduceByName(categoryCodes.ineligible_defaults.list) : {},

  [GE.GET_INELIGIBLE_CODES_WITHOUT_OV_XA]: ({ categoryCodes }) => {
    if (!categoryCodes) return [];
    return categoryCodes.ineligible_codes.list.filter(({ code }) => code !== "OV" && code !== "XA");
  }
};
