import Axios from "axios";
import { compose, equals, prop, not } from "ramda";

import { actions as AC, mutations as MU, CONTRAS_CALCULATOR_MATCHES_STATE } from "./constants";
import { actions as ROOT_AC, NOTIFICATION_TYPE } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetVendorInformation = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING_VENDOR, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const { data } = await Axios.get(`/api/loan/${loan.id}/ap/balances`);
    const vendors = data.map(({ id, name, accountBalance }) => ({ id, name, accountBalance }));

    commit(MU.SET_LIST_VENDOR, vendors);
  } catch (err) {
    commit(MU.SET_ERROR_VENDOR, logAndGetErrorMessage(AC.CALL_GET_VENDOR_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_VENDOR, false);
  }
};

const callGetDebtorInformation = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING_DEBTOR, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const { data } = await Axios.get(`/api/loan/${loan.id}/debtors/balances`);
    const debtors = data.map(({ id, name, accountBalance }) => ({ id, name, accountBalance }));

    commit(MU.SET_LIST_DEBTOR, debtors);
  } catch (err) {
    commit(MU.SET_ERROR_DEBTOR, logAndGetErrorMessage(AC.CALL_GET_DEBTOR_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_DEBTOR, false);
  }
};

const callGetMatchesInformation = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING_MATCHES, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const { data } = await Axios.get(`/api/loan/${loan.id}/contras`);
    const matches = data.map(({ objid, vendor, debtor }) => ({
      action: objid ? CONTRAS_CALCULATOR_MATCHES_STATE.MATCHED : CONTRAS_CALCULATOR_MATCHES_STATE.CREATE,
      objid,
      vendor: { id: vendor.id, name: vendor.vendorname, accountBalance: vendor.accountBalance },
      debtor: { id: debtor.id, name: debtor.debtorname, accountBalance: debtor.accountBalance }
    }));

    commit(MU.SET_LIST_MATCHES, matches);
  } catch (err) {
    commit(MU.SET_ERROR_MATCHES, logAndGetErrorMessage(AC.CALL_GET_MATCHES_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_MATCHES, false);
  }
};

const callSaveAllChanges = async ({ commit, dispatch, rootState, state }) => {
  try {
    commit(MU.SET_LOADING_SAVING, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    const notMatched = compose(not, equals(CONTRAS_CALCULATOR_MATCHES_STATE.MATCHED), prop("action"));
    const data = state.list.matches
      .filter(notMatched)
      .map(m => ({ ...m, loanid: loan.id, vendorid: m.vendor.id, debtorid: m.debtor.id }));

    await Axios.put(`/api/loan/${loan.id}/contras`, JSON.stringify(data));

    if (state.calculateIneligibles) {
      await Axios.put(`/api/loan/${loan.id}/calculate/ineligible`);
    }

    await dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.success,
      body: "Changes were successfully saved!",
      redirectTo: "loan-stats"
    });
  } catch (err) {
    dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: logAndGetErrorMessage(AC.CALL_SAVE_ALL_CHANGES, err)
    });
  } finally {
    commit(MU.SET_LOADING_SAVING, false);
  }
};

const callGetLastUpdated = ({ state, dispatch, rootState }, reportId) =>
  new Promise(async resolve => {
    try {
      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];
      const { data } = await Axios.get(`/api/loan/${loan.id}/lastupdated/max`);
      resolve(data);
    } catch (err) {
      logAndGetErrorMessage(AC.CALL_GET_LAST_UPDATED, err);
    }

    resolve([]);
  });

export const actions = {
  [AC.CALL_GET_VENDOR_INFORMATION]: callGetVendorInformation,
  [AC.CALL_GET_DEBTOR_INFORMATION]: callGetDebtorInformation,
  [AC.CALL_GET_MATCHES_INFORMATION]: callGetMatchesInformation,
  [AC.CALL_SAVE_ALL_CHANGES]: callSaveAllChanges,
  [AC.CALL_GET_LAST_UPDATED]: callGetLastUpdated
};
