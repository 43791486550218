class SessionStorageManager {
  constructor() {
    this.CLIENT_ID = "CLIENT_ID";
    this.ABLSERVE_MESSAGE_CLOSED = "ABLSERVE_MESSAGE_CLOSED";
  }

  clearAll() {
    this.removeClientId();
    this.removeAblserveMessageClosed();
  }

  getClientId() {
    return sessionStorage.getItem(this.CLIENT_ID);
  }

  setClientId(clientid) {
    if (clientid) {
      sessionStorage.setItem(this.CLIENT_ID, clientid);
    }
  }

  removeClientId() {
    sessionStorage.removeItem(this.CLIENT_ID);
  }

  getAblserveMessageClosed() {
    return sessionStorage.getItem(this.ABLSERVE_MESSAGE_CLOSED) || false;
  }

  setAblserveMessageClosed(closed) {
    sessionStorage.setItem(this.ABLSERVE_MESSAGE_CLOSED, closed);
  }

  removeAblserveMessageClosed() {
    sessionStorage.removeItem(this.ABLSERVE_MESSAGE_CLOSED);
  }
}

export const SessionStorage = new SessionStorageManager();
