import { appendName } from "../utils";

const append = appendName("AUDITLOG");

export const actions = {
  CALL_GET_TABLE_NAMES: append("CALL_GET_TABLE_NAMES"),
  CALL_GET_LOG_ENTRIES: append("CALL_GET_LOG_ENTRIES")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR")
};
