import { appendName } from "@/store/utils";

import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_BANK_ACCOUNTS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_BANK_ACCOUNTS"),
  CALL_GET_DISBURSEMENT_ACCOUNTS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_DISBURSEMENT_ACCOUNTS"),

  CREATE_BANK_ACCOUNT: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_BANK_ACCOUNT"),
  CREATE_DISBURSEMENT_ACCOUNT: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_DISBURSEMENT_ACCOUNT"),

  UPDATE_BANK_ACCOUNT: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_BANK_ACCOUNT"),
  UPDATE_DISBURSEMENT_ACCOUNT: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_DISBURSEMENT_ACCOUNT"),

  DELETE_BANK_ACCOUNT: APPEND_BORROWER_DETAIL_MODULE_NAME("DELETE_BANK_ACCOUNT")
};

export const mutations = {
  //> Bank
  SET_LOADING_BANK_ACCOUNT: appendToLoading("BANK_ACCOUNT"),
  SET_ERROR_BANK_ACCOUNT: appendToError("BANK_ACCOUNT"),
  SET_LIST_BANK_ACCOUNT: appendToList("BANK_ACCOUNT"),
  //> Disbursement
  SET_LOADING_DISBURSEMENT_ACCOUNT: appendToLoading("DISBURSEMENT_ACCOUNT"),
  SET_ERROR_DISBURSEMENT_ACCOUNT: appendToError("DISBURSEMENT_ACCOUNT"),
  SET_LIST_DISBURSEMENT_ACCOUNT: appendToList("DISBURSEMENT_ACCOUNT"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("DISBURSEMENT_ACCOUNT")
};

export const DEFAULT_STATE = {
  loading: { bank: false, disbursement: false },
  error: { bank: null, disbursement: null },
  list: { bank: [], disbursement: [] }
};
