import Axios from "axios";
import { assoc, isEmpty, omit } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetMonitoringGeneral = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_MONITORING_GENERAL, true);

    const responses = await Promise.allSettled([
      Axios.get(`/api/borrower/client/${clientid}/summary/monitoringgeneral`),
      Axios.get(`/api/client/${clientid}/fta/salesProjection`)
    ]);

    const hasError = responses.find(r => r.status === "rejected");
    if (hasError || isEmpty(responses) || responses.length < 2) {
      commit(
        MU.SET_ERROR_MONITORING_GENERAL,
        logAndGetErrorMessage(AC.CALL_GET_MONITORING_GENERAL, "There was an error retrieving Other information.")
      );
    } else {
      const data = responses[0].value.data;
      const salesProjection = responses[1].value.data;

      // Set in main data object
      data.salesProjMonth = salesProjection.date;
      data.salesProjValue = salesProjection.amount;

      commit(MU.SET_INFORMATION_MONITORING_GENERAL, data);
    }
  } catch (err) {
    commit(MU.SET_ERROR_MONITORING_GENERAL, logAndGetErrorMessage(AC.CALL_GET_MONITORING_GENERAL, err));
  } finally {
    commit(MU.SET_LOADING_MONITORING_GENERAL, false);
  }
};

const saveMonitoringGeneral = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_MONITORING_GENERAL, true);
    await Axios.patch(
      `/api/borrower/client/${clientid}/summary/monitoringgeneral`,
      assoc("clientid", clientid, formData)
    );
    dispatch(AC.CALL_GET_MONITORING_GENERAL);
  } catch (err) {
    commit(MU.SET_ERROR_MONITORING_GENERAL, logAndGetErrorMessage(AC.SAVE_MONITORING_GENERAL, err));
  } finally {
    commit(MU.SET_LOADING_MONITORING_GENERAL, false);
  }
};

const callGetFinanceSettings = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_FINANCE_SETTINGS, true);
    const { data } = await Axios.get(`/api/borrower/${clientid}/finance`);

    const omitKeys = omit(["createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"]);
    commit(MU.SET_INFORMATION_FINANCE_SETTINGS, omitKeys(data));
  } catch (err) {
    commit(MU.SET_ERROR_FINANCE_SETTINGS, logAndGetErrorMessage(AC.CALL_GET_FINANCE_SETTINGS, err));
  } finally {
    commit(MU.SET_LOADING_FINANCE_SETTINGS, false);
  }
};

const saveFinanceSettings = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_FINANCE_SETTINGS, true);
    if (formData.id) {
      await Axios.put(`/api/borrower/${clientid}/finance/${formData.id}`, assoc("clientid", clientid, formData));
    } else {
      await Axios.post(`/api/borrower/${clientid}/finance`, assoc("clientid", clientid, formData));
    }
    dispatch(AC.CALL_GET_FINANCE_SETTINGS);
  } catch (err) {
    commit(MU.SET_ERROR_FINANCE_SETTINGS, logAndGetErrorMessage(AC.SAVE_FINANCE_SETTINGS, err));
  } finally {
    commit(MU.SET_LOADING_FINANCE_SETTINGS, false);
  }
};

const callGetCustomerVisit = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_CUSTOMER_VISIT, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/CustomerVisit`);
    commit(MU.SET_LIST_CUSTOMER_VISIT, data);
  } catch (err) {
    commit(MU.SET_ERROR_CUSTOMER_VISIT, logAndGetErrorMessage(AC.CALL_GET_CUSTOMER_VISIT, err));
  } finally {
    commit(MU.SET_LOADING_CUSTOMER_VISIT, false);
  }
};

const callGetPostClose = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_POST_CLOSE, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/PostClose`);
    commit(MU.SET_LIST_POST_CLOSE, data);
  } catch (err) {
    commit(MU.SET_ERROR_POST_CLOSE, logAndGetErrorMessage(AC.CALL_GET_POST_CLOSE, err));
  } finally {
    commit(MU.SET_LOADING_POST_CLOSE, false);
  }
};

const callGetInsurance = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_INSURANCE, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/Insurance`);
    commit(MU.SET_LIST_INSURANCE, data);
  } catch (err) {
    commit(MU.SET_ERROR_INSURANCE, logAndGetErrorMessage(AC.CALL_GET_INSURANCE, err));
  } finally {
    commit(MU.SET_LOADING_INSURANCE, false);
  }
};

const createCustomerVisit = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_CUSTOMER_VISIT, true);
    await Axios.post(`/api/borrower/helper/${clientid}/CustomerVisit`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_CUSTOMER_VISIT);
  } catch (err) {
    commit(MU.SET_ERROR_CUSTOMER_VISIT, logAndGetErrorMessage(AC.CREATE_CUSTOMER_VISIT, err));
  } finally {
    commit(MU.SET_LOADING_CUSTOMER_VISIT, false);
  }
};

const createPostClose = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_POST_CLOSE, true);
    await Axios.post(`/api/borrower/helper/${clientid}/PostClose`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_POST_CLOSE);
  } catch (err) {
    commit(MU.SET_ERROR_POST_CLOSE, logAndGetErrorMessage(AC.CREATE_POST_CLOSE, err));
  } finally {
    commit(MU.SET_LOADING_POST_CLOSE, false);
  }
};

const createInsurance = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_INSURANCE, true);
    await Axios.post(`/api/borrower/helper/${clientid}/Insurance`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_INSURANCE);
  } catch (err) {
    commit(MU.SET_ERROR_INSURANCE, logAndGetErrorMessage(AC.CREATE_INSURANCE, err));
  } finally {
    commit(MU.SET_LOADING_INSURANCE, false);
  }
};

const updateCustomerVisit = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_CUSTOMER_VISIT, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/CustomerVisit`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_CUSTOMER_VISIT);
  } catch (err) {
    commit(MU.SET_ERROR_CUSTOMER_VISIT, logAndGetErrorMessage(AC.UPDATE_CUSTOMER_VISIT, err));
  } finally {
    commit(MU.SET_LOADING_CUSTOMER_VISIT, false);
  }
};

const updatePostClose = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_POST_CLOSE, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/PostClose`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_POST_CLOSE);
  } catch (err) {
    commit(MU.SET_ERROR_POST_CLOSE, logAndGetErrorMessage(AC.UPDATE_POST_CLOSE, err));
  } finally {
    commit(MU.SET_LOADING_POST_CLOSE, false);
  }
};

const updateInsurance = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_INSURANCE, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/Insurance`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_INSURANCE);
  } catch (err) {
    commit(MU.SET_ERROR_INSURANCE, logAndGetErrorMessage(AC.UPDATE_INSURANCE, err));
  } finally {
    commit(MU.SET_LOADING_INSURANCE, false);
  }
};

export const actions = {
  [AC.CALL_GET_MONITORING_GENERAL]: callGetMonitoringGeneral,
  [AC.SAVE_MONITORING_GENERAL]: saveMonitoringGeneral,
  [AC.CALL_GET_FINANCE_SETTINGS]: callGetFinanceSettings,
  [AC.SAVE_FINANCE_SETTINGS]: saveFinanceSettings,
  [AC.CALL_GET_INSURANCE]: callGetInsurance,
  [AC.CALL_GET_CUSTOMER_VISIT]: callGetCustomerVisit,
  [AC.CALL_GET_POST_CLOSE]: callGetPostClose,

  [AC.CREATE_CUSTOMER_VISIT]: createCustomerVisit,
  [AC.CREATE_POST_CLOSE]: createPostClose,
  [AC.CREATE_INSURANCE]: createInsurance,

  [AC.UPDATE_CUSTOMER_VISIT]: updateCustomerVisit,
  [AC.UPDATE_POST_CLOSE]: updatePostClose,
  [AC.UPDATE_INSURANCE]: updateInsurance
};
