import { appendName } from "../utils";

const append = appendName("TRIAL_BALANCE");

export const actions = {
  //> category
  CALL_GET_GRID_CATEGORY_INFORMATION: append("CALL_GET_GRID_CATEGORY_INFORMATION"),
  CREATE_TRIAL_BALANCE_CATEGORY: append("CREATE_TRIAL_BALANCE_CATEGORY"),
  UPDATE_TRIAL_BALANCE_CATEGORY: append("UPDATE_TRIAL_BALANCE_CATEGORY"),

  //> fta field
  CALL_GET_FTA_FIELD_INFORMATION: append("CALL_GET_FTA_FIELD_INFORMATION"),

  //> mapping
  CALL_GET_TRIAL_BALANCE_MAPPING_GRID: append("CALL_GET_TRIAL_BALANCE_MAPPING_GRID"),
  SAVE_TRIAL_BALANCE_MAPPING_DETAIL: append("SAVE_TRIAL_BALANCE_MAPPING_DETAIL")
};

export const mutations = {
  //> grid
  SET_LOADING_GRID_CATEGORY: append("SET_LOADING_GRID_CATEGORY"),
  SET_ERROR_GRID_CATEGORY: append("SET_ERROR_GRID_CATEGORY"),
  SET_LIST_GRID_CATEGORY: append("SET_LIST_GRID_CATEGORY"),

  //> mapping
  SET_LOADING_GRID_MAPPING: append("SET_LOADING_GRID_MAPPING"),
  SET_ERROR_GRID_MAPPING: append("SET_ERROR_GRID_MAPPING"),
  SET_LIST_GRID_MAPPING: append("SET_LIST_GRID_MAPPING"),

  //> fta field
  SET_LOADING_FTA_FIELD: append("SET_LOADING_FTA_FIELD"),
  SET_ERROR_FTA_FIELD: append("SET_ERROR_FTA_FIELD"),
  SET_LIST_FTA_FIELD: append("SET_LIST_FTA_FIELD"),

  //> modal
  SET_LOADING_MODAL_CATEGORY: append("SET_LOADING_MODAL_CATEGORY"),
  SET_ERROR_MODAL_CATEGORY: append("SET_ERROR_MODAL_CATEGORY"),

  //> modal mapping
  SET_LOADING_MODAL_MAPPING: append("SET_LOADING_MODAL_MAPPING"),
  SET_ERROR_MODAL_MAPPING: append("SET_ERROR_MODAL_MAPPING"),

  //> curernt id
  SET_CURRENT_DOCUMENT_INFORMATION: append("SET_CURRENT_DOCUMENT_INFORMATION")
};

export const getters = {
  GET_FTA_FIELD_AS_OPT_GROUP: append("GET_FTA_FIELD_AS_OPT_GROUP")
};
