import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_GRID_CATEGORY]: function(state, loading) {
    Vue.set(state.loading, "categoryGrid", loading);
  },
  [MU.SET_LOADING_GRID_MAPPING]: function(state, loading) {
    Vue.set(state.loading, "mappingGrid", loading);
  },
  [MU.SET_LOADING_FTA_FIELD]: function(state, loading) {
    Vue.set(state.loading, "ftaField", loading);
  },
  [MU.SET_LOADING_MODAL_CATEGORY]: function(state, loading) {
    Vue.set(state.loading, "categoryModal", loading);
  },
  [MU.SET_LOADING_MODAL_MAPPING]: function(state, loading) {
    Vue.set(state.loading, "mappingModal", loading);
  },

  [MU.SET_ERROR_GRID_CATEGORY]: function(state, error) {
    Vue.set(state.error, "categoryGrid", error);
  },
  [MU.SET_ERROR_GRID_MAPPING]: function(state, loading) {
    Vue.set(state.loading, "mappingGrid", loading);
  },
  [MU.SET_ERROR_FTA_FIELD]: function(state, error) {
    Vue.set(state.error, "ftaField", error);
  },
  [MU.SET_ERROR_MODAL_CATEGORY]: function(state, error) {
    Vue.set(state.error, "categoryModal", error);
  },
  [MU.SET_ERROR_MODAL_MAPPING]: function(state, error) {
    Vue.set(state.error, "mappingModal", error);
  },

  [MU.SET_LIST_GRID_CATEGORY]: function(state, categoryCodes) {
    Vue.set(state.list, "categoryGrid", categoryCodes);
  },
  [MU.SET_LIST_GRID_MAPPING]: function(state, categoryCodes) {
    Vue.set(state.list, "mappingGrid", categoryCodes);
  },
  [MU.SET_LIST_FTA_FIELD]: function(state, categoryCodes) {
    Vue.set(state.list, "ftaField", categoryCodes);
  },
  [MU.SET_CURRENT_DOCUMENT_INFORMATION]: function(state, current) {
    Vue.set(state, "current", { ...state.current, ...current });
  }
};
