import { appendName } from "@/store/utils";

const append = appendName("BORROWER_STATUS");

export const actions = {
  CALL_GET_BORROWER_STATUS: append("CALL_GET_BORROWER_STATUS"),
  SAVE_BORROWER_STATUS: append("SAVE_BORROWER_STATUS")
};

export const mutations = {
  //> status
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_DATA: append("SET_DATA"),

  //> clean
  FULL_CLEAR_STATE: append("FULL_CLEAR_STATE")
};

export const DEFAULT_STATE = {
  loading: false,
  error: null,
  data: { primary: null, siena: null }
};
