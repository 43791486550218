import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_NAVIGATION]: function(state, loading) {
    Vue.set(state.loading, "navigation", loading);
  },
  [MU.SET_LOADING_DEBTOR]: function(state, loading) {
    Vue.set(state.loading, "debtor", loading);
  },
  [MU.SET_LOADING_ADJUSTMENT]: function(state, loading) {
    Vue.set(state.loading, "adjustment", loading);
  },
  [MU.SET_ERROR_NAVIGATION]: function(state, error) {
    Vue.set(state.error, "navigation", error);
  },
  [MU.SET_ERROR_DEBTOR]: function(state, error) {
    Vue.set(state.error, "debtor", error);
  },
  [MU.SET_ERROR_ADJUSTMENT]: function(state, error) {
    Vue.set(state.error, "adjustment", error);
  },
  [MU.SET_CHANGE_NEW]: function(state, items) {
    state.changes.news = items;
  },
  [MU.SET_CHANGE_EDIT]: function(state, items) {
    state.changes.edits = items;
  },
  [MU.SET_LOANID]: function(state, loanid) {
    Vue.set(state, "loanid", loanid);
  },
  [MU.SET_CURRENT_CODE]: function(state, currentCode) {
    Vue.set(state, "currentCode", currentCode);
  },
  [MU.SET_NAVIGATION_LIST]: function(state, list) {
    Vue.set(state.list, "navigation", list);
  },
  [MU.SET_MANUAL_ADJUSTMENTS_TOTAL]: function(state, total) {
    Vue.set(state.list, "manualAdjustmentsTotal", total);
  },
  [MU.SET_DEBTOR_LIST]: function(state, list) {
    Vue.set(state.list, "debtor", list);
  },
  [MU.ADD_CHANGE_NEW]: function(state, item) {
    state.changes.news.push(item);
  },
  [MU.ADD_CHANGE_EDIT]: function(state, item) {
    if (!state.changes.edits.includes(item)) state.changes.edits.push(item);
  },
  [MU.ADD_CHANGE_DELETE]: function(state, item) {
    state.changes.deletes.push(item);
  },
  [MU.CLEAN_ALL_CHANGES]: function(state) {
    state.changes.news = [];
    state.changes.edits = [];
    state.changes.deletes = [];
  }
};
