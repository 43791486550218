import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_BORROWER]: function(state, borrower) {
    Vue.set(state, "borrower", { ...state.borrower, ...borrower });
  },
  [MU.SET_LOAN]: function(state, loan) {
    Vue.set(state, "loan", { ...state.loan, ...loan });
  },
  [MU.SET_DISBURSEMENTS]: function(state, disbursements) {
    Vue.set(state, "disbursements", disbursements);
  },
  [MU.SET_REQUEST_ADVANCE]: function(state, requestAdvance) {
    Vue.set(state, "requestAdvance", { ...state.requestAdvance, ...requestAdvance });
  },
  [MU.SET_LOADING_BORROWINGBASE_REQUEST]: function(state, loading) {
    Vue.set(state.borrowingBaseRequest, "loading", loading);
  },
  [MU.SET_ERROR_BORROWINGBASE_REQUEST]: function(state, error) {
    Vue.set(state.borrowingBaseRequest, "error", error);
  },
  [MU.SET_LIST_BORROWINGBASE_REQUEST]: function(state, list) {
    Vue.set(state.borrowingBaseRequest, "list", list);
  }
};
