import { actions as AC, mutations } from "./constants";
import { mutations as MBorrowerLoan, actions as ACBorrowerLoan } from "../borrower-loan/constants";

const addClientToTab = async ({ commit }, { clientid, name, isHealthcare }) => {
  const client = {
    name,
    clientid,
    isHealthcare,
    route: "loan-stats",
    notify: false,
    loan: {}
  };
  commit(mutations.SET_CLIENT, client);
};

const removeClientFromTab = async ({ commit, dispatch, state, rootState }, clientid) => {
  if (state.clients.hasOwnProperty(clientid)) {
    const { [clientid]: removed, ...clients } = state.clients;
    const clientsKeys = Object.keys(clients);
    const length = clientsKeys.length;

    if (length === 0 || !state.current) {
      dispatch(ACBorrowerLoan.SAVE_CURRENT_BORROWER_ID, rootState.user.profile.clientid);
      return Promise.resolve(null);
    }

    const current = length === 0 ? "" : clientsKeys[length - 1];
    commit(mutations.SAVE_CLIENTS, clients);
    commit(mutations.SET_CURRENT_TAB_BY_CLIENT, current);
    commit(MBorrowerLoan.SET_BORROWER, { current });

    return Promise.resolve(current);
  }
};

const clearState = ({ commit }) => {
  commit(mutations.SAVE_CLIENTS, {});
  commit(mutations.SET_CURRENT_TAB_BY_CLIENT, "");
  commit(mutations.SET_SSE_INSTANCE, null);
};

export const actions = {
  [AC.ADD_CLIENT_TO_TAB]: addClientToTab,
  [AC.REMOVE_CLIENT_FROM_TAB]: removeClientFromTab,
  [AC.CLEAR_STATE]: clearState
};
