import Vue from "vue";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", error);
  },
  [MU.SET_DATA]: function(state, data) {
    Vue.set(state, "data", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    for (const key in DEFAULT_STATE) {
      Vue.set(state, key, DEFAULT_STATE[key]);
    }
  }
};
