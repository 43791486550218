import { actions } from "./actions";
import { mutations } from "./mutations";

export const NaicsModule = {
  state: {
    categories: null,
    loading: false,
    error: null
  },
  actions,
  mutations
};
