import { appendName } from "@/store/utils";

import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_APPRAISALS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_APPRAISALS"),
  CALL_GET_APPRAISALS_RESULT: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_APPRAISALS_RESULT"),
  CALL_GET_APPRAISAL_CRITERIA: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_APPRAISAL_CRITERIA"),

  SAVE_APPRAISAL_CRITERIA: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_APPRAISAL_CRITERIA"),

  CREATE_APPRAISAL: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_APPRAISAL"),
  UPDATE_APPRAISAL: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_APPRAISAL"),

  CREATE_APPRAISAL_RESULT: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_APPRAISAL_RESULT"),
  UPDATE_APPRAISAL_RESULT: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_APPRAISAL_RESULT")
};

export const mutations = {
  //> appraisal
  SET_LOADING_APPRAISAL: appendToLoading("APPRAISAL"),
  SET_ERROR_APPRAISAL: appendToError("APPRAISAL"),
  SET_LIST_APPRAISAL: appendToList("APPRAISAL"),
  //> appraisal result
  SET_LOADING_APPRAISAL_RESULT: appendToLoading("APPRAISAL_RESULT"),
  SET_ERROR_APPRAISAL_RESULT: appendToError("APPRAISAL_RESULT"),
  SET_LIST_APPRAISAL_RESULT: appendToList("APPRAISAL_RESULT"),
  //> appraisal criteria
  SET_LOADING_APPRAISAL_CRITERIA: appendToLoading("APPRAISAL_CRITERIA"),
  SET_ERROR_APPRAISAL_CRITERIA: appendToError("APPRAISAL_CRITERIA"),
  SET_LIST_APPRAISAL_CRITERIA: appendToList("APPRAISAL_CRITERIA"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("APPRAISAL_CRITERIA")
};

export const DEFAULT_STATE = {
  loading: { appraisal: false, result: false, criteria: false },
  error: { appraisal: null, result: null, criteria: null },
  list: { appraisal: [], result: [], criteria: {} }
};
