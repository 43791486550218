import Vue from "vue";

import VueFormulateCustomRules from "../formulate/rules";
import { alphanumericspace, maxMessage, minMessage } from "../formulate/validation-message";

//> Import the Vue-Formulate Custom Components
const FormulateCurrency = () =>
  import(/* webpackChunkName: "formulate-money" */ "../formulate/components/Currency.vue");

const FormulateDatepicker = () =>
  import(/* webpackChunkName: "formulate-datepicker" */ "../formulate/components/Datepicker.vue");

const FormulateInputFloat = () =>
  import(/* webpackChunkName: "formulate-input-float" */ "../formulate/components/InputFloat.vue");

const FormulateSwitch = () =>
  import(/* webpackChunkName: "formulate-switch" */ "../formulate/components/SwitchInput.vue");

const FormulateMultiSelect = () =>
  import(/* webpackChunkName: "formulate-multiselect" */ "../formulate/components/MultiSelect.vue");

const FormulateRadioWithButton = () =>
  import(/* webpackChunkName: "formulate-radio-button" */ "../formulate/components/RadioWithButton.vue");

const FormulateAutoComplete = () =>
  import(/* webpackChunkName: "formulate-autocomplete" */ "../formulate/components/AutoComplete.vue");

const LoadingLabel = () =>
  import(/* webpackChunkName: "formulate-loading-label" */ "../formulate/components/LoadingLabel.vue");

const HelpTextTextarea = () =>
  import(/* webpackChunkName: "formulate-help-text-textarea" */ "../formulate/components/HelpTextTextarea.vue");

//> Register the Vue-Formulate Custom Component
Vue.component("FormulateCurrency", FormulateCurrency);
Vue.component("FormulateDatepicker", FormulateDatepicker);
Vue.component("FormulateInputFloat", FormulateInputFloat);
Vue.component("FormulateSwitch", FormulateSwitch);
Vue.component("FormulateMultiSelect", FormulateMultiSelect);
Vue.component("FormulateRadioWithButton", FormulateRadioWithButton);
Vue.component("FormulateAutoComplete", FormulateAutoComplete);
Vue.component("LoadingLabel", LoadingLabel);
Vue.component("HelpTextTextarea", HelpTextTextarea);

//> create custom component object
const library = {
  money: {
    classification: "text",
    component: "FormulateCurrency",
    slotProps: {
      component: ["allowNegative", "disabled", "allowNull"]
    }
  },
  datepicker: {
    classification: "date",
    component: FormulateDatepicker
  },
  float: {
    classification: "text",
    component: "FormulateInputFloat",
    slotProps: {
      component: ["allowNegative", "disabled", "allowNull", "precision"]
    }
  },
  switch: {
    classification: "box",
    component: FormulateSwitch,
    slotProps: {
      component: [
        "activeText",
        "inactiveText",
        "defaultLabel",
        "showLabelInline",
        "showStatusText",
        "checkValue",
        "uncheckValue"
      ]
    }
  },
  multiselect: {
    classification: "select",
    component: FormulateMultiSelect,
    slotProps: {
      component: ["multiple", "closeOnSelect", "clearable", "selectOnTab"]
    }
  },
  radiobutton: {
    classification: "radio",
    component: FormulateRadioWithButton,
    slotProps: {
      component: ["spanText", "parentId"]
    }
  },
  autocomplete: {
    classification: "text",
    component: "FormulateAutoComplete"
  },
  textarea: {
    slotComponents: {
      help: "HelpTextTextarea"
    },
    slotProps: {
      help: ["maxCharacters"]
    }
  }
};

const slotComponents = {
  label: "LoadingLabel"
};

const slotProps = {
  label: ["loading"]
};

const locale = {
  en: {
    alphanumericspace: alphanumericspace,
    max: maxMessage,
    min: minMessage
  }
};

const commonClases = {
  label: ["form-label"],
  error: ["small", "text-danger", "text-left"],
  errors: "list-unstyled",
  help: ["form-text", "text-muted", "fs-12"]
};

//> setup Vue Formulate as Promise Dependency to detach bundle size
export const initVueFormulateConfiguration = async () => {
  const VueFormulateAsync = async () => await import(/* webpackChunkName: "formulate-lib" */ "@braid/vue-formulate");
  const { default: VueFormulate } = await VueFormulateAsync();

  Vue.use(VueFormulate, {
    validationNameStrategy: ["validationName", "label", "name", "type"],
    classes: commonClases,
    library: library,
    slotComponents: slotComponents,
    slotProps: slotProps,
    rules: VueFormulateCustomRules,
    locales: locale
  });
};

//> setup Vue Formulate for login workflow as Promise Dependency to detach bundle size
export const initializeVueFormulateLoginConfiguration = async () => {
  const VueFormulateAsync = async () => await import(/* webpackChunkName: "formulate-lib" */ "@braid/vue-formulate");
  const { default: VueFormulate } = await VueFormulateAsync();

  Vue.use(VueFormulate, {
    validationNameStrategy: ["validationName", "label", "name", "type"],
    classes: commonClases,
    locales: locale
  });
};
