import { appendName } from "../utils";

const append = appendName("CONFIG_PROPERTY");

export const actions = {
  CALL_GET_CONFIG_PROPERTIES: append("CALL_GET_CONFIG_PROPERTIES"),
  CALL_GET_CONFIG_PROPERTY: append("CALL_GET_CONFIG_PROPERTY"),
  CREATE_CONFIG_PROPERTY: append("CREATE_CONFIG_PROPERTY"),
  UPDATE_CONFIG_PROPERTY: append("UPDATE_CONFIG_PROPERTY")
};

export const mutations = {
  SET_LOADING_GRID: append("SET_LOADING_GRID"),
  SET_ERROR_GRID: append("SET_ERROR_GRID"),
  SET_GRID_DATA: append("SET_GRID_DATA"),
  SET_LOADING_MODAL: append("SET_LOADING_MODAL"),
  SET_ERROR_MODAL: append("SET_ERROR_MODAL")
};

export const MODAL_HELPER_ACTION = {
  CREATE: "create",
  UPDATE: "update"
};
