import { actions } from "./actions";
import { mutations } from "./mutations";

export const AnalystModule = {
  state: {
    list: [],
    loading: false,
    error: ""
  },
  actions,
  getters: {},
  mutations
};
