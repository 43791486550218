import { map, head, toLower, compose, includes, prop, isNil, not, equals, path } from "ramda";

import { getters as GE } from "./constants";
import { capitalize, joinProps, sortArray } from "../../utils";

const loggedUserHasPermission = ({ authorities }) => permission => {
  const check = compose(includes(toLower(permission)), map(toLower));
  return check(authorities);
};

const getUsersByClientId = ({ list }) => contact => {
  const notNil = compose(not, isNil);
  const getFullName = joinProps(["firstname", "lastname"]);
  const getStatus = compose(capitalize, prop("status"));
  const getValueFromContact = p => path(p, contact);

  const primary = getValueFromContact(["data", "primary"]);
  const primaryUsername = getValueFromContact(["data", "primary", "username"]);

  const users = list.user.map(({ profile, groups }) => ({
    isPrimaryContact: notNil(primary) ? equals(primaryUsername, profile.username) : false,
    fullname: getFullName(profile),
    username: prop("username", profile),
    email: prop("email", profile),
    role: head(groups),
    status: getStatus(profile)
  }));
  return notNil(primary) ? sortArray(users, "isPrimaryContact").reverse() : sortArray(users, "fullname");
};

const getBorrowerTypeOptions = ({ profile }) => {
  let borrowerTypes = {};
  const types = profile.borrowerTypes;
  types.forEach(t => (borrowerTypes[toLower(t)] = capitalize(toLower(t))));
  return borrowerTypes;
};

export const getters = {
  [GE.LOGGED_USER_HAS_PERMISSION]: loggedUserHasPermission,
  [GE.GET_USERS_BY_CLIENT_ID]: getUsersByClientId,
  [GE.GET_BORROWER_TYPE_OPTIONS]: getBorrowerTypeOptions
};
