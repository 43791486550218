import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_LIST]: function(state, loading) {
    Vue.set(state.loading, "list", loading);
  },
  [MU.SET_LOADING_DETAILS]: function(state, loading) {
    Vue.set(state.loading, "details", loading);
  },
  [MU.SET_LOADING_INVOICES]: function(state, loading) {
    Vue.set(state.loading, "invoices", loading);
  },
  [MU.SET_LOADING_SPREAD]: function(state, loading) {
    Vue.set(state.loading, "spread", loading);
  },
  [MU.SET_HAS_CHANGES_LIST]: function(state, hasChanges) {
    Vue.set(state.hasChanges, "list", hasChanges);
  },
  [MU.SET_HAS_CHANGES_DETAILS]: function(state, hasChanges) {
    Vue.set(state.hasChanges, "details", hasChanges);
  },
  [MU.SET_HAS_CHANGES_INVOICES]: function(state, hasChanges) {
    Vue.set(state.hasChanges, "invoices", hasChanges);
  },
  [MU.SET_ERROR_LIST]: function(state, error) {
    Vue.set(state.error, "list", error);
  },
  [MU.SET_ERROR_DETAILS]: function(state, error) {
    Vue.set(state.error, "details", error);
  },
  [MU.SET_ERROR_INVOICES]: function(state, error) {
    Vue.set(state.error, "invoices", error);
  },
  [MU.SET_ERROR_SPREAD]: function(state, error) {
    Vue.set(state.error, "spread", error);
  },
  [MU.SET_LOANID]: function(state, loanid) {
    Vue.set(state, "loanid", loanid);
  },
  [MU.SET_DEBTOR]: function(state, { id, name }) {
    Vue.set(state, "id", id);
    Vue.set(state, "name", name);
  },
  [MU.SET_INEL_DAYS_OVER]: function(state, days) {
    Vue.set(state, "inelDaysOver", days);
  },
  [MU.SET_SPREAD]: function(state, spread) {
    Vue.set(state, "spread", spread);
  },
  [MU.SET_PAYOR_CLASSES]: function(state, classes) {
    Vue.set(state, "payorClasses", classes);
  },
  [MU.SET_COUNTRY_CODES]: function(state, countryCodes) {
    Vue.set(state, "countryCodes", countryCodes);
  },
  [MU.SET_DEBTOR_LIST]: function(state, data) {
    Vue.set(state.list, "debtors", data);
  },
  [MU.SET_DEBTOR_DETAILS]: function(state, data) {
    Vue.set(state, "current", data);
  },
  [MU.SET_LINKED_DEBTORS]: function(state, data) {
    Vue.set(state.list, "linked", data);
  },
  [MU.SET_HAS_CHANGES_LINKED_DEBTORS]: function(state, boolean) {
    Vue.set(state.hasChanges, "linked", boolean);
  }
};
