import { actions } from "./actions";
import { mutations } from "./mutations";

export const IneligibleModule = {
  state: {
    loading: { navigation: false, debtor: false, adjustment: false },
    error: { navigation: null, debtor: null, adjustment: null },
    list: {
      navigation: [],
      manualAdjustmentsTotal: 0,
      debtor: []
    },
    loanid: null,
    currentCode: null,
    changes: {
      news: [], // store id from form group
      edits: [], // store id from form group
      deletes: [] // store id from form group
    }
  },
  actions,
  mutations,
  getters: {}
};
