import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", error);
  },
  [MU.SET_LIST]: function(state, list) {
    Vue.set(state, "list", list);
  },
  [MU.SET_HAS_CHANGES]: function(state, hasChanges) {
    Vue.set(state, "hasChanges", hasChanges);
  },
  [MU.SET_CHANGE_NEW]: function(state, items) {
    state.changes.news = items;
  },
  [MU.SET_CHANGE_DELETE]: function(state, items) {
    state.changes.deletes = items;
  },
  [MU.SET_CHANGE_EDIT]: function(state, items) {
    state.changes.edits = items;
  },
  [MU.SET_LOANID]: function(state, loanid) {
    Vue.set(state, "loanid", loanid);
  },
  [MU.ADD_CHANGE_NEW]: function(state, item) {
    state.changes.news.push(item);
  },
  [MU.ADD_CHANGE_EDIT]: function(state, item) {
    if (!state.changes.edits.includes(item)) state.changes.edits.push(item);
  },
  [MU.ADD_CHANGE_DELETE]: function(state, item) {
    state.changes.deletes.push(item);
  },
  [MU.CLEAN_CHANGES]: function(state) {
    state.hasChanges = false;
    state.changes.news = [];
    state.changes.edits = [];
    state.changes.deletes = [];
  }
};
