import { actions } from "./actions";
import { mutations } from "./mutations";

export const DebtorModule = {
  state: {
    loading: { list: false, details: false, invoices: false, spread: false },
    hasChanges: { list: false, details: false, invoices: false, linked: false },
    error: { list: null, details: null, invoices: null, spread: null },
    id: null,
    name: null,
    loanid: null,
    inelDaysOver: null,
    spread: [],
    payorClasses: [],
    countryCodes: [],
    list: { debtors: [], linked: [] },
    current: {}
  },
  actions,
  mutations,
  getters: {}
};
