import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const ContrasCalculatorModule = {
  state: {
    loading: { vendor: false, debtor: false, matches: false, saving: false },
    error: { vendor: null, debtor: null, matches: null },
    search: { hasDebtor: false, hasVendor : false},
    list: {
      vendor: [],
      debtor: [],
      matches: []
    },
    loanid: null,
    calculateIneligibles: false,
    changes: {
      matches: [], // store id from form group
      deletes: [] // store id from form group
    }
  },
  actions,
  mutations,
  getters
};
