import Vue from "vue";
import { mutations as MU, MODAL_ACTION } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", error);
  },
  [MU.SET_CATEGORY_CODES]: function(state, categoryCodes) {
    Vue.set(state, "categoryCodes", categoryCodes);
  },
  [MU.SET_CATEGORY]: function(state, category) {
    Vue.set(state, "category", category);
  },
  [MU.UPDATED_CATEGORY]: function(state, category) {
    Vue.set(state, "updatedCategory", category);
  }
};
