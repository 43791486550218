import { isAxiosError } from "axios";
import { compose, not, has, trim } from "ramda";

//> private
const isNotAxiosError = compose(not, isAxiosError);
const hasMessage = has("message");
const notHasMessage = compose(not, hasMessage);

//> public
export const logError = (e, a = null) => {
  const group = trim(`Error ${a || ""}`);
  console.group(group);

  if (isNotAxiosError(e)) {
    console.table({ messsage: hasMessage(e) ? e.message : e });
  } else {
    const { response, request } = e;
    const url = response ? response.config.url : request.responseURL;
    const responseFromServer = response ? response.data : request.response;
    const status = response ? response.status : request.status;

    console.table({ url, responseFromServer, status });
  }

  console.groupEnd();
};

export const getMessageFromError = e => {
  if (!navigator.onLine) return "Please check your internet connection, seems like your are offline.";

  if (isNotAxiosError(e) && hasMessage(e)) return e.message;
  if (isNotAxiosError(e) && notHasMessage(e)) return e;

  if (e.response) {
    let msg = null;
    switch (e.response.status) {
      case 400:
        msg = "<strong>Bad Request:</strong> The server could not resolve the request.";
        break;
      case 401:
        msg = "<strong>Unauthorized:</strong> Needs to be authenticated to get the response.";
        break;
      case 403:
        msg = "Need credentials to continue with the request.";
        break;
      case 404:
        msg = "<strong>Not Found:</strong> The server could not find the requested content.";
        break;
      case 500:
        msg = "<strong>Internal Server Error:</strong> The server could not process the request.";
        break;
      case 503:
        msg = "<strong>Service Unavailable:</strong> The server is not available at this moment.";
        break;
      default:
        msg =
          "<strong>We're really sorry:</strong> something went wrong on our end. We're working on it and we hope to have you back up and running soon.";
    }
    // we should probably check when is a java or data base error to not show the `e.response.data.message`
    if (e.response.data && e.response.data.message)
      return `${msg} <br /> <strong>More information:</strong> ${e.response.data.message}`;

    return msg;
  }

  if (e.request) return e.request.responseText;
  //> if for some reason does not match with any `if` return the same param
  return e;
};
