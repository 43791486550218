import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";
import { getters as BLGE } from "@/store/borrower-loan/constants";

const callGetNoteInformationBySection = async ({ commit, state, rootState }) => {
  try {
    commit(MU.SET_LOADING_LIST, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { entity, item } = state.section;
    const { data } = await Axios.get(`/api/notes/client/${clientid}/${entity.id}/${item.id}`);
    commit(MU.SET_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR_LIST, logAndGetErrorMessage(AC.CALL_GET_NOTE_INFORMATION_BY_SECTION, err));
  } finally {
    commit(MU.SET_LOADING_LIST, false);
  }
};

const callSaveNoteInformationBySection = ({ commit, state, rootState }, formData) =>
  new Promise(async resolve => {
    let valueToResolve = false;
    commit(MU.SET_LOADING_MODAL, true);
    try {
      const clientid = rootState.borrower_loan.borrower.current;
      const { entity, item } = state.section;
      await Axios.post(`/api/note/client/${clientid}`, {
        clientid: clientid,
        entity: entity.id,
        entityId: item.id,
        ...formData
      });
      valueToResolve = true;
    } catch (err) {
      commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.CALL_SAVE_NOTE_INFORMATION_BY_SECTION, err));
    } finally {
      commit(MU.SET_LOADING_MODAL, false);
    }
    resolve(valueToResolve);
  });

const callGetLatestNote = ({ commit, rootState }, loanid) =>
  new Promise(async resolve => {
    try {
      const clientid = rootState.borrower_loan.borrower.current;
      const { data } = await Axios.get(`/api/notes/client/${clientid}/loan_master/${loanid}/latest`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_LATEST_NOTE, err));
    }
    resolve([]);
  });

const callGetHealthcareComments = ({ commit, rootState }, loanid) =>
  new Promise(async resolve => {
    try {
      const clientid = rootState.borrower_loan.borrower.current;
      const { data } = await Axios.get(`/api/hccredit/client/${clientid}`);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_HEALTHCARE_COMMENTS, err));
    }
    resolve([]);
  });

export const actions = {
  [AC.CALL_GET_NOTE_INFORMATION_BY_SECTION]: callGetNoteInformationBySection,
  [AC.CALL_SAVE_NOTE_INFORMATION_BY_SECTION]: callSaveNoteInformationBySection,
  [AC.CALL_GET_LATEST_NOTE]: callGetLatestNote,
  [AC.CALL_GET_HEALTHCARE_COMMENTS]: callGetHealthcareComments
};
