import { appendName } from "../utils";

const append = appendName("DOCUMENT");

export const DOCUMENT_FREQUENCY_OPTIONS = {
  D: "Daily",
  W: "Weekly",
  M: "Monthly",
  Q: "Quarterly",
  B: "Biannually",
  A: "Annually"
};

export const TYPES_CHECK_PERIOD_ENDING = ["ap_aging", "ar_agings", "AP_AGINGS_SUMMARY", "AR_AGINGS_SUMMARY"];

export const actions = {
  CALL_DOCUMENTS_BY_CLIENTID: append("CALL_DOCUMENTS_BY_CLIENTID"),
  CALL_DOCUMENTS_DEFINITIONS: append("CALL_DOCUMENTS_DEFINITIONS"),
  CALL_DOCUMENTS_DEFINITIONS_BY_PERMISSIONS: append("CALL_DOCUMENTS_DEFINITIONS_BY_PERMISSIONS"),
  CALL_CREATE_DOCUMENT_TYPE: append("CALL_CREATE_DOCUMENT_TYPE"),
  CALL_SAVE_CHANGES: append("CALL_SAVE_CHANGES"),
  SAVE_INPUT_TEXT_CHANGES: append("SAVE_INPUT_TEXT_CHANGES"),
  ADD_INFO_TO_CHANGES: append("ADD_INFO_TO_CHANGES"),
  REMOVE_INFO_FROM_CHANGES: append("REMOVE_INFO_FROM_CHANGES"),
  UPLOAD_DOCUMENT_FILES: append("UPLOAD_DOCUMENT_FILES"),
  DOWNLOAD_SPECIFIC_FILE: append("DOWNLOAD_SPECIFIC_FILE"),
  CALL_DELETE_DOCUMENT: append("CALL_DELETE_DOCUMENT"),
  CALL_UPDATE_DOCUMENT_INFORMATION: append("CALL_UPDATE_DOCUMENT_INFORMATION")
};

export const getters = {
  GET_DOCUMENT_DEFINITION_FOR_TABLE: append("GET_DOCUMENT_DEFINITION_FOR_TABLE"),
  GET_DOC_TYPE_BY_FREQUENCY: append("GET_DOC_TYPE_BY_FREQUENCY"),
  GET_DOC_TYPE_PERMISSION_BY_FREQUENCY: append("GET_DOC_TYPE_PERMISSION_BY_FREQUENCY"),
  GET_ROWS_FOR_DOCUMENT_GRID: append("GET_ROWS_FOR_DOCUMENT_GRID")
};

export const mutations = {
  SET_DOCUMENTS: append("SET_DOCUMENTS"),
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_DOC_DEFINITION_INFO: append("SET_DOC_DEFINITION_INFO"),
  SET_DOC_UPLOAD_INFO: append("SET_DOC_UPLOAD_INFO")
};
