import Axios from "axios";
import { assoc, compose, isNil } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetCovenants = async ({ commit, rootState }, showLoading = true) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    if (showLoading) commit(MU.SET_LOADING_COVENANT, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/Covenants`);
    const activeCovenants = data.filter(d => d.status === null || d.status === "active");
    commit(MU.SET_LIST_COVENANT, activeCovenants);
  } catch (err) {
    commit(MU.SET_ERROR_COVENANT, logAndGetErrorMessage(AC.CALL_GET_COVENANT, err));
  } finally {
    if (showLoading) commit(MU.SET_LOADING_COVENANT, false);
  }
};

const callGetDefaults = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_DEFAULT, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/ClientDefaults`);
    commit(MU.SET_LIST_DEFAULT, data);
  } catch (err) {
    commit(MU.SET_ERROR_DEFAULT, logAndGetErrorMessage(AC.CALL_GET_DEFAULT, err));
  } finally {
    commit(MU.SET_LOADING_DEFAULT, false);
  }
};

const createCovenant = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_COVENANT, true);
    await Axios.post(`/api/borrower/helper/${clientid}/Covenants`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_COVENANT);
  } catch (err) {
    commit(MU.SET_ERROR_COVENANT, logAndGetErrorMessage(AC.CREATE_COVENANT, err));
  } finally {
    commit(MU.SET_LOADING_COVENANT, false);
  }
};

const createCovenantTest = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_COVENANT_TEST, true);
    const addValues = compose(assoc("clientid", clientid), assoc("covenantsId", formData.covenantsId));
    const ct = addValues(formData);

    await Axios.post(`/api/${clientid}/covenants/${formData.covenantsId}/tests`, ct);
    dispatch(AC.CALL_GET_COVENANT, false);
  } catch (err) {
    commit(MU.SET_ERROR_COVENANT_TEST, logAndGetErrorMessage(AC.CREATE_COVENANT_TEST, err));
  } finally {
    commit(MU.SET_LOADING_COVENANT_TEST, false);
  }
};

const createDefault = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_DEFAULT, true);
    await Axios.post(`/api/borrower/helper/${clientid}/ClientDefaults`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_DEFAULT);
  } catch (err) {
    commit(MU.SET_ERROR_DEFAULT, logAndGetErrorMessage(AC.CREATE_DEFAULT, err));
  } finally {
    commit(MU.SET_LOADING_DEFAULT, false);
  }
};

const updateCovenant = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_COVENANT, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/Covenants`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_COVENANT, false);
  } catch (err) {
    commit(MU.SET_ERROR_COVENANT, logAndGetErrorMessage(AC.UPDATE_COVENANT, err));
  } finally {
    commit(MU.SET_LOADING_COVENANT, false);
  }
};

const updateCovenantTest = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_COVENANT_TEST, true);
    await Axios.put(
      `/api/${clientid}/covenants/${formData.covenantsId}/tests/${formData.id}`,
      assoc("clientid", clientid, formData)
    );
    dispatch(AC.CALL_GET_COVENANT, false);
  } catch (err) {
    commit(MU.SET_ERROR_COVENANT_TEST, logAndGetErrorMessage(AC.UPDATE_COVENANT_TEST, err));
  } finally {
    commit(MU.SET_LOADING_COVENANT_TEST, false);
  }
};

const updateDefault = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_DEFAULT, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/ClientDefaults`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_DEFAULT);
  } catch (err) {
    commit(MU.SET_ERROR_DEFAULT, logAndGetErrorMessage(AC.UPDATE_DEFAULT, err));
  } finally {
    commit(MU.SET_LOADING_DEFAULT, false);
  }
};

const saveCovenantFormInformation = async ({ commit, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_COVENANT_FORM, true);
    await Axios.patch(`/api/borrower/client/${clientid}/summary/covenants`, assoc("clientid", clientid, formData));
    commit(MU.SET_COVENANT_FORM, formData);
  } catch (err) {
    commit(MU.SET_ERROR_COVENANT_FORM, logAndGetErrorMessage(AC.SAVE_COVENANT_FORM_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_COVENANT_FORM, false);
  }
};

const callGetCovenantFormInformation = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_COVENANT_FORM, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/summary/covenants`);

    const formData = isNil(data.reportingCompliance) ? assoc("reportingCompliance", true, data) : data;
    commit(MU.SET_COVENANT_FORM, formData);
  } catch (err) {
    commit(MU.SET_ERROR_COVENANT_FORM, logAndGetErrorMessage(AC.CALL_GET_COVENANT_FORM_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_COVENANT_FORM, false);
  }
};

const showCovenantTestModal = ({ commit }, values) => {
  commit(MU.SET_COVENANT_TEST_MODAL, {
    ...values,
    show: true
  });
};

const closeCovenantTestModal = ({ commit }) =>
  commit(MU.SET_COVENANT_TEST_MODAL, {
    show: false
  });

export const actions = {
  [AC.CALL_GET_COVENANT]: callGetCovenants,
  [AC.CALL_GET_DEFAULT]: callGetDefaults,
  [AC.CREATE_COVENANT]: createCovenant,
  [AC.CREATE_COVENANT_TEST]: createCovenantTest,
  [AC.CREATE_DEFAULT]: createDefault,
  [AC.UPDATE_COVENANT]: updateCovenant,
  [AC.UPDATE_COVENANT_TEST]: updateCovenantTest,
  [AC.UPDATE_DEFAULT]: updateDefault,
  [AC.SAVE_COVENANT_FORM_INFORMATION]: saveCovenantFormInformation,
  [AC.CALL_GET_COVENANT_FORM_INFORMATION]: callGetCovenantFormInformation,
  [AC.SHOW_COVENANT_TEST_MODAL]: showCovenantTestModal,
  [AC.CLOSE_COVENANT_TEST_MODAL]: closeCovenantTestModal
};
