import { appendName } from "../utils";

const append = appendName("VERIFICATIONS");

export const actions = {
  CALL_GET_DEBTOR_NAMES: append("CALL_GET_DEBTOR_NAMES"),
  CALL_SEARCH_VERIFICATIONS: append("CALL_SEARCH_VERIFICATIONS"),
  CALL_GET_NUM_VERIFICATIONS: append("CALL_GET_NUM_VERIFICATIONS"),
  CALL_GET_VERIFICATIONS_BY_STATUS: append("CALL_GET_VERIFICATIONS_BY_STATUS"),
  CALL_GET_VERIFICATIONS_SUMMARY_FOR_LOAN: append("CALL_GET_VERIFICATIONS_SUMMARY_FOR_LOAN"),
  CALL_GET_VERIFICATIONS_CRITERIA: append("CALL_GET_VERIFICATIONS_CRITERIA"),
  CALL_VERIFY_VERIFICATIONS: append("CALL_VERIFY_VERIFICATIONS"),
  CALL_UPDATE_VERIFICATIONS_STATUS: append("CALL_UPDATE_VERIFICATIONS_STATUS"),
  CALL_SAVE_VERIFICATIONS: append("CALL_SAVE_VERIFICATIONS"),
  CALL_SAVE_VERIFICATIONS_CRITERIA: append("CALL_SAVE_VERIFICATIONS_CRITERIA"),
  CALL_UPLOAD_FILE: append("CALL_UPLOAD_FILE")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ACTION: append("SET_ACTION"),
  SET_SEARCHING: append("SET_SEARCHING"),
  SET_LOANID: append("SET_LOANID"),
  SET_STATUS_COUNT: append("SET_STATUS_COUNT"),
  SET_REQUESTED_AMOUNT: append("SET_REQUESTED_AMOUNT"),
  SET_SEARCH: append("SET_SEARCH"),
  RESET_SEARCH: append("RESET_SEARCH")
};

export const VERIFICATION_ACTIONS = {
  REQUEST: "p",
  AUTHORIZE: "a",
  CLOSE: "c",
  CANCEL: "r",
  UPLOAD: "u"
};

export const RESOLUTION_OPTIONS = {
  0: "No Response",
  1: "Positive Response",
  2: "Minor Difference",
  3: "Major Difference"
};
export const VERIFICATION_METHOD_OPTIONS = { 1: "Phone", 2: "Email", 3: "Portal", 4: "Audit", 5: "Cash Receipt" };
