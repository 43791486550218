import Axios from "axios";
import { compose, omit, equals, prop, assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ROOT_AC, NOTIFICATION_TYPE } from "../root/constants";
import { concatArrays } from "@/utils/index";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetCollateralReserve = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const { data } = await Axios.get(`/api/collateral/reserves/loans/${loan.id}`);
    commit(MU.SET_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_COLLATERAL_RESERVES, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callSaveAllChanges = async ({ commit, dispatch, rootState, state }, formData) => {
  try {
    commit(MU.SET_LOADING, true);
    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const { news, edits, deletes } = state.changes;

    const normalizeItem = (action, omitProps, clientid, loanId) => item => {
      const itemWithoutProps = {
        ...omit(omitProps, item),
        action,
        loanId,
        clientid
      };
      //> null check for amount to set `0` as value
      return assoc("amount", itemWithoutProps.amount || 0, itemWithoutProps);
    };

    const findInFormValues = id => formData.find(compose(equals(id), prop("id")));
    const getItem = (action, omitProps, clientid, loanId) =>
      compose(normalizeItem(action, omitProps, clientid, loanId), findInFormValues);

    const getPostItems = getItem("POST", ["id"], clientid, loan.id);
    const getPutItems = getItem("PUT", [""], clientid, loan.id);

    const changes = concatArrays([
      news.map(getPostItems),
      edits.map(getPutItems),
      deletes.map(id => ({ id, clientid, loanId: loan.id, action: "DELETE" }))
    ]);

    await Axios.put(`api/collateral/reserves/${clientid}/loan/${loan.id}`, JSON.stringify(changes));
    commit(MU.CLEAN_CHANGES);

    dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.success,
      body: "Changes were successfully saved!",
      redirectTo: "loan-stats"
    });
  } catch (err) {
    dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: logAndGetErrorMessage(AC.CALL_SAVE_ALL_CHANGES, err)
    });
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_COLLATERAL_RESERVES]: callGetCollateralReserve,
  [AC.CALL_SAVE_ALL_CHANGES]: callSaveAllChanges
};
