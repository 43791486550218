import { actions } from "./actions";
import { mutations } from "./mutations";

export const AdvanceWorklistModule = {
  state: {
    search: null,
    list: {
      contents: []
    },
    fees: [],
    loading: false,
    success: false,
    error: ""
  },
  actions,
  getters: {},
  mutations
};
