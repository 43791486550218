import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_DEAL_STRUCTURE]: function(state, loading) {
    Vue.set(state.dealStructure.loading, "dealStructure", loading);
  },
  [MU.SET_ERROR_DEAL_STRUCTURE]: function(state, error) {
    Vue.set(state.dealStructure.error, "dealStructure", error);
  },
  [MU.SET_INFORMATION_DEAL_STRUCTURE]: function(state, data) {
    Vue.set(state.dealStructure, "data", data);
  },
  [MU.SET_LOADING_LINK_BORROWERS]: function(state, loading) {
    Vue.set(state.dealStructure.loading, "linkBorrower", loading);
  },
  [MU.SET_ERROR_LINK_BORROWERS]: function(state, error) {
    Vue.set(state.dealStructure.error, "linkBorrower", error);
  },
  [MU.SET_LIST_LINK_BORROWERS]: function(state, list) {
    Vue.set(state.dealStructure.list, "linkBorrower", list);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "dealStructure", clone(DEFAULT_STATE));
  }
};
