import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetAdvanceWorklist = async ({ commit, state }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/api/borrowerbase", {
      params: state.search
    });

    const contents = data.map(({ base, customerDescription }) => ({
      ...base,
      customerDescription
    }));

    const response = {
      ...data,
      contents
    };
    commit(MU.SET_ADVANCE_WORKLIST, response);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_ADVANCE_WORKLIST, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callUpdateAdvanceWorklist = async ({ commit, dispatch, state }, dataUpdated) => {
  try {
    commit(MU.SET_LOADING, false);
    await Axios.put(`/api/borrowerbase/${dataUpdated.objid}`, dataUpdated);
    dispatch(AC.CALL_GET_ADVANCE_WORKLIST); // need BE to refresh as it has logic on data set and ordering
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_UPDATE_ADVANCE_WORKLIST, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callGetFees = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/api/fees/category", {
      params: {
        category: "A"
      }
    });
    commit(MU.SET_FEES, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_FEES, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callDelete = async ({ commit, dispatch }, id) => {
  try {
    commit(MU.SET_LOADING, true);
    await Axios.delete(`/api/borrowerbase/${id}`);
    dispatch(AC.CALL_GET_ADVANCE_WORKLIST);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_DELETE, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_ADVANCE_WORKLIST]: callGetAdvanceWorklist,
  [AC.CALL_UPDATE_ADVANCE_WORKLIST]: callUpdateAdvanceWorklist,
  [AC.CALL_GET_FEES]: callGetFees,
  [AC.CALL_DELETE]: callDelete
};
