import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ROOT_AC, NOTIFICATION_TYPE } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const numbers = ["accountBalance"];
const dates = ["establishedDate", "creditscoredate"];

const callGetList = ({ commit, rootState }, loanid) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_LIST, true);

      const [
        {
          value: { data: data }
        }
      ] = await Promise.allSettled([
        Axios.get(`/api/loan/${loanid}/debtors`),
        getPayorClasses({ commit, loanid: loanid })
      ]);

      commit(MU.SET_DEBTOR_LIST, data);
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR_LIST, logAndGetErrorMessage(AC.CALL_GET_LIST, err));
    } finally {
      commit(MU.SET_LOADING_LIST, false);
    }
    resolve([]);
  });

const callSaveList = async ({ commit, rootState }, data) => {
  try {
    commit(MU.SET_LOADING_LIST, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    await Axios.patch(`/api/loan/${loan.id}/debtors`, data);
  } catch (err) {
    commit(MU.SET_ERROR_LIST, logAndGetErrorMessage(AC.CALL_SAVE_LIST, err));
  } finally {
    commit(MU.SET_LOADING_LIST, false);
  }
};

const callGetDetails = ({ commit, state }, params) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_DETAILS, true);
      const id = params ? params.id : state.id;
      const loanid = params ? params.loanid : state.loanid;

      const [
        {
          value: { data: data }
        }
      ] = await Promise.allSettled([Axios.get(`/api/debtors/${id}`), getPayorClasses({ commit, loanid: loanid })]);

      // Normalize numbers and dates
      // Need to set any numbers that are null to zero
      // Date fields need to be set with timestamp so its shown in localtime
      numbers.forEach(f => {
        data[f] = data[f] || 0;
      });

      dates.forEach(f => {
        if (data[f]) data[f] += " 00:00:00";
      });

      // Save inelDaysOver as its used in invoices component as part of a header name
      commit(MU.SET_INEL_DAYS_OVER, data.inelDaysOver);

      await getCountryCodes({ commit, state });

      if (!params) {
        commit(MU.SET_DEBTOR_DETAILS, data);
      }

      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR_DETAILS, logAndGetErrorMessage(AC.CALL_GET_DETAILS, err));
    } finally {
      commit(MU.SET_LOADING_DETAILS, false);
    }
    resolve([]);
  });

const getPayorClasses = async ({ commit, loanid }) => {
  try {
    const { data } = await Axios.get(`/api/loan/${loanid}/payorclassess`);

    // Map payor class to debtor form fields for easy comparison
    const payorClasses = data.map(p => ({
      id: p.id,
      name: p.name,
      inelCreditLimit: p.creditlimit,
      inelConcentrationPercentage: p.inelConcentrationPercentage,
      inelCrossAgingPercentage: p.inelCrossAgingPercentage,
      inelDaysOver: p.inelDaysOver
    }));

    commit(MU.SET_PAYOR_CLASSES, payorClasses);
  } catch (err) {
    commit(MU.SET_ERROR_DETAILS, logAndGetErrorMessage("getPayorClasses", err));
  }
};

const getCountryCodes = async ({ commit }) => {
  try {
    const { data } = await Axios.get(`/api/countrycodes`);
    commit(MU.SET_COUNTRY_CODES, data);
  } catch (err) {
    commit(MU.SET_ERROR_DETAILS, logAndGetErrorMessage("getCountryCodes", err));
  }
};

const callGetInvoices = ({ commit, state }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING_INVOICES, true);
      const { data } = await Axios.get(`/api/invoices/debtor/${state.id}`, {
        params: {
          pageSize: 100
        }
      });
      resolve(data);
    } catch (err) {
      commit(MU.SET_ERROR_INVOICES, logAndGetErrorMessage(AC.CALL_GET_INVOICES, err));
    } finally {
      commit(MU.SET_LOADING_INVOICES, false);
    }
    resolve([]);
  });

const callSaveInvoices = async ({ commit, dispatch, rootState }, formValues) => {
  try {
    commit(MU.SET_LOADING_INVOICES, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    await Axios.put(`/api/loan/${loan.id}/invoices`, formValues);
    dispatch(ROOT_AC.SHOW_SUCCESS_NOTIFICATION);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.CALL_SAVE_INVOICES, err);
    commit(MU.SET_ERROR_INVOICES, msg);
    dispatch(ROOT_AC.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_INVOICES, false);
  }
};

const editDebtor = async ({ commit, dispatch, rootState, state }, formValues) => {
  try {
    commit(MU.SET_LOADING_DETAILS, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    // Set holdFlag1, payorClassId to null if not set for proper handling when sending via API
    if (formValues.holdFlag1 === "") formValues.holdFlag1 = null;
    if (formValues.payorClassId === "") formValues.payorClassId = null;

    // Need to remove debtorNames.creditMonitorVendorDetail if its null
    // as API cannot handle it
    if (formValues.debtorNames) {
      delete formValues.debtorNames.creditMonitorVendorDetail;
    }

    // Strip out the time stamp so api works for now
    dates.forEach(field => {
      if (formValues[field]) formValues[field] = formValues[field].replace(/^(.*?)\s[0-9\:]+/, "$1");
    });

    await Axios.put(`/api/loan/${loan.id}/debtor/${state.id}`, formValues);
    dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.success,
      body: "Changes were successfully saved!"
    });
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.EDIT_DEBTOR, err);
    commit(MU.SET_ERROR_DETAILS, msg);
    dispatch(ROOT_AC.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    commit(MU.SET_LOADING_DETAILS, false);
  }
};

const saveAsParentDebtor = async ({ commit, dispatch, rootState }, data) => {
  try {
    commit(MU.SET_LOADING_DETAILS, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const childIds = data.child;

    await Axios.patch(`/api/loan/${loan.id}/address/debtors`, childIds);

    if (data.notify) {
      dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
        type: NOTIFICATION_TYPE.success,
        body: "Changes were successfully saved!",
        redirectTo: "debtor-detail"
      });
    }
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.SAVE_AS_PARENT_DEBTOR, err);
    commit(MU.SET_ERROR_DETAILS, msg);
    dispatch(ROOT_AC.SHOW_ERROR_NOTIFICATION, msg);
  } finally {
    if (!data.continue) {
      commit(MU.SET_LOADING_DETAILS, false);
    }
  }
};

const getLinkedDebtors = async ({ commit, rootState }, id) => {
  try {
    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];
    const { data } = await Axios.get(`/api/loan/${loan.id}/address/${id}/debtors`);
    commit(MU.SET_LINKED_DEBTORS, data);
  } catch (err) {
    commit(MU.SET_ERROR_DETAILS, logAndGetErrorMessage(AC.GET_LINKED_DEBTORS, err));
  }
};

const searchCreditSafe = ({ dispatch }, name) =>
  new Promise(async resolve => {
    try {
      const { data } = await Axios.get(`/api/creditsafe/match/${name}`);
      resolve(data);
    } catch (err) {
      dispatch(ROOT_AC.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.SEARCH_CREDIT_SAFE, err));
    }
    resolve([]);
  });

const getCreditReport = ({ state, dispatch }, reportId) =>
  new Promise(async resolve => {
    try {
      const { data } = await Axios.get(`/api/debtor/${state.id}/report/creditsafe/${reportId}`);
      resolve(data);
    } catch (err) {
      dispatch(ROOT_AC.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.GET_CREDIT_REPORT, err));
    }

    resolve([]);
  });

const getCallSpreadsAmount = async ({ commit, state }) => {
  try {
    commit(MU.SET_LOADING_SPREAD, true);
    const { data } = await Axios.get(`/api/loan/${state.loanid}/debtor/${state.id}/spreads`);
    commit(MU.SET_SPREAD, data);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.GET_CALL_SPREADS_AMOUNT, err);
    commit(MU.SET_ERROR_SPREAD, msg);
  } finally {
    commit(MU.SET_LOADING_SPREAD, false);
  }
};

export const actions = {
  [AC.EDIT_DEBTOR]: editDebtor,
  [AC.CALL_GET_LIST]: callGetList,
  [AC.CALL_SAVE_LIST]: callSaveList,
  [AC.CALL_GET_DETAILS]: callGetDetails,
  [AC.CALL_GET_INVOICES]: callGetInvoices,
  [AC.CALL_SAVE_INVOICES]: callSaveInvoices,
  [AC.SAVE_AS_PARENT_DEBTOR]: saveAsParentDebtor,
  [AC.GET_LINKED_DEBTORS]: getLinkedDebtors,
  [AC.SEARCH_CREDIT_SAFE]: searchCreditSafe,
  [AC.GET_CREDIT_REPORT]: getCreditReport,
  [AC.GET_CALL_SPREADS_AMOUNT]: getCallSpreadsAmount
};
