import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS]: function(state, loading) {
    Vue.set(state.monitoring.loading, "portfolioReviewItems", loading);
  },
  [MU.SET_LOADING_MONITORING_POINTS]: function(state, loading) {
    Vue.set(state.monitoring.loading, "monitoringPoints", loading);
  },
  [MU.SET_ERROR_PORTFOLIO_REVIEW_ITEMS]: function(state, error) {
    Vue.set(state.monitoring.error, "portfolioReviewItems", error);
  },
  [MU.SET_ERROR_MONITORING_POINTS]: function(state, error) {
    Vue.set(state.monitoring.error, "monitoringPoints", error);
  },
  [MU.SET_LIST_PORTFOLIO_REVIEW_ITEMS]: function(state, data) {
    Vue.set(state.monitoring.list, "portfolioReviewItems", data);
  },
  [MU.SET_LIST_MONITORING_POINTS]: function(state, data) {
    Vue.set(state.monitoring.list, "monitoringPoints", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "monitoring", clone(DEFAULT_STATE));
  }
};
