import { appendName } from "@/store/utils";

import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToSetDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_HEALTHCARE: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_HEALTHCARE"),
  CALL_GET_HEALTHCARE_TICKLERS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_HEALTHCARE_TICKLERS"),
  CALL_GET_HEALTHCARE_TICKLERS_FIELDS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_HEALTHCARE_TICKLERS_FIELDS"),
  SAVE_HEALTHCARE: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_HEALTHCARE"),
  SAVE_HEALTHCARE_TICKLER: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_HEALTHCARE_TICKLER")
};

export const mutations = {
  //> form
  SET_LOADING_HEALTHCARE: appendToLoading("HEALTHCARE"),
  SET_ERROR_HEALTHCARE: appendToError("HEALTHCARE"),
  SET_INFORMATION_HEALTHCARE: appendToSetDetail("HEALTHCARE"),
  //> tickler
  SET_LOADING_HEALTHCARE_TICKLER: appendToLoading("HEALTHCARE_TICKLER"),
  SET_ERROR_HEALTHCARE_TICKLER: appendToError("HEALTHCARE_TICKLER"),
  SET_INFORMATION_HEALTHCARE_TICKLER: appendToSetDetail("HEALTHCARE_TICKLER"),
  //> fields
  SET_LOADING_HEALTHCARE_TICKLER_FIELDS: appendToLoading("HEALTHCARE_TICKLER_FIELDS"),
  SET_ERROR_HEALTHCARE_TICKLER_FIELDS: appendToError("HEALTHCARE_TICKLER_FIELDS"),
  SET_INFORMATION_HEALTHCARE_TICKLER_FIELDS: appendToSetDetail("HEALTHCARE_TICKLER_FIELDS"),
  //> mapped
  SET_INFORMATION_HEALTHCARE_TICKLER_FIELDS_MAPPED: appendToSetDetail("HEALTHCARE_TICKLER_FIELDS_MAPPED"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("HEALTHCARE")
};

export const DEFAULT_STATE = {
  loading: { form: false, tickler: false, fields: false },
  error: { form: null, tickler: null, fields: null },
  data: { form: {}, tickler: [], fields: [], mapped: {} }
};
