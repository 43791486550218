import { omit, whereEq, compose } from "ramda";
import { capitalize } from "../../utils";

export const periodHeaders = ["daily", "weekly", "monthly", "quarterly", "biannually", "annually"];

export const getPeriodCharAt = p => {
  const capitalized = capitalize(p);
  return capitalized.charAt(0);
};

export const removeLetterFromString = (l, v) => {
  return v.replace(l.toUpperCase(), "");
};

export const equalsDefinition = (a, b) => {
  const ommitKeys = omit(["lastupdated", "status", "shortDescription", "document"]);
  const pred = compose(whereEq, ommitKeys)(a);
  return pred(ommitKeys(b));
};
