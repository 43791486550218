import { appendName } from "../utils";

const append = appendName("RATES");

export const actions = {
  CALL_GET_RATES: append("CALL_GET_RATES"),
  CREATE_RATE: append("CREATE_RATE"),
  UPDATE_RATE: append("UPDATE_RATE")
};

export const mutations = {
  //> grid
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_LIST: append("SET_LIST"),
  // modal
  SET_LOADING_MODAL: append("SET_LOADING_MODAL"),
  SET_ERROR_MODAL: append("SET_ERROR_MODAL")
};
