import {
  compose,
  concat,
  zipObj,
  sum,
  prop,
  props,
  omit,
  assoc,
  isEmpty,
  length,
  isNil,
  values as objectValues,
  gt as greaterThan,
  head,
  takeLast,
  take,
  map
} from "ramda";

import {
  isLoanArType,
  createLoanDto,
  createLoanCollateralComparisonItem,
  createDebtorDto,
  createCollateralReserveDto,
  createSpreadDto,
  createShiftDto,
  createArTrendDto,
  createArInvIneligibleDto,
  createTopVendorDto,
  createArActivityDto,
  createArIneligibleDto,
  createComparisonDates,
  createComparisonIneligibleRows,
  createComparisonInvCollateralRows,
  createComparisonReservesRows,
  createGrossAvailabilityDto,
  createInvAgingComparisonLoans,
  createInvAgingComparisonTotals,
  getPercentageDiffValue
} from "./helper";

export const mapCollateralComparison = d =>
  d.map(item => {
    const {
      loanName,
      loanid,
      loanType,
      arValueDTO,
      ineligibles,
      defaultLoanToCharge,
      invCollateralTrends,
      collateralReserves,
      bbTotalAvailability
    } = item;

    const loan = { loanId: loanid, loanName, loanType, defaultLoanToCharge };
    const columns = createComparisonDates(item);
    const ineligibleRows = isNil(ineligibles) ? [] : createComparisonIneligibleRows(ineligibles);
    const invCollateralRows = isNil(invCollateralTrends) ? [] : createComparisonInvCollateralRows(invCollateralTrends);
    const reservesRows =
      isNil(collateralReserves) && isNil(arValueDTO)
        ? []
        : createComparisonReservesRows(arValueDTO, collateralReserves);
    const totals = isNil(bbTotalAvailability) ? {} : createInvAgingComparisonTotals(bbTotalAvailability);

    const createDto = compose(
      assoc("ineligibles", ineligibleRows),
      assoc("columns", columns),
      assoc("invCollateral", invCollateralRows),
      assoc("reserves", reservesRows),
      assoc("totals", totals)
    );

    const collateral = createLoanCollateralComparisonItem(item, loan);
    return createDto(collateral);
  });

export const mapAgingComparison = d => {
  const defaultProps = ["current", "prior", "delta", "percentage"];
  const dateProps = ["currAsOfDate", "currAgingDate", "priorAsOfDate", "priorAgingDate"];
  const loans = omit(["client"])(d);

  const rows = objectValues(loans).reduce((acc, aging) => {
    const { name, loanid, loanType } = aging;
    const loan = zipObj(["loanId", "loanName", "loanType"], [loanid, name, loanType]);
    const current = aging.curr.ARTotal;
    const prior = aging.prior.ARTotal;
    const delta = current - prior;
    const percentage = getPercentageDiffValue(current, prior);

    const currAgingDate = aging.curr.AgingDate;
    const currAsOfDate = aging.curr.AsOfDate;
    const priorAgingDate = aging.prior.AgingDate;
    const priorAsOfDate = aging.prior.AsOfDate;

    const rowValues = zipObj(concat(concat(["loan"], defaultProps), dateProps), [
      createLoanDto(loan),
      current,
      prior,
      delta,
      percentage,
      currAsOfDate,
      currAgingDate,
      priorAsOfDate,
      priorAgingDate
    ]);

    return concat([rowValues], acc);
  }, []);

  //> gross a/r total
  const sumCurrent = sum(rows.map(prop("current")));
  const sumPrior = sum(rows.map(prop("prior")));
  const sumDelta = sum(rows.map(prop("delta")));
  const sumPercentage = sum(rows.map(prop("percentage")));
  const grossTotal = zipObj(defaultProps, [sumCurrent, sumPrior, sumDelta, sumPercentage]);

  //> total net availability
  const totalNetCurrent = d.client.curr.Availability;
  const totalNetPrior = d.client.prior.Availability;
  const totalNetDelta = totalNetCurrent - totalNetPrior;
  const totalNetPercentage = getPercentageDiffValue(totalNetCurrent, totalNetPrior);
  const totalAvailability = zipObj(defaultProps, [totalNetCurrent, totalNetPrior, totalNetDelta, totalNetPercentage]);

  const footerValueProps = props(["TodaysAvailabilityBeforeAdvance", "AdvanceAmount", "AvailabilityAfterAdvance"])(
    d.client
  );
  const footer = zipObj(["before", "amount", "after"], footerValueProps);

  const normalized = {
    rows,
    grossTotal,
    totalAvailability,
    footer
  };

  return normalized;
};

export const mapTopDebtors = d => {
  const loans = omit(["client"])(d);

  const rows = objectValues(loans).reduce((acc, aging) => {
    if (isEmpty(aging.DebtorDiff)) return acc;

    const { name, loanid, loanType } = aging;
    const loan = zipObj(["loanId", "loanName", "loanType"], [loanid, name, loanType]);
    const debtors = aging.DebtorDiff.map(createDebtorDto);
    const rowValues = assoc("debtors", debtors, createLoanDto(loan));

    return concat([rowValues], acc);
  }, []);

  return rows;
};

export const mapCollateralReserve = d =>
  objectValues(d).reduce((acc, { generic: reserve, loanName, loanType, loanid }) => {
    const rows = reserve.map(createCollateralReserveDto);
    const loan = zipObj(["id", "name", "type"], [loanid, loanName, loanType]);
    const rowValues = assoc("rows", rows, loan);

    return concat([rowValues], acc);
  }, []);

export const mapSpreads = d => d.filter(isLoanArType()).map(createSpreadDto);

export const mapShift = d =>
  objectValues(d)
    .filter(isLoanArType())
    .map(createShiftDto);

export const mapArTrend = d =>
  objectValues(d)
    .filter(isLoanArType())
    .map(createArTrendDto);

export const mapInvIneligible = d => objectValues(d).map(createArInvIneligibleDto);

export const mapTopVendor = d => d.filter(isLoanArType()).map(createTopVendorDto);

export const mapArActivity = d =>
  objectValues(d)
    .filter(({ generic }) => greaterThan(length(generic), 0))
    .map(createArActivityDto);

export const mapArIneligible = d => d.filter(isLoanArType()).map(createArIneligibleDto);

export const mapInvAgingComparison = data => {
  const allMinusLastTwo = take(data.length - 2, data);
  const lastTwo = takeLast(2, data);
  const antepenultimateItem = head(lastTwo);
  const lastItem = head(takeLast(1, lastTwo));

  const loans = map(createInvAgingComparisonLoans, allMinusLastTwo);
  const grossInv = createGrossAvailabilityDto(antepenultimateItem);
  const availability = createGrossAvailabilityDto(lastItem);

  return zipObj(["availability", "gross", "loans"], [availability, grossInv, loans]);
};
