import Axios from "axios";
import { assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetBorrowerConfiguration = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/summary/configuration`);
    commit(MU.SET_DATA, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_BORROWER_CONFIGURATION, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const saveBorrowerConfiguration = async ({ commit, rootState, dispatch }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING, true);
    await Axios.patch(`/api/borrower/client/${clientid}/summary/configuration`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_BORROWER_CONFIGURATION);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.SAVE_BORROWER_CONFIGURATION, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_BORROWER_CONFIGURATION]: callGetBorrowerConfiguration,
  [AC.SAVE_BORROWER_CONFIGURATION]: saveBorrowerConfiguration
};
