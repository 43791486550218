import Axios from "axios";
import { assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetYieldFee = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_YIELD_FEE, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/summary/fees`);
    commit(MU.SET_INFORMATION_YIELD_FEE, data);
  } catch (err) {
    commit(MU.SET_ERROR_YIELD_FEE, logAndGetErrorMessage(AC.CALL_GET_YIELD_FEE, err));
  } finally {
    commit(MU.SET_LOADING_YIELD_FEE, false);
  }
};

const saveYieldFee = async ({ commit, rootState, dispatch }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_YIELD_FEE, true);
    await Axios.patch(`/api/borrower/client/${clientid}/summary/fees`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_YIELD_FEE);
  } catch (err) {
    commit(MU.SET_ERROR_YIELD_FEE, logAndGetErrorMessage(AC.SAVE_YIELD_FEE, err));
  } finally {
    commit(MU.SET_LOADING_YIELD_FEE, false);
  }
};

export const actions = {
  [AC.CALL_GET_YIELD_FEE]: callGetYieldFee,
  [AC.SAVE_YIELD_FEE]: saveYieldFee
};
