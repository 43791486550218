import Axios from "axios";
import { assoc, compose, filter, find, isNil, propEq, prop, omit, head, map, not, isEmpty, trim } from "ramda";

import { actions as AC, mutations as MU, getters as GE } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callProcessDocumentPreview = async ({ commit }, { docId, sheetName = null }) => {
  try {
    commit(MU.SET_LOADING, { preview: true });
    const params = { params: { sections: sheetName } };
    const { data } = await Axios.get(`/api/processing/documents/${docId}/preview`, params);

    commit(MU.SET_PROCESS_DOCUMENT_PREVIEW, head(data.sections).data);
    commit(MU.SET_COLUMN_MAP, head(data.sections).columnMap);
    commit(MU.SET_PROCESSING, {
      isProcessing: data.processing,
      totalProcessed: data.totalProcessed,
      totalRecords: data.totalRecords
    });
  } catch (err) {
    commit(MU.SET_ERROR, { preview: logAndGetErrorMessage(AC.CALL_PROCESS_DOCUMENT_PREVIEW, err) });
  } finally {
    commit(MU.SET_LOADING, { preview: false });
  }
};

const callMetaFieldTypes = ({ commit }, docPermission) =>
  new Promise(async resolve => {
    try {
      const { data } = await Axios.get(`/api/documents/meta/fields/type/${docPermission}`);
      resolve(map(compose(assoc("list", []), omit(["type"])), data));
    } catch (err) {
      commit(MU.SET_ERROR, { general: logAndGetErrorMessage(AC.CALL_META_FIELD_TYPES, err) });
    }
    resolve([]);
  });

const computeAvailableExcelColumns = ({ commit, getters }, { index, sheet }) => {
  try {
    const data = getters[GE.GET_SPREADSHEET_DATA](sheet);
    const { rows } = head(data);
    const { cells } = rows[index];
    const notEmptyName = compose(not, isEmpty, trim, prop("name"));

    const columns = Object.values(cells)
      .map(prop("text"))
      .map((c, idx) => ({ name: c, index: idx }))
      .filter(notEmptyName);
    return Promise.resolve(columns);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.COMPUTE_AVAILABLE_EXCEL_COLUMNS, err);
    commit(MU.SET_ERROR, { general: msg });
    return Promise.reject(msg);
  }
};

const startProcessDocument = async (
  { commit },
  { documentId, fields, excelColumns, headerRowStart, dataRowStart, headerRowEnd, dataRowEnd, includeSections, isFTA }
) => {
  try {
    const notNil = compose(not, isNil);

    const columnMap = isFTA
      ? fields //> for fta, `fields` are the mapped values
      : excelColumns.reduce((acc, { index }) => {
          const finByIndex = find(propEq("index", index));
          const filterbyNotNullIndex = compose(notNil, finByIndex, prop("list"));

          const mappedFields = filter(filterbyNotNullIndex, fields);
          const values = mappedFields.map(prop("id"));

          return assoc(index, values, acc);
        }, {});

    const docData = {
      headerRowStart,
      dataRowStart,
      headerRowEnd,
      dataRowEnd,
      columnMap,
      includeSections
    };

    await Axios.post(`/api/processing/documents/${documentId}`, docData);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.START_PROCESS_DOCUMENT, err);
    commit(MU.SET_ERROR, { general: msg });
  }
};

export const callGetSheetNames = async ({ rootState, commit }, id) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING, { general: true });
    const { data } = await Axios.get(`api/documents/client/${clientid}/sheets/${id}`);
    commit(MU.SET_EXCEL_SHEET_NAMES, data);
  } catch (err) {
    commit(MU.SET_ERROR, { general: logAndGetErrorMessage(AC.CALL_GET_SHEET_NAMES, err) });
  } finally {
    commit(MU.SET_LOADING, { general: false });
  }
};

export const actions = {
  [AC.CALL_PROCESS_DOCUMENT_PREVIEW]: callProcessDocumentPreview,
  [AC.CALL_META_FIELD_TYPES]: callMetaFieldTypes,
  [AC.COMPUTE_AVAILABLE_EXCEL_COLUMNS]: computeAvailableExcelColumns,
  [AC.START_PROCESS_DOCUMENT]: startProcessDocument,
  [AC.CALL_GET_SHEET_NAMES]: callGetSheetNames
};
