import { appendName } from "../utils";

const append = appendName("ANALYST");

export const actions = {
  CALL_GET_ANALYST: append("CALL_GET_ANALYST"),
  CALL_GET_ANALYST_PORTFOLIO_SUMMARY: append("CALL_GET_ANALYST_PORTFOLIO_SUMMARY")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_LIST: append("SET_LIST")
};
