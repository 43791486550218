import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const TrialBalanceModule = {
  state: {
    list: { categoryGrid: [], ftaField: [], mappingGrid: [] },
    loading: { categoryGrid: false, ftaField: false, mappingGrid: false, categoryModal: false, mappingModal: false },
    error: { categoryGrid: null, ftaField: null, mappingGrid: null, categoryModal: null, mappingModal: null },
    current: {
      sheetName: "",
      headerIndex: 0,
      rowIndex: 0,
      docId: 0,
      mappedColumns: []
    }
  },
  actions,
  mutations,
  getters
};
