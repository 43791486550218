import { appendName } from "@/store/utils";

const append = appendName("BORROWER_CONFIGURATION");

export const actions = {
  CALL_GET_BORROWER_CONFIGURATION: append("CALL_GET_BORROWER_CONFIGURATION"),
  SAVE_BORROWER_CONFIGURATION: append("SAVE_BORROWER_CONFIGURATION")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_DATA: append("SET_DATA"),
  FULL_CLEAR_STATE: append("FULL_CLEAR_STATE")
};

export const DEFAULT_STATE = {
  loading: false,
  error: null,
  data: null
};
