import { clone } from "ramda";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { DEFAULT_STATE } from "./constants";

export const SubordinatorGuarantorModule = {
  state: clone(DEFAULT_STATE),
  actions,
  mutations,
  getters: {}
};
