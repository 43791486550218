import { clone } from "ramda";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { DEFAULT_REPORT_CRITERIA } from "./constants";

export const ReportsModule = {
  state: {
    loading: false,
    error: null,
    report: {},
    reportCriteria: clone(DEFAULT_REPORT_CRITERIA),
    reportCriteriaChanged: false,
    reportDisabled: false,
    selectedReportName: null
  },
  actions,
  mutations,
  getters: {}
};
