import Axios from "axios";
import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetAnalyst = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { username } = rootState.root;
    const { data } = await Axios.get("/api/summary/clients", {
      params: {
        username
      }
    });
    commit(MU.SET_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_ANALYST, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callGetAnalystPorfolioSummary = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/api/summary/clients");
    commit(MU.SET_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_ANALYST_PORTFOLIO_SUMMARY, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_ANALYST]: callGetAnalyst,
  [AC.CALL_GET_ANALYST_PORTFOLIO_SUMMARY]: callGetAnalystPorfolioSummary
};
