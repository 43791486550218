import Axios from "axios";
import { assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetAppraisals = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/MonitoringCollateral`);
    commit(
      MU.SET_LIST_APPRAISAL,
      data.filter(d => d.type !== "FIELD_EXAM")
    );
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL, logAndGetErrorMessage(AC.CALL_GET_APPRAISALS, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL, false);
  }
};

const callGetAppraisalsResult = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL_RESULT, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/AppraisalResult`);
    commit(MU.SET_LIST_APPRAISAL_RESULT, data);
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL_RESULT, logAndGetErrorMessage(AC.CALL_GET_APPRAISALS_RESULT, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL_RESULT, false);
  }
};

const createAppraisal = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL, true);
    await Axios.post(`/api/borrower/helper/${clientid}/MonitoringCollateral`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_APPRAISALS);
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL, logAndGetErrorMessage(AC.CREATE_APPRAISAL, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL, false);
  }
};

const updateAppraisal = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/MonitoringCollateral`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_APPRAISALS);
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL, logAndGetErrorMessage(AC.UPDATE_APPRAISAL, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL, false);
  }
};

const createAppraisalResult = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL_RESULT, false);
    await Axios.post(`/api/borrower/helper/${clientid}/AppraisalResult`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_APPRAISALS_RESULT);
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL_RESULT, logAndGetErrorMessage(AC.CREATE_APPRAISAL_RESULT, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL_RESULT, false);
  }
};

const updateAppraisalResult = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL_RESULT, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/AppraisalResult`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_APPRAISALS_RESULT);
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL_RESULT, logAndGetErrorMessage(AC.UPDATE_APPRAISAL_RESULT, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL_RESULT, false);
  }
};

const callGetAppraisalCriteria = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL_CRITERIA, true);
    const { data } = await Axios.get(`/api/borrower/${clientid}/BorrowersMonitoringCollateral`);
    commit(MU.SET_LIST_APPRAISAL_CRITERIA, data);
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL_CRITERIA, logAndGetErrorMessage(AC.CALL_GET_APPRAISAL_CRITERIA, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL_CRITERIA, false);
  }
};

const saveAppraisalCriteria = async ({ rootState, commit, dispatch }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_APPRAISAL_CRITERIA, true);
    const data = assoc("clientid", clientid, formData);
    if (data.id) await Axios.put(`/api/borrower/helper/${data.id}/BorrowersMonitoringCollateral`, data);
    else await Axios.post(`/api/borrower/helper/${data.clientid}/BorrowersMonitoringCollateral`, data);

    await dispatch(AC.CALL_GET_APPRAISAL_CRITERIA);
  } catch (err) {
    commit(MU.SET_ERROR_APPRAISAL_CRITERIA, logAndGetErrorMessage(AC.SAVE_APPRAISAL_CRITERIA, err));
  } finally {
    commit(MU.SET_LOADING_APPRAISAL_CRITERIA, false);
  }
};

export const actions = {
  [AC.CALL_GET_APPRAISALS]: callGetAppraisals,
  [AC.CALL_GET_APPRAISALS_RESULT]: callGetAppraisalsResult,
  [AC.CALL_GET_APPRAISAL_CRITERIA]: callGetAppraisalCriteria,
  [AC.SAVE_APPRAISAL_CRITERIA]: saveAppraisalCriteria,
  [AC.CREATE_APPRAISAL]: createAppraisal,
  [AC.UPDATE_APPRAISAL]: updateAppraisal,
  [AC.CREATE_APPRAISAL_RESULT]: createAppraisalResult,
  [AC.UPDATE_APPRAISAL_RESULT]: updateAppraisalResult
};
