import { clone } from "ramda";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { DEFAULT_STATE } from "./constants";

export const LoanStatsModule = {
  state: clone(DEFAULT_STATE),
  actions,
  mutations,
  getters
};
