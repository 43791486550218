import { getters as GE } from "./constants";

const getDocumentTypesSelectOptions = ({ list }) => {
  return list.document_types
    .filter(({ upload }) => upload === "Y")
    .map(data => ({ ...data, label: data.shortDescription, value: data.document }));
};

const getClientSelectOptions = ({ list }) => {
  return list.clients.map(data => ({
    label: `${data.profile.firstname} ${data.profile.lastname}`,
    value: data.profile.username
  }));
};

export const getters = {
  [GE.GET_DOCUMENT_TYPES_SELECT_OPTIONS]: getDocumentTypesSelectOptions,
  [GE.GET_CLIENT_SELECT_OPTIONS]: getClientSelectOptions
};
