import { compose, propEq, filter, and, equals, map } from "ramda";
import { sortArray } from "@/utils/index";

import { getters as GE } from "./constants";

// private fns
const getSortedLoansByType = (type, list) => {
  const filterByType = filter(propEq("loanType", type));
  const result = filterByType(list);
  return sortArray(result, "loanId");
};

const getInforWithTotalRow = list => list.filter(l => !l.totalRecord);

// getters
const getLoansByARType = ({ list }) => {
  const loans = getInforWithTotalRow(list.table);
  return filter(propEq("loanType", "AR"), loans);
};

const getLoansByARTypeSorted = ({ list }) => {
  const loans = getInforWithTotalRow(list.table);
  return getSortedLoansByType("AR", loans);
};

const getLoansByINType = ({ list }) => {
  const loans = getInforWithTotalRow(list.table);
  return filter(propEq("loanType", "IN"), loans);
};

const getLoansByINTypeSorted = ({ list }) => {
  const loans = getInforWithTotalRow(list.table);
  return getSortedLoansByType("IN", loans);
};

const getLoansNonArOrIn = ({ list }) => {
  const filterByNonArOrIn = filter(({ loanType }) => and(!equals("AR", loanType), !equals("IN", loanType)));
  const loans = getInforWithTotalRow(list.table);
  const result = filterByNonArOrIn(loans);
  return sortArray(result, "loanId");
};

export const getters = {
  [GE.GET_LOANS_BY_AR_TYPE]: getLoansByARType,
  [GE.GET_LOANS_BY_AR_TYPE_SORTED]: getLoansByARTypeSorted,
  [GE.GET_LOANS_BY_IN_TYPE]: getLoansByINType,
  [GE.GET_LOANS_BY_IN_TYPE_SORTED]: getLoansByINTypeSorted,
  [GE.GET_LOANS_NON_AR_OR_IN]: getLoansNonArOrIn
};
