import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_INVENTORY_COLLATERAL_DETAIL]: function(state, loading) {
    Vue.set(state.loading, "detail", loading);
  },
  [MU.SET_LOADING_PARENT]: function(state, loading) {
    Vue.set(state.loading, "parent", loading);
  },
  [MU.SET_LOADING_CHILD]: function(state, loading) {
    Vue.set(state.loading, "child", loading);
  },
  [MU.SET_ERROR_INVENTORY_COLLATERAL_DETAIL]: function(state, error) {
    Vue.set(state.error, "detail", error);
  },
  [MU.SET_ERROR_PARENT]: function(state, error) {
    Vue.set(state.error, "parent", error);
  },
  [MU.SET_ERROR_CHILD]: function(state, error) {
    Vue.set(state.error, "child", error);
  },
  [MU.SET_LIST_INVENTORY_COLLATERAL_DETAIL]: function(state, categoryCodes) {
    Vue.set(state.list, "detail", categoryCodes);
  },
  [MU.SET_PREVIOUS_AS_OF_DATE]: function(state, asOfDate) {
    Vue.set(state, "asOfDate", asOfDate);
  }
};
