import Axios from "axios";
import { sub, formatISO } from "date-fns";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";
import {
  mapCollateralComparison,
  mapAgingComparison,
  mapTopDebtors,
  mapCollateralReserve,
  mapSpreads,
  mapShift,
  mapArTrend,
  mapInvIneligible,
  mapTopVendor,
  mapArActivity,
  mapArIneligible,
  mapInvAgingComparison
} from "./mapper";

const callGetCollateralComparison = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING_COLLATERAL_COMPARISON, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/dashboards/collateral/comparison/client/${clientid}`);

    const collateral = mapCollateralComparison(data);

    commit(MU.SET_LIST_COLLATERAL_COMPARISON, collateral);
  } catch (err) {
    commit(MU.SET_ERROR_COLLATERAL_COMPARISON, logAndGetErrorMessage(AC.CALL_GET_COLLATERAL_COMPARISON, err));
  } finally {
    commit(MU.SET_LOADING_COLLATERAL_COMPARISON, false);
  }
};

const callGetAgingComparison = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING_AGING_COMPARISON, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/dashboards/aging/comparison/client/${clientid}`);

    const aging = mapAgingComparison(data);
    const debtors = mapTopDebtors(data);

    commit(MU.SET_LIST_AGING_COMPARISON, aging);
    commit(MU.SET_LIST_TOP_DEBTOR, debtors);
  } catch (err) {
    commit(MU.SET_ERROR_AGING_COMPARISON, logAndGetErrorMessage(AC.CALL_GET_AGING_COMPARISON, err));
  } finally {
    commit(MU.SET_LOADING_AGING_COMPARISON, false);
  }
};

const callGetArIneligible = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_AR_INELIGIBLE, true);
    const { data } = await Axios.get(`/api/dashboards/ar/ineligible/client/${clientid}`);

    const ineligibles = mapArIneligible(data);

    commit(MU.SET_LIST_AR_INELIGIBLE, ineligibles);
  } catch (err) {
    commit(MU.SET_ERROR_AR_INELIGIBLE, logAndGetErrorMessage(AC.CALL_GET_AR_INELIGIBLE, err));
  } finally {
    commit(MU.SET_LOADING_AR_INELIGIBLE, false);
  }
};

const callGetCollateralReserve = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_COLLATERAL_RESERVE, true);
    const { data } = await Axios.get(`/api/dashboards/collateral/reserve/client/${clientid}`);

    const reserves = mapCollateralReserve(data);

    commit(MU.SET_LIST_COLLATERAL_RESERVE, reserves);
  } catch (err) {
    commit(MU.SET_ERROR_COLLATERAL_RESERVE, logAndGetErrorMessage(AC.CALL_GET_COLLATERAL_RESERVE, err));
  } finally {
    commit(MU.SET_LOADING_COLLATERAL_RESERVE, false);
  }
};

const callGetShift = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    const limit = 5;
    const today = new Date();
    const start = formatISO(sub(today, { days: 90 }), { representation: "date" });
    const end = formatISO(sub(today, { days: 83 }), { representation: "date" });

    commit(MU.SET_LOADING_SHIFT, true);
    const { data } = await Axios.get(
      `/api/dashboards/ar/shift/client/${clientid}/start/${start}/end/${end}/limit/${limit}`
    );
    const shifts = mapShift(data);

    commit(MU.SET_LIST_SHIFT, shifts);
  } catch (err) {
    commit(MU.SET_ERROR_SHIFT, logAndGetErrorMessage(AC.CALL_GET_SHIFT, err));
  } finally {
    commit(MU.SET_LOADING_SHIFT, false);
  }
};

const callGetSpread = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_SPREAD, true);
    const { data } = await Axios.get(`/api/dashboards/spreads/client/${clientid}`);

    const spreads = mapSpreads(data);

    commit(MU.SET_LIST_SPREAD, spreads);
  } catch (err) {
    commit(MU.SET_ERROR_SPREAD, logAndGetErrorMessage(AC.CALL_GET_SPREAD, err));
  } finally {
    commit(MU.SET_LOADING_SPREAD, false);
  }
};

const callGetArActivity = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_AR_ACTIVITY, true);
    const { data } = await Axios.get(`/api/dashboards/ar/activity/client/${clientid}`);

    const activities = mapArActivity(data);

    commit(MU.SET_LIST_AR_ACTIVITY, activities);
  } catch (err) {
    commit(MU.SET_ERROR_AR_ACTIVITY, logAndGetErrorMessage(AC.CALL_GET_AR_ACTIVITY, err));
  } finally {
    commit(MU.SET_LOADING_AR_ACTIVITY, false);
  }
};

const callGetArTrend = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_AR_TREND, true);
    const { data } = await Axios.get(`/api/dashboards/ar/trend/client/${clientid}`);

    const trends = mapArTrend(data);

    commit(MU.SET_LIST_AR_TREND, trends);
  } catch (err) {
    commit(MU.SET_ERROR_AR_TREND, logAndGetErrorMessage(AC.CALL_GET_AR_TREND, err));
  } finally {
    commit(MU.SET_LOADING_AR_TREND, false);
  }
};

const callGetInvAgingComparison = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_INV_AGING_COMPARISON, true);
    const { data } = await Axios.get(`/api/dashboards/inv/aging/comparison/client/${clientid}`);
    const comparisons = mapInvAgingComparison(data);

    commit(MU.SET_LIST_INV_AGING_COMPARISON, comparisons);
  } catch (err) {
    commit(MU.SET_ERROR_INV_AGING_COMPARISON, logAndGetErrorMessage(AC.CALL_GET_INV_AGING_COMPARISON, err));
  } finally {
    commit(MU.SET_LOADING_INV_AGING_COMPARISON, false);
  }
};

const callGetInvIneligible = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_INV_INELIGIBLE, true);
    const { data } = await Axios.get(`/api/dashboards/inv/ineligible/client/${clientid}`);

    const ineligibles = mapInvIneligible(data);

    commit(MU.SET_LIST_INV_INELIGIBLE, ineligibles);
  } catch (err) {
    commit(MU.SET_ERROR_INV_INELIGIBLE, logAndGetErrorMessage(AC.CALL_GET_INV_INELIGIBLE, err));
  } finally {
    commit(MU.SET_LOADING_INV_INELIGIBLE, false);
  }
};

const callGetTopVendor = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_TOP_VENDOR, true);
    const limit = 5;
    const period = formatISO(new Date(), { representation: "date" });
    const { data } = await Axios.get(`/api/dashboards/topvendors/client/${clientid}/period/${period}/limit/${limit}`);

    const vendors = mapTopVendor(data);

    commit(MU.SET_LIST_TOP_VENDOR, vendors);
  } catch (err) {
    commit(MU.SET_ERROR_TOP_VENDOR, logAndGetErrorMessage(AC.CALL_GET_TOP_VENDOR, err));
  } finally {
    commit(MU.SET_LOADING_TOP_VENDOR, false);
  }
};

export const actions = {
  [AC.CALL_GET_COLLATERAL_COMPARISON]: callGetCollateralComparison,
  [AC.CALL_GET_AGING_COMPARISON]: callGetAgingComparison,
  [AC.CALL_GET_AR_INELIGIBLE]: callGetArIneligible,
  [AC.CALL_GET_COLLATERAL_RESERVE]: callGetCollateralReserve,
  [AC.CALL_GET_SHIFT]: callGetShift,
  [AC.CALL_GET_SPREAD]: callGetSpread,
  [AC.CALL_GET_AR_ACTIVITY]: callGetArActivity,
  [AC.CALL_GET_AR_TREND]: callGetArTrend,
  [AC.CALL_GET_INV_AGING_COMPARISON]: callGetInvAgingComparison,
  [AC.CALL_GET_INV_INELIGIBLE]: callGetInvIneligible,
  [AC.CALL_GET_TOP_VENDOR]: callGetTopVendor
};
