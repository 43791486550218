import { appendName } from "../utils";

const append = appendName("SCHEDULE_D");

export const actions = {
  CALL_DOCUMENT_TYPES: append("CALL_DOCUMENT_TYPES"),
  CALL_CLIENT_DOCUMENTS: append("CALL_CLIENT_DOCUMENTS"),
  CALL_USER_CLIENT: append("CALL_USER_CLIENT"),
  CREATE_SCHEDULE_D: append("CREATE_SCHEDULE_D"),
  UPDATE_SCHEDULE_D: append("UPDATE_SCHEDULE_D"),
  DELETE_SCHEDULE_D: append("DELETE_SCHEDULE_D"),
  SET_SELECTED_SCHEDULE_D: append("SET_SELECTED_SCHEDULE_D")
};

export const getters = {
  GET_DOCUMENT_TYPES_SELECT_OPTIONS: append("GET_DOCUMENT_TYPES_SELECT_OPTIONS"),
  GET_CLIENT_SELECT_OPTIONS: append("GET_CLIENT_SELECT_OPTIONS")
};

export const mutations = {
  SET_CLIENT_DOCUMENTS: append("SET_CLIENT_DOCUMENTS"),
  SET_DOCUMENT_TYPES: append("SET_DOCUMENT_TYPES"),
  SET_ERROR_DOCUMENTS: append("SET_ERROR_DOCUMENTS"),
  SET_LOADING_DOCUMENTS: append("SET_LOADING_DOCUMENTS"),
  SET_CLIENT_ERROR_DOCUMENTS: append("SET_CLIENT_ERROR_DOCUMENTS"),
  SET_CLIENT_LOADING_DOCUMENTS: append("SET_CLIENT_LOADING_DOCUMENTS"),

  SET_USER_CLIENTS: append("SET_USER_CLIENTS"),
  SET_USER_CLIENTS_LOADING: append("SET_USER_CLIENTS_LOADING"),
  SET_USER_CLIENTS_ERROR: append("SET_USER_CLIENTS_ERROR"),

  CREATE_SCHEDULE_D: append("CREATE_SCHEDULE_D"),
  CREATE_ERROR_SCHEDULE_D: append("CREATE_ERROR_SCHEDULE_D"),
  CREATE_LOADING_SCHEDULE_D: append("CREATE_LOADING_SCHEDULE_D"),
  CREATE_SCHEDULE_D_SUCCESS: append("CREATE_SCHEDULE_D_SUCCESS"),
  SET_SELECTED_SCHEDULE_D: append("SET_SELECTED_SCHEDULE_D"),
  //> clean
  FULL_CLEAR_STATE: append("FULL_CLEAR_STATE")
};

export const DEFAULT_STATE = {
  list: { documents: [], document_types: [], clients: [] },
  loading: { documents: false, document_types: false, clients: false },
  error: { documents: null, document_types: null, clients: null },
  savingSuccess: false,
  selectedData: null
};
