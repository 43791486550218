import { appendName } from "../utils";

const append = appendName("CONTRAS_CALCULATOR");

export const actions = {
  CALL_SAVE_ALL_CHANGES: append("CALL_SAVE_ALL_CHANGES"),
  CALL_GET_DEBTOR_INFORMATION: append("CALL_GET_DEBTOR_INFORMATION"),
  CALL_GET_VENDOR_INFORMATION: append("CALL_GET_VENDOR_INFORMATION"),
  CALL_GET_MATCHES_INFORMATION: append("CALL_GET_MATCHES_INFORMATION"),
  CALL_GET_LAST_UPDATED: append("CALL_GET_LAST_UPDATED")
};

export const mutations = {
  SET_LOADING_DEBTOR: append("SET_LOADING_DEBTOR"),
  SET_LOADING_VENDOR: append("SET_LOADING_VENDOR"),
  SET_LOADING_MATCHES: append("SET_LOADING_MATCHES"),
  SET_LOADING_SAVING: append("SET_LOADING_SAVING"),
  SET_ERROR_DEBTOR: append("SET_ERROR_DEBTOR"),
  SET_ERROR_VENDOR: append("SET_ERROR_VENDOR"),
  SET_ERROR_MATCHES: append("SET_ERROR_MATCHES"),
  SET_LIST_DEBTOR: append("SET_LIST_DEBTOR"),
  SET_LIST_VENDOR: append("SET_LIST_VENDOR"),
  SET_LIST_MATCHES: append("SET_LIST_MATCHES"),
  SET_LOANID: append("SET_LOANID"),
  SET_CALCULATE_INELIGIBLES: append("SET_CALCULATE_INELIGIBLES"),
  CLEAN_CURRENT_VALUES: append("CLEAN_CURRENT_VALUES"),
  SET_HAS_DEBTOR_SEARCH: append("SET_HAS_DEBTOR_SEARCH"),
  SET_HAS_VENDOR_SEARCH: append("SET_HAS_VENDOR_SEARCH")
};

export const getters = {
  GET_AVAILABLE_MATCHES: append("GET_AVAILABLE_MATCHES")
};

export const CONTRAS_CALCULATOR_MATCHES_STATE = {
  MATCHED: "matched",
  CREATE: "create",
  DELETE: "delete"
};
