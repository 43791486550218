import { actions } from "./actions";
import { mutations } from "./mutations";
import { DEFAULT_NOTIFICATION_VALUES } from "./constants";

export const RootModule = {
  state: {
    username: null,
    requestHeaders: [], // [{header: value}]
    notification: DEFAULT_NOTIFICATION_VALUES,
    hasToValidateRedirectAfterLogging: true,
    clientIdSiena: "CLIENT_20070910162652",
    showTestSystemBanner: false
  },
  actions,
  mutations,
  getters: {}
};
