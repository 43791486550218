import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const TabModule = {
  state: {
    current: "",
    clients: {},
    sse: null
  },
  actions,
  getters,
  mutations
};
