import Axios from "axios";
import { isNil, head, findIndex, propEq } from "ramda";

import { getters as GE, actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot, NOTIFICATION_TYPE } from "@/store/root/constants";
import { actions as ACTab } from "@/store/tab/constants";

import { sortArray } from "@/utils/index";
import { SessionStorage } from "@/config/sessionStorage";
import { logAndGetErrorMessage } from "@/store/utils";

const callBorrowers = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_BORROWER, { loading: true });

    const { clientid, division } = rootState.user.profile;
    const { data } = await Axios.get("/api/borrower/summary");

    const borrowerList = sortArray(data, "customerDescription");
    const userBorrower = borrowerList.find(f => f.clientid === clientid);
    //> check if the user's clientid exists in the filtered borrowers by division
    const userClientid = !isNil(userBorrower) ? userBorrower.clientid : head(borrowerList).clientid;

    commit(MU.SET_BORROWER, {
      current: SessionStorage.getClientId() || userClientid,
      list: borrowerList,
      loading: false,
      error: ""
    });
  } catch (err) {
    commit(MU.SET_BORROWER, { error: logAndGetErrorMessage(AC.CALL_BORROWERS, err), loading: false });
  }
};

const callBorrower = async ({ commit, getters, state }) => {
  try {
    commit(MU.SET_BORROWER, { loading: true });

    const { data } = await Axios.get(`/api/borrower/summary/${state.borrower.current}`);

    const borrowerList = getters[GE.GET_BORROWERS_LIST];
    const index = findIndex(propEq("clientid", state.borrower.current))(borrowerList);
    if (index !== -1) {
      const updatedBorrowerList = [...borrowerList.slice(0, index), ...data, ...borrowerList.slice(index + 1)];
      commit(MU.SET_BORROWER, { list: updatedBorrowerList, loading: false });
    }
  } catch (err) {
    commit(MU.SET_BORROWER, { error: logAndGetErrorMessage(AC.CALL_BORROWERS, err), loading: false });
  }
};

const callLoans = async ({ commit, getters, state }) => {
  if (state.borrower.current) {
    try {
      commit(MU.SET_LOAN, { loading: true });
      const { data } = await Axios.get(`/api/loans/client/${state.borrower.current}`);
      const list = sortArray(
        data.filter(d => d.clientid !== null),
        "loanName"
      );
      let current = "";
      if (list.length) {
        const defaultLoanId = getters[GE.GET_CURRENT_BORROWER_VALUE]("defaultLoanToCharge");
        current = defaultLoanId || list[0].loanId;
      }
      commit(MU.SET_LOAN, { list, current, error: "", loading: false });
    } catch (err) {
      commit(MU.SET_LOAN, { error: logAndGetErrorMessage(AC.CALL_LOANS, err), loading: false });
    }
  } else {
    // clean the state if borrower is empty
    commit(MU.SET_LOAN, { list: [], current: "", loading: false, error: null });
  }
};

const callDisbursements = async ({ commit, state }) => {
  try {
    if (state.borrower.current) {
      const { data } = await Axios.get(`/api/borrower/disbursement-account/client/${state.borrower.current}`);
      commit(MU.SET_DISBURSEMENTS, data);
    }
  } catch (err) {
    logAndGetErrorMessage(AC.CALL_DISBURSEMENTS, err);
  }
};

const callIneligibles = ({ getters, state }) =>
  new Promise(async resolve => {
    try {
      let ineligibles = {};
      if (state.borrower.current) {
        const loans = getters[GE.GET_LOANS_BY_AR_TYPE];
        //@krojas, this can be done with Promise.allSetled, to call all the apis at the same time and track the error(s) for the one that failed
        for (const l of loans) {
          const { data } = await Axios.get(`/api/ineligibles/buckets/codes/loan/${l.loanId}`);
          ineligibles[l.loanId] = data;
        }
      }
      resolve(ineligibles);
    } catch (err) {
      logAndGetErrorMessage(AC.CALL_INELIGIBLES, err);
    }
    resolve([]);
  });

const saveRequestAdvanceForLoan = ({ commit, rootState, state, getters, dispatch }, values) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_REQUEST_ADVANCE, { loading: true });
      const clientid = state.borrower.current;
      const bankChg = rootState.advance_worklist.fees.find(aw => aw.type === "Bank Chg") || {
        amount: 0,
        type: ""
      };

      // Use default loan id if set
      const defaultLoanId = getters[GE.GET_CURRENT_BORROWER_VALUE]("defaultLoanToCharge");
      const loanid = defaultLoanId || state.loan.current;

      const arrBorrowers = [
        {
          clientid,
          loanid: values.loanid,
          disbursementAccount: values.disbursementAccount,
          requestAmount: values.amount,
          fundingRequestedFor: values.fundingDate,
          status: "active",
          comment: values.comment,
          advanceFee: bankChg.amount,
          advanceFeeType: bankChg.type
        }
      ];
      if (values.disbursementAccounts.length > 0) {
        values.disbursementAccounts.forEach(({ disbursementAccount, amount, loanid }) => {
          arrBorrowers.push({
            clientid,
            loanid,
            disbursementAccount,
            requestAmount: amount,
            fundingRequestedFor: values.fundingDate,
            status: "active",
            comment: values.comment,
            advanceFee: bankChg.amount,
            advanceFeeType: bankChg.type
          });
        });
      }

      await Axios.post(`/api/borrowerbase/loan/${loanid}`, JSON.stringify(arrBorrowers));
      dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
        type: NOTIFICATION_TYPE.success,
        body: "Changes were successfully saved!"
      });
      resolve(true);
    } catch (err) {
      commit(MU.SET_REQUEST_ADVANCE, {
        error: logAndGetErrorMessage(AC.SAVE_REQUEST_ADVANCE_FOR_LOAN, err),
        loading: false
      });
      resolve(false);
    } finally {
      commit(MU.SET_REQUEST_ADVANCE, { loading: false });
    }
  });

const saveCurrentBorrowerId = ({ commit, dispatch }, clientid) => {
  commit(MU.SET_BORROWER, { current: clientid });
  SessionStorage.setClientId(clientid);
  dispatch(ACTab.CLEAR_STATE);
};

const callGetBorrowingBaseRequests = async ({ commit, rootState }, dates) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_BORROWINGBASE_REQUEST, true);
    const config = {
      params: {
        searchDateStart: dates.start,
        searchDateEnd: dates.end
      }
    };
    const { data } = await Axios.get(`/api/borrowerbase/${clientid}`, config);
    const requests = data.map(d => d.base);
    commit(MU.SET_LIST_BORROWINGBASE_REQUEST, requests);
  } catch (err) {
    commit(MU.SET_ERROR_BORROWINGBASE_REQUEST, logAndGetErrorMessage(AC.CALL_GET_APPRAISALS, err));
  } finally {
    commit(MU.SET_LOADING_BORROWINGBASE_REQUEST, false);
  }
};

export const actions = {
  [AC.CALL_BORROWERS]: callBorrowers,
  [AC.CALL_BORROWER]: callBorrower,
  [AC.CALL_LOANS]: callLoans,
  [AC.CALL_DISBURSEMENTS]: callDisbursements,
  [AC.CALL_INELIGIBLES]: callIneligibles,
  [AC.SAVE_REQUEST_ADVANCE_FOR_LOAN]: saveRequestAdvanceForLoan,
  [AC.SAVE_CURRENT_BORROWER_ID]: saveCurrentBorrowerId,
  [AC.CALL_BORROWINGBASE_REQUESTS]: callGetBorrowingBaseRequests
};
