import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state.status, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state.status, "error", error);
  },

  [MU.SET_DATA]: function(state, data) {
    Vue.set(state.status, "data", data);
  },

  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "status", clone(DEFAULT_STATE));
  }
};
