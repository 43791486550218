import { assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const addNotificationItem = async ({ commit, state }, item) => {
  try {
    const items = assoc(item.id, item, state.items);
    commit(MU.SET_NOTIFICATION_ITEMS, items);
  } catch (error) {
    logAndGetErrorMessage(AC.ADD_NOTIFICATION_ITEM, err);
  }
};

const removeNotificationItem = async ({ commit, state }, id) => {
  try {
    const { [id]: removed, ...items } = state.items;
    commit(MU.SET_NOTIFICATION_ITEMS, items);
  } catch (error) {
    logAndGetErrorMessage(AC.REMOVE_NOTIFICATION_ITEM, err);
  }
};

export const actions = {
  [AC.ADD_NOTIFICATION_ITEM]: addNotificationItem,
  [AC.REMOVE_NOTIFICATION_ITEM]: removeNotificationItem
};
