import Vue from "vue";

import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_MODAL]: function(state, loading) {
    Vue.set(state.loading, "modal", loading);
  },
  [MU.SET_LOADING_LIST]: function(state, loading) {
    Vue.set(state.loading, "list", loading);
  },
  [MU.SET_ERROR_MODAL]: function(state, error) {
    Vue.set(state.error, "modal", error);
  },
  [MU.SET_ERROR_LIST]: function(state, error) {
    Vue.set(state.error, "list", error);
  },
  [MU.SET_LIST]: function(state, list) {
    Vue.set(state, "list", list);
  },
  [MU.SET_NOTE_SECTION]: function(state, section) {
    Vue.set(state, "section", section);
  },
  [MU.SET_NOTE_SHOW_MODAL]: function(state, show) {
    Vue.set(state, "showModal", show);
  }
};
