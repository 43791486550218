import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR_BANK_ACCOUNT]: function(state, error) {
    Vue.set(state.error, "bankAccount", error);
  },
  [MU.SET_ERROR_UPLOAD]: function(state, error) {
    Vue.set(state.error, "upload", error);
  },
  [MU.SET_INDEX]: function(state, index) {
    Vue.set(state, "index", index);
  },
  [MU.SET_SHOW_TOTALS]: function(state, showTotals) {
    Vue.set(state, "showTotals", showTotals);
  }
};


