import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetGLCodes = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_GRID, true);
    const { data } = await Axios.get("/api/glcodes");
    commit(MU.SET_LIST_GRID, data);
  } catch (err) {
    commit(MU.SET_ERROR_GRID, logAndGetErrorMessage(AC.CALL_GET_GL_CODES, err));
  } finally {
    commit(MU.SET_LOADING_GRID, false);
  }
};

const createGLCode = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.post("/api/glcode", formData);
    dispatch(AC.CALL_GET_GL_CODES);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.CREATE_GL_CODE, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const updateGLCode = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.put(`/api/glcode/${formData.id}`, formData);
    dispatch(AC.CALL_GET_GL_CODES);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.UPDATE_GL_CODE, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

export const actions = {
  [AC.CALL_GET_GL_CODES]: callGetGLCodes,
  [AC.CREATE_GL_CODE]: createGLCode,
  [AC.UPDATE_GL_CODE]: updateGLCode
};
