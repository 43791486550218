import { appendName } from "@/store/utils";
import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToModal = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_MODAL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_COVENANT: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_COVENANT"),
  CALL_GET_DEFAULT: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_DEFAULT"),
  CALL_GET_COVENANT_FORM_INFORMATION: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_COVENANT_FORM_INFORMATION"),

  CREATE_COVENANT: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_COVENANT"),
  CREATE_COVENANT_TEST: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_COVENANT_TEST"),
  CREATE_DEFAULT: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_DEFAULT"),

  UPDATE_COVENANT: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_COVENANT"),
  UPDATE_COVENANT_TEST: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_COVENANT_TEST"),
  UPDATE_DEFAULT: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_DEFAULT"),

  SAVE_COVENANT_FORM_INFORMATION: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_COVENANT_FORM_INFORMATION"),

  SHOW_COVENANT_TEST_MODAL: APPEND_BORROWER_DETAIL_MODULE_NAME("SHOW_COVENANT_TEST_MODAL"),
  CLOSE_COVENANT_TEST_MODAL: APPEND_BORROWER_DETAIL_MODULE_NAME("CLOSE_COVENANT_TEST_MODAL")
};

export const mutations = {
  //> covenant
  SET_LOADING_COVENANT: appendToLoading("COVENANT"),
  SET_ERROR_COVENANT: appendToError("COVENANT"),
  SET_LIST_COVENANT: appendToList("COVENANT"),
  //> covenant test
  SET_LOADING_COVENANT_TEST: appendToLoading("COVENANT_TEST"),
  SET_ERROR_COVENANT_TEST: appendToError("COVENANT_TEST"),
  //> default
  SET_LOADING_DEFAULT: appendToLoading("DEFAULT"),
  SET_ERROR_DEFAULT: appendToError("DEFAULT"),
  SET_LIST_DEFAULT: appendToList("DEFAULT"),
  //> covenant form
  SET_LOADING_COVENANT_FORM: appendToLoading("COVENANT_FORM"),
  SET_ERROR_COVENANT_FORM: appendToError("COVENANT_FORM"),
  SET_COVENANT_FORM: appendToDetail("COVENANT_FORM"),
  SET_COVENANT_CHANGES: appendToDetail("COVENANT_CHANGES"),
  SET_COVENANT_TEST_MODAL: appendToModal("COVENANT_TEST_MODAL"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("COVENANT")
};

export const DEFAULT_STATE = {
  loading: { covenant: false, covenantTest: false, default: false },
  error: { covenant: null, covenantTest: null, default: null },
  list: { covenant: [], default: [] },
  form: {
    loading: false,
    error: null,
    data: {}
  },
  covenantTestModal: {
    show: false
  }
};
