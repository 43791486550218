import Vue from "vue";
import { mutations as MU } from "./constants";
import { sortArray } from "../../utils";

export const mutations = {
  [MU.SET_LOADING_DEBTOR]: function(state, loading) {
    Vue.set(state.loading, "debtor", loading);
  },
  [MU.SET_LOADING_VENDOR]: function(state, loading) {
    Vue.set(state.loading, "vendor", loading);
  },
  [MU.SET_LOADING_MATCHES]: function(state, loading) {
    Vue.set(state.loading, "matches", loading);
  },
  [MU.SET_LOADING_SAVING]: function(state, loading) {
    Vue.set(state.loading, "saving", loading);
  },
  [MU.SET_ERROR_DEBTOR]: function(state, error) {
    Vue.set(state.error, "debtor", error);
  },
  [MU.SET_ERROR_VENDOR]: function(state, error) {
    Vue.set(state.error, "vendor", error);
  },
  [MU.SET_ERROR_MATCHES]: function(state, error) {
    Vue.set(state.error, "adjustment", error);
  },
  [MU.SET_LIST_DEBTOR]: function(state, list) {
    Vue.set(state.list, "debtor", sortArray(list, "name"));
  },
  [MU.SET_LIST_VENDOR]: function(state, list) {
    Vue.set(state.list, "vendor", sortArray(list, "name"));
  },
  [MU.SET_LIST_MATCHES]: function(state, list) {
    Vue.set(state.list, "matches", list);
  },
  [MU.SET_LOANID]: function(state, loanid) {
    Vue.set(state, "loanid", loanid);
  },
  [MU.SET_CALCULATE_INELIGIBLES]: function(state, value) {
    Vue.set(state, "calculateIneligibles", value);
  },
  [MU.SET_HAS_DEBTOR_SEARCH]: function( state, value ){
    Vue.set(state.search, "hasDebtor", value);
  },
  [MU.SET_HAS_VENDOR_SEARCH]: function( state, value ){
    Vue.set(state.search, "hasVendor", value);
  }
};
