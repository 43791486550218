import { appendName } from "@/store/utils";
import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_PORTFOLIO_REVIEW_ITEMS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_PORTFOLIO_REVIEW_ITEMS"),
  CALL_GET_MONITORING_POINTS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_MONITORING_POINTS"),

  CREATE_PORTFOLIO_REVIEW_ITEM: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_PORTFOLIO_REVIEW_ITEM"),
  CREATE_MONITORING_POINT: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_MONITORING_POINT"),

  UPDATE_PORTFOLIO_REVIEW_ITEM: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_PORTFOLIO_REVIEW_ITEM"),
  UPDATE_MONITORING_POINT: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_MONITORING_POINT"),

  DELETE_PORTFOLIO_REVIEW_ITEM: APPEND_BORROWER_DETAIL_MODULE_NAME("DELETE_PORTFOLIO_REVIEW_ITEM"),
  DELETE_MONITORING_POINT: APPEND_BORROWER_DETAIL_MODULE_NAME("DELETE_MONITORING_POINT")
};

export const mutations = {
  //> porftolio items
  SET_LOADING_PORTFOLIO_REVIEW_ITEMS: appendToLoading("PORTFOLIO_REVIEW_ITEMS"),
  SET_ERROR_PORTFOLIO_REVIEW_ITEMS: appendToError("PORTFOLIO_REVIEW_ITEMS"),
  SET_LIST_PORTFOLIO_REVIEW_ITEMS: appendToList("PORTFOLIO_REVIEW_ITEMS"),
  //> monitoring points
  SET_LOADING_MONITORING_POINTS: appendToLoading("MONITORING_POINTS"),
  SET_ERROR_MONITORING_POINTS: appendToError("MONITORING_POINTS"),
  SET_LIST_MONITORING_POINTS: appendToList("MONITORING_POINTS"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("MONITORING")
};

export const DEFAULT_STATE = {
  loading: { portfolioReviewItems: false, monitoringPoints: false },
  error: { portfolioReviewItems: null, monitoringPoints: null },
  list: { portfolioReviewItems: [], monitoringPoints: [] }
};
