import Vue from "vue";
import Vuex from "vuex";

import { RootModule } from "./root";
import { BorrowerLoanModule } from "./borrower-loan";
import { BorrowerModule } from "./borrower";
import { GroupModule } from "./group";
import { TabModule } from "./tab";
import { UserModule } from "./user";
import { DocumentModule } from "./document";
import { CodesModule } from "./codes";
import { NaicsModule } from "./naics";
import { ProcessDocumentModule } from "./process-document";
import { LoanDocumentModule } from "./loan-document";
import { LoanModule } from "./loan";
import { RollforwardModule } from "./rollforward";
import { AdvanceWorklistModule } from "./advance-worklist";
import { CollateralReserveModule } from "./collateral-reserve";
import { IneligibleModule } from "./ineligible";
import { ContrasCalculatorModule } from "./contras-calculator";
import { AnalystModule } from "./analyst";
import { LoanStatsModule } from "./loan-stats";
import { DebtorModule } from "./debtor";
import { VendorModule } from "./vendor";
import { CommentModule } from "./comment";
import { AuditLogModule } from "./audit-log";
import { ScheduleDModule } from "./schedule-d";
import { FeesModule } from "./fees";
import { TrialBalanceModule } from "./trial-balance";
import { ActiveSessionModule } from "./active-session";
import { VerificationsModule } from "./verifications";
import { OperatingCash } from "./operating-cash";
import { InventoryColalteralDetailModule } from "./inventory-collateral-detail";
import { ReportsModule } from "./reports";
import { NotificationModule } from "./notification";
import { DashboardModule } from "./dashboard";
import { ConfigPropertyModule } from "./config-property";
import { GLCodeModule } from "./glCode";
import { GeneralLedgerEntryModule } from "./ledger-entry";
import { RateModule } from "./rate";
import { HolidayManagementModule } from "./holiday-management";
import { BorrowerConfigurationModule } from "./borrower-configuration";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    root: RootModule,
    borrower_loan: BorrowerLoanModule,
    group: GroupModule,
    tab: TabModule,
    user: UserModule,
    document: DocumentModule,
    processdocument: ProcessDocumentModule,
    borrower: BorrowerModule,
    loan_document: LoanDocumentModule,
    loan: LoanModule,
    codes: CodesModule,
    naics: NaicsModule,
    rollforward: RollforwardModule,
    advance_worklist: AdvanceWorklistModule,
    collateral_reserve: CollateralReserveModule,
    ineligible: IneligibleModule,
    analyst: AnalystModule,
    loan_stats: LoanStatsModule,
    debtor: DebtorModule,
    vendor: VendorModule,
    contras_calculator: ContrasCalculatorModule,
    schedule_d: ScheduleDModule,
    comment: CommentModule,
    fees: FeesModule,
    audit_log: AuditLogModule,
    trial_balance: TrialBalanceModule,
    active_session: ActiveSessionModule,
    verifications: VerificationsModule,
    operatingCash: OperatingCash,
    inventory_collateral_detail: InventoryColalteralDetailModule,
    reports: ReportsModule,
    dashboard: DashboardModule,
    notification: NotificationModule,
    config_property: ConfigPropertyModule,
    glCode: GLCodeModule,
    general_ledger_entry: GeneralLedgerEntryModule,
    rate: RateModule,
    holiday_management: HolidayManagementModule,
    borrower_configuration: BorrowerConfigurationModule
  }
});

export default store;
