import { actions } from "./actions";
import { mutations } from "./mutations";

export const CommentModule = {
  state: {
    loading: { modal: false, list: false },
    error: { modal: false, list: false },
    showModal: false,
    // will store the values from `SECTION_ENTITY_ID` and a new value for the `id` & `name`
    // {entity: {name:"", id:""}, item: {name:"", id:""}}
    section: {}
  },
  actions,
  mutations,
  getters: {}
};
