import { appendName } from "../utils";

const append = appendName("NOTIFICATION");

export const actions = {
  ADD_NOTIFICATION_ITEM: append("ADD_NOTIFICATION_ITEM"),
  REMOVE_NOTIFICATION_ITEM: append("REMOVE_NOTIFICATION_ITEM")
};

export const mutations = {
  SET_NOTIFICATION_ITEMS: append("SET_NOTIFICATION_ITEMS")
};
