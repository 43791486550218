import Vue from "vue";
import store from "./store";
import router from "./router/login";

import { initializeVueFormulateLoginConfiguration } from "./config/vueformulate";
const LoginApp = () => import(/* webpackChunkName: "login-view" */ "./Login.vue");
initializeVueFormulateLoginConfiguration();


new Vue({
  components: {
    login: LoginApp
  },
  store,
  router
}).$mount("#login");
