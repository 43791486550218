import { uniqueId } from "@/utils/index";
import {
  isNil,
  concat,
  zipObj,
  equals,
  prop,
  props,
  assoc,
  compose,
  toLower,
  length,
  map,
  head,
  keys as objectKeys,
  values as objectValues,
  prepend,
  replace
} from "ramda";

const lowerWithNullCheck = v => toLower(v || "");
const DEFAULT_KEYS_FOR_INV_AGING_COMPARISON = ["id", "label", "current", "prior", "delta", "percentage"];

/* Calculation taken from passport logic in NumberHelper.java getPercentageDiffValue and getPercentageValue */
export const getPercentageDiffValue = (number, total) => {
  if (number === 0 || total === 0) return 0;
  let pctDiff = 100 * (number / total);
  pctDiff -= 100;
  return pctDiff;
};

export const filterByName = name =>
  compose(equals(replace(/\s/g, "", toLower(name))), replace(/\s/g, ""), toLower, prop("name"));

export const createLoanDto = ({ loanId, loanName, loanType, defaultLoanToCharge }) =>
  zipObj(["id", "name", "type", "main"], [loanId, loanName, loanType, defaultLoanToCharge]);

export const isLoanArType = (type = "loanType") => compose(equals("ar"), lowerWithNullCheck, prop(type));

export const createLoanCollateralComparisonItem = ({ curr, prior, priorMinus1, priorMinus2 }, loan) => ({
  current: curr,
  prior: prior,
  priorMinusOne: priorMinus1,
  priorMinusTwo: priorMinus2,
  loan: createLoanDto(loan)
});

export const createDebtorDto = ({
  currbalance = 0,
  currdebtor_id = null,
  currdebtor_name = "",
  priorbalance = 0,
  priordebtor_id = null,
  priordebtor_name = "",
  Diff = null,
  DiffPcnt = null
}) => {
  const current = { id: currdebtor_id, name: currdebtor_name, amount: currbalance };
  const prior = { id: priordebtor_id, name: priordebtor_name, amount: priorbalance };
  return zipObj(["current", "prior", "amount", "percentage"], [current, prior, Diff, DiffPcnt]);
};

export const createCollateralReserveDto = ({ curr, prior, name }) => {
  const delta = curr - prior;
  const percentage = getPercentageDiffValue(curr, prior);
  return zipObj(["current", "prior", "delta", "percentage", "name"], [curr, prior, delta, percentage, name]);
};

export const createSpreadDto = ({ loanName, spreadsDetailDTO }) => {
  const { apDaysMap, arDaysMap, totalAPAmount, totalARAmount } = spreadsDetailDTO;

  const keys = objectKeys(arDaysMap);
  const arDays = objectValues(arDaysMap);
  const apDays = objectValues(apDaysMap);

  const columns = keys.reduce(
    (acc, current, idx, originalArray) => {
      if (originalArray[originalArray.length - 1] === current) return concat(acc, [`> ${current}`]);

      const id = length(acc);
      const currentMaxValue = Number(originalArray[id - 1]) + 1;
      const nextMaxValue = Number(originalArray[id]);

      if (equals(idx, 0)) return concat(acc, [`0 - ${nextMaxValue}`]);
      const cols = concat(acc, [`${currentMaxValue} - ${nextMaxValue}`]);

      return cols;
    },
    ["total"]
  );

  const arRows = arDays.reduce((acc, current) => concat(acc, [current]), []);
  const apRows = apDays.reduce((acc, current) => concat(acc, [current]), []);

  const ar = assoc("total", totalARAmount, { rows: arRows });
  const ap = assoc("total", totalAPAmount, { rows: apRows });
  const dto = zipObj(["loan", "columns", "ar", "ap"], [loanName, columns, ar, ap]);

  return dto;
};

export const createShiftDto = ({ loanName, generic }) => {
  const dtoProps = ["name", "pastDue", "shift", "total", "shiftPercentage"];
  const shiftProps = ["debtorname", "pastDueAmount", "shiftAmount", "debtorBalance", "percentageDiffValue"];
  const normalize = compose(zipObj(dtoProps), props(shiftProps));

  const debtors = generic.map(normalize);
  const dto = zipObj(["loan", "debtors"], [loanName, debtors]);

  return dto;
};

export const createArTrendDto = ({ loanName, oneMonth, threeMonth, sixMonth, twelveMonth }) => {
  const months = zipObj(["one", "three", "six", "twelve"], [oneMonth, threeMonth, sixMonth, twelveMonth]);
  const dto = zipObj(["name", "months"], [loanName, months]);
  return dto;
};

export const createArInvIneligibleDto = ({ loanName, generic }) => {
  const { agingDatesDTO, invInelDTOS, invInelTrendDTOS } = generic;

  //> Inventory Ineligible Trend
  const invInelTrendColumnsNames = ["current", "prior", "priorMinusOne", "priorMinusTwo", "name"];
  const invInelTrendDtoProps = props(["currInel", "priorInel", "priorMinus1Inel", "priorMinus2Inel", "name"]);
  const getInvInelTendDtoPropValues = compose(zipObj(invInelTrendColumnsNames), invInelTrendDtoProps);
  const invIneligibleTrendCategories = map(getInvInelTendDtoPropValues);

  const keys = ["agingDate", "asOfDate"];
  const columns = [
    zipObj(keys, [agingDatesDTO.currLastUpdated, agingDatesDTO.currDate]),
    zipObj(keys, [agingDatesDTO.priorLastUpdated, agingDatesDTO.priorDate]),
    zipObj(keys, [agingDatesDTO.priorMinus1LastUpdated, agingDatesDTO.priorMinus1Date]),
    zipObj(keys, [agingDatesDTO.priorMinus2LastUpdated, agingDatesDTO.priorMinus2Date])
  ];

  const createinvIneligibleTrendDto = compose(
    assoc("columns", columns),
    assoc("categories", invIneligibleTrendCategories(invInelTrendDTOS))
  );
  const invInelTrendDto = createinvIneligibleTrendDto({});

  //> Inventory Ineligible
  const invInelColumnName = ["name", "current", "prior", "delta", "percentage"];
  const createInvInelDtop = vs => zipObj(invInelColumnName, vs);
  const invInelDtoRows = invInelDTOS.map(({ curr, prior, name }) => {
    const delta = curr - prior;
    const percentage = getPercentageDiffValue(curr, prior);
    return createInvInelDtop([name, curr, prior, delta, percentage]);
  });

  const dto = zipObj(["loan", "ineligible", "invIneligibleTrend"], [loanName, invInelDtoRows, invInelTrendDto]);
  return dto;
};

export const createTopVendorDto = ({ loanName, topVendorDetailDTOList }) => {
  if (equals(length(topVendorDetailDTOList), 0)) return { loan: loanName, rows: [] };

  const propsNames = ["percentagePastDue", "percentageOfAp", "ap", "vendor"];
  const getProps = props(["pcntPastDue", "pctnOfAP", "vendorBalance", "vendorName"]);
  const createPropsDto = compose(zipObj(propsNames), getProps);
  const rows = map(createPropsDto, topVendorDetailDTOList);

  const dto = zipObj(["loan", "rows"], [loanName, rows]);
  return dto;
};

export const createArActivityDto = ({ loanName, generic }) => {
  if (equals(length(generic), 0)) return { loan: loanName, rows: [] };

  const propsNames = ["name", "current", "prior", "delta", "percentage"];
  const createPropsDto = vs => zipObj(propsNames, vs);

  const rows = generic.map(({ currValue, priorValue, name }) => {
    const delta = currValue - priorValue;
    const percentage = getPercentageDiffValue(currValue, priorValue);
    return createPropsDto([name, currValue, priorValue, delta, percentage]);
  });

  const first = head(generic);
  const columns = zipObj(
    ["current", "currentLastUpdated", "prior", "priorLastUpdated"],
    [first.currDate, first.currLastUpdated, first.priorDate, first.priorLastUpdated]
  );
  const activity = zipObj(["rows", "columns"], [rows, columns]);
  const dto = zipObj(["loan", "activity"], [loanName, activity]);
  return dto;
};

export const createArIneligibleDto = ({ loanName, curr, prior }) => {
  const defaultValue = { value: 0 };
  const propsNames = ["name", "current", "prior", "delta", "percentage"];
  const createPropsDto = vs => zipObj(propsNames, vs);

  const rows = curr.map(({ name, value }) => {
    const p = (prior.find(filterByName(name)) || defaultValue).value;
    const delta = value - p;
    const percentage = getPercentageDiffValue(value, p);
    return createPropsDto([name, value, p, delta, percentage]);
  });

  const dto = zipObj(["loan", "rows"], [loanName, rows]);
  return dto;
};

export const createComparisonDates = item => {
  const { curr, prior, priorMinus1, priorMinus2 } = item;

  const keys = ["agingDate", "asOfDate"];
  return [
    zipObj(keys, [curr.agingDate, curr.asOfDate]),
    zipObj(keys, [prior.agingDate, prior.asOfDate]),
    zipObj(keys, [priorMinus1.agingDate, priorMinus1.asOfDate]),
    zipObj(keys, [priorMinus2.agingDate, priorMinus2.asOfDate])
  ];
};

export const createComparisonIneligibleRows = ineligibles => {
  const defaultValue = { value: 0 };
  const { curr, prior, priorMinus1, priorMinus2 } = ineligibles;
  const isEqualsToTotal = equals("Total");

  // Compile all unique names and codes as they could differ across time periods
  const names = [...curr, ...prior, ...priorMinus1, ...priorMinus2].reduce((acc, { code, name }) => {
    if (!acc.find(filterByName(name))) acc.push({ code, name });
    return acc;
  }, []);

  return names.map(({ code, name }) => {
    const isSameName = filterByName(name);
    const isTotal = isEqualsToTotal(code);

    const c = (curr.find(isSameName) || defaultValue).value;
    const p = (prior.find(isSameName) || defaultValue).value;
    const pm1 = (priorMinus1.find(isSameName) || defaultValue).value;
    const pm2 = (priorMinus2.find(isSameName) || defaultValue).value;
    const labelName = isTotal ? "Total Ineligible" : name;

    return zipObj(
      ["label", "code", "current", "prior", "priorMinusOne", "priorMinusTwo", "isTotal"],
      [labelName, code, c, p, pm1, pm2, isTotal]
    );
  });
};

export const createComparisonInvCollateralRows = collaterals =>
  collaterals.map(ict => {
    const gross = zipObj(
      ["id", "label", "current", "prior", "priorMinusOne", "priorMinusTwo"],
      [uniqueId(), "Gross Value", ict.currGross, ict.priorGross, ict.priorMinus1Gross, ict.priorMinus2Gross]
    );
    const inel = zipObj(
      ["id", "label", "current", "prior", "priorMinusOne", "priorMinusTwo"],
      [uniqueId(), "Ineligibles", ict.currInel, ict.priorInel, ict.priorMinus1Inel, ict.priorMinus2Inel]
    );
    const invValue = zipObj(
      ["id", "label", "current", "prior", "priorMinusOne", "priorMinusTwo"],
      [
        uniqueId(),
        `Inventory Value @ ${Number(ict.advanceRate)}%`,
        ict.currAmount,
        ict.priorAmount,
        ict.priorMinus1Amount,
        ict.priorMinus2Amount
      ]
    );
    const details = [gross, inel, invValue];
    return zipObj(["name", "details"], [ict.name, details]);
  });

export const createARValueRow = row =>
  zipObj(
    ["name", "curr", "prior", "priorMinus1", "priorMinus2"],
    [row.name, row.arValueDTO.curr, row.arValueDTO.prior, row.arValueDTO.priorMinus1, row.arValueDTO.priorMinus2]
  );

export const createComparisonReservesRows = (arValue, reserves) => {
  if (!isNil(arValue)) {
    reserves = prepend(createARValueRow(arValue), reserves);
  }

  return reserves.map(({ name, curr, prior, priorMinus1, priorMinus2 }) =>
    zipObj(
      ["id", "label", "current", "prior", "priorMinusOne", "priorMinusTwo", "isTotal"],
      [uniqueId(), name, curr, prior, priorMinus1, priorMinus2, name === "Total Reserve"]
    )
  );
};

export const createGrossAvailabilityDto = ({ name, currInvTotal, priorInvTotal }) => {
  const delta = currInvTotal - priorInvTotal;
  const percentage = getPercentageDiffValue(currInvTotal, priorInvTotal);
  return zipObj(DEFAULT_KEYS_FOR_INV_AGING_COMPARISON, [
    uniqueId(),
    name,
    currInvTotal,
    priorInvTotal,
    delta,
    percentage
  ]);
};

export const createInvAgingComparisonLoans = item => {
  const { name, curr, prior, collateralComparisons } = item;
  const delta = curr.gross - prior.gross;
  const percentage = getPercentageDiffValue(curr.gross, prior.gross);
  const dateProps = ["currAsOfDate", "currAgingDate", "priorAsOfDate", "priorAgingDate"];
  const loan = zipObj(concat(DEFAULT_KEYS_FOR_INV_AGING_COMPARISON, dateProps), [
    curr.loanid,
    name,
    curr.gross,
    prior.gross,
    delta,
    percentage,
    curr.asOfDate,
    curr.agingDate,
    prior.asOfDate,
    prior.agingDate
  ]);
  const comparisons = collateralComparisons.map(cc => {
    const prior = cc.prior || 0;
    const delta = cc.curr - prior;
    const percentage = getPercentageDiffValue(cc.curr, prior);
    return zipObj(DEFAULT_KEYS_FOR_INV_AGING_COMPARISON, [uniqueId(), cc.invCode, cc.curr, prior, delta, percentage]);
  });

  return { loan, comparisons };
};

export const createInvAgingComparisonTotals = ({
  advances,
  netLoanBalance,
  todaysAvailAfterAdvance,
  todaysAvailBeforeAdvance,
  totalAvailability,
  netARAvailability
}) => {
  const getFieldsValues = (label, d) =>
    concat([uniqueId(), label], props(["curr", "prior", "priorMinus1", "priorMinus2"], d));
  const defaultNames = ["id", "label", "current", "prior", "priorMinusOne", "priorMinusTwo"];

  const netBalance = zipObj(defaultNames, getFieldsValues("Net Loan Balance", netLoanBalance));
  const totalAvailabilityRows = zipObj(defaultNames, getFieldsValues("Total Availability", totalAvailability));
  const netAr = zipObj(defaultNames, getFieldsValues("Net AR Availability", netARAvailability));

  return zipObj(
    ["advance", "before", "after", "availability", "net", "netAr"],
    [advances, todaysAvailBeforeAdvance, todaysAvailAfterAdvance, totalAvailabilityRows, netBalance, netAr]
  );
};
