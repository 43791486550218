import { appendName } from "@/store/utils";
import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToSetDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_SUBORDINATOR_GUARANTOR: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_SUBORDINATOR_GUARANTOR"),
  CALL_GET_SUBORDINATOR: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_SUBORDINATOR"),

  SAVE_SUBORDINATOR_GUARANTOR: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_SUBORDINATOR_GUARANTOR"),

  CREATE_SUBORDINATOR: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_SUBORDINATOR"),
  CREATE_PERSONAL_GUARANTOR: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_PERSONAL_GUARANTOR"),
  CREATE_CORPORATE_GUARANTOR: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_CORPORATE_GUARANTOR"),

  UPDATE_SUBORDINATOR: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_SUBORDINATOR"),
  UPDATE_PERSONAL_GUARANTOR: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_PERSONAL_GUARANTOR"),
  UPDATE_CORPORATE_GUARANTOR: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_CORPORATE_GUARANTOR")
};

export const mutations = {
  //> subordinator guarantor
  SET_LOADING_SUBORDINATOR_GUARANTOR: appendToLoading("SUBORDINATOR_GUARANTOR"),
  SET_ERROR_SUBORDINATOR_GUARANTOR: appendToError("SUBORDINATOR_GUARANTOR"),
  SET_INFORMATION_SUBORDINATOR_GUARANTOR: appendToSetDetail("SUBORDINATOR_GUARANTOR"),
  //> subordinator
  SET_LOADING_SUBORDINATOR: appendToLoading("SUBORDINATOR"),
  SET_ERROR_SUBORDINATOR: appendToError("SUBORDINATOR"),
  //> personal guarantor
  SET_LOADING_PERSONAL_GUARANTOR: appendToLoading("PERSONAL_GUARANTOR"),
  SET_ERROR_PERSONAL_GUARANTOR: appendToError("PERSONAL_GUARANTOR"),
  SET_LIST_PERSONAL_GUARANTOR: appendToList("PERSONAL_GUARANTOR"),
  SET_LIST_SUBORDINATOR: appendToList("SUBORDINATOR"),
  //> corporate guarantor
  SET_LOADING_CORPORATE_GUARANTOR: appendToLoading("CORPORATE_GUARANTOR"),
  SET_ERROR_CORPORATE_GUARANTOR: appendToError("CORPORATE_GUARANTOR"),
  SET_LIST_CORPORATE_GUARANTOR: appendToList("CORPORATE_GUARANTOR"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("SUBORDINATOR_GUARANTOR")
};

export const DEFAULT_STATE = {
  loading: { subordinatorGuarantor: false, subordinator: false, personalGuarantor: false, corporateGuarantor: false },
  error: { subordinatorGuarantor: null, subordinator: null, personalGuarantor: null, corporateGuarantor: null },
  list: { subordinator: [], personalGuarantor: [], corporateGuarantor: [] },
  data: null
};
