import { appendName } from "../utils";

const append = appendName("GROUP");

export const actions = {
  CALL_GET_GROUPS: append("CALL_GET_GROUPS"),
  CALL_GET_GROUP_DETAIL: append("CALL_GET_GROUP_DETAIL"),
  CALL_GET_AUTHORITIES: append("CALL_GET_AUTHORITIES"),
  CALL_CREATE_AUTHORITY: append("CALL_CREATE_AUTHORITY"),
  ADD_AUTH_TO_GROUP: append("ADD_AUTH_TO_GROUP"),
  REMOVE_AUTH_FROM_GROUP: append("REMOVE_AUTH_FROM_GROUP"),
  CALL_CREATE_GROUP: append("CALL_CREATE_GROUP"),
  CALL_SAVE_AUTH_CHANGES: append("CALL_SAVE_AUTH_CHANGES")
};

export const getters = {
  GET_GROUPS_NAMES: append("GET_GROUPS_NAMES"),
  GET_CURRENT_GROUP_INFO: append("GET_CURRENT_GROUP_INFO"),
  GET_AUTHORITIES_FOR_PLATFORM_SECTION: append("GET_AUTHORITIES_FOR_PLATFORM_SECTION"),
  GET_AUTHORITIES_FOR_REPORT_SECTION: append("GET_AUTHORITIES_FOR_REPORT_SECTION"),
  GET_AUTHORITIES_FOR_DOCUMENT_SECTION: append("GET_AUTHORITIES_FOR_DOCUMENT_SECTION")
};

export const mutations = {
  SET_LOADING_GROUP: append("SET_LOADING_GROUP"),
  SET_LOADING_AUTHORITY: append("SET_LOADING_AUTHORITY"),
  SET_ERROR_GROUP: append("SET_ERROR_GROUP"),
  SET_ERROR_AUTHORITY: append("SET_ERROR_AUTHORITY"),
  SET_LIST_GROUP: append("SET_LIST_GROUP"),
  SET_LIST_AUTHORITY: append("SET_LIST_AUTHORITY"),
  SET_CURRENT_GROUP: append("SET_CURRENT_GROUP"),
  SET_CURRENT_AUTHORITY: append("SET_CURRENT_AUTHORITY"),
  SET_AUTHORITY_CHANGES: append("SET_AUTHORITY_CHANGES")
};

export const AUTHORITY_SECTION = {
  platform: {
    id: "PLATFORM",
    title: "Platform"
  },
  report: {
    id: "REPORTS",
    title: "Report"
  },
  document: {
    id: "DOCUMENTS",
    title: "Documents"
  },
  modal: "MODAL"
};

export const AUTHORITY_PERMISSION_ACTION = {
  add: "ADD",
  remove: "REMOVE"
};
