import { actions } from "./actions";
import { mutations } from "./mutations";

export const InventoryColalteralDetailModule = {
  state: {
    list: { detail: [] },
    loading: { detail: false, parent: false, child: false },
    error: { detail: null, parent: null, child: null },
    asOfDate: null
  },
  actions,
  mutations,
  getters: {}
};
