import Vue from "vue";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_DOCUMENTS]: function(state, loading) {
    Vue.set(state.loading, "documents", loading);
  },
  [MU.SET_ERROR_DOCUMENTS]: function(state, error) {
    Vue.set(state.error, "documents", error);
  },
  [MU.SET_LOAN_DOCUMENTS]: function(state, documents) {
    Vue.set(state.list, "documents", documents);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "list", DEFAULT_STATE.list);
    Vue.set(state, "error", DEFAULT_STATE.error);
    Vue.set(state, "loading", DEFAULT_STATE.loading);
  }
};
