import { appendName } from "@/store/utils";

const append = appendName("LEDGED_ENTRY");

export const actions = {
  CALL_GET_ALL_LOANS: append("CALL_GET_ALL_LOANS"),
  CALL_GET_LEDGER_ENTRY_DATA: append("CALL_GET_LEDGER_ENTRY_DATA"),
  SAVE_LEDGER_ENTRY_DATA: append("SAVE_LEDGER_ENTRY_DATA")
};

export const mutations = {
  SET_LOADING_LEDGER_ENTRY: append("SET_LOADING_LEDGER_ENTRY"),
  SET_ERROR_LEDGER_ENTRY: append("SET_ERROR_LEDGER_ENTRY"),
  SET_LIST_LEDGER_ENTRY: append("SET_LIST_LEDGER_ENTRY"),
  //> loan
  SET_LOADING_LOANS: append("SET_LOADING_LOANS"),
  SET_ERROR_LOANS: append("SET_ERROR_LOANS")
};
