import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const BorrowerLoanModule = {
  state: {
    borrower: { list: [], current: "", loading: false, error: null },
    loan: { list: [], current: "", loading: false, error: null },
    disbursements: [],
    requestAdvance: { loading: false, error: null },
    borrowingBaseRequest: { list: [], loading: false, error: null }
  },
  actions,
  getters,
  mutations
};
