import { appendName } from "../utils";

const append = appendName("DASHBOARD");

export const actions = {
  CALL_GET_COLLATERAL_COMPARISON: append("CALL_GET_COLLATERAL_COMPARISON"),
  CALL_GET_AGING_COMPARISON: append("CALL_GET_AGING_COMPARISON"),
  CALL_GET_AR_INELIGIBLE: append("CALL_GET_AR_INELIGIBLE"),
  CALL_GET_COLLATERAL_RESERVE: append("CALL_GET_COLLATERAL_RESERVE"),
  CALL_GET_SHIFT: append("CALL_GET_SHIFT"),
  CALL_GET_SPREAD: append("CALL_GET_SPREAD"),
  CALL_GET_AR_ACTIVITY: append("CALL_GET_AR_ACTIVITY"),
  CALL_GET_AR_TREND: append("CALL_GET_AR_TREND"),
  CALL_GET_INV_AGING_COMPARISON: append("CALL_GET_INV_AGING_COMPARISON"),
  CALL_GET_INV_INELIGIBLE: append("CALL_GET_INV_INELIGIBLE"),
  CALL_GET_TOP_VENDOR: append("CALL_GET_TOP_VENDOR")
};

export const mutations = {
  //> collateral comparison
  SET_LOADING_COLLATERAL_COMPARISON: append("SET_LOADING_COLLATERAL_COMPARISON"),
  SET_ERROR_COLLATERAL_COMPARISON: append("SET_ERROR_COLLATERAL_COMPARISON"),
  SET_LIST_COLLATERAL_COMPARISON: append("SET_LIST_COLLATERAL_COMPARISON"),
  //> aging comparison
  SET_LOADING_AGING_COMPARISON: append("SET_LOADING_AGING_COMPARISON"),
  SET_ERROR_AGING_COMPARISON: append("SET_ERROR_AGING_COMPARISON"),
  SET_LIST_AGING_COMPARISON: append("SET_LIST_AGING_COMPARISON"),
  //> ar ineligible
  SET_LOADING_AR_INELIGIBLE: append("SET_LOADING_AR_INELIGIBLE"),
  SET_ERROR_AR_INELIGIBLE: append("SET_ERROR_AR_INELIGIBLE"),
  SET_LIST_AR_INELIGIBLE: append("SET_LIST_AR_INELIGIBLE"),
  //> collateral reserve
  SET_LOADING_COLLATERAL_RESERVE: append("SET_LOADING_COLLATERAL_RESERVE"),
  SET_ERROR_COLLATERAL_RESERVE: append("SET_ERROR_COLLATERAL_RESERVE"),
  SET_LIST_COLLATERAL_RESERVE: append("SET_LIST_COLLATERAL_RESERVE"),
  //> shift
  SET_LOADING_SHIFT: append("SET_LOADING_SHIFT"),
  SET_ERROR_SHIFT: append("SET_ERROR_SHIFT"),
  SET_LIST_SHIFT: append("SET_LIST_SHIFT"),
  //> spread
  SET_LOADING_SPREAD: append("SET_LOADING_SPREAD"),
  SET_ERROR_SPREAD: append("SET_ERROR_SPREAD"),
  SET_LIST_SPREAD: append("SET_LIST_SPREAD"),
  //> ar activity
  SET_LOADING_AR_ACTIVITY: append("SET_LOADING_AR_ACTIVITY"),
  SET_ERROR_AR_ACTIVITY: append("SET_ERROR_AR_ACTIVITY"),
  SET_LIST_AR_ACTIVITY: append("SET_LIST_AR_ACTIVITY"),
  //> ar trend
  SET_LOADING_AR_TREND: append("SET_LOADING_AR_TREND"),
  SET_ERROR_AR_TREND: append("SET_ERROR_AR_TREND"),
  SET_LIST_AR_TREND: append("SET_LIST_AR_TREND"),
  //> inv aging comparison
  SET_LOADING_INV_AGING_COMPARISON: append("SET_LOADING_INV_AGING_COMPARISON"),
  SET_ERROR_INV_AGING_COMPARISON: append("SET_ERROR_INV_AGING_COMPARISON"),
  SET_LIST_INV_AGING_COMPARISON: append("SET_LIST_INV_AGING_COMPARISON"),
  //> inv ineligible
  SET_LOADING_INV_INELIGIBLE: append("SET_LOADING_INV_INELIGIBLE"),
  SET_ERROR_INV_INELIGIBLE: append("SET_ERROR_INV_INELIGIBLE"),
  SET_LIST_INV_INELIGIBLE: append("SET_LIST_INV_INELIGIBLE"),
  //> top vendor
  SET_LOADING_TOP_VENDOR: append("SET_LOADING_TOP_VENDOR"),
  SET_ERROR_TOP_VENDOR: append("SET_ERROR_TOP_VENDOR"),
  SET_LIST_TOP_VENDOR: append("SET_LIST_TOP_VENDOR"),
  //> top debtor
  SET_LIST_TOP_DEBTOR: append("SET_LIST_TOP_DEBTOR"),
  //> clean
  CLEAN_STATE: append("CLEAN_STATE")
};

export const DEFAULT_STATE = {
  list: {
    collateralComparison: [],
    agingComparison: [],
    arIneligible: [],
    collateralReserve: [],
    shift: [],
    spread: [],
    arActivity: [],
    arTrend: [],
    invAgingComparison: [],
    invIneligible: [],
    topVendor: [],
    topDebtor: []
  },
  loading: {
    collateralComparison: false,
    agingComparison: false,
    arIneligible: false,
    collateralReserve: false,
    shift: false,
    spread: false,
    arActivity: false,
    arTrend: false,
    invAgingComparison: false,
    invIneligible: false,
    topVendor: false
  },
  error: {
    collateralComparison: null,
    agingComparison: null,
    arIneligible: null,
    collateralReserve: null,
    shift: null,
    spread: null,
    arActivity: null,
    arTrend: null,
    invAgingComparison: null,
    invIneligible: null,
    topVendor: null
  }
};
