import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_GROUP]: function (state, value) {
    Vue.set(state.loading, "group", value);
  },
  [MU.SET_LOADING_AUTHORITY]: function (state, value) {
    Vue.set(state.loading, "authority", value);
  },
  [MU.SET_ERROR_GROUP]: function (state, value) {
    Vue.set(state.error, "group", value);
  },
  [MU.SET_ERROR_AUTHORITY]: function (state, value) {
    Vue.set(state.error, "authority", value);
  },
  [MU.SET_LIST_GROUP]: function (state, value) {
    Vue.set(state.list, "group", value);
  },
  [MU.SET_LIST_AUTHORITY]: function (state, value) {
    Vue.set(state.list, "authority", value);
  },
  [MU.SET_CURRENT_GROUP]: function (state, value) {
    Vue.set(state.current, "group", value);
  },
  [MU.SET_CURRENT_AUTHORITY]: function (state, value) {
    Vue.set(state.current, "authority", value);
  },
  [MU.SET_AUTHORITY_CHANGES]: function (state, value) {
    Vue.set(state, "authorityChanges", value);
  }
};
