import { appendName } from "@/store/utils";

import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToSetDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  //> deal structure
  CALL_GET_DEAL_STRUCTURE: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_DEAL_STRUCTURE"),
  SAVE_DEAL_STRUCTURE: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_DEAL_STRUCTURE"),
  // link borrowers
  CALL_GET_LINK_BORROWERS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_LINK_BORROWERS"),
  SAVE_LINK_BORROWER: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_LINK_BORROWER")
};

export const mutations = {
  //> deal structure
  SET_LOADING_DEAL_STRUCTURE: appendToLoading("DEAL_STRUCTURE"),
  SET_ERROR_DEAL_STRUCTURE: appendToError("DEAL_STRUCTURE"),
  SET_INFORMATION_DEAL_STRUCTURE: appendToSetDetail("DEAL_STRUCTURE"),
  // link borrowers
  SET_LOADING_LINK_BORROWERS: appendToLoading("LINK_BORROWERS"),
  SET_ERROR_LINK_BORROWERS: appendToError("LINK_BORROWERS"),
  SET_LIST_LINK_BORROWERS: appendToList("LINK_BORROWERS"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("DEAL_STRUCTURE")
};

export const getters = {
  GET_DATA: APPEND_BORROWER_DETAIL_MODULE_NAME("GET_DATA")
};

export const DEFAULT_STATE = {
  loading: { dealStructure: false, linkBorrower: false },
  error: { dealStructure: null, linkBorrower: null },
  list: { linkBorrower: [] },
  changes: {},
  data: null
};

export const DEAL_TYPE_AGENT = "agent";
