import { appendName } from "../utils";

const append = appendName("LOAN");

export const actions = {
  CREATE_LOAN: append("CREATE_LOAN"),
  EDIT_LOAN: append("EDIT_LOAN"),
  CALL_GET_ADMIN_CODES: append("CALL_GET_ADMIN_CODES"),
  CALL_GET_INTEREST_RATE: append("CALL_GET_INTEREST_RATE"),
  CALL_GET_LOAN_SUMMARY_BORROWING_BASE_TOTALS: append("CALL_GET_LOAN_SUMMARY_BORROWING_BASE_TOTALS")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_CURENT_LOANID: append("SET_CURENT_LOANID"),
  SET_PARTICIPATION_CODE: append("SET_PARTICIPATION_CODE"),
  SET_INTEREST_RATE: append("SET_INTEREST_RATE"),
  //> borrowing base totals
  SET_LOADING_LOAN_SUMMARY_BORROWING_BASE_TOTAL: append("SET_LOADING_LOAN_SUMMARY_BORROWING_BASE_TOTAL"),
  SET_ERROR_LOAN_SUMMARY_BORROWING_BASE_TOTAL: append("SET_ERROR_LOAN_SUMMARY_BORROWING_BASE_TOTAL"),
  SET_LIST_LOAN_SUMMARY_BORROWING_BASE_TOTAL: append("SET_LIST_LOAN_SUMMARY_BORROWING_BASE_TOTAL")
};
