import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// Default
const LoginLayout = () => import(/* webpackChunkName: "login-layout" */ "../layouts/LoginLayout.vue");
const Login = () => import(/* webpackChunkName: "login-form-view" */ "../views/login/Login.vue");
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password-view" */ "../views/login/ForgotPassword.vue");
const ChangePassword = () => import(/* webpackChunkName: "change-password-view" */ "../views/login/ChangePassword.vue");
const ExpiredPassword = () => import(/* webpackChunkName: "expired-password-view" */ "../views/login/ExpiredPassword.vue");

const routes = [
  {
    path: "",
    component: LoginLayout,
    children: [
      { path: "/",  alias: "/login", name: "login",  component: Login },
      { path: "/forgot-password", name: "forgot-password",  component: ForgotPassword },
      { path: "/change-password", name: "change-password",  component: ChangePassword },
      { path: "/expired-password", name: "expired-password",  component: ExpiredPassword },
      { path: "*", redirect: { name: "login" } }
    ]
  }
];

const router = new VueRouter({
  routes: routes
});

export default router;
