import Axios from "axios";
import { defaultTo, prop, omit } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { actions as RAC, NOTIFICATION_TYPE } from "../root/constants";
import { actions as BLAC, getters as BLGE } from "../borrower-loan/constants";
import { mutations as COLLATERAL_RESERVE_MU } from "../collateral-reserve/constants";

import { logAndGetErrorMessage } from "@/store/utils";

const showAlert = async (dispatch, type, body, redirectTo = null) => {
  await dispatch(RAC.SHOW_ALERT_NOTIFICATION, {
    type,
    body,
    redirectTo
  });
};

const createLoan = async ({ commit, dispatch, rootState, getters }, formValues) => {
  try {
    commit(MU.SET_LOADING, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const facilityCode = getters[BLGE.GET_CURRENT_BORROWER_VALUE]("facilityCode");

    // Keep until switch vueformulate can accept a default value
    const excludeFromBorrowerAvailability = defaultTo(false, prop("excludeFromBorrowerAvailability", formValues));

    await Axios.post(`/api/loans/${formValues.loanId}/client/${clientid}`, {
      ...omit(["excludeFromBorrowerAvailability"], formValues),
      excludeFromBorrowerAvailability,
      clientid,
      facilityCode: facilityCode,
      status: "active"
    });
    await showAlert(dispatch, NOTIFICATION_TYPE.success, "Loan created successfully!", "loan");
    dispatch(BLAC.CALL_LOANS);
  } catch (err) {
    commit(MU.SET_ERROR, err.response.data.message);
    showAlert(dispatch, NOTIFICATION_TYPE.error, logAndGetErrorMessage(AC.CREATE_LOAN, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const editLoan = async ({ commit, dispatch, rootState, getters }, formValues) => {
  try {
    commit(MU.SET_LOADING, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const facilityCode = getters[BLGE.GET_CURRENT_BORROWER_VALUE]("facilityCode");

    // Keep until switch vueformulate can accept a default value
    const excludeFromBorrowerAvailability = defaultTo(false, prop("excludeFromBorrowerAvailability", formValues));

    await Axios.put(`/api/loans/${formValues.loanId}/client/${clientid}`, {
      ...omit(["excludeFromBorrowerAvailability"], formValues),
      excludeFromBorrowerAvailability,
      facilityCode,
      status: "active"
    });
    await showAlert(dispatch, NOTIFICATION_TYPE.success, "Loan updated successfully!", "loan");
    dispatch(BLAC.CALL_LOANS);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.EDIT_LOAN, err);
    commit(MU.SET_ERROR, msg);
    showAlert(dispatch, NOTIFICATION_TYPE.error, msg);
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const callGetAdminCodes = async ({ commit }) => {
  try {
    commit(MU.SET_PARTICIPATION_CODE, { loading: true });
    const { data } = await Axios.get("/api/admin/codes");
    const item = data.find(({ code }) => code === "PARTICIPATION_CODE");
    if (item) {
      commit(MU.SET_PARTICIPATION_CODE, { list: item.basicCategoryCodes });
    }
    const reserves = data.find(({ code }) => code === "RESERVE");
    if (reserves) {
      commit(COLLATERAL_RESERVE_MU.SET_RESERVE_CATEGORY_CODES, reserves.basicCategoryCodes);
    }

    commit(MU.SET_PARTICIPATION_CODE, { list: data });
  } catch (err) {
    commit(MU.SET_PARTICIPATION_CODE, { error: logAndGetErrorMessage(AC.CALL_GET_ADMIN_CODES, err) });
  } finally {
    commit(MU.SET_PARTICIPATION_CODE, { loading: false });
  }
};

export const callGetInterestRate = async ({ commit }) => {
  try {
    commit(MU.SET_INTEREST_RATE, { loading: true });
    const { data } = await Axios.get("/api/rates");
    commit(MU.SET_INTEREST_RATE, { list: data });
  } catch (err) {
    commit(MU.SET_INTEREST_RATE, { error: logAndGetErrorMessage(AC.CALL_GET_INTEREST_RATE, err) });
  } finally {
    commit(MU.SET_INTEREST_RATE, { loading: false });
  }
};

export const callGetLoanSummaryBorrowingBaseTotals = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING_LOAN_SUMMARY_BORROWING_BASE_TOTAL, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/loan/summary/borrowingbase/totals/${clientid}`);
    commit(MU.SET_LIST_LOAN_SUMMARY_BORROWING_BASE_TOTAL, data);
  } catch (err) {
    commit(
      MU.SET_ERROR_LOAN_SUMMARY_BORROWING_BASE_TOTAL,
      logAndGetErrorMessage(AC.CALL_GET_LOAN_SUMMARY_BORROWING_BASE_TOTALS, err)
    );
  } finally {
    commit(MU.SET_LOADING_LOAN_SUMMARY_BORROWING_BASE_TOTAL, false);
  }
};

export const actions = {
  [AC.CREATE_LOAN]: createLoan,
  [AC.EDIT_LOAN]: editLoan,
  [AC.CALL_GET_ADMIN_CODES]: callGetAdminCodes,
  [AC.CALL_GET_INTEREST_RATE]: callGetInterestRate,
  [AC.CALL_GET_LOAN_SUMMARY_BORROWING_BASE_TOTALS]: callGetLoanSummaryBorrowingBaseTotals
};
