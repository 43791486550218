import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const GroupModule = {
  state: {
    list: { group: [], authority: [] },
    loading: { group: false, authority: false },
    error: { group: null, authority: null },
    current: { group: null, authority: null },
    authorityChanges: {}
  },
  actions,
  getters,
  mutations
};
