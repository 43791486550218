import { appendName } from "../utils";

const append = appendName("ADVANCE_WORKLIST");

export const actions = {
  CALL_GET_ADVANCE_WORKLIST: append("CALL_GET_ADVANCE_WORKLIST"),
  CALL_UPDATE_ADVANCE_WORKLIST: append("CALL_UPDATE_ADVANCE_WORKLIST"),
  CALL_GET_FEES: append("CALL_GET_FEES"),
  CALL_DELETE: append("CALL_DELETE")
};

export const getters = {};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_SUCCESS: append("SET_SUCCESS"),
  SET_ADVANCE_WORKLIST: append("SET_ADVANCE_WORKLIST"),
  SET_FEES: append("SET_FEES"),
  SET_SEARCH: append("SET_SEARCH")
};
