import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetRates = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/api/rates");
    commit(MU.SET_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_RATES, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const createRate = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.post("/api/rate", formData);
    dispatch(AC.CALL_GET_RATES);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.CREATE_RATE, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const updateRate = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.put("/api/rate", formData);
    dispatch(AC.CALL_GET_RATES);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.UPDATE_RATE, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

export const actions = {
  [AC.CALL_GET_RATES]: callGetRates,
  [AC.CREATE_RATE]: createRate,
  [AC.UPDATE_RATE]: updateRate
};
