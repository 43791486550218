import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const ProcessDocumentModule = {
  state: {
    loading: {
      mapping: false,
      preview: false
    },
    error: {
      general: null,
      mapping: null,
      preview: null
    },
    sheets: [],
    columnMap: {},
    preview: null,
    doc: {
      id: null,
      type: null,
      name: null,
      permission: null,
      periodEnding: null
    },
    processing: {
      isProcessing: false,
      totalRecords: 0,
      totalProcessed: 0
    }
  },
  actions,
  getters,
  mutations
};
