import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_YIELD_FEE]: function(state, loading) {
    Vue.set(state.yieldFee, "loading", loading);
  },
  [MU.SET_ERROR_YIELD_FEE]: function(state, error) {
    Vue.set(state.yieldFee, "error", error);
  },
  [MU.SET_INFORMATION_YIELD_FEE]: function(state, data) {
    Vue.set(state.yieldFee, "data", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "yieldFee", clone(DEFAULT_STATE));
  }
};
