import { actions } from "./actions";
import { mutations } from "./mutations";

export const RateModule = {
  state: {
    list: { loading: false, error: null, data: [] },
    modal: { loading: false, error: null }
  },
  actions,
  mutations,
  getters: {}
};
