import { appendName } from "../utils";

const append = appendName("DEBTOR");

export const INELIGIBLE_FLAGS = {
  CREDIT_LIMIT: "CL",
  FOREIGN_INSURANCE: "FI"
};

export const actions = {
  EDIT_DEBTOR: append("EDIT_DEBTOR"),
  CALL_SAVE_LIST: append("CALL_SAVE_LIST"),
  CALL_GET_LIST: append("CALL_GET_LIST"),
  CALL_GET_DETAILS: append("CALL_GET_DETAILS"),
  CALL_GET_INVOICES: append("CALL_GET_INVOICES"),
  CALL_SAVE_INVOICES: append("CALL_SAVE_INVOICES"),
  SAVE_AS_PARENT_DEBTOR: append("SAVE_AS_PARENT_DEBTOR"),
  GET_LINKED_DEBTORS: append("GET_LINKED_DEBTORS"),
  SEARCH_CREDIT_SAFE: append("SEARCH_CREDIT_SAFE"),
  GET_CREDIT_REPORT: append("GET_CREDIT_REPORT"),
  GET_CALL_SPREADS_AMOUNT: append("GET_CALL_SPREADS_AMOUNT"),
  GET_CALL_INELIGIBLES: append
};

export const mutations = {
  SET_LOADING_LIST: append("SET_LOADING_LIST"),
  SET_HAS_CHANGES_LIST: append("SET_HAS_CHANGES_LIST"),
  SET_ERROR_LIST: append("SET_ERROR_LIST"),
  SET_LOADING_DETAILS: append("SET_LOADING_DETAILS"),
  SET_HAS_CHANGES_DETAILS: append("SET_HAS_CHANGES_DETAILS"),
  SET_ERROR_DETAILS: append("SET_ERROR_DETAILS"),
  SET_LOADING_INVOICES: append("SET_LOADING_INVOICES"),
  SET_HAS_CHANGES_INVOICES: append("SET_HAS_CHANGES_INVOICES"),
  SET_ERROR_INVOICES: append("SET_ERROR_INVOICES"),
  SET_LOANID: append("SET_LOANID"),
  SET_DEBTOR: append("SET_DEBTOR"),
  SET_DEBTOR_LIST: append("SET_DEBTOR_LIST"),
  SET_INEL_DAYS_OVER: append("SET_INEL_DAYS_OVER"),
  SET_PAYOR_CLASSES: append("SET_PAYOR_CLASSES"),
  SET_COUNTRY_CODES: append("SET_COUNTRY_CODES"),
  SET_DEBTOR_DETAILS: append("SET_DEBTOR_DETAILS"),
  SET_LINKED_DEBTORS: append("SET_LINKED_DEBTORS"),
  SET_HAS_CHANGES_LINKED_DEBTORS: append("SET_HAS_CHANGES_LINKED_DEBTORS"),
  //> spread
  SET_LOADING_SPREAD: append("SET_LOADING_SPREAD"),
  SET_ERROR_SPREAD: append("SET_ERROR_SPREAD"),
  SET_SPREAD: append("SET_SPREAD"),
  SET_INELIGIBLES: append("SET_INELIGIBLES")
};
