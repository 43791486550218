import { appendName } from "../utils";

const append = appendName("COLLATERAL_RESERVE");

export const actions = {
  CALL_SAVE_ALL_CHANGES: append("CALL_SAVE_ALL_CHANGES"),
  CALL_GET_COLLATERAL_RESERVES: append("CALL_GET_COLLATERAL_RESERVES")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_LIST: append("SET_LIST"),
  SET_CHANGE_NEW: append("SET_CHANGE_NEW"),
  SET_CHANGE_EDIT: append("SET_CHANGE_EDIT"),
  SET_LOANID: append("SET_LOANID"),
  SET_RESERVE_CATEGORY_CODES: append("SET_RESERVE_CATEGORY_CODES"),
  ADD_CHANGE_NEW: append("ADD_CHANGE_NEW"),
  ADD_CHANGE_EDIT: append("ADD_CHANGE_EDIT"),
  ADD_CHANGE_DELETE: append("ADD_CHANGE_DELETE"),
  CLEAN_CHANGES: append("CLEAN_CHANGES")
};
