import { appendName } from "../utils";

const append = appendName("ROOLFORWARD");

export const actions = {
  CALL_GET_LOAN_COLLATERAL_BALANCE: append("CALL_GET_LOAN_COLLATERAL_BALANCE"),
  CALL_SAVE_ALL_CHANGES: append("CALL_SAVE_ALL_CHANGES"),
  CALL_GET_ROLLFORWARD: append("CALL_GET_ROLLFORWARD")
};

export const mutations = {
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_LIST: append("SET_LIST"),
  SET_HAS_CHANGES: append("HAS_CHANGES"),
  SET_CHANGE_NEW: append("SET_CHANGE_NEW"),
  SET_CHANGE_EDIT: append("SET_CHANGE_EDIT"),
  SET_CHANGE_DELETE: append("SET_CHANGE_DELETE"),
  SET_LOANID: append("SET_LOANID"),
  ADD_CHANGE_NEW: append("ADD_CHANGE_NEW"),
  ADD_CHANGE_EDIT: append("ADD_CHANGE_EDIT"),
  ADD_CHANGE_DELETE: append("ADD_CHANGE_DELETE"),
  CLEAN_CHANGES: append("CLEAN_CHANGES")
};

export const FILE = {
  mutations: mutations,
  actions: actions
};
