import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_DOCUMENTS]: function(state, list) {
    Vue.set(state, "list", list);
  },
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", error);
  },
  [MU.SET_DOC_DEFINITION_INFO]: function(state, definition) {
    Vue.set(state, "definition", { ...state.definition, ...definition });
  },
  [MU.SET_DOC_UPLOAD_INFO]: function(state, upload) {
    Vue.set(state, "upload", { ...state.upload, ...upload });
  }
};
