import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { formatToShortDateISO } from "@/utils/index";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetLoanDocuments = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_DOCUMENTS, true);
    const { data } = await Axios.get(`/api/documents/loan_docs/client/${clientid}`);
    commit(MU.SET_LOAN_DOCUMENTS, data);
  } catch (err) {
    commit(MU.SET_ERROR_DOCUMENTS, logAndGetErrorMessage(AC.CALL_GET_LOAN_DOCUMENTS, err));
  } finally {
    commit(MU.SET_LOADING_DOCUMENTS, false);
  }
};

const uploadDocumentFile = async ({ commit, dispatch, rootState }, values) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_DOCUMENTS, true);

    const formData = new FormData();
    formData.append("type", values.type);
    formData.append("effectiveDate", formatToShortDateISO(values.effectiveDate));
    formData.append("comment", values.comment);
    formData.append("file", values.file);
    await Axios.post(`/api/documents/loan_docs/client/${clientid}`, formData);

    dispatch(AC.CALL_GET_LOAN_DOCUMENTS);
  } catch (err) {
    commit(MU.SET_ERROR_DOCUMENTS, logAndGetErrorMessage(AC.UPLOAD_DOCUMENT_FILE, err));
  } finally {
    commit(MU.SET_LOADING_DOCUMENTS, false);
  }
};

const updateLoanDocument = async ({ commit, dispatch, rootState }, values) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_DOCUMENTS, true);

    const formData = new FormData();
    formData.append("type", values.type);
    formData.append("effectiveDate", formatToShortDateISO(values.effectiveDate));
    formData.append("comment", values.comment);
    await Axios.put(`/api/documents/loan_docs/client/${clientid}/${values.id}`, formData);

    dispatch(AC.CALL_GET_LOAN_DOCUMENTS);
  } catch (err) {
    commit(MU.SET_ERROR_DOCUMENTS, logAndGetErrorMessage(AC.UPLOAD_DOCUMENT_FILE, err));
  } finally {
    commit(MU.SET_LOADING_DOCUMENTS, false);
  }
};

const deleteLoanDocument = async ({ commit, dispatch, rootState }, id) => {
  try {
    commit(MU.SET_LOADING_DOCUMENTS, true);
    const clientid = rootState.borrower_loan.borrower.current;
    await Axios.delete(`api/documents/loan_docs/client/${clientid}/${id}`);
    await dispatch(AC.CALL_GET_LOAN_DOCUMENTS);
  } catch (err) {
    commit(MU.SET_ERROR_DOCUMENTS, logAndGetErrorMessage(AC.DELETE_LOAN_DOCUMENT, err));
  } finally {
    commit(MU.SET_LOADING_DOCUMENTS, false);
  }
};

export const actions = {
  [AC.CALL_GET_LOAN_DOCUMENTS]: callGetLoanDocuments,
  [AC.UPLOAD_DOCUMENT_FILE]: uploadDocumentFile,
  [AC.UPDATE_LOAN_DOCUMENT]: updateLoanDocument,
  [AC.DELETE_LOAN_DOCUMENT]: deleteLoanDocument
};
