import { appendName } from "../utils";

const append = appendName("FEES");

export const actions = {
  CALL_FEES_APPLIED: append("CALL_FEES_APPLIED"),
  CALL_FEES: append("CALL_FEES"),
  SAVE_FEES: append("SAVE_FEES")
};

export const getters = {
  GET_FEES: append("GET_FEES")
};

export const mutations = {
  SET_FEES: append("SET_FEES"),
  SET_LOADING: append("SET_LOADING"),
  SET_ERROR: append("SET_ERROR"),
  SET_FEES_APPLIED: append("SET_FEES_APPLIED")
};
