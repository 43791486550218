import Axios from "axios";
import { assoc, prop, filter, find, isNil, propEq, map } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetGridCategoryInformation = async ({ commit, rootState }, id) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_GRID_CATEGORY, true);
    const { data } = await Axios.get(`/api/trialbalance/secondary/${clientid}/${id}`);
    commit(MU.SET_LIST_GRID_CATEGORY, data);
  } catch (err) {
    commit(MU.SET_ERROR_GRID_CATEGORY, logAndGetErrorMessage(AC.CALL_GET_GRID_CATEGORY_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_GRID_CATEGORY, false);
  }
};

const callGetFTAFieldInformation = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_FTA_FIELD, true);
    const { data } = await Axios.get("/api/trialbalance/field");

    commit(MU.SET_LIST_FTA_FIELD, data);
  } catch (err) {
    commit(MU.SET_ERROR_FTA_FIELD, logAndGetErrorMessage(AC.CALL_GET_FTA_FIELD_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_FTA_FIELD, false);
  }
};

const createTrialBalanceCategory = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_MODAL_CATEGORY, true);
    await Axios.post(`/api/trialbalance/secondary/${clientid}`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_GRID_CATEGORY_INFORMATION, formData.trialBalanceFieldId);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL_CATEGORY, logAndGetErrorMessage(AC.CREATE_TRIAL_BALANCE_CATEGORY, err));
  } finally {
    commit(MU.SET_LOADING_MODAL_CATEGORY, false);
  }
};

const updateTrialBalanceCategory = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_MODAL_CATEGORY, true);
    await Axios.put(`/api/trialbalance/client/${clientid}/secondary/${formData.id}`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_GRID_CATEGORY_INFORMATION, formData.trialBalanceFieldId);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL_CATEGORY, logAndGetErrorMessage(AC.UPDATE_TRIAL_BALANCE_CATEGORY, err));
  } finally {
    commit(MU.SET_LOADING_MODAL_CATEGORY, false);
  }
};

const callGetTrialBalanceMappingGrid = async ({ commit, dispatch, rootState, state }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_GRID_MAPPING, true);

    //> fta fields
    if (state.list.ftaField.length === 0) {
      await dispatch(AC.CALL_GET_FTA_FIELD_INFORMATION);
    }
    const { docId, fields, mappedColumns, headerIndex, rowIndex, sheetName } = state.current;

    // BE cannot be changed so create column map consisting of excel column keys to a
    // list of db fields they are mapped to
    const columnMap = mappedColumns.reduce((previous, { index }) => {
      const mappedFields = filter(f => !isNil(find(propEq("index", index))(f.list)), fields);
      previous[index] = mappedFields.map(prop("id"));
      return previous;
    }, {});

    const { data } = await Axios.post(`/api/documents/client/${clientid}/${docId}`, {
      columnMap: columnMap,
      headerRowStart: headerIndex - 1,
      dataRowStart: rowIndex - 1,
      includeSections: [sheetName],
      headerRowEnd: null,
      dataRowEnd: null
    });

    commit(MU.SET_LIST_GRID_MAPPING, data);
  } catch (err) {
    commit(MU.SET_ERROR_GRID_MAPPING, logAndGetErrorMessage(AC.CALL_GET_TRIAL_BALANCE_MAPPING_GRID, err));
  } finally {
    commit(MU.SET_LOADING_GRID_MAPPING, false);
  }
};

const saveTrialBalanceMappingDetail = async ({ commit, rootState, dispatch }, formData) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING_MODAL_MAPPING, true);
    await Axios.put(
      `/api/trialbalance/mapping/${formData.trialBalanceMappingId}`,
      assoc("clientid", clientid, formData)
    );

    dispatch(AC.CALL_GET_TRIAL_BALANCE_MAPPING_GRID);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL_MAPPING, logAndGetErrorMessage(AC.SAVE_TRIAL_BALANCE_MAPPING_DETAIL, err));
  } finally {
    commit(MU.SET_LOADING_MODAL_MAPPING, false);
  }
};

export const actions = {
  [AC.CALL_GET_GRID_CATEGORY_INFORMATION]: callGetGridCategoryInformation,
  [AC.CALL_GET_FTA_FIELD_INFORMATION]: callGetFTAFieldInformation,
  [AC.CREATE_TRIAL_BALANCE_CATEGORY]: createTrialBalanceCategory,
  [AC.UPDATE_TRIAL_BALANCE_CATEGORY]: updateTrialBalanceCategory,
  [AC.CALL_GET_TRIAL_BALANCE_MAPPING_GRID]: callGetTrialBalanceMappingGrid,
  [AC.SAVE_TRIAL_BALANCE_MAPPING_DETAIL]: saveTrialBalanceMappingDetail
};
