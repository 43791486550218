import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_GENERAL_INFO_BORROWER]: function(state, loading) {
    Vue.set(state.generalInfo.loading, "borrower", loading);
  },
  [MU.SET_LOADING_GENERAL_INFO_OWNERSHIP]: function(state, loading) {
    Vue.set(state.generalInfo.loading, "ownership", loading);
  },
  [MU.SET_LOADING_GENERAL_INFO_SPONSORS]: function(state, loading) {
    Vue.set(state.generalInfo.loading, "sponsors", loading);
  },
  [MU.SET_ERROR_GENERAL_INFO_BORROWER]: function(state, error) {
    Vue.set(state.generalInfo.error, "borrower", error);
  },
  [MU.SET_ERROR_GENERAL_INFO_OWNERSHIP]: function(state, error) {
    Vue.set(state.generalInfo.error, "ownership", error);
  },
  [MU.SET_ERROR_GENERAL_INFO_SPONSORS]: function(state, error) {
    Vue.set(state.generalInfo.error, "sponsors", error);
  },
  [MU.SET_INFORMATION_GENERAL_INFO_BORROWER]: function(state, data) {
    Vue.set(state.generalInfo, "borrower", data);
  },
  [MU.SET_LIST_GENERAL_INFO_OWNERSHIP]: function(state, data) {
    Vue.set(state.generalInfo.list, "ownership", data);
  },
  [MU.SET_LIST_GENERAL_INFO_SPONSORS]: function(state, data) {
    Vue.set(state.generalInfo.list, "sponsors", data);
  },
  [MU.SET_GENERAL_INFO_CHANGES]: function(state, value) {
    Vue.set(state.generalInfo, "changes", value);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "generalInfo", clone(DEFAULT_STATE));
  }
};
