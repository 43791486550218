import { assoc, reduce, groupBy, prop, compose, map, omit, sortWith, ascend, replace } from "ramda";
import { capitalize } from "@/utils/index";
import { getters as GE } from "./constants";

export const getters = {
  [GE.GET_FTA_FIELD_AS_OPT_GROUP]: ({ list }) => {
    const groupByFieldType = groupBy(compose(capitalize, replace(/_/, " "), prop("fieldtype")));
    const sanitizeData = compose(map(omit(["arithmetic", "editable", "importable", "isytd"])));

    const sortAscbyIndex = sortWith([ascend(prop("itemindex"))]);
    const createOption = (old, { id, displayname }) => assoc(id, displayname, old);

    const typeGroups = compose(groupByFieldType, sanitizeData, sortAscbyIndex)(list.ftaField);
    const mapToReduceOptions = map(reduce(createOption, {}));

    return mapToReduceOptions(typeGroups);
  }
};
