import Axios from "axios";
import { assoc, head, isEmpty } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";
import { mapTicklerFieldsWithValues } from "./mapper";

const callGetHealthcare = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_HEALTHCARE, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/BorrowerHealthcare`);
    //> should be an objet, for now use `head` in the array
    commit(MU.SET_INFORMATION_HEALTHCARE, head(data) || {});
  } catch (err) {
    commit(MU.SET_ERROR_HEALTHCARE, logAndGetErrorMessage(AC.CALL_GET_HEALTHCARE, err));
  } finally {
    commit(MU.SET_LOADING_HEALTHCARE, false);
  }
};

const callGetHealthcareTicklersFields = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_HEALTHCARE_TICKLER_FIELDS, true);
    const { data } = await Axios.get("/api/healthcare/tickler/fields");
    commit(MU.SET_INFORMATION_HEALTHCARE_TICKLER_FIELDS, data);
  } catch (err) {
    commit(MU.SET_ERROR_HEALTHCARE_TICKLER_FIELDS, logAndGetErrorMessage(AC.CALL_GET_HEALTHCARE_TICKLERS_FIELDS, err));
  } finally {
    commit(MU.SET_LOADING_HEALTHCARE_TICKLER_FIELDS, false);
  }
};

const callGetHealthcareTicklers = async ({ commit, rootState, state }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_HEALTHCARE_TICKLER, true);
    const { data } = await Axios.get(`/api/healthcare/tickler/${clientid}`);
    if (!isEmpty(state.mapped)) commit(MU.SET_INFORMATION_HEALTHCARE_TICKLER_FIELDS_MAPPED, {});

    commit(MU.SET_INFORMATION_HEALTHCARE_TICKLER, data);
    commit(
      MU.SET_INFORMATION_HEALTHCARE_TICKLER_FIELDS_MAPPED,
      mapTicklerFieldsWithValues(state.healthcare.data.fields, data)
    );
  } catch (err) {
    commit(MU.SET_ERROR_HEALTHCARE_TICKLER, logAndGetErrorMessage(AC.CALL_GET_HEALTHCARE_TICKLERS, err));
  } finally {
    commit(MU.SET_LOADING_HEALTHCARE_TICKLER, false);
  }
};

const saveHealthcare = async ({ commit, rootState, dispatch }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_HEALTHCARE, true);
    const healthcare = assoc("clientid", clientid, formData);

    if (formData.id) {
      await Axios.put(`/api/borrower/helper/${formData.id}/BorrowerHealthcare`, healthcare);
    } else {
      await Axios.post(`/api/borrower/helper/${clientid}/BorrowerHealthcare`, healthcare);
    }

    dispatch(AC.CALL_GET_HEALTHCARE);
  } catch (err) {
    commit(MU.SET_ERROR_HEALTHCARE, logAndGetErrorMessage(AC.SAVE_HEALTHCARE, err));
  } finally {
    commit(MU.SET_LOADING_HEALTHCARE, false);
  }
};

const saveHealthcareTickler = async ({ commit, rootState, dispatch }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_HEALTHCARE_TICKLER, true);
    const healthcare = assoc("clientid", clientid, formData);

    if (formData.objid) {
      await Axios.put(`/api/healthcare/tickler/${clientid}`, healthcare);
    } else {
      await Axios.post(`/api/healthcare/tickler/${clientid}`, healthcare);
    }
  } catch (err) {
    commit(MU.SET_ERROR_HEALTHCARE_TICKLER, logAndGetErrorMessage(AC.SAVE_HEALTHCARE_TICKLER, err));
  } finally {
    commit(MU.SET_LOADING_HEALTHCARE_TICKLER, false);
  }
};

export const actions = {
  [AC.CALL_GET_HEALTHCARE]: callGetHealthcare,
  [AC.CALL_GET_HEALTHCARE_TICKLERS]: callGetHealthcareTicklers,
  [AC.CALL_GET_HEALTHCARE_TICKLERS_FIELDS]: callGetHealthcareTicklersFields,
  [AC.SAVE_HEALTHCARE]: saveHealthcare,
  [AC.SAVE_HEALTHCARE_TICKLER]: saveHealthcareTickler
};
