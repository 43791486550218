import Axios from "axios";
import { assoc, compose, isEmpty, omit } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const addLastUpdatedField = assoc("lastupdated", new Date().toISOString());

const callGetInventoryCollateralDetailInformation = async ({ commit }, loanid) => {
  try {
    commit(MU.SET_LOADING_INVENTORY_COLLATERAL_DETAIL, true);
    const { data } = await Axios.get(`/api/loan/${loanid}/inventory`);
    if (data.length > 0) commit(MU.SET_PREVIOUS_AS_OF_DATE, data[0].asOfDate);
    commit(MU.SET_LIST_INVENTORY_COLLATERAL_DETAIL, data);
  } catch (err) {
    commit(
      MU.SET_ERROR_INVENTORY_COLLATERAL_DETAIL,
      logAndGetErrorMessage(AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION, err)
    );
  } finally {
    commit(MU.SET_LOADING_INVENTORY_COLLATERAL_DETAIL, false);
  }
};

const callCreateInventoryCollateralDetailInformation = async ({ commit, dispatch }, formValues) => {
  try {
    commit(MU.SET_LOADING_PARENT, true);
    const data = addLastUpdatedField(formValues);
    await Axios.post(`/api/loan/${data.loanid}/inventory`, data);
    dispatch(AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION, formValues.loanid);
  } catch (err) {
    commit(MU.SET_ERROR_PARENT, logAndGetErrorMessage(AC.CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_PARENT, false);
  }
};

const callUpdateInventoryCollateralDetailInformation = async ({ commit, dispatch }, formValues) => {
  try {
    commit(MU.SET_LOADING_PARENT, true);
    const data = addLastUpdatedField(formValues);
    await Axios.put(`/api/loan/${data.loanid}/inventory/${data.objid}`, data);
    dispatch(AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION, data.loanid);
  } catch (err) {
    commit(MU.SET_ERROR_PARENT, logAndGetErrorMessage(AC.CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION, err));
  } finally {
    commit(MU.SET_LOADING_PARENT, false);
  }
};

const callCreateInventoryCollateralDetailChildInformation = async ({ commit, dispatch, rootState }, formValues) => {
  try {
    commit(MU.SET_LOADING_CHILD, true);
    const clientid = rootState.borrower_loan.borrower.current;
    await Axios.post(
      `/api/loan/${formValues.loanid}/inventory/collateraldetail`,
      assoc("clientid", clientid, formValues)
    );
    dispatch(AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION, formValues.loanid);
  } catch (err) {
    commit(
      MU.SET_ERROR_CHILD,
      logAndGetErrorMessage(AC.CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION, err)
    );
  } finally {
    commit(MU.SET_LOADING_CHILD, false);
  }
};

const callUpdateInventoryCollateralDetailChildInformation = async ({ commit, dispatch, rootState }, formValues) => {
  try {
    commit(MU.SET_LOADING_CHILD, true);
    const clientid = rootState.borrower_loan.borrower.current;
    await Axios.put(
      `/api/loan/${formValues.loanid}/inventory/collateraldetail/${formValues.id}`,
      assoc("clientid", clientid, formValues)
    );
    dispatch(AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION, formValues.loanid);
  } catch (err) {
    commit(
      MU.SET_ERROR_CHILD,
      logAndGetErrorMessage(AC.CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION, err)
    );
  } finally {
    commit(MU.SET_LOADING_CHILD, false);
  }
};

const callDeleteInventoryCollateralDetailChildInformation = async ({ commit, dispatch }, formValues) => {
  try {
    commit(MU.SET_LOADING_CHILD, true);
    await Axios.delete(`/api/loan/${formValues.loanid}/inventory/collateraldetail/${formValues.id}`);
    dispatch(AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION, formValues.loanid);
    dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION, "Record was removed successfully!");
  } catch (err) {
    const error = logAndGetErrorMessage(AC.CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION, err);
    commit(MU.SET_ERROR_CHILD, error);
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, error);
  } finally {
    commit(MU.SET_LOADING_CHILD, false);
  }
};

const callUpdateAsOfDateInventoryCollateralDetailInformation = async (
  { commit, dispatch, state, rootState },
  asOfDate
) => {
  try {
    commit(MU.SET_LOADING_INVENTORY_COLLATERAL_DETAIL, true);
    const lastUpdated = new Date().toISOString();
    const normalizeChildItem = compose(omit(["id", "invcollobjid"]), assoc("asofdate", asOfDate));

    const appendChildrenToDetail = (children, detail) =>
      compose(
        omit(["objid"]),
        assoc("userid", rootState.root.username),
        assoc("lastupdated", lastUpdated),
        assoc("asOfDate", asOfDate),
        assoc("inventoryCollateralDetails", children)
      )(detail);

    const posts = state.list.detail.map(d => {
      const children = d.inventoryCollateralDetails.map(normalizeChildItem);
      const detail = appendChildrenToDetail(children, d);
      return Axios.post(`/api/loan/${d.loanid}/inventory`, detail);
    });

    const responses = await Promise.allSettled(posts);
    const errors = responses.filter(r => r.status === "rejected");

    dispatch(AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION, state.list.detail[0].loanid);
    if (isEmpty(errors)) {
      dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION);
    } else {
      commit(
        MU.SET_ERROR_INVENTORY_COLLATERAL_DETAIL,
        logAndGetErrorMessage(
          AC.CALL_UPDATE_AS_OF_DATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION,
          "Some of the rows were not updated!"
        )
      );
    }
  } catch (err) {
    commit(
      MU.SET_ERROR_INVENTORY_COLLATERAL_DETAIL,
      logAndGetErrorMessage(AC.CALL_UPDATE_AS_OF_DATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION, err)
    );
  } finally {
    commit(MU.SET_LOADING_INVENTORY_COLLATERAL_DETAIL, false);
  }
};

export const actions = {
  [AC.CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION]: callGetInventoryCollateralDetailInformation,
  [AC.CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION]: callCreateInventoryCollateralDetailInformation,
  [AC.CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION]: callUpdateInventoryCollateralDetailInformation,
  [AC.CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION]: callCreateInventoryCollateralDetailChildInformation,
  [AC.CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION]: callUpdateInventoryCollateralDetailChildInformation,
  [AC.CALL_DELETE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION]: callDeleteInventoryCollateralDetailChildInformation,
  [AC.CALL_UPDATE_AS_OF_DATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION]: callUpdateAsOfDateInventoryCollateralDetailInformation
};
