import Axios from "axios";
import {
  assoc,
  compose,
  concat,
  equals,
  isEmpty,
  head,
  filter,
  prop,
  props,
  keys,
  toLower,
  reduce,
  includes,
  __,
  zipObj
} from "ramda";

import { actions as AC, mutations as MU, getters as GE, REQUIRED_CODES } from "./constants";
import { actions as RAC, NOTIFICATION_TYPE } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const createPutCategoryRequests = v => v.map(v => Axios.put(`/api/admin/code/category/${v.objid}`, v));

const showAlert = async (dispatch, type, body, redirectTo = null) => {
  await dispatch(RAC.SHOW_ALERT_NOTIFICATION, {
    type,
    body,
    redirectTo
  });
};

const callGetCodes = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/api/admin/codes");

    const filterByRequiredCodes = compose(includes(__, REQUIRED_CODES), prop("code"));
    const getCodeNameInLowerCase = compose(toLower, prop("code"));
    const getCategoryProps = compose(
      zipObj(["id", "code", "name", "description", "list"]),
      props(["objid", "code", "name", "description", "basicCategoryCodes"])
    );
    const createCategoryCodes = (p, c) => assoc(getCodeNameInLowerCase(c), getCategoryProps(c), p);

    const requiredCodes = filter(filterByRequiredCodes, data);
    const categoryCodes = reduce(createCategoryCodes, {}, requiredCodes);
    commit(MU.SET_CATEGORY, data);
    commit(MU.SET_CATEGORY_CODES, categoryCodes);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_CODES, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callCreateNewHoldCode = ({ commit }, formData) =>
  new Promise(async (resolve, reject) => {
    let valueToResolve = false;
    try {
      commit(MU.SET_LOADING, true);
      await Axios.post("/api/admin/code/category", formData);
      valueToResolve = true;
    } catch (err) {
      reject(logAndGetErrorMessage(AC.CALL_CREATE_NEW_HOLD_CODE, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve(valueToResolve);
  });

const callEditHoldCode = ({ commit }, formData) =>
  new Promise(async (resolve, reject) => {
    let valueToResolve = false;
    try {
      commit(MU.SET_LOADING, true);
      await Axios.put(`/api/admin/code/category/${formData.objid}`, formData);
      valueToResolve = true;
    } catch (err) {
      reject(logAndGetErrorMessage(AC.CALL_EDIT_HOLD_CODE, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve(valueToResolve);
  });

const callSaveDaysOverColumns = ({ commit, state, getters }, formData) =>
  new Promise(async (resolve, reject) => {
    let valueToResolve = false;
    try {
      commit(MU.SET_LOADING, true);

      const previousDaysData = getters[GE.GET_AGING_SPREADS_BY_DAY];
      const days = head(formData.days);
      const categoryObjid = state.categoryCodes.aging_spreads.id;

      let post = [];
      let put = [];

      keys(days).forEach(nd => {
        const previous = previousDaysData[nd];
        if (!previous) {
          const item = zipObj(["code", "name", "categoryObjid", "fieldorder"], [nd, days[nd], categoryObjid, nd]);
          post = concat(post, [item]);
        }
        if (previous) {
          const name = days[nd];
          if (!equals(previous.name, name)) {
            put = concat(put, [assoc("name", name, previous)]);
          }
        }
      });

      if (!isEmpty(post)) {
        const postCalls = post.map(p => Axios.post("/api/admin/code/category", p));
        await Promise.all(postCalls);
      }

      if (!isEmpty(put)) {
        //@krojas, use Promise.allSetled and check for the error, if there's one
        await Promise.all(createPutCategoryRequests(put));
      }

      valueToResolve = true;
    } catch (err) {
      reject(logAndGetErrorMessage(AC.CALL_SAVE_DAYS_OVER_COLUMNS, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve(valueToResolve);
  });

const callSaveIneligibleDefaults = ({ commit, getters }, formData) =>
  new Promise(async (resolve, reject) => {
    let valueToResolve = false;

    try {
      commit(MU.SET_LOADING, true);
      const ineligible = head(formData.ineligible);
      const previousDefaults = getters[GE.GET_INELIGIBLE_DEFAULTS_BY_NAME];

      let put = [];

      keys(ineligible).forEach(d => {
        const previous = previousDefaults[d];

        const name = ineligible[d];
        if (!equals(parseFloat(previous.name), parseFloat(name))) {
          put = concat(put, [assoc("name", name, previous)]);
        }
      });

      if (!isEmpty(put)) {
        //@krojas, use Promise.allSetled and check for the error, if there's one
        await Promise.all(createPutCategoryRequests(put));
      }

      valueToResolve = true;
    } catch (err) {
      logAndGetErrorMessage(AC.CALL_SAVE_INELIGIBLE_DEFAULTS, err);
      reject(err);
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve(valueToResolve);
  });

const callSaveCategory = async ({ commit, dispatch }, payload) => {
  try {
    commit(MU.SET_LOADING, true);
    let strNotif = payload.requestType == "post" ? "added" : "updated";
    let { data } =
      payload.requestType == "post"
        ? await Axios.post("/api/admin/code", payload.data)
        : await Axios.put(`/api/admin/code/${payload.data.id}`, payload.data);
    commit(MU.UPDATED_CATEGORY, data);
    await dispatch(AC.CALL_GET_CODES);

    showAlert(dispatch, NOTIFICATION_TYPE.success, `Category successfully ${strNotif}!`);
  } catch (err) {
    showAlert(dispatch, NOTIFICATION_TYPE.error, logAndGetErrorMessage(AC.CALL_SAVE_CATEGORY, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callSaveCode = async ({ commit, dispatch }, payload) => {
  try {
    commit(MU.SET_LOADING, true);
    const strNotif = payload.requestType === "post" ? "added" : "updated";
    if (payload.requestType === "post") {
      await Axios.post("/api/admin/code/category", payload.data);
    } else {
      await Axios.put(`/api/admin/code/category/${payload.data.id}`, payload.data);
    }
    await dispatch(AC.CALL_GET_CODES);
    showAlert(dispatch, NOTIFICATION_TYPE.success, `Category Code successfully ${strNotif}!`);
  } catch (err) {
    showAlert(dispatch, NOTIFICATION_TYPE.error, logAndGetErrorMessage(AC.CALL_SAVE_CODE, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_CODES]: callGetCodes,
  [AC.CALL_CREATE_NEW_HOLD_CODE]: callCreateNewHoldCode,
  [AC.CALL_EDIT_HOLD_CODE]: callEditHoldCode,
  [AC.CALL_SAVE_DAYS_OVER_COLUMNS]: callSaveDaysOverColumns,
  [AC.CALL_SAVE_INELIGIBLE_DEFAULTS]: callSaveIneligibleDefaults,
  [AC.CALL_SAVE_CATEGORY]: callSaveCategory,
  [AC.CALL_SAVE_CODE]: callSaveCode
};
