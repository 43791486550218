import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export const CodesModule = {
  state: {
    categoryCodes: null,
    loading: false,
    error: null,
    category: null,
    updatedCategory: null
  },
  actions,
  mutations,
  getters
};
