import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_SUBORDINATOR_GUARANTOR]: function(state, loading) {
    Vue.set(state.subordinatorGuarantor.loading, "subordinatorGuarantor", loading);
  },
  [MU.SET_LOADING_SUBORDINATOR]: function(state, loading) {
    Vue.set(state.subordinatorGuarantor.loading, "subordinator", loading);
  },
  [MU.SET_LOADING_PERSONAL_GUARANTOR]: function(state, loading) {
    Vue.set(state.subordinatorGuarantor.loading, "personalGuarantor", loading);
  },
  [MU.SET_LOADING_CORPORATE_GUARANTOR]: function(state, loading) {
    Vue.set(state.subordinatorGuarantor.loading, "corporateGuarantor", loading);
  },
  [MU.SET_ERROR_SUBORDINATOR_GUARANTOR]: function(state, error) {
    Vue.set(state.subordinatorGuarantor.error, "subordinatorGuarantor", error);
  },
  [MU.SET_ERROR_SUBORDINATOR]: function(state, error) {
    Vue.set(state.subordinatorGuarantor.error, "subordinator", error);
  },
  [MU.SET_ERROR_PERSONAL_GUARANTOR]: function(state, error) {
    Vue.set(state.subordinatorGuarantor.error, "personalGuarantor", error);
  },
  [MU.SET_ERROR_CORPORATE_GUARANTOR]: function(state, error) {
    Vue.set(state.subordinatorGuarantor.error, "corporateGuarantor", error);
  },
  [MU.SET_INFORMATION_SUBORDINATOR_GUARANTOR]: function(state, data) {
    Vue.set(state.subordinatorGuarantor, "data", data);
  },
  [MU.SET_LIST_SUBORDINATOR]: function(state, data) {
    Vue.set(state.subordinatorGuarantor.list, "subordinator", data);
  },
  [MU.SET_LIST_PERSONAL_GUARANTOR]: function(state, data) {
    Vue.set(state.subordinatorGuarantor.list, "personalGuarantor", data);
  },
  [MU.SET_LIST_CORPORATE_GUARANTOR]: function(state, data) {
    Vue.set(state.subordinatorGuarantor.list, "corporateGuarantor", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "subordinatorGuarantor", clone(DEFAULT_STATE));
  }
};
