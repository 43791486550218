import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_LIST]: function(state, loading) {
    Vue.set(state.loading, "list", loading);
  },
  [MU.SET_LOADING_DETAILS]: function(state, loading) {
    Vue.set(state.loading, "details", loading);
  },
  [MU.SET_HAS_CHANGES_DETAILS]: function(state, hasChanges) {
    Vue.set(state.hasChanges, "details", hasChanges);
  },
  [MU.SET_LOADING_INVOICES]: function(state, loading) {
    Vue.set(state.loading, "invoices", loading);
  },
  [MU.SET_ERROR_LIST]: function(state, error) {
    Vue.set(state.error, "list", error);
  },
  [MU.SET_ERROR_DETAILS]: function(state, error) {
    Vue.set(state.error, "details", error);
  },
  [MU.SET_ERROR_INVOICES]: function(state, error) {
    Vue.set(state.error, "invoices", error);
  },
  [MU.SET_LOANID]: function(state, loanid) {
    Vue.set(state, "loanid", loanid);
  },
  [MU.SET_VENDOR]: function(state, payload) {
    Vue.set(state, "id", payload.id);
    Vue.set(state, "name", payload.name);
    Vue.set(state, "current", payload);
  },
  [MU.SET_COUNTRY_CODES]: function(state, countryCodes) {
    Vue.set(state, "countryCodes", countryCodes);
  },
  [MU.SET_VENDORS_LIST]: function(state, payload) {
    Vue.set(state.list, "vendors", payload);
  },
  [MU.SET_HAS_CHANGES_LINKED_VENDORS]: function(state, value) {
    Vue.set(state.hasChanges, "linked", value);
  },
  [MU.SET_LINKED_VENDORS]: function(state, payload) {
    Vue.set(state.list, "linked", payload);
  }
};
