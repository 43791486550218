import Axios from "axios";
import { compose, isNil, omit, not, equals, prop } from "ramda";
import formatDate from "date-fns/format";
import subDays from "date-fns/subDays";
import parseISO from "date-fns/parseISO";
import compareAsc from "date-fns/compareAsc";
import startOfToday from "date-fns/startOfToday";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ROOT_AC, NOTIFICATION_TYPE } from "../root/constants";
import { concatArrays } from "@/utils/index";

import { logAndGetErrorMessage } from "@/store/utils";

const callGetRollForward = async ({ commit, rootState }, postingday) => {
  try {
    commit(MU.SET_LOADING, true);
    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    const params = { params: { postingday } };
    const { data } = await Axios.get(`/api/worksheets/client/${clientid}/loan/${loan.id}`, params);

    commit(MU.SET_LIST, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_ROLLFORWARD, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callSaveAllChanges = async ({ commit, dispatch, rootState, state }, { postingday, formData }) => {
  try {
    commit(MU.SET_LOADING, true);
    // normalize the object before sending it to the API
    const normalizeItem = (action, omitProps, clientid, loanid) => item =>
      item.amount === 0
        ? null
        : {
            ...omit(omitProps, item),
            action,
            loanid,
            clientid,
            postingday,
            [item.field]: item.amount
          };

    // find values by Ids in the formData
    const findInFormValues = objid => formData.find(compose(equals(objid), prop("objid")));

    // get the item for the specific action, remove/ommit some props
    const getItem = (action, omitProps, clientid, loanid) =>
      compose(normalizeItem(action, omitProps, clientid, loanid), findInFormValues);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    // get the item for POST
    const getPostItems = getItem("POST", ["amount", "field", "objid"], clientid, loan.id);
    // get the item for PUT
    const getPutItems = getItem("PUT", ["amount", "field"], clientid, loan.id);
    // get the item for DELETE
    const normalizeDeletes = d => ({
      clientid,
      loanid: loan.id,
      objid: d,
      action: "DELETE",
      postingday: postingday
    });

    const { news, edits, deletes } = state.changes;
    const changes = concatArrays([
      news.map(getPostItems),
      edits.map(getPutItems),
      deletes.map(normalizeDeletes)
    ]).filter(compose(not, isNil)); // filter the possible null (mostly the item that were created as default)

    let successMsg;
    if (changes.length > 0) {
      const isBackDated = compareAsc(parseISO(postingday), startOfToday()) === -1;
      if (isBackDated) {
        successMsg = "Changes were successfully saved and Rollforward processed!";
        await Axios.put(
          `api/worksheets/client/${clientid}/loan/${loan.id}/backdate/${postingday}`,
          JSON.stringify(changes)
        );
      } else {
        successMsg = "Changes were successfully saved!";
        await Axios.put(`api/worksheets/client/${clientid}/loan/${loan.id}`, JSON.stringify(changes));
      }
      commit(MU.CLEAN_CHANGES);

      dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
        type: NOTIFICATION_TYPE.success,
        body: successMsg,
        redirectTo: "loan-stats"
      });
    }
  } catch (err) {
    dispatch(ROOT_AC.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: logAndGetErrorMessage(AC.CALL_SAVE_ALL_CHANGES, err)
    });
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callGetLoanCollateralBalance = ({ rootState, commit }, postingday) =>
  new Promise(async resolve => {
    let balance = { endingCollateralBalance: 0 };
    try {
      commit(MU.SET_LOADING, true);
      const yesterday = formatDate(subDays(parseISO(postingday), 1), "yyyy-MM-dd");
      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];

      const { data } = await Axios.get(`/api/loans/balance/client/${clientid}/loan/${loan.id}/${yesterday}`);
      balance = data;
    } catch (err) {
      const error = logAndGetErrorMessage(AC.CALL_GET_LOAN_COLLATERAL_BALANCE, err);
      commit(MU.SET_ERROR, error);
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve(balance);
  });

export const actions = {
  [AC.CALL_GET_ROLLFORWARD]: callGetRollForward,
  [AC.CALL_SAVE_ALL_CHANGES]: callSaveAllChanges,
  [AC.CALL_GET_LOAN_COLLATERAL_BALANCE]: callGetLoanCollateralBalance
};
