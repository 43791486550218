import { equals, head } from "ramda";
import { capitalize, formatNumber } from "../utils";

const maxMoney = (name, value) => `${capitalize(name)} must be less than or equal to ${formatNumber(value)}.`;

const maxPercentage = (name, value) =>
  `${capitalize(name)} must be less than or equal to ${formatNumber(value, true)}.`;

const minMoney = (name, value) => `${capitalize(name)} must be greater than or equal to ${formatNumber(value)}.`;

const minPercentage = (name, value) =>
  `${capitalize(name)} must be greater than or equal to ${formatNumber(value, true)}.`;

export const maxMessage = ({ name, args, vm }) => {
  const value = head(args);
  if (equals(vm.type, "float")) return maxPercentage(name, value);
  if (equals(vm.type, "money")) return maxMoney(name, value);
  return `${capitalize(name)} must be less than or equal to ${value}.`;
};

export const minMessage = ({ name, args, vm }) => {
  const value = head(args);
  if (equals(vm.type, "float")) return minPercentage(name, value);
  if (equals(vm.type, "money")) return minMoney(name, value);
  return `${capitalize(name)} must be greater than or equal to ${value}.`;
};

export const csvOnlyAllowsEmails = ({ name }) =>
  `The ${name} has an invalid email format, each email address must be separated by a comma.`;

export const alphanumericspace = ({ name }) => `The ${name} can only contain numbers, letters and spaces`;
