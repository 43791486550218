import { appendName } from "../utils";

const append = appendName("HOLIDAY_MANAGEMENT");

export const actions = {
  CALL_GET_HOLIDAYS: append("CALL_GET_HOLIDAYS"),
  CREATE_HOLIDAY: append("CREATE_HOLIDAY"),
  UPDATE_HOLIDAY: append("UPDATE_HOLIDAY"),
  DELETE_HOLIDAY: append("DELETE_HOLIDAY")
};

export const mutations = {
  SET_LOADING_GRID: append("SET_LOADING_GRID"),
  SET_ERROR_GRID: append("SET_ERROR_GRID"),
  SET_GRID_DATA: append("SET_GRID_DATA"),
  SET_COUNTRY_CODES: append("SET_COUNTRY_CODES"),
  SET_LOADING_MODAL: append("SET_LOADING_MODAL"),
  SET_ERROR_MODAL: append("SET_ERROR_MODAL")
};

export const MODAL_HELPER_ACTION = {
  CREATE: "create",
  UPDATE: "update"
};
