import { actions } from "./actions";
import { mutations } from "./mutations";

export const HolidayManagementModule = {
  state: {
    loading: { grid: false, modal: false },
    error: { grid: null, modal: null },
    list: { grid: [] },
    countryCodes: []
  },
  actions,
  mutations,
  getters: {}
};
