import { getters as GE } from "./constants";
import { isNilOrEmpty } from "@/utils/index";

const getClientsArray = ({ clients }) => {
  const keys = Object.keys(clients);
  return keys
    .reduce((pre, clientid) => [...pre, { clientid, name: clients[clientid]["name"] }], [])
    .filter(k => !isNilOrEmpty(k.name));
};

export const getters = {
  [GE.GET_CLIENTS_ARRAY]: getClientsArray
};
