import Axios from "axios";
import { compose, assoc, prop, equals, filter, toLower } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { actions as RAC, NOTIFICATION_TYPE } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

//> private funcstions
const convertToCsv = data => {
  let csv = "";
  data.forEach(value => {
    csv += value + ",";
  });
  return csv.slice(0, -1);
};

//> public functions
const callUserClient = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_USER_CLIENTS_LOADING, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/authorities/users/client/${clientid}`);

    commit(MU.SET_USER_CLIENTS, data);
  } catch (err) {
    commit(MU.SET_USER_CLIENTS_ERROR, logAndGetErrorMessage(AC.CALL_USER_CLIENT, err));
  } finally {
    commit(MU.SET_USER_CLIENTS_LOADING, false);
  }
};

const callDocumentTypes = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_DOCUMENTS, true);
    const { data } = await Axios.get("/api/documents/definitions");
    commit(MU.SET_DOCUMENT_TYPES, data);
  } catch (err) {
    commit(MU.SET_ERROR_DOCUMENTS, logAndGetErrorMessage(AC.CALL_DOCUMENT_TYPES, err));
  } finally {
    commit(MU.SET_LOADING_DOCUMENTS, false);
  }
};

const callDocuments = async ({ commit, rootState }) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.CREATE_LOADING_SCHEDULE_D, true);

    const { data } = await Axios.get(`/api/documents/monitoring/client/${clientid}`);

    const isFrequency = f => compose(equals(f), toLower, prop("docTypeFrequency"));
    const daily = filter(isFrequency("d"), data);
    const weekly = filter(isFrequency("w"), data);
    const monthly = filter(isFrequency("m"), data);
    const quarterly = filter(isFrequency("q"), data);
    const biannually = filter(isFrequency("b"), data);
    const annually = filter(isFrequency("a"), data);

    const documents = {
      daily,
      weekly,
      monthly,
      quarterly,
      biannually,
      annually
    };

    commit(MU.SET_CLIENT_DOCUMENTS, documents);
  } catch (err) {
    commit(MU.SET_CLIENT_ERROR_DOCUMENTS, logAndGetErrorMessage(AC.CALL_CLIENT_DOCUMENTS, err));
  } finally {
    commit(MU.CREATE_LOADING_SCHEDULE_D, false);
    commit(MU.SET_CLIENT_LOADING_DOCUMENTS, false);
  }
};

const createScheduleD = async ({ commit, rootState, dispatch }, data) => {
  try {
    commit(MU.CREATE_LOADING_SCHEDULE_D, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const notify = data.notify ? convertToCsv(data.notify) : data.notify;
    const addActionWithNotify = compose(assoc("action", "create"), assoc("notify", notify));

    const values = addActionWithNotify(data);
    await Axios.post(`/api/documents/monitoring/client/${clientid}`, values);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.CREATE_SCHEDULE_D, err);
    commit(MU.CREATE_ERROR_SCHEDULE_D, msg);
  } finally {
    commit(MU.CREATE_LOADING_SCHEDULE_D, false);
  }
};

const updateScheduleD = async ({ commit, rootState, dispatch }, data) => {
  try {
    commit(MU.CREATE_LOADING_SCHEDULE_D, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const notify = data.notify ? convertToCsv(data.notify) : data.notify;
    const addActionWithNotify = compose(assoc("action", "update"), assoc("notify", notify));
    const values = addActionWithNotify(data);

    await Axios.put(`/api/documents/monitoring/client/${clientid}`, [values]);
    commit(AC.SET_SELECTED_SCHEDULE_D, null);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.UPDATE_SCHEDULE_D, err);
    commit(MU.CREATE_ERROR_SCHEDULE_D, msg);
  } finally {
    commit(MU.CREATE_LOADING_SCHEDULE_D, false);
  }
};

const deleteScheduleD = async ({ commit, rootState, dispatch }, id) => {
  try {
    commit(MU.CREATE_LOADING_SCHEDULE_D, true);
    const clientid = rootState.borrower_loan.borrower.current;
    await Axios.delete(`/api/documents/monitoring/client/${clientid}/${id}`);

    // refresh schedule-d documents
    dispatch(AC.CALL_CLIENT_DOCUMENTS);
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.DELETE_SCHEDULE_D, err);
    commit(MU.CREATE_ERROR_SCHEDULE_D, msg);
  } finally {
    commit(MU.CREATE_LOADING_SCHEDULE_D, false);
  }
};

//> take a look into this, can be removed @krojas
const setSelectedScheduleD = async ({ commit }, data) => {
  try {
    commit(MU.SET_SELECTED_SCHEDULE_D, data);
  } catch (err) {
    logAndGetErrorMessage(AC.SET_SELECTED_SCHEDULE_D, err);
  }
};
export const actions = {
  [AC.CALL_DOCUMENT_TYPES]: callDocumentTypes,
  [AC.CALL_CLIENT_DOCUMENTS]: callDocuments,
  [AC.CALL_USER_CLIENT]: callUserClient,
  [AC.CREATE_SCHEDULE_D]: createScheduleD,
  [AC.UPDATE_SCHEDULE_D]: updateScheduleD,
  [AC.DELETE_SCHEDULE_D]: deleteScheduleD,
  [AC.SET_SELECTED_SCHEDULE_D]: setSelectedScheduleD
};
