import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_PRIMARY_CONTACT]: function(state, loading) {
    Vue.set(state.contact.loading, "primary", loading);
  },
  [MU.SET_LOADING_SIENA_CONTACT]: function(state, loading) {
    Vue.set(state.contact.loading, "siena", loading);
  },
  [MU.SET_ERROR_PRIMARY_CONTACT]: function(state, error) {
    Vue.set(state.contact.error, "primary", error);
  },
  [MU.SET_ERROR_SIENA_CONTACT]: function(state, error) {
    Vue.set(state.contact.error, "siena", error);
  },
  [MU.SET_INFORMATION_PRIMARY_CONTACT]: function(state, data) {
    Vue.set(state.contact.data, "primary", data);
  },
  [MU.SET_INFORMATION_SIENA_CONTACT]: function(state, data) {
    Vue.set(state.contact.data, "siena", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "contact", clone(DEFAULT_STATE));
  }
};
