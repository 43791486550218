import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING_GRID]: function(state, loading) {
    Vue.set(state.loading, "grid", loading);
  },
  [MU.SET_LOADING_MODAL]: function(state, loading) {
    Vue.set(state.loading, "modal", loading);
  },
  [MU.SET_ERROR_GRID]: function(state, error) {
    Vue.set(state.error, "grid", error);
  },
  [MU.SET_ERROR_MODAL]: function(state, error) {
    Vue.set(state.error, "modal", error);
  },
  [MU.SET_LIST_GRID]: function(state, list) {
    Vue.set(state, "list", list);
  }
};
