import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_CLIENT_DOCUMENTS]: function(state, value) {
    Vue.set(state.list, "documents", value);
  },
  [MU.SET_DOCUMENT_TYPES]: function(state, value) {
    Vue.set(state.list, "document_types", value);
  },
  [MU.SET_ERROR_DOCUMENTS]: function(state, value) {
    Vue.set(state.error, "document_types", value);
  },
  [MU.SET_LOADING_DOCUMENTS]: function(state, value) {
    Vue.set(state.loading, "documents", value);
  },
  [MU.SET_CLIENT_LOADING_DOCUMENTS]: function(state, value) {
    Vue.set(state.loading, "documents", value);
  },
  [MU.SET_USER_CLIENTS]: function(state, value) {
    Vue.set(state.list, "clients", value);
  },
  [MU.SET_USER_CLIENTS_ERROR]: function(state, value) {
    Vue.set(state.error, "clients", value);
  },
  [MU.SET_USER_CLIENTS_LOADING]: function(state, value) {
    Vue.set(state.loading, "clients", value);
  },
  [MU.CREATE_ERROR_SCHEDULE_D]: function(state, value) {
    Vue.set(state.error, "documents", value);
  },
  [MU.CREATE_LOADING_SCHEDULE_D]: function(state, value) {
    Vue.set(state.loading, "documents", value);
  },
  [MU.CREATE_SCHEDULE_D_SUCCESS]: function(state, savingSuccess) {
    Vue.set(state, "savingSuccess", savingSuccess);
  },
  [MU.SET_SELECTED_SCHEDULE_D]: function(state, data) {
    Vue.set(state, "selectedData", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "list", clone(DEFAULT_STATE.list));
    Vue.set(state, "error", clone(DEFAULT_STATE.error));
    Vue.set(state, "loading", clone(DEFAULT_STATE.loading));
    Vue.set(state, "savingSuccess", clone(DEFAULT_STATE.savingSuccess));
    Vue.set(state, "selectedData", clone(DEFAULT_STATE.selectedData));
  }
};
