import { appendName } from "@/store/utils";

const append = appendName("INELIGIBLE");

export const actions = {
  CALL_GET_GL_CODES: append("CALL_GET_GL_CODES"),
  CREATE_GL_CODE: append("CREATE_GL_CODE"),
  UPDATE_GL_CODE: append("UPDATE_GL_CODE")
};

export const mutations = {
  SET_LOADING_GRID: append("SET_LOADING_GRID"),
  SET_ERROR_GRID: append("SET_ERROR_GRID"),
  SET_LIST_GRID: append("SET_LIST_GRID"),
  //> modal
  SET_LOADING_MODAL: append("SET_LOADING_MODAL"),
  SET_ERROR_MODAL: append("SET_ERROR_MODAL")
};

export const MODAL_HELPER_ACTION = {
  CREATE: "create",
  UPDATE: "update"
};

export const CREDIT_DEBIT_OPTIONS = [
  {
    value: "D",
    label: "Decrease"
  },
  {
    value: "I",
    label: "Increase"
  }
];

export const LOAN_REQ_OPTIONS = [
  {
    value: "NR",
    label: "Not Required"
  },
  {
    value: "RD",
    label: "Required for Debit"
  },
  {
    value: "RC",
    label: "Required for Credit"
  },
  {
    value: "RB",
    label: "Required for both"
  }
];
