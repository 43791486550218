import { appendName } from "../utils";

const append = appendName("BORROWER_LOAN");

export const actions = {
  CALL_BORROWERS: append("CALL_BORROWERS"),
  CALL_BORROWER: append("CALL_BORROWER"),
  CALL_LOANS: append("CALL_LOANS"),
  CALL_DISBURSEMENTS: append("CALL_DISBURSEMENTS"),
  CALL_INELIGIBLES: append("CALL_INELIGIBLES"),
  CALL_COLLATERAL_RESERVES: append("CALL_COLLATERAL_RESERVES"),
  CALL_BORROWINGBASE_REQUESTS: append("CALL_BORROWINGBASE_REQUESTS"),
  SAVE_REQUEST_ADVANCE_FOR_LOAN: append("SAVE_REQUEST_ADVANCE_FOR_LOAN"),
  SAVE_CURRENT_BORROWER_ID: append("SAVE_CURRENT_BORROWER_ID")
};

export const getters = {
  GET_CURRENT_BORROWER: append("GET_CURRENT_BORROWER"),
  GET_CURRENT_BORROWER_VALUE: append("GET_CURRENT_BORROWER_VALUE"),
  GET_BORROWERS_LIST: append("GET_BORROWERS_LIST"),
  GET_CURRENT_LOANS: append("GET_CURRENT_LOANS"),
  GET_LOAN_BY_ID: append("GET_LOAN_BY_ID"),
  GET_LOANS_BY_AR_TYPE: append("GET_AR_TYPE_LOANS")
};

export const mutations = {
  SET_BORROWER: append("SET_BORROWER"),
  SET_LOAN: append("SET_LOAN"),
  SET_DISBURSEMENTS: append("SET_DISBURSEMENTS"),
  SET_COLLATERAL_RESERVES: append("SET_COLLATERAL_RESERVES"),
  SET_REQUEST_ADVANCE: append("SET_REQUEST_ADVANCE"),
  SET_LOADING_BORROWINGBASE_REQUEST: append("SET_LOADING_BORROWINGBASE_REQUEST"),
  SET_ERROR_BORROWINGBASE_REQUEST: append("SET_ERROR_BORROWINGBASE_REQUEST"),
  SET_LIST_BORROWINGBASE_REQUEST: append("SET_LIST_BORROWINGBASE_REQUEST")
};
