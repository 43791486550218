import { appendName } from "../utils";

const append = appendName("INVENTORY_COLLATERAL_DETAIL");

export const actions = {
  //> detail
  CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION: append("CALL_GET_INVENTORY_COLLATERAL_DETAIL_INFORMATION"),
  //> parent
  CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION: append("CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION"),
  CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION: append("CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION"),
  //child
  CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION: append("CALL_CREATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION"),
  CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION: append("CALL_UPDATE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION"),
  CALL_DELETE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION: append("CALL_DELETE_INVENTORY_COLLATERAL_DETAIL_CHILD_INFORMATION"),

  CALL_UPDATE_AS_OF_DATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION: append("CALL_UPDATE_AS_OF_DATE_INVENTORY_COLLATERAL_DETAIL_INFORMATION"),
};

export const mutations = {
  //> detail
  SET_LOADING_INVENTORY_COLLATERAL_DETAIL: append("SET_LOADING_INVENTORY_COLLATERAL_DETAIL"),
  SET_ERROR_INVENTORY_COLLATERAL_DETAIL: append("SET_ERROR_INVENTORY_COLLATERAL_DETAIL"),
  SET_LIST_INVENTORY_COLLATERAL_DETAIL: append("SET_LIST_INVENTORY_COLLATERAL_DETAIL"),
  //> parent
  SET_LOADING_PARENT: append("SET_LOADING_PARENT"),
  SET_ERROR_PARENT: append("SET_ERROR_PARENT"),
  SET_CURRENT_PARENT_ID: append("SET_CURRENT_PARENT_ID"),
  //> child
  SET_LOADING_CHILD: append("SET_LOADING_CHILD"),
  SET_ERROR_CHILD: append("SET_ERROR_CHILD"),
  //
  SET_PREVIOUS_AS_OF_DATE: append("SET_PREVIOUS_AS_OF_DATE")
};

export const MODAL_HELPER_ACTION = {
  CREATE: "CREATE",
  UPDATE: "UPDATE"
};
