import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_HEALTHCARE]: function(state, loading) {
    Vue.set(state.healthcare.loading, "form", loading);
  },
  [MU.SET_ERROR_HEALTHCARE]: function(state, error) {
    Vue.set(state.healthcare.error, "form", error);
  },
  [MU.SET_INFORMATION_HEALTHCARE]: function(state, data) {
    Vue.set(state.healthcare.data, "form", data);
  },
  [MU.SET_LOADING_HEALTHCARE_TICKLER]: function(state, loading) {
    Vue.set(state.healthcare.loading, "tickler", loading);
  },
  [MU.SET_ERROR_HEALTHCARE_TICKLER]: function(state, error) {
    Vue.set(state.healthcare.error, "tickler", error);
  },
  [MU.SET_INFORMATION_HEALTHCARE_TICKLER]: function(state, data) {
    Vue.set(state.healthcare.data, "tickler", data);
  },
  [MU.SET_LOADING_HEALTHCARE_TICKLER_FIELDS]: function(state, loading) {
    Vue.set(state.healthcare.loading, "fields", loading);
  },
  [MU.SET_ERROR_HEALTHCARE_TICKLER_FIELDS]: function(state, error) {
    Vue.set(state.healthcare.error, "fields", error);
  },
  [MU.SET_INFORMATION_HEALTHCARE_TICKLER_FIELDS]: function(state, data) {
    Vue.set(state.healthcare.data, "fields", data);
  },
  [MU.SET_INFORMATION_HEALTHCARE_TICKLER_FIELDS_MAPPED]: function(state, data) {
    Vue.set(state.healthcare.data, "mapped", data);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "healthcare", clone(DEFAULT_STATE));
  }
};
