import { appendName } from "@/store/utils";

import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToSetDetail = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_DETAIL"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_PRIMARY_CONTACT: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_PRIMARY_CONTACT"),
  CALL_GET_SIENA_CONTACT: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_SIENA_CONTACTFEE"),
  CALL_GET_BORROWER_STATUS: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GETBORROWER_STATUSE"),
  SAVE_BORROWER_STATUS: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_BORROWER_STATUS")
};

export const mutations = {
  //> primary contact
  SET_LOADING_PRIMARY_CONTACT: appendToLoading("PRIMARY_CONTACT"),
  SET_ERROR_PRIMARY_CONTACT: appendToError("PRIMARY_CONTACT"),
  SET_INFORMATION_PRIMARY_CONTACT: appendToSetDetail("PRIMARY_CONTACT"),
  //> siena contact
  SET_LOADING_SIENA_CONTACT: appendToLoading("SIENA_CONTACT"),
  SET_ERROR_SIENA_CONTACT: appendToError("SIENA_CONTACT"),
  SET_INFORMATION_SIENA_CONTACT: appendToSetDetail("SIENA_CONTACT"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("BORROWER_STATUS")
};

export const DEFAULT_STATE = {
  loading: { primary: false, siena: false },
  error: { primary: null, siena: null },
  data: { primary: null, siena: null }
};
