import { actions } from "./actions";
import { mutations } from "./mutations";
import { VERIFICATION_ACTIONS } from "./constants";

export const VerificationsModule = {
  state: {
    loading: false,
    action: null,
    loanid: null,
    search: {
      searching: true,
      hasSearched: false,
      list: [],
      requestedAmount: 0,
      amount: 0
    },
    counts: {
      [VERIFICATION_ACTIONS.REQUEST]: 0,
      [VERIFICATION_ACTIONS.AUTHORIZE]: 0
    }
  },
  actions,
  mutations,
  getters: {}
};
