import { appendName } from "../utils";

const append = appendName("TAB");

export const actions = {
  ADD_CLIENT_TO_TAB: append("ADD_CLIENT_TO_TAB"),
  REMOVE_CLIENT_FROM_TAB: append("REMOVE_CLIENT_FROM_TAB"),
  CLEAR_STATE: append("CLEAR_STATE")
};

export const getters = {
  GET_CLIENTS_ARRAY: append("GET_CLIENTS_ARRAY")
};

export const mutations = {
  SET_CURRENT_TAB_BY_CLIENT: append("SET_CURRENT_TAB_BY_CLIENT"),
  SET_CLIENT: append("SET_CLIENT"),
  SAVE_CLIENTS: append("SAVE_CLIENTS"),
  SET_SSE_INSTANCE: append("SET_SSE_INSTANCE"),
  SET_CLIENT_INFO: append("SET_CLIENT_INFO")
};
