import { isEmpty, propEq, filter, find, prop } from "ramda";
import { getters as GE } from "./constants";

const getCurrentBorrower = ({ borrower }) => borrower.current;

const getCurrentBorrowerValue = ({ borrower }) => field =>
  prop(field, find(propEq("clientid", borrower.current), borrower.list));

const getBorrowersList = ({ borrower }) => borrower.list;

const getCurrentLoans = ({ loan }) => loan.list;

const getLoanById = ({ loan }) => loanId => {
  const dl = filter(propEq("loanId", loanId), loan.list);
  return !isEmpty(dl) ? dl.shift() : {};
};

const getLoansByARType = ({ loan }) => filter(propEq("loanType", "AR"), loan.list);

export const getters = {
  [GE.GET_CURRENT_BORROWER]: getCurrentBorrower,
  [GE.GET_CURRENT_BORROWER_VALUE]: getCurrentBorrowerValue,
  [GE.GET_BORROWERS_LIST]: getBorrowersList,
  [GE.GET_CURRENT_LOANS]: getCurrentLoans,
  [GE.GET_LOAN_BY_ID]: getLoanById,
  [GE.GET_LOANS_BY_AR_TYPE]: getLoansByARType
};
