import Vue from "vue";
import { clone } from "ramda";
import { mutations as MU, DEFAULT_STATE } from "./constants";

export const mutations = {
  [MU.SET_LOADING_APPRAISAL]: function(state, loading) {
    Vue.set(state.appraisal.loading, "appraisal", loading);
  },
  [MU.SET_LOADING_APPRAISAL_RESULT]: function(state, loading) {
    Vue.set(state.appraisal.loading, "result", loading);
  },
  [MU.SET_LOADING_APPRAISAL_CRITERIA]: function(state, loading) {
    Vue.set(state.appraisal.loading, "criteria", loading);
  },
  [MU.SET_ERROR_APPRAISAL]: function(state, error) {
    Vue.set(state.appraisal.error, "appraisal", error);
  },
  [MU.SET_ERROR_APPRAISAL_RESULT]: function(state, error) {
    Vue.set(state.appraisal.error, "result", error);
  },
  [MU.SET_ERROR_APPRAISAL_CRITERIA]: function(state, error) {
    Vue.set(state.appraisal.error, "criteria", error);
  },
  [MU.SET_LIST_APPRAISAL]: function(state, list) {
    Vue.set(state.appraisal.list, "appraisal", list);
  },
  [MU.SET_LIST_APPRAISAL_RESULT]: function(state, list) {
    Vue.set(state.appraisal.list, "result", list);
  },
  [MU.SET_LIST_APPRAISAL_CRITERIA]: function(state, criteria) {
    Vue.set(state.appraisal.list, "criteria", criteria);
  },
  [MU.FULL_CLEAR_STATE]: function(state) {
    Vue.set(state, "appraisal", clone(DEFAULT_STATE));
  }
};
