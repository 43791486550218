import Axios from "axios";
import { includes } from "ramda";

import { actions as ACUser } from "../store/user/constants";
import store from "../store";

const trimObjectValues = obj => {
  let trimed = {};
  for (const key in obj) {
    const element = obj[key];
    if (typeof element === "string") {
      trimed = { ...trimed, [key]: element.trim() };
    }
  }
  return { ...obj, ...trimed };
};

export const addCustomHeaders = (h, v) => {
  Axios.defaults.headers[h] = v;
};

export const clearCustomHeader = h => {
  Axios.defaults.headers[h] = null;
};

Axios.interceptors.request.use(req => {
  try {
    if (!req.data) return req;

    const data = typeof data === "string" ? JSON.parse(req.data) : req.data;

    if (Array.isArray(data)) {
      req.data = data.map(trimObjectValues);
    }

    if (!Array.isArray(data) && typeof data === "object" && !data instanceof FormData) {
      req.data = trimObjectValues(data);
    }
  } catch (err) {
    console.log(`interceptor error: ${err}`);
  }
  return req;
});

Axios.interceptors.response.use(async res => {
  if (includes("invalid-session=true", res.request.responseURL)) {
    await store.dispatch(ACUser.CALL_LOGOUT, { headers: store.state.root.requestHeaders, doLogoutRequest: false });
    location.href = res.headers.location;
    return res;
  } else {
    return res;
  }
});
