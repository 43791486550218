import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_LOADING]: function(state, loading) {
    Vue.set(state, "loading", loading);
  },
  [MU.SET_ERROR]: function(state, error) {
    Vue.set(state, "error", error);
  },
  [MU.SET_CATEGORIES]: function(state, categories) {
    Vue.set(state, "categories", categories);
  }
};
