import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot, NOTIFICATION_TYPE } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callFeesApplied = async ({ commit, rootState }) => {
  try {
    commit(MU.SET_LOADING, true);
    const clientid = rootState.borrower_loan.borrower.current;
    const { data } = await Axios.get(`/api/feeapplied/client/${clientid}`);
    commit(MU.SET_FEES_APPLIED, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_FEES_APPLIED, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callFees = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get("/api/fees");
    const fees = data.filter(d => d.category !== "A" && d.reserved !== "Y");
    commit(MU.SET_FEES, fees);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_FEES, err));
  }
};

const saveFees = async ({ commit, dispatch, rootState }, requestData) => {
  try {
    const clientid = rootState.borrower_loan.borrower.current;
    commit(MU.SET_LOADING, true);

    await Axios.put(`/api/feeapplied/client/${clientid}`, requestData);

    dispatch(AC.CALL_FEES_APPLIED);

    dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.success,
      body: "Fees were successfully saved!"
    });
  } catch (err) {
    const msg = logAndGetErrorMessage(AC.SAVE_FEES, err);
    commit(MU.SET_ERROR, msg);
    dispatch(ACRoot.SHOW_ALERT_NOTIFICATION, {
      type: NOTIFICATION_TYPE.error,
      body: msg
    });
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_FEES]: callFees,
  [AC.CALL_FEES_APPLIED]: callFeesApplied,
  [AC.SAVE_FEES]: saveFees
};
