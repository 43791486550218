import Axios from "axios";
import { isNil, isEmpty, head } from "ramda";

import { actions as AC, mutations as MU, VERIFICATION_ACTIONS } from "./constants";
import { actions as ACRoot } from "../root/constants";
import { logAndGetErrorMessage } from "@/store/utils";

const transformList = data => {
  // Data to return after transformation
  let list = [];
  let amount = 0;

  if (isEmpty(data)) return { list, amount };

  // first sort by debtor name
  data.sort((a, b) => {
    a = a.debtorname.toUpperCase();
    b = b.debtorname.toUpperCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  // Cycle over the rows and add a grouping row to display
  let prevDebtor = "";

  // various objects and ids to properly add group rows with debtor name,
  // description and ability to control checkboxes
  let debtorGroup, groupId, index, debtorAmount, debtorInvoices;
  groupId = index = debtorAmount = debtorInvoices = 0;
  data.forEach(elem => {
    amount += elem.invoiceAmount;
    const debtor = elem.debtorname;

    // Add a row to show the debtor group
    if (prevDebtor != debtor) {
      ++groupId;

      // Set totals in current debtor group before setting new debtor group
      if (!isNil(debtorGroup)) {
        debtorGroup.numInvoices = debtorInvoices;
        debtorGroup.debtorAmount = debtorAmount;
      }

      // Reset counters and create new debtor group to save in data
      debtorAmount = debtorInvoices = 0;
      debtorGroup = { group: true, debtorname: debtor, groupId: `group-${groupId}`, selected: false, invoiceAmount: 0 };
      list.push(debtorGroup);
      prevDebtor = debtor;
    }

    // Set this after checking for debtor group changing
    debtorAmount += elem.invoiceAmount;
    debtorInvoices++;

    // Add the original row of data (invoice)
    elem.group = false;
    elem.groupId = `group-${groupId}`;
    elem.rowId = `invoice-${elem.invoiceNumber}`;
    elem.selected = false;
    elem.stripe = ++index % 2 == 0;

    // Set invoice Number if debtorBalanceVerification is true
    if (elem.debtorBalanceVerification) elem.invoiceNumber = "(balance)";

    list.push(elem);
  });

  // Set last debtorGroup
  debtorGroup.numInvoices = debtorInvoices;
  debtorGroup.debtorAmount = debtorAmount;

  return { list, amount };
};

const callGetDebtorNames = ({ rootState }, debtorName) =>
  new Promise(async resolve => {
    try {
      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];
      const params = { params: { debtorname: debtorName } };

      const { data } = await Axios.get(`/api/loan/${loan.id}/debtors/search`, params);
      resolve(data);
    } catch (err) {
      dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_GET_DEBTOR_NAMES, err));
    }
    resolve([]);
  });

const callSearchVerifications = async ({ commit, rootState, dispatch }, searchCriteria) => {
  try {
    commit(MU.SET_LOADING, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    const { data } = await Axios.get(`/api/client/${clientid}/loan/${loan.id}/invoice/verification`, {
      params: searchCriteria
    });

    commit(MU.SET_SEARCH, transformList(data));
  } catch (err) {
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_SEARCH_VERIFICATIONS, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callGetNumVerifications = async ({ commit, rootState, dispatch }) => {
  try {
    commit(MU.SET_LOADING, true);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    const { data } = await Axios.get(`/api/client/${clientid}/loan/${loan.id}/invoice/verification/status/count`);
    data.forEach(d => commit(MU.SET_STATUS_COUNT, { status: d.status, count: d.count }));
  } catch (err) {
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_GET_NUM_VERIFICATIONS, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const callGetVerificationsByStatus = async ({ commit, rootState, dispatch }, status) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);

      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];

      const { data } = await Axios.get(`/api/client/${clientid}/loan/${loan.id}/invoice/verification/status/${status}`);

      // TODO: Remove after date datepicker is fixed
      data.forEach(d => {
        d.requestedDate = d.requestedDate.replace(/T.*/, "");
      });

      const { list } = transformList(data);
      resolve(list);
    } catch (err) {
      dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_GET_VERIFICATIONS_BY_STATUS, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve([]);
  });

const callGetVerificationSummaryForLoan = ({ commit, rootState, dispatch }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);

      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];

      const { data } = await Axios.get(`/api/client/${clientid}/loan/${loan.id}/invoice/verification/summary`);
      resolve(data || {});
    } catch (err) {
      dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_GET_VERIFICATIONS_SUMMARY_FOR_LOAN, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve({});
  });

const callGetVerificationCriteria = ({ commit, rootState, dispatch }) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_LOADING, true);

      const { current: clientid } = rootState.borrower_loan.borrower;

      const { data } = await Axios.get(`/api/client/${clientid}/invoice/verification/criteria`);
      resolve(data || {});
    } catch (err) {
      dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_GET_VERIFICATIONS_CRITERIA, err));
    } finally {
      commit(MU.SET_LOADING, false);
    }
    resolve({});
  });

const callVerifyVerifications = async ({ commit, rootState, dispatch }, values) => {
  try {
    commit(MU.SET_ACTION, VERIFICATION_ACTIONS.REQUEST);

    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    await Axios.post(`/api/client/${clientid}/loan/${loan.id}/invoice/verification`, values);

    dispatch(AC.CALL_GET_NUM_VERIFICATIONS);

    // get type of verification from first element of values passed in to customize msg
    let msg = `${values.length} invoice verification(s) requested.`;
    if (values && values[0].debtorBalanceVerification) msg = "Debtor balance verification requested.";

    dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION, msg);
  } catch (err) {
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_VERIFY_VERIFICATIONS, err));
  } finally {
    commit(MU.SET_ACTION, null);
  }
};

const callUpdateVerificationsStatus = async ({ commit, rootState, dispatch }, values) => {
  try {
    const { current: clientid } = rootState.borrower_loan.borrower;
    const { loan } = rootState.tab.clients[clientid];

    // get status from first element of values passed in
    const status = head(values).status;
    let msg = "submitted";
    if (status) {
      commit(MU.SET_ACTION, status);

      switch (status) {
        case VERIFICATION_ACTIONS.REQUEST:
          msg = "requested";
          break;
        case VERIFICATION_ACTIONS.AUTHORIZE:
          msg = "requested to authorize";
          break;
        case VERIFICATION_ACTIONS.CLOSE:
          msg = "closed";
          break;
        case VERIFICATION_ACTIONS.CANCEL:
          msg = "canceled";
          break;
      }
    }

    await Axios.patch(`/api/client/${clientid}/loan/${loan.id}/invoice/verification/status`, values);

    dispatch(AC.CALL_GET_NUM_VERIFICATIONS);

    dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION, `${values.length} verification(s) ${msg}.`);
  } catch (err) {
    dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_UPDATE_VERIFICATIONS_STATUS, err));
  } finally {
    commit(MU.SET_ACTION, null);
  }
};

const callSaveVerifications = async ({ commit, rootState, dispatch }, values) =>
  new Promise(async resolve => {
    let success = true;
    try {
      const status = head(values).status;
      commit(MU.SET_ACTION, status);

      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];

      await Axios.patch(`/api/client/${clientid}/loan/${loan.id}/invoice/verification/summary`, values);

      dispatch(AC.CALL_GET_NUM_VERIFICATIONS);

      const msg = status === "c" ? "closed" : "saved";
      dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION, `${values.length} verification(s) ${msg}.`);
    } catch (err) {
      success = false;
      dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_SAVE_VERIFICATIONS, err));
    } finally {
      commit(MU.SET_ACTION, null);
    }

    resolve(success);
  });

const callSaveVerificationCriteria = ({ rootState, dispatch }, values) =>
  new Promise(async resolve => {
    let success = true;
    try {
      const { current: clientid } = rootState.borrower_loan.borrower;

      // If id exists we need to call update api otherwise call create api
      if (values.id) {
        await Axios.put(`/api/client/${clientid}/invoice/verification/criteria/${values.id}`, values);
      } else {
        // since creating need to also pass in clientid
        values.clientid = clientid;
        await Axios.post(`/api/client/${clientid}/invoice/verification/criteria`, values);
      }

      dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION);
    } catch (err) {
      success = false;
      dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_SAVE_VERIFICATIONS_CRITERIA, err));
    }

    resolve(success);
  });

const callUploadFile = async ({ commit, rootState, dispatch }, values) =>
  new Promise(async resolve => {
    try {
      commit(MU.SET_ACTION, VERIFICATION_ACTIONS.UPLOAD);

      const { current: clientid } = rootState.borrower_loan.borrower;
      const { loan } = rootState.tab.clients[clientid];

      const formData = new FormData();
      formData.append("clientid", clientid);
      formData.append("loanid", loan.id);
      formData.append("entityId", values.id);
      formData.append("comment", values.comment);
      formData.append("file", values.file);
      formData.append("typeFrequency", "D");

      const { data } = await Axios.post(
        `/api/client/${clientid}/loan/${loan.id}/invoice/verification/${values.id}`,
        formData
      );
      resolve(data);
    } catch (err) {
      dispatch(ACRoot.SHOW_ERROR_NOTIFICATION, logAndGetErrorMessage(AC.CALL_UPLOAD_FILE, err));
    } finally {
      commit(MU.SET_ACTION, null);
    }
    resolve([]);
  });

export const actions = {
  [AC.CALL_GET_DEBTOR_NAMES]: callGetDebtorNames,
  [AC.CALL_SEARCH_VERIFICATIONS]: callSearchVerifications,
  [AC.CALL_GET_NUM_VERIFICATIONS]: callGetNumVerifications,
  [AC.CALL_GET_VERIFICATIONS_BY_STATUS]: callGetVerificationsByStatus,
  [AC.CALL_GET_VERIFICATIONS_SUMMARY_FOR_LOAN]: callGetVerificationSummaryForLoan,
  [AC.CALL_GET_VERIFICATIONS_CRITERIA]: callGetVerificationCriteria,
  [AC.CALL_VERIFY_VERIFICATIONS]: callVerifyVerifications,
  [AC.CALL_UPDATE_VERIFICATIONS_STATUS]: callUpdateVerificationsStatus,
  [AC.CALL_SAVE_VERIFICATIONS]: callSaveVerifications,
  [AC.CALL_SAVE_VERIFICATIONS_CRITERIA]: callSaveVerificationCriteria,
  [AC.CALL_UPLOAD_FILE]: callUploadFile
};
