import Vue from "vue";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_FEES]: function (state, value) {
    Vue.set(state, "list", value);
  },
  [MU.SET_LOADING]: function (state, value) {
    Vue.set(state, "loading", value);
  },
  [MU.SET_FEES_APPLIED] : function (state, data ) {
    Vue.set(state, "applied", data);
  },
  [MU.SET_ERROR]: function (state, value) {
    Vue.set(state, "error", value);
  },
};
