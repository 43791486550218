import Vue from "vue";
import { assoc, mergeAll } from "ramda";
import { mutations as MU } from "./constants";

export const mutations = {
  [MU.SET_CLIENT]: function(state, client) {
    Vue.set(state, "clients", assoc(client.clientid, client, state.clients));
  },
  [MU.SET_CURRENT_TAB_BY_CLIENT]: function(state, current) {
    Vue.set(state, "current", current);
  },
  [MU.SAVE_CLIENTS]: function(state, clients) {
    Vue.set(state, "clients", clients);
  },
  [MU.SET_SSE_INSTANCE]: function(state, sse) {
    Vue.set(state, "sse", sse);
  },
  [MU.SET_CLIENT_INFO]: function(state, client) {
    const current = state.clients[client.clientid];
    const updated = mergeAll([current, client]);

    Vue.set(state, "clients", assoc(client.clientid, updated, state.clients));
  }
};
