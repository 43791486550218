import { appendName } from "@/store/utils";

import { APPEND_BORROWER_DETAIL_MODULE_NAME } from "../constants";

const appendToLoading = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LOADING"));
const appendToError = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_ERROR"));
const appendToList = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("SET_LIST"));
const appendToClear = appendName(APPEND_BORROWER_DETAIL_MODULE_NAME("FULL_CLEAN_STATE"));

export const actions = {
  CALL_GET_FIELD_EXAM: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_FIELD_EXAM"),
  CREATE_FIELD_EXAM: APPEND_BORROWER_DETAIL_MODULE_NAME("CREATE_FIELD_EXAM"),
  UPDATE_FIELD_EXAM: APPEND_BORROWER_DETAIL_MODULE_NAME("UPDATE_FIELD_EXAM"),
  SAVE_FIELD_EXAM_CRITERIA: APPEND_BORROWER_DETAIL_MODULE_NAME("SAVE_FIELD_EXAM_CRITERIA"),
  CALL_GET_FIELD_EXAM_CRITERIA: APPEND_BORROWER_DETAIL_MODULE_NAME("CALL_GET_FIELD_EXAM_CRITERIA")
};

export const mutations = {
  SET_LOADING_FIELD_EXAM: appendToLoading("FIELD_EXAM"),
  SET_ERROR_FIELD_EXAM: appendToError("FIELD_EXAM"),
  SET_LIST_FIELD_EXAM: appendToList("FIELD_EXAM"),
  SET_LOADING_FIELD_EXAM_CRITERIA: appendToLoading("FIELD_EXAM_CRITERIA"),
  SET_ERROR_FIELD_EXAM_CRITERIA: appendToError("FIELD_EXAM_CRITERIA"),
  SET_LIST_FIELD_EXAM_CRITERIA: appendToList("FIELD_EXAM_CRITERIA"),
  //> clean
  FULL_CLEAR_STATE: appendToClear("FIELD_EXAM")
};

export const DEFAULT_STATE = {
  loading: { fieldExam: false, criteria: false },
  error: { fieldExam: null, criteria: null },
  list: { fieldExam: [], criteria: {} }
};
