import Axios from "axios";
import { omit } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { actions as ACRoot } from "@/store/root/constants";
import { logAndGetErrorMessage } from "@/store/utils";
import { isNilOrEmpty } from "@/utils/index";

const callGetAllLoans = ({ commit }) =>
  new Promise(async resolve => {
    let responseData = [];
    try {
      commit(MU.SET_LOADING_LOANS, true);
      const { data } = await Axios.get("/api/loans");
      responseData = data;
    } catch (err) {
      commit(MU.SET_ERROR_LOANS, logAndGetErrorMessage(AC.CALL_GET_ALL_LOANS, err));
    } finally {
      commit(MU.SET_LOADING_LOANS, false);
    }
    resolve(responseData);
  });

const callGetLedgerEntryData = async ({ commit }) => {
  let response = [];
  try {
    commit(MU.SET_LOADING_LEDGER_ENTRY, true);
    const { data } = await Axios.get("/api/gladjustment");
    const ommitProps = omit(["action", "approvedByDate", "enteredByDate", "approvedByUserid", "enteredByUserid"]);
    response = data.map(ommitProps);
  } catch (err) {
    commit(MU.SET_ERROR_LEDGER_ENTRY, logAndGetErrorMessage(AC.CALL_GET_LEDGER_ENTRY_DATA, err));
  } finally {
    commit(MU.SET_LOADING_LEDGER_ENTRY, false);
  }
  return new Promise(resolve => resolve(response));
};

const saveLedgerEntryData = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_LEDGER_ENTRY, true);
    const unProcessedList = formData.filter(f => isNilOrEmpty(f.processed));
    await Axios.post("/api/gladjustment", JSON.stringify(unProcessedList));
    dispatch(ACRoot.SHOW_SUCCESS_NOTIFICATION);
  } catch (err) {
    commit(MU.SET_ERROR_LEDGER_ENTRY, logAndGetErrorMessage(AC.SAVE_LEDGER_ENTRY_DATA, err));
  } finally {
    commit(MU.SET_LOADING_LEDGER_ENTRY, false);
  }
};

export const actions = {
  [AC.CALL_GET_ALL_LOANS]: callGetAllLoans,
  [AC.CALL_GET_LEDGER_ENTRY_DATA]: callGetLedgerEntryData,
  [AC.SAVE_LEDGER_ENTRY_DATA]: saveLedgerEntryData
};
