import Axios from "axios";
import { assoc } from "ramda";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetPortfolioReviewItems = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/PortfolioReviewItem`);
    commit(MU.SET_LIST_PORTFOLIO_REVIEW_ITEMS, data);
  } catch (err) {
    commit(MU.SET_ERROR_PORTFOLIO_REVIEW_ITEMS, logAndGetErrorMessage(AC.CALL_GET_PORTFOLIO_REVIEW_ITEMS, err));
  } finally {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, false);
  }
};

const callGetMonitoringPoints = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_MONITORING_POINTS, true);
    const { data } = await Axios.get(`/api/borrower/helper/${clientid}/MonitoringPoint`);
    commit(MU.SET_LIST_MONITORING_POINTS, data);
  } catch (err) {
    commit(MU.SET_ERROR_MONITORING_POINTS, logAndGetErrorMessage(AC.CALL_GET_MONITORING_POINTS, err));
  } finally {
    commit(MU.SET_LOADING_MONITORING_POINTS, false);
  }
};

const createPortfolioReviewItem = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, true);
    await Axios.post(`/api/borrower/helper/${clientid}/PortfolioReviewItem`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_PORTFOLIO_REVIEW_ITEMS);
  } catch (err) {
    commit(MU.SET_ERROR_PORTFOLIO_REVIEW_ITEMS, logAndGetErrorMessage(AC.CREATE_PORTFOLIO_REVIEW_ITEM, err));
  } finally {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, false);
  }
};

const createMonitoringPoint = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_MONITORING_POINTS, true);
    await Axios.post(`/api/borrower/helper/${clientid}/MonitoringPoint`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_MONITORING_POINTS);
  } catch (err) {
    commit(MU.SET_ERROR_MONITORING_POINTS, logAndGetErrorMessage(AC.CREATE_MONITORING_POINT, err));
  } finally {
    commit(MU.SET_LOADING_MONITORING_POINTS, false);
  }
};

const updatePortfolioReviewItem = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/PortfolioReviewItem`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_PORTFOLIO_REVIEW_ITEMS);
  } catch (err) {
    commit(MU.SET_ERROR_PORTFOLIO_REVIEW_ITEMS, logAndGetErrorMessage(AC.UPDATE_PORTFOLIO_REVIEW_ITEM, err));
  } finally {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, false);
  }
};

const updateMonitoringPoint = async ({ commit, dispatch, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING_MONITORING_POINTS, true);
    await Axios.put(`/api/borrower/helper/${formData.id}/MonitoringPoint`, assoc("clientid", clientid, formData));
    dispatch(AC.CALL_GET_MONITORING_POINTS);
  } catch (err) {
    commit(MU.SET_ERROR_MONITORING_POINTS, logAndGetErrorMessage(AC.UPDATE_MONITORING_POINT, err));
  } finally {
    commit(MU.SET_LOADING_MONITORING_POINTS, false);
  }
};

const deletePortfolioReviewItem = async ({ commit, dispatch, rootState }, formData) => {
  try {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, true);
    await Axios.delete(`/api/borrower/helper/${formData.id}/PortfolioReviewItem`);
    dispatch(AC.CALL_GET_PORTFOLIO_REVIEW_ITEMS);
  } catch (err) {
    commit(MU.SET_ERROR_PORTFOLIO_REVIEW_ITEMS, logAndGetErrorMessage(AC.DELETE_PORTFOLIO_REVIEW_ITEM, err));
  } finally {
    commit(MU.SET_LOADING_PORTFOLIO_REVIEW_ITEMS, false);
  }
};

const deleteMonitoringPoint = async ({ commit, dispatch, rootState }, formData) => {
  try {
    commit(MU.SET_LOADING_MONITORING_POINTS, true);
    await Axios.delete(`/api/borrower/helper/${formData.id}/MonitoringPoint`);
    dispatch(AC.CALL_GET_MONITORING_POINTS);
  } catch (err) {
    commit(MU.SET_ERROR_MONITORING_POINTS, logAndGetErrorMessage(AC.DELETE_MONITORING_POINT, err));
  } finally {
    commit(MU.SET_LOADING_MONITORING_POINTS, false);
  }
};

export const actions = {
  [AC.CALL_GET_PORTFOLIO_REVIEW_ITEMS]: callGetPortfolioReviewItems,
  [AC.CALL_GET_MONITORING_POINTS]: callGetMonitoringPoints,
  [AC.CREATE_PORTFOLIO_REVIEW_ITEM]: createPortfolioReviewItem,
  [AC.CREATE_MONITORING_POINT]: createMonitoringPoint,
  [AC.UPDATE_PORTFOLIO_REVIEW_ITEM]: updatePortfolioReviewItem,
  [AC.UPDATE_MONITORING_POINT]: updateMonitoringPoint,
  [AC.DELETE_PORTFOLIO_REVIEW_ITEM]: deletePortfolioReviewItem,
  [AC.DELETE_MONITORING_POINT]: deleteMonitoringPoint
};
