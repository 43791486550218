import { actions } from "./actions";
import { mutations } from "./mutations";

export const NotificationModule = {
  state: {
    items: {} //> Item = { id: String|Number: {id: String|Number, title: String, message: String, success: Boolean} }
  },
  actions,
  getters: {},
  mutations
};
