import {
  prop,
  compose,
  equals,
  head,
  split,
  last,
  filter,
  isNil,
  not,
  find,
  sort,
  toLower,
  replace,
  reduceBy,
  zipObj,
  mergeAll,
  __
} from "ramda";

import { sortArray, concatValues } from "../../utils";

import { getters as GE, AUTHORITY_SECTION } from "./constants";
import { getters as GEDoc } from "../document/constants";

//@krojas, private functions, use ramda curry instead of doing it manually
const filterAuthBySection = section => ({ shortDescription }) => {
  if (!shortDescription) return false;
  const isSection = equals(section);
  const first = compose(head, split("_"));
  return isSection(first(shortDescription));
};

const getValueForAuthPermission = data => (permission, { section, shortDescription }) => {
  if (!section || !shortDescription) return false;
  const isPermission = toLower(concatValues([section, "_", shortDescription]));
  const equalPermission = equals(toLower(permission));
  const endWord = compose(toLower, last, split("_"));

  const permissions = filter(compose(equals(isPermission), toLower, prop("shortDescription")));
  const findPermission = find(compose(equalPermission, endWord, toLower, prop("authority")));

  return compose(not, isNil, findPermission, permissions)(data);
};

const compareLocaleStrings = (a, b) => a.localeCompare(b);

// exposed getters
const getGroupsName = ({ list }) => sort(compareLocaleStrings, list.group.map(prop("groupName")));

// @krojas, take a look later to add more ramda fns to the filters & maps logic for getting permission for sections
const getAuthoritiesForPlatformSection = ({ list, current }) => {
  if (!current.group) return [];
  const auths = current.group.authorities.filter(filterAuthBySection(AUTHORITY_SECTION.platform.id));
  const hasPermission = getValueForAuthPermission(auths);
  const isPlatform = equals(AUTHORITY_SECTION.platform.id);

  const platform = list.authority
    .filter(({ id }) => isPlatform(id.section))
    .map(({ id, longDescription }, idx) => ({
      id: idx,
      name: longDescription,
      section: id.section,
      shortDescription: id.shortDescription,
      create: hasPermission("CREATE", id),
      edit: hasPermission("EDIT", id),
      view: hasPermission("VIEW", id),
      delete: hasPermission("DELETE", id),
      none: hasPermission("NONE", id)
    }));

  return sortArray(platform, "name");
};

const getAuthoritiesForReportSection = ({ list, current }) => {
  if (!current.group) return [];
  const auths = current.group.authorities
    .filter(filterAuthBySection(AUTHORITY_SECTION.report.id))
    .map(a => a.authority);
  const isReport = equals(AUTHORITY_SECTION.report.id);

  const reports = list.authority
    .filter(({ id }) => isReport(id.section))
    .map(({ id, longDescription }, idx) => ({
      id: idx,
      name: longDescription,
      section: id.section,
      shortDescription: id.shortDescription,
      checked: auths.includes(id.shortDescription)
    }));

  return sortArray(reports, "name");
};

const getAuthoritiesForDocumentSection = ({ current }, getters) => {
  if (!current.group) return [];

  const removeDocPrefix = replace(/documents_/i, "");
  const removeNamePrefix = replace(__, "", __);
  const setTrue = k => zipObj([k], [true]);

  const auths = current.group.authorities
    .filter(filterAuthBySection(AUTHORITY_SECTION.document.id))
    .filter(a => !!a.shortDescription);

  const getPermissionsByAuth = (acc, { authority, shortDescription }) => {
    const name = compose(removeDocPrefix, toLower)(shortDescription);
    const permission = compose(removeNamePrefix(`${name}_`), toLower)(authority);

    return acc.concat(setTrue(permission));
  };

  const groupByShortDescription = ({ shortDescription }) =>
    !shortDescription ? "no-defined" : compose(removeDocPrefix, toLower)(shortDescription);

  const authPermissions = reduceBy(getPermissionsByAuth, [], groupByShortDescription, auths);

  const definitions = getters[GEDoc.GET_DOCUMENT_DEFINITION_FOR_TABLE];
  const documents = definitions.map((de, idx) => {
    const common = {
      id: idx,
      name: de.shortDescription,
      section: AUTHORITY_SECTION.document.id,
      shortDescription: de.document,
      upload: false,
      download: false,
      process: false,
      delete: false
    };

    const isDisabled = compose(not, equals(true));
    const current = authPermissions[toLower(de.document)];

    return {
      ...common,
      ...(!isNil(current) ? mergeAll(current) : null),
      disable: {
        upload: isDisabled(de.upload),
        download: isDisabled(de.download),
        process: isDisabled(de.process),
        delete: isDisabled(de.delete)
      }
    };
  });
  return sortArray(documents, "name");
};

export const getters = {
  [GE.GET_GROUPS_NAMES]: getGroupsName,
  [GE.GET_AUTHORITIES_FOR_PLATFORM_SECTION]: getAuthoritiesForPlatformSection,
  [GE.GET_AUTHORITIES_FOR_REPORT_SECTION]: getAuthoritiesForReportSection,
  [GE.GET_AUTHORITIES_FOR_DOCUMENT_SECTION]: getAuthoritiesForDocumentSection
};
