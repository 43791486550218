import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { mutations as MUBorrower } from "../constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetBorrowerStatus = async ({ commit, rootState }) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING, true);
    const { data } = await Axios.get(`/api/borrower/client/${clientid}/status`);
    commit(MUBorrower.SET_IS_ACTIVE_BORROWER, data.status === "active");
    commit(MU.SET_DATA, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.CALL_GET_BORROWER_STATUS, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

const saveBorrowerStatus = async ({ commit, rootState }, formData) => {
  const clientid = rootState.borrower_loan.borrower.current;
  try {
    commit(MU.SET_LOADING, true);
    const status = formData.status ? "active" : "inactive";
    const data = {
      status,
      payoffDate: formData.payoffDate
    };
    await Axios.patch(`/api/borrower/client/${clientid}/status`, data);
    commit(MUBorrower.SET_IS_ACTIVE_BORROWER, formData.status);
    commit(MU.SET_DATA, data);
  } catch (err) {
    commit(MU.SET_ERROR, logAndGetErrorMessage(AC.SAVE_BORROWER_STATUS, err));
  } finally {
    commit(MU.SET_LOADING, false);
  }
};

export const actions = {
  [AC.CALL_GET_BORROWER_STATUS]: callGetBorrowerStatus,
  [AC.SAVE_BORROWER_STATUS]: saveBorrowerStatus
};
