import { appendName } from "../utils";

const append = appendName("INELIGIBLE");

export const actions = {
  CALL_SAVE_ALL_CHANGES: append("CALL_SAVE_ALL_CHANGES"),
  CALL_GET_NAVIGATION_INFORMATION: append("CALL_GET_NAVIGATION_INFORMATION"),
  CALL_GET_DEBTOR_INFORMATION: append("CALL_GET_DEBTOR_INFORMATION"),
  CALL_GET_ADJUSTMENT_INFORMATION: append("CALL_GET_ADJUSTMENT_INFORMATION"),
  CALL_GET_MOST_RECENT_PERIOD: append("CALL_GET_MOST_RECENT_PERIOD")
};

export const mutations = {
  SET_LOADING_NAVIGATION: append("SET_LOADING_NAVIGATION"),
  SET_LOADING_DEBTOR: append("SET_LOADING_DEBTOR"),
  SET_LOADING_ADJUSTMENT: append("SET_LOADING_ADJUSTMENT"),
  SET_ERROR_NAVIGATION: append("SET_ERROR_NAVIGATION"),
  SET_ERROR_DEBTOR: append("SET_ERROR_DEBTOR"),
  SET_ERROR_ADJUSTMENT: append("SET_ERROR_ADJUSTMENT"),
  SET_CHANGE_NEW: append("SET_CHANGE_NEW"),
  SET_CHANGE_EDIT: append("SET_CHANGE_EDIT"),
  SET_LOANID: append("SET_LOANID"),
  SET_NAVIGATION_LIST: append("SET_NAVIGATION_LIST"),
  SET_MANUAL_ADJUSTMENTS_TOTAL: append("SET_MANUAL_ADJUSTMENTS_TOTAL"),
  SET_DEBTOR_LIST: append("SET_DEBTOR_LIST"),
  SET_CURRENT_CODE: append("SET_CURRENT_CODE"),
  ADD_CHANGE_NEW: append("ADD_CHANGE_NEW"),
  ADD_CHANGE_EDIT: append("ADD_CHANGE_EDIT"),
  ADD_CHANGE_DELETE: append("ADD_CHANGE_DELETE"),
  CLEAN_ALL_CHANGES: append("CLEAN_ALL_CHANGES")
};
