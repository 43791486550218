import { appendName } from "../utils";

const append = appendName("COMMENTS");

export const actions = {
  CALL_GET_NOTE_INFORMATION_BY_SECTION: append("CALL_GET_NOTE_INFORMATION_BY_SECTION"),
  CALL_SAVE_NOTE_INFORMATION_BY_SECTION: append("CALL_SAVE_NOTE_INFORMATION_BY_SECTION"),
  CALL_GET_LATEST_NOTE: append("CALL_GET_LATEST_NOTE"),
  CALL_GET_HEALTHCARE_COMMENTS: append("CALL_GET_HEALTHCARE_COMMENTS")
};

export const mutations = {
  SET_LOADING_MODAL: append("SET_LOADING_MODAL"),
  SET_LOADING_LIST: append("SET_LOADING_LIST"),
  SET_ERROR_MODAL: append("SET_ERROR_MODAL"),
  SET_ERROR_LIST: append("SET_ERROR_LIST"),
  SET_LIST: append("SET_LIST"),
  SET_NOTE_SECTION: append("SET_NOTE_SECTION"),
  SET_NOTE_SHOW_MODAL: append("SET_NOTE_SHOW_MODAL")
};

export const COMMENT_ENTITY = {
  BORROWER: { name: "Borrower", id: "borrowers" },
  LOAN: { name: "Loan", id: "loan_master" }
};
