import Axios from "axios";

import { actions as AC, mutations as MU } from "./constants";
import { logAndGetErrorMessage } from "@/store/utils";

const callGetHolidays = async ({ commit }) => {
  try {
    commit(MU.SET_LOADING_GRID, true);
    const { data } = await Axios.get("/api/holidays");
    await getCountryCodes({ commit });
    commit(MU.SET_GRID_DATA, data);
  } catch (err) {
    commit(MU.SET_ERROR_GRID, logAndGetErrorMessage(AC.CALL_GET_HOLIDAYS, err));
  } finally {
    commit(MU.SET_LOADING_GRID, false);
  }
};

const createHoliday = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.post("/api/holiday", formData);
    dispatch(AC.CALL_GET_HOLIDAYS);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.CREATE_HOLIDAY, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const updateHoliday = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.put(`/api/holiday/${formData.objid}`, formData);
    dispatch(AC.CALL_GET_HOLIDAYS);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.UPDATE_HOLIDAY, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const deleteHoliday = async ({ commit, dispatch }, formData) => {
  try {
    commit(MU.SET_LOADING_MODAL, true);
    await Axios.delete(`/api/holiday/${formData.objid}`);
    dispatch(AC.CALL_GET_HOLIDAYS);
  } catch (err) {
    commit(MU.SET_ERROR_MODAL, logAndGetErrorMessage(AC.DELETE_HOLIDAY, err));
  } finally {
    commit(MU.SET_LOADING_MODAL, false);
  }
};

const getCountryCodes = async ({ commit }) => {
  try {
    const { data } = await Axios.get(`/api/countrycodes`);
    commit(MU.SET_COUNTRY_CODES, data);
  } catch (err) {
    commit(MU.SET_LOADING_GRID, logAndGetErrorMessage("getCountryCodes", err));
  }
};

export const actions = {
  [AC.CALL_GET_HOLIDAYS]: callGetHolidays,
  [AC.CREATE_HOLIDAY]: createHoliday,
  [AC.UPDATE_HOLIDAY]: updateHoliday,
  [AC.DELETE_HOLIDAY]: deleteHoliday
};
